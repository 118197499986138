import React from "react";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Card, CardHeader, CardTitle, CardSubtitle } from "@cdk-uip/react-card";
import { Icon } from "@cdk-uip/react-icon";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import ExportEntities from "./ExportEntities";

class ExportEntitiesManagement extends React.Component {
  render() {
    return (
      <React.Fragment>
        <LayoutGrid className="s-content-route--entity-management c-layout-section__dash-grid entity-management">
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <CardHeader>
                  <CardTitle large>
                    <Icon>settings</Icon> Export Organizations
                  </CardTitle>
                  <CardSubtitle>
                    You'll be able to do the following things here:
                  </CardSubtitle>
                  <CardSubtitle>#1) Export child organizations.</CardSubtitle>
                </CardHeader>
              </Card>
            </Elevation>
          </LayoutGridCell>
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <ExportEntities />
          </LayoutGridCell>
        </LayoutGrid>
      </React.Fragment>
    );
  }
}

ExportEntitiesManagement = connect()(
  WithNavigationToHome(ExportEntitiesManagement)
);
class ExportEntitiesManagementContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <ExportEntitiesManagement {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(ExportEntitiesManagementContainer);
