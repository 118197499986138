import PendingUserNote from "../components/PendingUserNote";
import { connect } from "react-redux";
import { actions } from "../reducers/pendingUsers";

export default connect(
  // mapStateToProps
  state => ({
    pendingUsers: state.pendingUsers.pendingUsers,
    error: state.pendingUsers.error,
    updateStoredNoteReducer: state.pendingUsers.updateStoredNoteReducer
  }),
  // Inject all actions into the component
  actions
)(PendingUserNote);
