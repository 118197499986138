import React from "react";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Card, CardHeader, CardTitle, CardSubtitle } from "@cdk-uip/react-card";
import { Dialog } from "@cdk-uip/react-dialog";
import { FormattedMessage } from "react-intl";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import SearchAndLinkEntityWithUser from "./SearchAndLinkEntityWithUser";
import { Button } from "@cdk-uip/react-button";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import AssociatedEntitiesContainer from "./AssociatedEntitiesContainer";
import types from "./types";
import { connect } from "react-redux";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";
import { Icon } from "@cdk-uip/react-icon";
import SuspendUserDialog from "../ApprovedUsers/SuspendUserDialog";
import UnsuspendUserDialog from "./UnsuspendUserDialog";

class UserProfile extends React.Component {
  state = {
    openEntitySearch: false,
    isEmailSent: false,
    isUserApproved: false,
    showSuspendUserDialog: false,
    isUserSuspended: false,
    showUnsuspendUserDialog: false,
    isUserUnsuspended: false,
    snackbarMessage: ""
  };

  componentDidMount() {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getUserDetails(this.props.match.params.userEmail, accessToken);
  }

  openSuspendUserDialog = ({ userId, name, email }) => {
    this.setState({
      showSuspendUserDialog: true,
      userToBeSuspended: name,
      userId: userId,
      email: email
    });
  };

  closeSuspendUserDialog = () => {
    this.setState({
      showSuspendUserDialog: false
    });
  };

  suspendUser = (userId, email) => {
    const {
      auth: { accessToken }
    } = this.props;

    this.props.suspendUser({ userId, email, accessToken });
  };

  openUnsuspendUserDialog = ({ userId, name, email }) => {
    this.setState({
      showUnsuspendUserDialog: true,
      userToBeUnsuspended: name,
      userId: userId,
      email: email
    });
  };

  closeUnsuspendUserDialog = () => {
    this.setState({
      showUnsuspendUserDialog: false
    });
  };

  unsuspendUser = (userId, email) => {
    const {
      auth: { accessToken }
    } = this.props;

    this.props.unsuspendUser({ userId, email, accessToken });
  };

  resendEmail = email => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.resendEmailToSignedUpUsers({ email, accessToken });
  };

  fetchLinkedEntities = uid => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchLinkedEntities({ uid, accessToken });
    this.setState({ uid: uid });
  };

  onShowEntitySearch = userObj => {
    this.props.dispatch({
      type: types.RESET_IS_ADDED
    });
    this.setState({
      openEntitySearch: true,
      uid: userObj.id,
      selectedUserObj: userObj
    });
  };

  onCloseEntitySearch = () => {
    this.setState({ openEntitySearch: false });
  };

  onApproveUser = ({ uid, email }) => {
    const {
      getUserDetails,
      auth: { accessToken }
    } = this.props;
    this.props
      .approveUser({ uid, status: true, accessToken })
      .then(function(res) {
        if (res.response.code === 200) {
          getUserDetails(email, accessToken);
        }
      });
  };

  setColor = color => {
    return (
      <Icon style={{ color: color }} className={"user-status-color"}>
        lens
      </Icon>
    );
  };

  render() {
    const userObj = this.props.userProfile.userDetails;
    const { isAdding, isAdded, entityId } = this.props.userEntities;

    if (this.props.userProfile.isFetching) {
      return (
        <div className="loading-container">
          <h4>Loading User Details</h4>
          <CircularProgress />
        </div>
      );
    }

    if (this.props.adminUpdateStatus.isFetching) {
      return (
        <div className="loading-container">
          <h4>Approving User</h4>
          <CircularProgress />
        </div>
      );
    }

    if (this.props.sendEmailToSignedUpUsers.isSending) {
      return (
        <div className="loading-container">
          <h4>Sending mail to User</h4>
          <CircularProgress />
        </div>
      );
    }

    return (
      <React.Fragment>
        {userObj && userObj.status === "ACTIVE" ? (
          <Card className="signed-up-users-page-heading-card">
            <CardHeader>
              <CardTitle className="user-needs-to-be-approved-heading">
                User Account is Active
              </CardTitle>
              <CardSubtitle>
                User can be linked to new organisations and removed from already
                associated organisations now.
              </CardSubtitle>
            </CardHeader>
          </Card>
        ) : userObj && userObj.profile.isEmailValidated === false ? (
          <Card className="signed-up-users-page-heading-card">
            <CardHeader>
              <CardTitle className="user-needs-to-be-approved-heading">
                User Needs To Verify Email
              </CardTitle>
              <CardSubtitle>
                Only users whose email has been verified can be approved.
              </CardSubtitle>
            </CardHeader>
          </Card>
        ) : (
          <Card className="signed-up-users-page-heading-card">
            <CardHeader>
              <CardTitle className="user-needs-to-be-approved-heading">
                User Account Needs To Be Approved First
              </CardTitle>
              <CardSubtitle>
                Only active users can be linked to an Organisation
              </CardSubtitle>
            </CardHeader>
          </Card>
        )}

        {userObj && (
          <Card className="c-card-pending-users" key={userObj.id}>
            <LayoutGrid nested className={"c-layout-section__home-grid"}>
              <LayoutGridCell span={8}>
                <CardHeader>
                  <CardTitle large>
                    <FormattedMessage
                      id="Users.username"
                      defaultMessage="{username}"
                      values={{
                        username:
                          userObj.profile.firstName +
                          " " +
                          userObj.profile.lastName
                      }}
                    />
                    <div
                      style={{ display: "inline-block" }}
                      className={"user-status"}
                    >
                      {userObj && userObj.status && userObj.status === "ACTIVE"
                        ? this.setColor("#1e8700")
                        : userObj.profile.isEmailValidated === false
                        ? this.setColor("#BDBDBD")
                        : this.setColor("#F3C118")}
                      {userObj.status}
                    </div>
                  </CardTitle>
                  <CardSubtitle>
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-email" />
                    <FormattedMessage
                      id="Users.email"
                      defaultMessage="{email}"
                      values={{ email: userObj.profile.email }}
                    />
                  </CardSubtitle>
                  <React.Fragment>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-account_circle" />
                      {userObj.id}
                    </CardSubtitle>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-home" />
                      {userObj.profile.streetAddress}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.profile.city}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.profile.state}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.profile.zipCode}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.profile.countryCode}
                    </CardSubtitle>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-keyboard" />
                      {`Created : ${new Date(userObj.created)}`}
                    </CardSubtitle>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-computer" />
                      {userObj.lastLogin
                        ? `Last Login : ${new Date(userObj.lastLogin)}`
                        : "Last Login : N/A"}
                    </CardSubtitle>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-computer" />
                      {userObj.lastUpdated
                        ? `Last Updated : ${new Date(userObj.lastUpdated)}`
                        : "Last Updated : N/A"}
                    </CardSubtitle>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-computer" />
                      {userObj.passwordChanged
                        ? `Password Changed : ${new Date(
                            userObj.passwordChanged
                          )}`
                        : "Password Changed : N/A"}
                    </CardSubtitle>
                  </React.Fragment>
                </CardHeader>
              </LayoutGridCell>
              <LayoutGridCell
                span={4}
                className="user-profile-actions-container"
              >
                {userObj && userObj.status && userObj.status === "ACTIVE" ? (
                  <div>
                    <Button
                      className="add-new-entity-button user-profile-actions"
                      outlined
                      onClick={() => {
                        this.fetchLinkedEntities(userObj.id);
                        this.onShowEntitySearch(userObj);
                      }}
                    >
                      Link user to Organisation
                    </Button>
                    <Button
                      className="add-new-entity-button user-profile-actions"
                      outlined
                      onClick={() => {
                        this.fetchLinkedEntities(userObj.id);
                      }}
                    >
                      Show associated Organisations
                    </Button>
                    <Button
                      className="add-new-entity-button user-profile-actions suspend-user-button"
                      outlined
                      onClick={() => {
                        this.openSuspendUserDialog({
                          userId: userObj.id,
                          name:
                            userObj.profile.firstName +
                            " " +
                            userObj.profile.lastName,
                          email: userObj.profile.email
                        });
                      }}
                    >
                      Suspend user
                    </Button>
                  </div>
                ) : userObj.profile.isEmailValidated === false ? (
                  <Button
                    className="add-new-entity-button user-profile-actions"
                    outlined
                    onClick={() => {
                      this.resendEmail(userObj.profile.email);
                      this.setState({ isEmailSent: true });
                    }}
                  >
                    Resend Email
                  </Button>
                ) : userObj.status === "SUSPENDED" ? (
                  <Button
                    className="add-new-entity-button user-profile-actions"
                    outlined
                    onClick={() => {
                      this.openUnsuspendUserDialog({
                        userId: userObj.id,
                        name:
                          userObj.profile.firstName +
                          " " +
                          userObj.profile.lastName,
                        email: userObj.profile.email
                      });
                    }}
                  >
                    Activate User
                  </Button>
                ) : (
                  <Button
                    className="add-new-entity-button user-profile-actions"
                    outlined
                    onClick={() => {
                      this.onApproveUser({
                        uid: userObj.id,
                        email: userObj.profile.email
                      });
                      this.setState({ isUserApproved: true });
                    }}
                  >
                    Approve User
                  </Button>
                )}
              </LayoutGridCell>
              <div>
                {this.props.sendEmailToSignedUpUsers.resendEmailMessage && (
                  <TemporarySnackbar
                    show={this.state.isEmailSent}
                    message={
                      this.props.sendEmailToSignedUpUsers.resendEmailMessage
                    }
                    timeout={5000}
                    actionText="Close"
                    onClose={() => this.setState({ isEmailSent: false })}
                    onAction={() => this.setState({ isEmailSent: false })}
                  />
                )}
                {this.props.adminUpdateStatus.userApproveMessage && (
                  <TemporarySnackbar
                    show={this.state.isUserApproved}
                    message={this.props.adminUpdateStatus.userApproveMessage}
                    timeout={5000}
                    actionText="Close"
                    onClose={() => this.setState({ isUserApproved: false })}
                    onAction={() => this.setState({ isUserApproved: false })}
                  />
                )}
              </div>
            </LayoutGrid>

            <AssociatedEntitiesContainer
              show={this.state.uid === userObj.id}
              uid={this.state.uid}
            />

            <Dialog
              open={
                this.state.openEntitySearch &&
                this.state.uid === userObj.id &&
                !isAdded
              }
              onCancel={this.onCloseEntitySearch}
            >
              <SearchAndLinkEntityWithUser
                onClick={selectedEntity => {
                  userObj.selectedEntity = selectedEntity;
                }}
                sId={userObj.id}
                uid={this.state.uid}
                addUserToEntity={this.props.addUserToEntity}
                isAdding={isAdding}
                entityId={entityId}
              />
            </Dialog>
          </Card>
        )}
        <SuspendUserDialog
          open={this.state.showSuspendUserDialog}
          userName={this.state.userToBeSuspended}
          userId={this.state.userId}
          email={this.state.email}
          onClose={this.closeSuspendUserDialog}
          suspendUser={this.suspendUser}
        />
        <UnsuspendUserDialog
          open={this.state.showUnsuspendUserDialog}
          userName={this.state.userToBeUnsuspended}
          userId={this.state.userId}
          email={this.state.email}
          onClose={this.closeUnsuspendUserDialog}
          unsuspendUser={this.unsuspendUser}
        />
        <TemporarySnackbar
          show={this.props.userProfile.isUserSuspended}
          message={this.props.userProfile.userSuspendedMessage}
          timeout={5000}
          onClose={() => this.setState({ isUserSuspended: false })}
          onAction={() => this.setState({ isUserSuspended: false })}
        />
        <TemporarySnackbar
          show={this.props.userProfile.isUserUnsuspended}
          message={this.props.userProfile.userUnsuspendedMessage}
          timeout={5000}
          onClose={() => this.setState({ isUserUnsuspended: false })}
          onAction={() => this.setState({ isUserUnsuspended: false })}
        />
      </React.Fragment>
    );
  }
}

UserProfile = WithNavigationToHome(connect()(UserProfile));

class UserProfileContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <UserProfile {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(UserProfileContainer);
