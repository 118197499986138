import types from "./types";

const initialState = {
  couponAssociations: [],
  fetchingCouponAssociations: false,
  fetchingCouponAssociationsError: null,
  fetchingCouponAssociationsErrorMessage: '',
  coupons: [],
  fetchingCoupons: false,
  fetchingCouponsError: null,
  dissociatedCoupon: null,
  isDissociatingCoupon: false,
  dissociatingCouponError: null,
  dissociatingCouponErrorMessage: '',
  isAssociatingCoupon: false,
  associateCouponResponse: null,
  associatingCouponError: null,
  associatingCouponErrorMessage: ''
};

const getErrorMessage = apierror => {
  if (apierror.hasOwnProperty("payload")) {
    if (apierror.payload.error) {
      return apierror.payload.error;
    } else if (typeof apierror.payload === "string") {
      return apierror.payload;
    } else if (apierror.payload.message) {
      return apierror.payload.message;
    }
  } else if (apierror.hasOwnProperty("message")) {
    return apierror.message;
  } else {
    return "Error";
  }
};

const reducerActions = {
  [types.GET_COUPON_ASSOCIATIONS_REQUEST]: state => ({
    ...state,
    couponAssociations: [],
    fetchingCouponAssociations: true,
    fetchingCouponAssociationsError: null,
    fetchingCouponAssociationsErrorMessage: ''
  }),
  [types.GET_COUPON_ASSOCIATIONS_RECEIVE]: (state, { response }) => ({
    ...state,
    couponAssociations: response,
    fetchingCouponAssociations: false,
    fetchingCouponAssociationsError: null,
    fetchingCouponAssociationsErrorMessage: ''
  }),
  [types.GET_COUPON_ASSOCIATIONS_ERROR]: (state, { apierror }) => ({
    ...state,
    couponAssociations: [],
    fetchingCouponAssociations: false,
    fetchingCouponAssociationsError: apierror,
    fetchingCouponAssociationsErrorMessage: getErrorMessage(apierror)
  }),
  [types.GET_COUPONS_REQUEST]: state => ({
    ...state,
    coupons: [],
    fetchingCoupons: true,
    fetchingCouponsError: null,
    fetchingCouponsErrorMessage: ''
  }),
  [types.GET_COUPONS_RECEIVE]: (state, { response }) => ({
    ...state,
    coupons: response,
    fetchingCoupons: false,
    fetchingCouponsError: null,
    fetchingCouponsErrorMessage: ''
  }),
  [types.GET_COUPONS_ERROR]: (state, { apierror }) => ({
    ...state,
    coupons: [],
    fetchingCoupons: false,
    fetchingCouponsError: apierror,
    fetchingCouponsErrorMessage: getErrorMessage(apierror)
  }),
  [types.DISSOCIATE_COUPON_REQUEST]: state => ({
    ...state,
    dissociatedCoupon: false,
    isDissociatingCoupon: true,
    dissociatingCouponError: null,
    dissociatingCouponErrorMessage: ''
  }),
  [types.DISSOCIATE_COUPON_RECEIVE]: (state) => ({
    ...state,
    dissociatedCoupon: true,
    isDissociatingCoupon: false,
    dissociatingCouponError: null,
    dissociatingCouponErrorMessage: ''
  }),
  [types.DISSOCIATE_COUPON_ERROR]: (state, { apierror }) => ({
    ...state,
    dissociatedCoupon: false,
    isDissociatingCoupon: true,
    dissociatingCouponError: apierror,
    dissociatingCouponErrorMessage: getErrorMessage(apierror)
  }),
  [types.ASSOCIATE_COUPON_REQUEST]: state => ({
    ...state,
    isAssociatingCoupon: true,
    associateCouponResponse: null,
    associatingCouponError: null,
    associatingCouponErrorMessage: ''
  }),
  [types.ASSOCIATE_COUPON_RECEIVE]: (state, { response }) => ({
    ...state,
    isAssociatingCoupon: false,
    associateCouponResponse: response,
    associatingCouponError: null,
    associatingCouponErrorMessage: ''
  }),
  [types.ASSOCIATE_COUPON_ERROR]: (state, { apierror }) => ({
    ...state,
    isAssociatingCoupon: true,
    associateCouponResponse: null,
    associatingCouponError: apierror,
    associatingCouponErrorMessage: getErrorMessage(apierror)
  }),
  [types.RESET_ASSOCIATION_STATE]: (state) => ({
    ...state,
    isAssociatingCoupon: false,
    associateCouponResponse: null,
    associatingCouponError: null,
    associatingCouponErrorMessage: ''
  })
};

export default function paymentCodesReducer(state = initialState, action) {
  return reducerActions[action.type]
    ? reducerActions[action.type](state, action)
    : state;
}
