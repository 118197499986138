import { useEffect, useRef, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  TableContainer,
  TableContent,
  TableHeader,
  TABLE_LAYOUT_OPTIONS,
  usePreventTableScrollingRef,
  IconDelete,
  IconButton,
} from "cdk-radial";
import classNames from "classnames";
import { EmptyStatesIllustration } from "cdk-radial";
import "./styles.css";

const CustomNoRowsOverlay = ({ message = "This org has no contracted Bundles" }) => (
  <div className="empty_no_rows_overlay">
    <EmptyStatesIllustration size={180} />
    <div className="empty_state_illustration_text">{message}</div>
  </div>
);

const EllipsisCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{value || ""}</div>
);
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date) 
    ? "" 
    : date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit"
      });
};

const DateCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{formatDate(value)}</div>
);

const DeleteActionButtonRenderer = ({ data }) => {
  const isDisabled = data?.fullBundle?.apis?.some((api) => api.hasIntegration);
  return (
    <IconButton icon={<IconDelete />} isDisabled={isDisabled} text="Button" />
  );
};

export default function ContractedBundlesGrid({ contractedBundles = [] }) {
  const [data, setData] = useState([]);
  const [contractedBundlesCount, setContractedBundlesCount] = useState(0);

  const gridRef = useRef();
  const tableContentRef = usePreventTableScrollingRef();

  // Memoize column definitions to avoid re-creation on every render
  const columns = useMemo(
    () => [
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "bundleName",
        headerName: "Bundle Name",
        minWidth: 350,
        sortable: true,
      },
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "numOfApis",
        headerName: "No of APIs",
        sortable: true,
        maxWidth: 120,
      },
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "createdBy",
        headerName: "Created by",
        minWidth: 300,
      },
      {
        cellRendererFramework: DateCellRenderer,
        field: "contractedOn",
        headerName: "Contracted on",
        minWidth: 300,
      },
      {
        cellRendererFramework: EllipsisCellRenderer,
        field: "visibility",
        headerName: "Visibility",
        minWidth: 200,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 20,
        cellRendererFramework: DeleteActionButtonRenderer,
      },
    ],
    []
  );

  useEffect(() => {
    const tableData = contractedBundles.map((bundle) => ({
      bundleName: bundle?.contractName ,
      numOfApis: bundle?.apis?.length,
      createdBy: bundle?.createdBy,
      contractedOn: bundle?.created,
      visibility: bundle?.visibility,
      fullBundle: bundle,
    }));

    setData(tableData);
    setContractedBundlesCount(contractedBundles.length);
  }, [contractedBundles]);

  // Memoize header properties to avoid unnecessary re-renders
  const headerProps = useMemo(
    () => ({
      dataTestId: `contracted-bundles-table-header`,
      headerTitle: (
        <div>
          Contracted Bundles
          {contractedBundlesCount > 0 ? ` (${contractedBundlesCount})` : ""}
        </div>
      ),
      hideBorder: false,
      hideTableHeader: false,
      hideTableTitle: false,
      id: `contracted-bundles-table-header`,
      isAlwaysExpanded: false,
      isSearchable: false,
      isPrintable: false,
      isDownloadable: false,
      overflowButtonProps: undefined,
    }),
    [contractedBundlesCount]
  );

  const handleGridReady = (gridParams) => {
    gridParams.api.sizeColumnsToFit();
    gridParams.api.refreshCells({ force: true });
    gridParams.api.showNoRowsOverlay();
  };

  return (
    <TableContainer
      id="ag-grid-container"
      className={classNames("contracted-bundles_grid", "ag-theme-balham")}
    >
      <TableHeader {...headerProps} />

      <TableContent
        data-testid="table-content"
        className="contracted-bundles_grid-content"
        ref={tableContentRef}
      >
        <AgGridReact
          columnDefs={columns}
          domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
          rowData={data}
          rowHeight={50}
          style={{ width: "100%" }}
          ref={gridRef}
          onGridReady={handleGridReady}
          suppressRowClickSelection={true}
          frameworkComponents={{
            customNoRowsOverlay: CustomNoRowsOverlay,
          }}
          noRowsOverlayComponent="customNoRowsOverlay"
        />
      </TableContent>
    </TableContainer>
  );
}
