import React, { Component } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";
import { TextArea } from "@cdk-uip/react-text-area";
import { Typography } from "@cdk-uip/react-typography";

export default class NamespaceDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      namespaces: "",
      errorMessage: ""
    };
  }

  componentDidMount() {
    const { entity } = this.props;
    this.setState({
      namespaces: (entity ? entity.namespaces : "") || "",
      errorMessage: ""
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { entity } = this.props;
    const prevId = prevProps.entity ? prevProps.entity.id : undefined;
    const curId = entity ? entity.id : undefined;

    if (prevId !== curId) {
      this.setState({
        namespaces: (entity ? entity.namespaces : "") || "",
        errorMessage: ""
      });
    }
  }

  handleChange = e => {
    this.setState({
      namespaces: e.target.value
    });
  };

  saveChanges = () => {
    this.setState({
      errorMessage: ""
    });
    this.props
      .saveChanges(this.props.entity, this.state.namespaces)
      .then(() => {
        this.props.onClose();
      })
      .catch(err => {
        console.error(err);
        this.setState({
          errorMessage: err.message || "An error occured."
        });
      });
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onCancel={onClose}>
        <DialogHeader>Manage NameSpaces</DialogHeader>
        <DialogBody>
          <TextArea
            fullWidth
            rows={4}
            className="manage-namespaces-text-area"
            label="NameSpaces"
            value={this.state.namespaces}
            onChange={this.handleChange}
          ></TextArea>
        </DialogBody>
        <DialogFooter>
          <Typography className="manage-namespaces-error">
            {this.state.errorMessage}
          </Typography>
          <DialogFooterButton onClick={this.saveChanges}>
            Save
          </DialogFooterButton>
          <DialogFooterButton onClick={onClose}>Cancel</DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}
