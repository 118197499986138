import { useEffect, useRef, useState } from "react";
import {
  IconClose,
  ICON_SIZE,
  Card,
  Input,
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  Popup,
  ListWrapper,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSubtext,
  LIST_SIZES,
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS
} from "cdk-radial";
import { withAuth } from "@cdk-prod/fortellis-auth-context";

import "./style.css";

import withNavigationToHome from "../components/WithNavigationToHome";
import LastTransactionsTable from "./LastTransactionsTable";
import { extractApiErrorMessage } from "./utils";

function LastTransactions({
  auth,
  setReportOrg,
  searchForOrgs,
  getLastTransactionsReport,
  lastTransactions: {
    reportOrg,
    orgResults,
    fetchingOrgs,
    fetchingOrgsError,
    transactionReportData,
    fetchingTransactionReport,
    fetchingTransactionReportError
  }
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);
  const [orgInputValue, setOrgInputValue] = useState("");
  const [orgInputError, setOrgInputError] = useState("");
  const [errorToastMessage, setErrorToastMessage] = useState("");

  const searchInputRef = useRef();
  const searchLabelRef = useRef();

  useEffect(() => {
    if (!firstSearch && !fetchingOrgs) {
      if (fetchingOrgsError) {
        const message = extractApiErrorMessage(fetchingOrgsError);
        setOrgInputError(`Failed to search for organizations: ${message}`);
      } else if (!orgResults.length) {
        setOrgInputError("No results");
      } else {
        setOrgInputError("");
      }
    } else {
      setOrgInputError("");
    }
  }, [firstSearch, orgResults, fetchingOrgs, fetchingOrgsError]);

  useEffect(() => {
    if (!fetchingTransactionReport && fetchingTransactionReportError) {
      const message = extractApiErrorMessage(fetchingTransactionReportError);
      setErrorToastMessage(message);
    } else {
      setErrorToastMessage("");
    }
  }, [fetchingTransactionReport, fetchingTransactionReportError]);

  const onPerformOrgSearch = e => {
    e.preventDefault();
    setFirstSearch(false);
    setShowMenu(true);
    setOrgInputError("");
    searchForOrgs({
      accessToken: auth.accessToken,
      query: orgInputValue
    });
  };

  const onOrgInputClear = () => {
    setOrgInputValue("");
    setFirstSearch(true);
    setShowMenu(false);
    setOrgInputError("");
  };

  const onOrgResultSelected = org => {
    setOrgInputValue(org.name);
    setShowMenu(false);
    setReportOrg(org);
    getLastTransactionsReport({
      accessToken: auth.accessToken,
      orgId: org.id
    });
  };

  return (
    <>
      <Card className="ltx-wrapper">
        <div className="ltx-search-org-wrapper">
          <form className="ltx-search-org" onSubmit={onPerformOrgSearch}>
            <Input
              ref={searchInputRef}
              labelRef={searchLabelRef}
              id="ltx-search-org-input"
              name="ltx-search-org-input"
              label="Dealer"
              placeholder="Search for an dealer by name or enter an org ID"
              errorMessage={orgInputError}
              value={orgInputValue}
              onChange={e => setOrgInputValue(e.target.value)}
              onInputClear={onOrgInputClear}
              hideLabel={true}
              hasClearButton={true}
              enableCustomValidation={true}
              hasError={!!orgInputError}
            />
            <div className="ltx-search-org-button">
              <Button
                text="Search"
                type="submit"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTON_SIZES.LARGE}
                isLoading={fetchingOrgs}
                isDisabled={fetchingOrgs}
              />
            </div>
          </form>
          <div>
            <div className="ltx-search-org-results-wrapper">
              <Popup
                className="ltx-search-org-results"
                isOpen={showMenu && !!orgResults.length && !fetchingOrgs}
                onOpen={() => {}}
                onClose={() => {}}
                labelRef={searchLabelRef}
                dimensionRef={searchInputRef}
                wrappedComponentContent={<div />}
              >
                <Card className="ltx-search-org-results-card">
                  <ListWrapper>
                    {orgResults.map(org => (
                      <ListItem
                        key={org.id}
                        size={LIST_SIZES.LARGE}
                        onClick={() => onOrgResultSelected(org)}
                      >
                        <ListItemText>
                          <ListItemPrimaryText size={LIST_SIZES.LARGE}>
                            {org.name}
                          </ListItemPrimaryText>
                          <ListItemSubtext>{org.id}</ListItemSubtext>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </ListWrapper>
                </Card>
              </Popup>
            </div>
          </div>
        </div>
        <LastTransactionsTable
          reportOrg={reportOrg}
          rows={transactionReportData}
        />
      </Card>
      {errorToastMessage && (
        <Toast
          id="ltx-report-fetch-failed"
          content={`Failed to get last transaction report: ${errorToastMessage}`}
          variant={TOAST_VARIANTS.NEGATIVE}
          position={TOAST_POSITIONS.FIXED}
          visibilityDuration={TOAST_VISIBILITY_DURATIONS.INFINITE}
          actions={[
            {
              variant: BUTTON_VARIANTS.ICON,
              text: "Dismiss",
              icon: <IconClose />,
              iconSize: ICON_SIZE.SMALL,
              onClick: () => setErrorToastMessage("")
            }
          ]}
        />
      )}
    </>
  );
}

export default withAuth(withNavigationToHome(LastTransactions));
