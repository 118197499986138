export default {
  ENTITY_POST_REQUEST: "ENTITY_POST_REQUEST",
  ENTITY_POST_RECEIVE: "ENTITY_POST_RECEIVE",
  ENTITY_POST_ERROR: "ENTITY_POST_ERROR",
  ENTITY_GET_REQUEST: "ENTITY_GET_REQUEST",
  ENTITY_GET_RECEIVE: "ENTITY_GET_RECEIVE",
  ENTITY_GET_ERROR: "ENTITY_GET_ERROR",
  ERROR_RESET2: "ERROR_RESET2",
  ERROR: "ERROR",
  ERROR_RESET: "ERROR_RESET",
  SUCCESS_RESET: "SUCCESS_RESET",
  IN_PROGRESS: "IN_PROGRESS",
  MODEL_RESET: "MODEL_RESET"
};
