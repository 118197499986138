import React from "react";

import {
  GlobalHeader,
  GlobalHeaderSection,
  GlobalHeaderMenu,
  GlobalHeaderMenuIcon,
  GlobalHeaderLabel,
  GlobalHeaderLabelAppName,
  GlobalHeaderIcon
} from "@cdk-uip/react-global-header";

import { MenuAnchor, Menu, MenuItem } from "@cdk-uip/react-menu";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { FormattedMessage } from "react-intl";
//import { LinkContainer } from "react-router-bootstrap";

class AppToolbar extends React.Component {
  state = { menuOpen: false };
  onMenu = () => {
    this.setState({ menuOpen: true });
  };
  onDismiss = () => {
    this.setState({ menuOpen: false });
  };
  onSelect = () => {
    const {
      auth: { userData, login, logout }
    } = this.props;
    this.setState({ menuOpen: false });
    if (userData) {
      logout();
    } else {
      login();
    }
  };

  render() {
    const { menuOpen } = this.state;
    const {
      auth: { userData }
    } = this.props;
    return (
      <GlobalHeader>
        <GlobalHeaderSection align="start">
          <GlobalHeaderMenu>
            <GlobalHeaderMenuIcon element="span" />
          </GlobalHeaderMenu>

          {/* <LinkContainer to="/">
            <GlobalHeaderLogo element="a">
              <GlobalHeaderLogoImage>
                <CDKLogo />
              </GlobalHeaderLogoImage>
            </GlobalHeaderLogo>
          </LinkContainer> */}

          <GlobalHeaderLabel>
            <GlobalHeaderLabelAppName>
              <FormattedMessage
                id="App.title"
                defaultMessage="Accounts Admin"
              />
            </GlobalHeaderLabelAppName>
          </GlobalHeaderLabel>
        </GlobalHeaderSection>

        <GlobalHeaderSection align="end" shrinkToFit>
          {userData && userData.name}
          <GlobalHeaderIcon id="user-menu" onClick={this.onMenu}>
            person
          </GlobalHeaderIcon>
          <MenuAnchor>
            <Menu
              open={menuOpen}
              onCancel={this.onDismiss}
              onSelected={this.onSelect}
            >
              <MenuItem id={`user-menu-${userData ? "logout" : "login"}`}>
                {userData ? "Log out" : "Log in"}
              </MenuItem>
            </Menu>
          </MenuAnchor>
        </GlobalHeaderSection>
      </GlobalHeader>
    );
  }
}

export default withAuth(AppToolbar);
