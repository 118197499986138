export default {
  ENTITY_POST_REQUEST: "ENTITY_POST_REQUEST",
  ENTITY_POST_RECEIVE: "ENTITY_POST_RECEIVE",
  ENTITY_POST_ERROR: "ENTITY_POST_ERROR",
  ENTITY_GET_REQUEST: "ENTITY_GET_REQUEST",
  ENTITY_GET_RECEIVE: "ENTITY_GET_RECEIVE",
  ENTITY_GET_ERROR: "ENTITY_GET_ERROR",
  DELETED_ENTITY_GET_REQUEST: "DELETED_ENTITY_GET_REQUEST",
  DELETED_ENTITY_GET_RECEIVE: "DELETED_ENTITY_GET_RECEIVE",
  DELETED_ENTITY_GET_ERROR: "DELETED_ENTITY_GET_ERROR",
  ENTITY_VERIFY_PUT_REQUEST: "ENTITY_VERIFY_PUT_REQUEST",
  ENTITY_VERIFY_PUT_RECEIVE: "ENTITY_VERIFY_PUT_RECEIVE",
  ENTITY_VERIFY_PUT_ERROR: "ENTITY_VERIFY_PUT_ERROR",
  ERROR_RESET2: "ERROR_RESET2",
  ERROR: "ERROR",
  ERROR_RESET: "ERROR_RESET",
  SUCCESS_RESET: "SUCCESS_RESET",
  IN_PROGRESS: "IN_PROGRESS",
  ENTITY_GET_REQUEST_NEW: "ENTITY_GET_REQUEST_NEW",
  ENTITY_GET_RECEIVE_NEW: "ENTITY_GET_RECEIVE_NEW",
  ENTITY_GET_ERROR_NEW: "ENTITY_GET_ERROR_NEW",
  ADD_AGREEMENT_REQUEST: "ADD_AGREEMENT_REQUEST",
  ADD_AGREEMENT_RECEIVE: "ADD_AGREEMENT_RECEIVE",
  ADD_AGREEMENT_ERROR: "ADD_AGREEMENT_ERROR",
  CLOSE_AGREEMENT_DIALOG: "CLOSE_AGREEMENT_DIALOG",
  AGREEMENT_STATUS_REQUEST: "AGREEMENT_STATUS_REQUEST",
  AGREEMENT_STATUS_RECEIVE: "AGREEMENT_STATUS_RECEIVE",
  AGREEMENT_STATUS_ERROR: "AGREEMENT_STATUS_ERROR",
  GET_AGREEMENT_REQUEST: "GET_AGREEMENT_REQUEST",
  GET_AGREEMENT_RECEIVE: "GET_AGREEMENT_RECEIVE",
  GET_AGREEMENT_ERROR: "GET_AGREEMENT_ERROR",
  DELETE_AGREEMENT_REQUEST: "DELETE_AGREEMENT_REQUEST",
  DELETE_AGREEMENT_RECEIVE: "DELETE_AGREEMENT_RECEIVE",
  DELETE_AGREEMENT_ERROR: "DELETE_AGREEMENT_ERROR",
  REPLACE_AGREEMENT_REQUEST: "REPLACE_AGREEMENT_REQUEST",
  REPLACE_AGREEMENT_RECEIVE: "REPLACE_AGREEMENT_RECEIVE",
  REPLACE_AGREEMENT_ERROR: "REPLACE_AGREEMENT_ERROR",
  ENABLE_FEATURE_GATE_REQUEST: "ENABLE_FEATURE_GATE_REQUEST",
  ENABLE_FEATURE_GATE_RECEIVE: "ENABLE_FEATURE_GATE_RECEIVE",
  ENABLE_FEATURE_GATE_ERROR: "ENABLE_FEATURE_GATE_ERROR",
  ENABLE_NAMESPACE_REQUEST: "ENABLE_NAMESPACE_REQUEST",
  ENABLE_NAMESPACE_RECEIVE: "ENABLE_NAMESPACE_RECEIVE",
  ENABLE_NAMESPACE_ERROR: "ENABLE_NAMESPACE_ERROR",
  DELETE_ORGANIZATION_REQUEST: "DELETE_ORGANIZATION_REQUEST",
  DELETE_ORGANIZATION_RECEIVE: "DELETE_ORGANIZATION_RECEIVE",
  DELETE_ORGANIZATION_ERROR: "DELETE_ORGANIZATION_ERROR",
  RESTORE_ORGANIZATION_REQUEST: "RESTORE_ORGANIZATION_REQUEST",
  RESTORE_ORGANIZATION_RECEIVE: "RESTORE_ORGANIZATION_RECEIVE",
  RESTORE_ORGANIZATION_ERROR: "RESTORE_ORGANIZATION_ERROR",
  ENABLE_ENHANCED_FLAGS_REQUEST: "ENABLE_ENHANCED_FLAGS_REQUEST",
  ENABLE_ENHANCED_FLAGS_RECEIVE: "ENABLE_ENHANCED_FLAGS_RECEIVE",
  ENABLE_ENHANCED_FLAGS_ERROR: "ENABLE_ENHANCED_FLAGS_ERROR"
};
