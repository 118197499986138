import { ASYNC_ACCESS_GRANT_EVENTS } from "./states";

const ERROR_RESET = {
  errorMessage: "",
  showErrorModal: false
};

export const stateMapper = {
  resetEntityContext: function({ state, entityId }) {
    const entity = {
      id: entityId
    };
    const newState = Object.assign({}, state, { entity }, ERROR_RESET);
    newState.subscriptions = "";
    newState.loadedEntityId = "";
    return newState;
  },
  mapSolutionsDataToState: function({ state, data: solutionsData }) {
    let solutions = {};
    //Building K/V store on state with solutionId as Key
    solutionsData.forEach(sol => {
      solutions[sol.appId || "INVALID_ID"] = sol;
    });
    return Object.assign({}, state, { solutions }, ERROR_RESET);
  },
  mapSubSolutionInfoToState: function({
    state,
    solutionId,
    error,
    data: solutionInfo
  }) {
    let solutions;
    if (error) {
      solutions = {
        ...state.solutions,
        [solutionId]: { error }
      };
    } else {
      solutions = {
        ...state.solutions,
        [solutionId]: {
          appId: solutionInfo.id,
          displayName: solutionInfo.displayName,
          createdAt: new Date(solutionInfo.created).getTime(),
          error: null,
          asyncApiIntegrations: solutionInfo.asyncApiIntegrations,
          integrations: solutionInfo.integrations,
          mpAppName: solutionInfo?.mpAppName,
          certificationStatus: solutionInfo?.certificationStatus,
          status: solutionInfo?.status
        }
      };
    }
    return Object.assign({}, state, { solutions: solutions });
  },
  mapSubscriptionsDataToState: function({
    state,
    loadedEntityId,
    data: subsciptionsData
  }) {
    let subscriptions = {};
    //Building K/V store on state with suscriptionId as Key
    subsciptionsData.forEach(sub => {
      subscriptions[sub.subscriptionId] = {
        subscriptionId: sub.subscriptionId,
        status: sub.status,
        solutionId: sub.solutionId,
        solutionName: sub.solutionName,
        connections: null
      };
    });

    // Map solutionName to subscriptions
    // Superseded by individual solution download
    //for (let sub in subscriptions) {
    //let solution =
    //  state.solutions[subscriptions[sub].solutionId || "NO_SOLUTIONID"] || {};
    //subscriptions[sub]["displayName"] =
    //  solution.displayName || subscriptions[sub].solutionName;
    //}
    return Object.assign(
      {},
      state,
      { loadedEntityId, subscriptions },
      ERROR_RESET
    );
  },
  mapConnectionDataToState: function({
    state,
    data: connectionData,
    subscriptionId
  }) {
    //Update connections on the subscriptions
    const newSubscriptions = Object.assign({}, state.subscriptions);
    if (!newSubscriptions[subscriptionId]) {
      newSubscriptions[subscriptionId] = { connections: connectionData };
    } else {
      newSubscriptions[subscriptionId].connections = connectionData;
    }
    return Object.assign(
      {},
      state,
      { subscriptions: newSubscriptions },
      ERROR_RESET
    );
  },
  mapOrgsToState: function({ state, data: org, orgId }) {
    const orgs = Object.assign({}, state.orgs ? state.orgs : {});
    orgs[orgId] = org;
    return Object.assign({}, state, { orgs });
  },
  mapAvailbleProvidersForAPIToState: function({
    state,
    data: availbleProvidersForAPI,
    api
  }) {
    //Update apiProducts object with available providers info
    const apiProducts = Object.assign(
      {},
      state.apiProducts ? state.apiProducts : {}
    );
    apiProducts[api] = availbleProvidersForAPI;
    return Object.assign({}, state, { apiProducts });
  },
  mapProviderInfoToState: function({ state, data: providerInfo, providerId }) {
    const providers = Object.assign({}, state.providers ? state.providers : {});
    providers[providerId] = providerInfo;
    return Object.assign({}, state, { providers }, ERROR_RESET);
  },
  mapConnectionActivationResponseToState: function({
    state,
    data: activationResponse
  }) {
    const {
      subscriptionId,
      connectionId,
      status,
      providerId
    } = activationResponse;
    const subscriptions = Object.assign({}, state.subscriptions);
    const connection = subscriptions[subscriptionId].connections.find(
      con => con.connectionId === connectionId
    );
    connection.status = status;
    connection.providerId = providerId;
    return Object.assign(
      {},
      state,
      { subscriptions, apiActivationDeactivation: { inProgress: false } },
      ERROR_RESET
    );
  },
  mapDisableConnectionResponseToState: function({
    state,
    data: disableConnectionRes
  }) {
    const { subscriptionId, connectionId, status } = disableConnectionRes;
    const subscriptions = Object.assign({}, state.subscriptions);
    const connection = subscriptions[subscriptionId].connections.find(
      con => con.connectionId === connectionId
    );
    connection.status = status;
    delete connection.providerId;
    return Object.assign(
      {},
      state,
      { subscriptions, apiActivationDeactivation: { inProgress: false } },
      ERROR_RESET
    );
  },
  mapSubDatatoState: function({ state, data: subscriptionResponse }) {
    const subscriptionId = subscriptionResponse.subscriptionId;
    const subscriptions = Object.assign({}, state.subscriptions);
    subscriptions[subscriptionId] = subscriptionResponse;
    return Object.assign({}, state, { subscriptions }, ERROR_RESET);
  },
  mapCreateSubscriptionResposeToState: function({
    state,
    displayName,
    data: subscriptionResponse
  }) {
    const {
      subscriptionId,
      entityId,
      solutionId,
      status
    } = subscriptionResponse.subscription;
    const subscriptions = Object.assign({}, state.subscriptions);
    subscriptions[subscriptionId] = {
      subscriptionId,
      entityId,
      solutionId,
      status,
      connections: "",
      displayName
    };
    return Object.assign(
      {},
      state,
      { subscriptions },
      {
        showAlertModal: true,
        alertMessage: `Subscription added successfully for ${displayName}`
      }
    );
  },
  mapSubscriptionEnablementToState: function({
    state,
    data: subsResponse,
    displayName
  }) {
    const subscriptions = Object.assign({}, state.subscriptions);
    const subscription =
      subscriptions[subsResponse.subscription.subscriptionId];
    subscription.status = subsResponse.subscription.status;
    return Object.assign(
      {},
      state,
      { subscriptions },
      {
        showErrorModal: true,
        errorMessage: `${displayName} Subscription enabled successfully`
      }
    );
  },
  mapSubscriptionDisablementToState: function({
    state,
    data: subsResponse,
    displayName
  }) {
    const subscriptions = Object.assign({}, state.subscriptions);
    const subscription =
      subscriptions[subsResponse.subscription.subscriptionId];
    subscription.status = subsResponse.subscription.status;
    return Object.assign(
      {},
      state,
      { subscriptions },
      {
        showErrorModal: true,
        errorMessage: `${displayName} Subscription disabled successfully`
      }
    );
  },
  mapEntityAsyncAccessGrantsToState: function({
    state,
    data: accessGrantsData
  }) {
    let asyncAccessGrants = {};
    //Building K/V store on state with async integration Id as Key
    accessGrantsData.items.forEach(accessGrant => {
      asyncAccessGrants[accessGrant.integrationId] = accessGrant;
    });
    return Object.assign({}, state, { asyncAccessGrants }, ERROR_RESET);
  },
  mapUpsertAsyncAccessGrantsToState: function({
    state,
    data: accessGrantsData
  }) {
    //upsert access grant on successful activation
    const newAccessGrants = Object.assign({}, state.asyncAccessGrants);
    if (!newAccessGrants[accessGrantsData.integrationId]) {
      newAccessGrants[accessGrantsData.integrationId] = accessGrantsData;
    } else {
      newAccessGrants[accessGrantsData.integrationId].status =
        accessGrantsData.status;
      newAccessGrants[accessGrantsData.integrationId].apiProvisioningStatus =
        accessGrantsData.apiProvisioningStatus;
    }
    return Object.assign(
      {},
      state,
      { asyncAccessGrants: newAccessGrants },
      ERROR_RESET
    );
  },
  mapDisableAsyncAccessGrantsToState: function({
    state,
    data: asyncApiIntegrationId
  }) {
    //disbale access grant status on successful deactivation
    const newAccessGrants = Object.assign({}, state.asyncAccessGrants);
    newAccessGrants[asyncApiIntegrationId].status =
      ASYNC_ACCESS_GRANT_EVENTS.DISABLED;
    newAccessGrants[asyncApiIntegrationId].apiProvisioningStatus =
      ASYNC_ACCESS_GRANT_EVENTS.INACTIVE;
    return Object.assign(
      {},
      state,
      { asyncAccessGrants: newAccessGrants },
      ERROR_RESET
    );
  }
};
