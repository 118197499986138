import React, { Fragment, useEffect, useState } from "react";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import "./style.css";
import { PrimaryButton } from "cdk-radial";
import BundleListV2 from "./viewv2";
import {
  Input,
  INPUT_ICON_POSITIONS,
  INPUT_SIZES,
  IconSearch
} from "cdk-radial";

function ViewBundles({auth, setReservedBundles, setBundleShareName, setBundleShareId, setScreen, setSelectedBundle }) {
  const { accessToken } = auth;
  const [bundleList, setBundleList] = useState([]);
  const [unAuthorizedUser, setUnAuthorizedUser] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const handleCreateBundle = () => {
    setScreen("Create Bundles")
  };

  useEffect(() => {
    setReservedBundles(bundleList.map(bundle => bundle.name))
  },[bundleList])

  return (
    <Fragment>
      <LayoutGrid
        className={
          "s-content-route--subscription-management c-layout-section__dash-grid"
        }
      >
        <LayoutGridCell
          className={"c-layout-section__dash-grid-cell"}
          span={12}
          spanTablet={8}
          spanPhone={4}
        >
           {!unAuthorizedUser && (
            <div className="search_and_create_bundle_button_container">
              <div className="bundle-search-box">
                <Input
                  type="text"
                  value={searchQuery}
                  iconPosition={INPUT_ICON_POSITIONS.LEADING}
                  icon={<IconSearch />}
                  placeholder="Search by Bundle Name"
                  size={INPUT_SIZES.SMALL}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
              </div>
              <div className="create_bundle_list_button">
                <PrimaryButton
                  className="create-bundle-button"
                  text="Create Bundle"
                  size={INPUT_SIZES.LARGE}
                  onClick={handleCreateBundle}
                />
              </div>
            </div>
          )}
          <BundleListV2
            bundleList={bundleList}
            setBundleList={setBundleList}
            setUnAuthorizedUser={setUnAuthorizedUser}
            accessToken={accessToken}
            searchQuery={searchQuery}
            setBundleShareId={setBundleShareId}
            setBundleShareName={setBundleShareName}
            setScreen = {setScreen}
            setSelectedBundle = {setSelectedBundle}
          />
        </LayoutGridCell>
      </LayoutGrid>
    </Fragment>
  );
}

ViewBundles = WithNavigationToHome(ViewBundles);
export default withAuth(ViewBundles);
