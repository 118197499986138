// fake button for the status indicator, to be stylistically compatible
// with the rest of admin app (even though we don't use the button for
// anything)

// External Modules
import React, { Component, Fragment } from "react";
import { StatusIndicatorInline } from "@cdk-uip/react-status-indicator";
import { Button } from "@cdk-uip/react-button";
// Project Modules
import { formatStatus, typeForStatus } from "./utils";

export default class StatusIconButton extends Component {
  render() {
    const statusType = typeForStatus(this.props.apiStatus);
    const statusVal = formatStatus(this.props.apiStatus);

    // center the status indicator in the column
    const btnStyle = { marginLeft: "auto", marginRight: "auto" };
    // make them all roughly the same width (100px is wide enough for "active" or "retired")
    const indicatorStyle = { minWidth: 100 };

    return (
      <Fragment>
        <Button className="status-icon-button" style={btnStyle} disabled>
          <StatusIndicatorInline type={statusType} style={indicatorStyle}>
            {statusVal}
          </StatusIndicatorInline>
        </Button>
      </Fragment>
    );
  }
}
