import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
import { Select } from "@cdk-uip/react-select";

import WithNavigationToHome from "../components/WithNavigationToHome";
import EntityCard from "../components/EntityCard";
import { Confirm, Modal, LoadingModal } from "../components/Confirm";
import EntityApprovalPagination from "./EntityApprovalPagination";

const PAGE_SIZE = 100;
const SORT = {
  OLD_TO_NEW: {
    label: "Oldest to Newest",
    value: "asc"
  },
  NEW_TO_OLD: {
    label: "Newest to Oldest",
    value: "desc"
  }
};

const DEFAULT_CONFIRM_STATE = {
  open: false,
  action: undefined,
  target: "",
  type: ""
};

class EntityApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingEntities: [],
      confirm: { ...DEFAULT_CONFIRM_STATE },
      sort: SORT.NEW_TO_OLD.value,
      page: 1
    };
  }

  componentDidMount() {
    this.getPendingOrgs();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      entityApproval: { loadingApproval, showingError },
      getAllEntities,
      auth
    } = this.props;
    const {
      entityApproval: { loadingApproval: prevLoadingApproval }
    } = prevProps;

    // If sort changes resort pending Entities
    if (this.state.sort !== prevState.sort) {
      this.getPendingOrgs();
    }

    if (
      loadingApproval === false &&
      prevLoadingApproval === true &&
      showingError === false
    ) {
      // we hit verify on an org and it succeeded
      // thus, the backend result will have changed
      // so wipe loaded pages from memory and re-download the current one
      getAllEntities(auth, PAGE_SIZE, this.state.page, this.state.sort);
    }
  }

  getPendingOrgs = () => {
    const { sort } = this.state;
    const { auth } = this.props;
    this.props.getAllEntities(auth, PAGE_SIZE, 1, sort);
    this.setState({ page: 1 });
  };

  loadPrevOrgs = async () => {
    const { page, sort } = this.state;
    const {
      loadMorePendingOrgs,
      auth,
      entityApproval: { organizations }
    } = this.props;

    if (page > 1) {
      if (!organizations[page - 1]) {
        const result = await loadMorePendingOrgs(
          auth,
          PAGE_SIZE,
          page - 1,
          sort
        );
        if (result.apierror) {
          // don't advance page state if we failed to load the page
          // (if we do, we end up with a blank "no pending entities" screen)
          return;
        }
      }
      this.setState(prev => ({
        page: prev.page - 1
      }));
    }
  };

  loadMoreOrgs = async () => {
    const { page, sort } = this.state;
    const {
      loadMorePendingOrgs,
      auth,
      entityApproval: { organizations, totalOrgCount }
    } = this.props;

    const nPages = Math.ceil(totalOrgCount / PAGE_SIZE);
    if (page < nPages) {
      if (!organizations[page + 1]) {
        const result = await loadMorePendingOrgs(
          auth,
          PAGE_SIZE,
          page + 1,
          sort
        );
        if (result.apierror) {
          // don't advance page state if we failed to load the page
          return;
        }
      }
      this.setState(prev => ({
        page: prev.page + 1
      }));
    }
  };

  approveEntity = id => {
    const { setEntityVerified, auth } = this.props;
    setEntityVerified(auth, id, true);
  };

  updateConfirm = confirm => {
    this.setState({ confirm });
  };

  render() {
    const { confirm, page, sort } = this.state;
    const {
      entityApproval: {
        loadingMoreEntities,
        loadingEntities,
        loadingApproval,
        loadingMessage,
        showingError,
        errorMessage,
        totalOrgCount,
        organizations: orgPages
      },
      dismissError
    } = this.props;

    const organizations = orgPages[page] || [];

    return (
      <Fragment>
        {loadingEntities ? (
          <div className="loading-container">
            <h3>{loadingMessage}</h3>
            <CircularProgress />
          </div>
        ) : organizations.length ? (
          <LayoutGrid nested>
            <LayoutGridCell span={12} spanTablet={12} spanPhone={12}>
              <Elevation z={10}>
                <EntityApprovalPagination
                  page={page}
                  pageSize={PAGE_SIZE}
                  total={totalOrgCount}
                  loading={loadingMoreEntities}
                  onPrevious={this.loadPrevOrgs}
                  onNext={this.loadMoreOrgs}
                  rightSide={
                    <Select
                      box
                      label="Sort by"
                      value={sort}
                      onChange={e => {
                        this.setState({
                          sort: e.target.value
                        });
                      }}
                    >
                      <option value={SORT.NEW_TO_OLD.value}>
                        {SORT.NEW_TO_OLD.label}
                      </option>
                      <option value={SORT.OLD_TO_NEW.value}>
                        {SORT.OLD_TO_NEW.label}
                      </option>
                    </Select>
                  }
                />
              </Elevation>
            </LayoutGridCell>
            {organizations.map(entity => (
              <EntityCard
                entity={entity}
                approve={() =>
                  this.updateConfirm({
                    open: true,
                    action: this.approveEntity,
                    target: entity,
                    type: "verify"
                  })
                }
                key={entity.id}
              />
            ))}
            <LayoutGridCell span={12} spanTablet={12} spanPhone={12}>
              <Elevation z={10}>
                <EntityApprovalPagination
                  page={page}
                  pageSize={PAGE_SIZE}
                  total={totalOrgCount}
                  loading={loadingMoreEntities}
                  onPrevious={this.loadPrevOrgs}
                  onNext={this.loadMoreOrgs}
                  rightSide={
                    <Button onClick={() => window.scrollTo(0, 0)}>
                      <ButtonIcon>expand_less</ButtonIcon>
                      Back to Top
                    </Button>
                  }
                />
              </Elevation>
            </LayoutGridCell>
          </LayoutGrid>
        ) : (
          <LayoutGrid>
            <LayoutGridCell span={12} spanTablet={8} spanPhone={4}>
              <EmptyState message="No unverified entities" />
            </LayoutGridCell>
          </LayoutGrid>
        )}
        <Confirm
          open={confirm.open}
          message={`Are you sure you want to ${confirm.type} ${confirm.target.name}?`}
          onAccept={() => {
            confirm.action(confirm.target.id);
            this.updateConfirm({ ...DEFAULT_CONFIRM_STATE });
          }}
          onCancel={() => this.updateConfirm({ ...DEFAULT_CONFIRM_STATE })}
          type={confirm.type}
        />
        <Modal
          open={showingError}
          onClose={dismissError}
          message={errorMessage}
        />
        <LoadingModal open={loadingApproval} message={loadingMessage} />
      </Fragment>
    );
  }
}

EntityApproval.propTypes = {
  entityApproval: PropTypes.object
};

const EmptyState = ({ message }) => {
  return <div className="empty-state">{message}</div>;
};

const EntityApprovalComp = WithNavigationToHome(EntityApproval);
class EntityApprovalContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <EntityApprovalComp {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(EntityApprovalContainer);

export { EntityApproval as UnwrappedEntityApproval };
