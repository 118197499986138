import types from "./types";

const maxOrgResults = 20;

const noReportOrg = {
  id: "Search above to get started",
  name: "No dealer selected"
};

const initialState = {
  reportOrg: noReportOrg,
  orgResults: [],
  fetchingOrgs: false,
  fetchingOrgsError: null,
  transactionReportData: [],
  fetchingTransactionReport: false,
  fetchingTransactionReportError: null
};

const reducerActions = {
  [types.SET_REPORT_ORG]: (state, { reportOrg }) => ({
    ...state,
    reportOrg
  }),
  [types.SEARCH_ORGS_REQUEST]: state => ({
    ...state,
    orgResults: [],
    fetchingOrgs: true,
    fetchingOrgsError: null
  }),
  [types.SEARCH_ORGS_RECEIVE]: (state, { response }) => ({
    ...state,
    orgResults: response.organizations.slice(0, maxOrgResults),
    fetchingOrgs: false,
    fetchingOrgsError: null
  }),
  [types.SEARCH_ORGS_ERROR]: (state, { apierror }) => ({
    ...state,
    fetchingOrgs: false,
    fetchingOrgsError: apierror
  }),
  [types.GET_TRANSACTIONS_REPORT_REQUEST]: state => ({
    ...state,
    transactionReportData: [],
    fetchingTransactionReport: true,
    fetchingTransactionReportError: null
  }),
  [types.GET_TRANSACTIONS_REPORT_RECEIVE]: (state, { response }) => ({
    ...state,
    transactionReportData: response,
    fetchingTransactionReport: false
  }),
  [types.GET_TRANSACTIONS_REPORT_ERROR]: (state, { apierror }) => ({
    ...state,
    fetchingTransactionReport: false,
    fetchingTransactionReportError: apierror
  })
};

export default function lastTransactionsReducer(state = initialState, action) {
  return reducerActions[action.type]
    ? reducerActions[action.type](state, action)
    : state;
}
