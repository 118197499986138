import React, { Component } from "react";
import {
  Dialog,
  DialogHeader,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";

export default class RestoreEntityDialog extends Component {
  constructor(props) {
    super(props);
  }

  restoreOrganization = e => {
    this.props.restoreOrganization(this.props.entity);
    this.props.onClose(e);
  };

  render() {
    const { open, onClose, entity } = this.props;
    return (
      <Dialog open={open} onCancel={onClose}>
        <DialogHeader>
          Are you sure you want to restore organization ?
        </DialogHeader>
        <DialogFooter>
          <DialogFooterButton onClick={e => this.restoreOrganization(e)}>
            Yes
          </DialogFooterButton>
          <DialogFooterButton onClick={onClose}>No</DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}
