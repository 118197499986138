import React from "react";
import { TypeaheadFilter } from "@fortellis/typeahead";
import SearchIcon from "@material-ui/icons/Search";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping but allow "-"
const toSafeRegexString = str =>
  str ? str.replace(/[.*+?^${}()|[\]\\]/, "") : "";

export const TypeaheadSearch = ({
  placeholder,
  search,
  onUpdate,
  searchOptions
}) => {
  return (
    <TypeaheadFilter
      label={placeholder}
      value={toSafeRegexString(search)}
      onChange={(value, results) => {
        onUpdate(value, results);
      }}
      options={searchOptions}
      fuzzy={true}
      trailingIcon={<SearchIcon />}
    />
  );
};

export default {
  TypeaheadSearch
};
