import React, { useState } from "react";

import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";

import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import types from "./types";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
import { Modal } from "../components/Confirm";

import { Card } from "@cdk-uip/react-card";
import { IconFilterList } from "cdk-radial";
import AuditTable from "./AuditTable/AuditTable";
import moment from "moment";
import "./AuditTable/style.css";

class AuditReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldDownload: true
    };
  }

  getAuditReportHandler = filters => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getAuditReport({
      accessToken,
      filters
    });
  };

  onErrorModalClose = () => {
    this.props.dispatch({
      type: types.ERROR_RESET
    });
  };

  getFilterAuditRecords = filters => {
    this.getAuditReportHandler(filters);
  };

  componentDidMount() {
    this.getAuditReportHandler({
      nextPage: 0,
      maxResults: 50,
      endDate: moment(),
      startDate: moment().subtract(1, "days")
    });
  }

  render() {
    const {
      auditEvent,
      reportLoading,
      error,
      showErrorModal,
      nextPage
    } = this.props.auditReports;

    return (
      <React.Fragment>
        {reportLoading && (
          <div className="c-circular-progress--overlay-wrapper">
            <Headline>Please wait...</Headline>
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        )}
        <LayoutGrid
          className={
            "s-content-route--subscription-management c-layout-section__dash-grid"
          }
        >
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <div className="main-container">
                  <div className="header-audit-use-text">
                    <span>
                      By default, this page shows the history of events for
                      the last 24 hours with most recent on the top. Use the
                      filtering options
                    </span>
                    <IconFilterList
                      style={{ fill: "rgba(21,27,37,0.7)" }}
                      className="icon-styles"
                    />
                    <span>to refine the displayed events.</span>
                  </div>
                  <AuditTable
                    auditEvent={auditEvent}
                    nextPage={nextPage}
                    getFilterAuditRecords={this.getFilterAuditRecords}
                  />
                </div>
              </Card>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>
        <Modal
          open={showErrorModal}
          onClose={this.onErrorModalClose}
          message={error}
        />
        ;
      </React.Fragment>
    );
  }
}

AuditReport = connect()(WithNavigationToHome(AuditReport));
class AuditReportContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <AuditReport {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(AuditReportContainer);
