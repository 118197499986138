import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import getUserSearchResults from "../api/getUserSearchResults";

export const actions = {
  getUserSearchResults: (userEmail, accessToken) => ({
    [CALL_API]: {
      ...getUserSearchResults(userEmail),
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.USER_SEARCH_GET_REQUEST,
        types.USER_SEARCH_GET_RECEIVE,
        types.USER_SEARCH_GET_ERROR
      ]
    }
  })
};
