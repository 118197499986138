const  SOLUTION_STATUS ={
CANCELLED:"Cancelled"
}
const APP_CANCELLED_TEXT = "This app is cancelled!";
const APP_CERTIFICATION_TEXT = "This app is not yet certified!";
const LOADING_TEXT = "Fetching solution details...";
const BULK_SUBSCRIPTION_APP_CANCELLED_TEXT = "You cannot request bulk subscription for a cancelled app."

export {
    SOLUTION_STATUS,
    APP_CANCELLED_TEXT,
    APP_CERTIFICATION_TEXT,
    LOADING_TEXT,
    BULK_SUBSCRIPTION_APP_CANCELLED_TEXT
}