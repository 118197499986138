import React, { useState } from "react";
import "./style.css";
import {
  IconButton,
  IconFilterList,
  MenuListItem,
  DialogFooter,
  BUTTON_VARIANTS
} from "cdk-radial";
import { Dialog, DialogHeader } from "@cdk-uip/react-dialog";
import { RenderFilterValues, filtersConfig } from "./RenderFilterValues";

const Filters = ({
  applyFilter,
  handleClear,
  updateState,
  filterState,
  handleClose
}) => {
  const [selectedMenu, setselectedMenu] = useState("userEmailId");
  const [filters, setFilters] = useState(filtersConfig);
  const handleApply = () => {
    applyFilter(filterState);
    handleClose();
  };
  const handleMenueSelected = keyName => {
    let newFilter = [...filters];
    newFilter.map(e => {
      e.menueSelection = false;
    });
    newFilter.map(e => {
      if (e.keyName === keyName) {
        e.menueSelection = true;
      }
    });
    setFilters(newFilter);
    setselectedMenu(keyName);
  };
  return (
    <div>
      <div className="filter-div">
        <div className="filter-menu">
          {filters.map(({ keyName, displayName, menueSelection }) => {
            return (
              <MenuListItem
                dataTestId={`menu-${keyName}`}
                isSelected={menueSelection}
                onClick={() => handleMenueSelected(keyName)}
              >
                {displayName}
              </MenuListItem>
            );
          })}
        </div>
        <div className="filter-values">
          <RenderFilterValues
            keyName={selectedMenu}
            updateState={updateState}
            filterState={filterState}
          />
        </div>
      </div>
      <div>
        <DialogFooter
          buttonsProps={[
            {
              id: "dialog-action-clear",
              onClick: () => {
                handleClear();
                setselectedMenu("userEmailId");
              },
              text: "Clear Filters",
              variant: BUTTON_VARIANTS.TEXT
            },
            {
              id: "dialog-action-apply",
              onClick: handleApply,
              text: "Apply",
              variant: BUTTON_VARIANTS.PRIMARY
            }
          ]}
        />
      </div>
    </div>
  );
};

const AuditFilters = ({
  applyFilters,
  handleClear,
  updateState,
  filterState
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <IconButton
        icon={<IconFilterList height={30} width={30} />}
        onClick={() => {
          setOpen(true);
        }}
        text={"Filter"}
      />
      <Dialog open={open} onCancel={handleClose}>
        <DialogHeader>Filters</DialogHeader>
        <Filters
          handleClose={handleClose}
          applyFilter={applyFilters}
          handleClear={handleClear}
          updateState={updateState}
          filterState={filterState}
        />
      </Dialog>
    </div>
  );
};

export default AuditFilters;
