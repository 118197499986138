export const CONNECTION_STATES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
  DECLINED: "declined"
};

export const SUBSCRIPTION_STATES = {
  ACTIVE: "active",
  INACTIVE: "inactive"
};

export const SUBSCRIPTION_EVENTS = {
  UNKNOWN_EVENT: "UNKNOWN_EVENT",
  GET_SOLUTIONS: "GET_SOLUTIONS",
  GET_ENTITY_SUBS: "GET_ENTITY_SUBS",
  GET_SUB_CONNECTIONS: "GET_SUB_CONNECTIONS",
  GET_ORG: "GET_ORG",
  SUB_ACTIVATE: "SUB_ACTIVATE",
  SUB_DEACTIVATE: "SUB_DEACTIVATE"
};

export const CONNECTION_EVENTS = {
  GET_PROVIDERS_FOR_API: "GET_PROVIDERS_FOR_API",
  GET_PROVIDER_BY_ID: "GET_PROVIDER_BY_ID", // To display provider name for connection in active/pending/declined status
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE"
};

export const ASYNC_ACCESS_GRANT_EVENTS = {
  ACTIVATE: "ACCESS_GRANT_UPSERT",
  DEACTIVATE: "ACCESS_GRANT_DISABLE",
  ENABLED: "enabled",
  DISABLED: "disabled",
  INACTIVE: "inactive"
};
