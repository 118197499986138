import AuditReport from "./AuditReport";
import { connect } from "react-redux";
import { actions } from "./AuditReportAction";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    state => ({
      auditReports: state.auditReducers
    }),
    actions
  )(AuditReport)
);
