const STATUS_TEXT = {
  PROVISIONING_FAILED: "Provisioning Failed",
  REJECTED: "Rejected",
  SUBMITTED: "Submitted",
  IN_REVIEW: "In Review",
  APPROVED: "Approved",
  PUBLISHED: "Published",
  PROVISIONING: "Provisioning",
  REGISTERED: "Registered"
};

const STATUS_NUM = {
  PROVISIONING_FAILED: -2,
  REJECTED: -1,
  SUBMITTED: 0,
  IN_REVIEW: 1,
  APPROVED: 2,
  PUBLISHED: 3,
  PROVISIONING: 4
};

const STATUS = {
  [STATUS_NUM.PROVISIONING_FAILED]: STATUS_TEXT.PROVISIONING_FAILED,
  [STATUS_NUM.REJECTED]: STATUS_TEXT.REJECTED,
  [STATUS_NUM.SUBMITTED]: STATUS_TEXT.SUBMITTED,
  [STATUS_NUM.IN_REVIEW]: STATUS_TEXT.IN_REVIEW,
  [STATUS_NUM.APPROVED]: STATUS_TEXT.APPROVED,
  [STATUS_NUM.PUBLISHED]: STATUS_TEXT.PUBLISHED,
  [STATUS_NUM.PROVISIONING]: STATUS_TEXT.PROVISIONING
};

function formatStatus(status) {
  return STATUS[status] || String(status);
}

function typeForStatus(status) {
  switch (status) {
    case STATUS_NUM.PROVISIONING_FAILED:
    case STATUS_NUM.REJECTED:
      return "negative";
    case STATUS_NUM.SUBMITTED:
      return "informational";
    case STATUS_NUM.IN_REVIEW:
      return "warning";
    case STATUS_NUM.APPROVED:
    case STATUS_NUM.PUBLISHED:
    case STATUS_NUM.PROVISIONING:
      return "positive";
    default:
      return "inactive";
  }
}

export { STATUS_NUM, STATUS_TEXT, formatStatus, typeForStatus };
