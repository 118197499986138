import React, { useState } from "react";
import "./style.css";
import { Input, DateRangePicker, DropdownMenu } from "cdk-radial";
import moment from "moment";

const types = {
  RADIO_GROUP: "radio-group",
  TEXT_INPUT: "text-input",
  TEXT_INPUT_ORG: "text-input-org",
  DATE_RANGE: "date-range",
  DROP_DOWN: "drop-down"
};

const eventGroupOptions = [
  {
    id: "Subscription Management",
    label: "Subscription Management",
    value: "Subscription Management"
  },
  {
    id: "API Management",
    label: "API Management",
    value: "API Management"
  },
  {
    id: "Async API Management",
    label: "Async API Management",
    value: "Async API Management"
  },
  {
    id: "API Integration Management",
    label: "API Integration Management",
    value: "API Integration Management"
  },
  {
    id: "Listing Fee Payment",
    label: "Listing Fee Payment",
    value: "Listing Fee Payment"
  },
  {
    id: "Solution Submission",
    label: "Solution Submission",
    value: "Solution Submission"
  },
  {
    id: "Lead Capture",
    label: "Lead Capture",
    value: "Lead Capture"
  },
  {
    id: "App Listing Version Create",
    label: "App Listing Version Create",
    value: "App Listing Version Create"
  },
  {
    id: "App Submitted In Review",
    label: "App Submitted In Review",
    value: "App Submitted In Review"
  },
  {
    id: "App Listing Approve",
    label: "App Listing Approve",
    value: "App Listing Approve"
  },
  {
    id: "App Listing Publish",
    label: "App Listing Publish",
    value: "App Listing Publish"
  },
  {
    id: "App Listing Withdrawn",
    label: "App Listing Withdrawn",
    value: "App Listing Withdrawn"
  },
  {
    id: "App Listing Recall",
    label: "App Listing Recall",
    value: "App Listing Recall"
  },
  {
    id: "App Listing Reject",
    label: "App Listing Reject",
    value: "App Listing Reject"
  },
  {
    id: "App Contract Notification Change",
    label: "App Contract Notification Change",
    value: "App Contract Notification Change"
  },
  {
    id: "App Subscription Enablement Change",
    label: "App Subscription Enablement Change",
    value: "App Subscription Enablement Change"
  },
  {
    id: "App Data Rights Enablement Change",
    label: "App Data Rights Enablement Change",
    value: "App Data Rights Enablement Change"
  },
  {
    id: "App Featured Status Change",
    label: "App Featured Status Change",
    value: "App Featured Status Change"
  },
  {
    id: "App Vision Case Enablement Change",
    label: "App Vision Case Enablement Change",
    value: "App Vision Case Enablement Change"
  },
  {
    id: "App Catalog Number Change",
    label: "App Catalog Number Change",
    value: "App Catalog Number Change"
  },
  {
    id: "App Dealer Consent Change",
    label: "App Dealer Consent Change",
    value: "App Dealer Consent Change"
  },
  {
    id: "App Data Integration Change",
    label: "App Data Integration Change",
    value: "App Data Integration Change"
  },
  {
    id: "App API Integration Change",
    label: "App API Integration Change",
    value: "App API Integration Change"
  },
  {
    id: "App Listing Revoke",
    label: "App Listing Revoke",
    value: "App Listing Revoke"
  },
  {
    id: "Organization Management",
    label: "Organization Management",
    value: "Organization Management"
  },
  {
    id: "Fortellis User Account Management",
    label: "Fortellis User Account Management",
    value: "Fortellis User Account Management"
  },
  {
    id: "APP Management",
    label: "APP Management",
    value: "APP Management"
  },
  {
    id: "Bulk Subscription",
    label: "Bulk Subscription",
    value: "Bulk Subscription"
  },
  {
    id: "Dealer Subscription",
    label: "Dealer Subscription",
    value: "Dealer Subscription"
  },
  {
    id: "Certification Management",
    label: "Certification Management",
    value: "Certification Management"
  },
  {
    id: "App Listing Fee Changed",
    label: "App Listing Fee Changed",
    value: "App Listing Fee Changed"
  },
  {
    id: "Certification Payment Date Modification",
    label: "Certification Payment Date Modification",
    value: "Certification Payment Date Modification"
  },
  {
    id: "Payment Terms Condition",
    label: "Payment Terms Condition",
    value: "Payment Terms Condition"
  },
  {
    id: "Certification Fee Payment",
    label: "Certification Fee Payment",
    value: "Certification Fee Payment"
  },
  {
    id: "Recertification Fee Payment",
    label: "Recertification Fee Payment",
    value: "Recertification Fee Payment"
  },
  {
    id: "App Cancel",
    label: "App Cancel",
    value: "App Cancel"
  },
  {
    id: "Payment Method Edit",
    label: "Payment Method Edit",
    value: "Payment Method Edit"
  },
  {
    id: "Manage Dealer Cart",
    label: "Manage Dealer Cart",
    value: "Manage Dealer Cart"
  },
  {
    id: "Stripe Automatic Card Update",
    label: "Stripe Automatic Card Update",
    value: "Stripe Automatic Card Update"
  },
  {
    id: "Bundle Management",
    label: "Bundle Management",
    value: "Bundle Management"
  },
  {
    id: "Contract Management",
    label: "Contract Management",
    value: "Contract Management"
  }
];

const eventNameOptions = [
  {
    id: "1",
    eventGroupId: "Subscription Management",
    label: "Manual Subscription Approval by Publisher",
    value: "Manual Subscription Approval by Publisher"
  },
  {
    id: "2",
    eventGroupId: "Subscription Management",
    label: "Manual Subscription Denied by Publisher",
    value: "Manual Subscription Denied by Publisher"
  },
  {
    id: "3",
    eventGroupId: "API Management",
    label: "Register API",
    value: "Register API"
  },
  {
    id: "4",
    eventGroupId: "API Management",
    label: "Remove API",
    value: "Remove API"
  },
  {
    id: "5",
    eventGroupId: "API Management",
    label: "API Visibility Change",
    value: "API Visibility Change"
  },
  {
    id: "6",
    eventGroupId: "API Management",
    label: "API Activation Model Change",
    value: "API Activation Model Change"
  },
  {
    id: "7",
    eventGroupId: "API Management",
    label: "API Share",
    value: "API Share"
  },
  {
    id: "8",
    eventGroupId: "API Management",
    label: "API Unshare",
    value: "API Unshare"
  },
  {
    id: "9",
    eventGroupId: "API Management",
    label: "API Pricing Plan Add",
    value: "API Pricing Plan Add"
  },
  {
    id: "10",
    eventGroupId: "API Management",
    label: "API Pricing Plan Update",
    value: "API Pricing Plan Update"
  },
  {
    id: "11",
    eventGroupId: "API Management",
    label: "API Pricing Plan Retire",
    value: "API Pricing Plan Retire"
  },
  {
    id: "12",
    eventGroupId: "API Management",
    label: "API Terms Update",
    value: "API Terms Update"
  },
  {
    id: "13",
    eventGroupId: "API Management",
    label: "API Addendum Update",
    value: "API Addendum Update"
  },
  {
    id: "14",
    eventGroupId: "API Management",
    label: "API Revision Register",
    value: "API Revision Register"
  },
  {
    id: "15",
    eventGroupId: "API Management",
    label: "API Revision Remove",
    value: "API Revision Remove"
  },
  {
    id: "16",
    eventGroupId: "API Management",
    label: "API Revision Spec Update",
    value: "API Revision Spec Update"
  },
  {
    id: "17",
    eventGroupId: "API Management",
    label: "API Revision Target URL Change",
    value: "API Revision Target URL Change"
  },
  {
    id: "18",
    eventGroupId: "API Management",
    label: "API Revision Access Model Change",
    value: "API Revision Access Model Change"
  },
  {
    id: "19",
    eventGroupId: "API Management",
    label: "API Revision Show In Directory Change",
    value: "API Revision Show In Directory Change"
  },
  {
    id: "20",
    eventGroupId: "Solution Submission",
    label: "Solution Submitted Successfully",
    value: "Solution Submitted Successfully"
  },
  {
    id: "21",
    eventGroupId: "Solution Submission",
    label: "Solution Submission Failed",
    value: "Solution Submission Failed"
  },
  {
    id: "22",
    eventGroupId: "Lead Capture",
    label: "Lead Captured Successfully",
    value: "Lead Captured Successfully"
  },
  {
    id: "23",
    eventGroupId: "Lead Capture",
    label: "Lead Capture Failed",
    value: "Lead Capture Failed"
  },
  {
    id: "24",
    eventGroupId: "App Listing Version Create",
    label: "App Listing Created Successfully",
    value: "App Listing Created Successfully"
  },
  {
    id: "25",
    eventGroupId: "App Listing Version Create",
    label: "Failed To Create App Listing",
    value: "Failed To Create App Listing"
  },
  {
    id: "26",
    eventGroupId: "App Submitted In Review",
    label: "App Submitted In Review Successfully",
    value: "App Submitted In Review Successfully"
  },
  {
    id: "27",
    eventGroupId: "App Submitted In Review",
    label: "Failed To Submit App In Review",
    value: "Failed To Submit App In Review"
  },
  {
    id: "28",
    eventGroupId: "App Listing Approve",
    label: "App Approved Suceessfully",
    value: "App Approved Suceessfully"
  },
  {
    id: "29",
    eventGroupId: "App Listing Approve",
    label: "Failed To Approve App",
    value: "Failed To Approve App"
  },
  {
    id: "30",
    eventGroupId: "App Listing Publish",
    label: "App Published Successfully",
    value: "App Published Successfully"
  },
  {
    id: "31",
    eventGroupId: "App Listing Publish",
    label: "Failed To Publish App",
    value: "Failed To Publish App"
  },
  {
    id: "32",
    eventGroupId: "App Listing Withdrawn",
    label: "App Withdrawn Successfully",
    value: "App Withdrawn Successfully"
  },
  {
    id: "33",
    eventGroupId: "App Listing Withdrawn",
    label: "Failed To Withdraw App",
    value: "Failed To Withdraw App"
  },
  {
    id: "34",
    eventGroupId: "App Listing Recall",
    label: "App Recall Was Successful",
    value: "App Recall Was Successful"
  },
  {
    id: "35",
    eventGroupId: "App Listing Recall",
    label: "Failed To Recall App",
    value: "Failed To Recall App"
  },
  {
    id: "36",
    eventGroupId: "App Listing Reject",
    label: "App Rejected Successfully",
    value: "App Rejected Successfully"
  },
  {
    id: "37",
    eventGroupId: "App Listing Reject",
    label: "Failed To Reject App",
    value: "Failed To Reject App"
  },
  {
    id: "38",
    eventGroupId: "App Contract Notification Change",
    label: "App Contract Notification Changed Successfully",
    value: "App Contract Notification Changed Successfully"
  },
  {
    id: "39",
    eventGroupId: "App Contract Notification Change",
    label: "Failed To Change App Contract Notifications",
    value: "Failed To Change App Contract Notifications"
  },
  {
    id: "40",
    eventGroupId: "App Subscription Enablement Change",
    label: "App Subscription Enablement Changed Successfully",
    value: "App Subscription Enablement Changed Successfully"
  },
  {
    id: "41",
    eventGroupId: "App Subscription Enablement Change",
    label: "Failed To Change Subscription Enablement For An App",
    value: "Failed To Change Subscription Enablement For An App"
  },
  {
    id: "42",
    eventGroupId: "App Data Rights Enablement Change",
    label: "App Data Rights Enablement Changed Successfully",
    value: "App Data Rights Enablement Changed Successfully"
  },
  {
    id: "43",
    eventGroupId: "App Data Rights Enablement Change",
    label: "Failed To Change Data Rights Enablement For An App",
    value: "Failed To Change Data Rights Enablement For An App"
  },
  {
    id: "44",
    eventGroupId: "App Featured Status Change",
    label: "Successfully Changed App Featured Status",
    value: "Successfully Changed App Featured Status"
  },
  {
    id: "45",
    eventGroupId: "App Featured Status Change",
    label: "Failed To Change Featured Status For An App",
    value: "Failed To Change Featured Status For An App"
  },
  {
    id: "46",
    eventGroupId: "App Vision Case Enablement Change",
    label: "Successfully Changed Vision Case Enablement For An App",
    value: "Successfully Changed Vision Case Enablement For An App"
  },
  {
    id: "47",
    eventGroupId: "App Vision Case Enablement Change",
    label: "Failed To Change Vision Case Enablement For An App'",
    value: "Failed To Change Vision Case Enablement For An App'"
  },
  {
    id: "48",
    eventGroupId: "App Catalog Number Change",
    label: "Successfully Changed Catalog Number For An App",
    value: "Successfully Changed Catalog Number For An App"
  },
  {
    id: "49",
    eventGroupId: "App Catalog Number Change",
    label: "Failed To Change Catalog Number For An App",
    value: "Failed To Change Catalog Number For An App"
  },
  {
    id: "50",
    eventGroupId: "App Dealer Consent Change",
    label: "Successfully Changed Dealer Consent Form For An App",
    value: "Successfully Changed Dealer Consent Form For An App"
  },
  {
    id: "51",
    eventGroupId: "App Dealer Consent Change",
    label: "Failed To Change Dealer Consent Form For An App",
    value: "Failed To Change Dealer Consent Form For An App"
  },
  {
    id: "52",
    eventGroupId: "App Data Integration Change",
    label: "Successfully Changed Data Integration For An App",
    value: "Successfully Changed Data Integration For An App"
  },
  {
    id: "53",
    eventGroupId: "App Data Integration Change",
    label: "Failed To Change Data Integration For An App",
    value: "Failed To Change Data Integration For An App"
  },
  {
    id: "54",
    eventGroupId: "App API Integration Change",
    label: "Suceessfully Updated APIS Into An App",
    value: "Suceessfully Updated APIS Into An App"
  },
  {
    id: "55",
    eventGroupId: "App API Integration Change",
    label: "Failed To Update API Integration For An App",
    value: "Failed To Update API Integration For An App"
  },
  {
    id: "56",
    eventGroupId: "App Listing Revoke",
    label: "App Listing Revoked Successfully",
    value: "App Listing Revoked Successfully"
  },
  {
    id: "57",
    eventGroupId: "App Listing Revoke",
    label: "Failed To Revoke An App",
    value: "Failed To Revoke An App"
  },
  {
    id: "58",
    eventGroupId: "Async API Management",
    label: "Async API Register",
    value: "Async API Register"
  },
  {
    id: "59",
    eventGroupId: "Async API Management",
    label: "Remove Async API",
    value: "Remove Async API"
  },
  {
    id: "60",
    eventGroupId: "Async API Management",
    label: "Async API Visibility Change",
    value: "Async API Visibility Change"
  },
  {
    id: "61",
    eventGroupId: "Async API Management",
    label: "Async API Show In Directory Change",
    value: "Async API Show In Directory Change"
  },
  {
    id: "62",
    eventGroupId: "Async API Management",
    label: "Async API Pricing Plan Add",
    value: "Async API Pricing Plan Add"
  },
  {
    id: "63",
    eventGroupId: "Async API Management",
    label: "Async API Pricing Plan Update",
    value: "Async API Pricing Plan Update"
  },
  {
    id: "64",
    eventGroupId: "Async API Management",
    label: "Async API Pricing Plan Retire",
    value: "Async API Pricing Plan Retire"
  },
  {
    id: "65",
    eventGroupId: "Async API Management",
    label: "Async API Terms Update",
    value: "Async API Terms Update"
  },
  {
    id: "66",
    eventGroupId: "Async API Management",
    label: "Async API Addendum Update",
    value: "Async API Addendum Update"
  },
  {
    id: "67",
    eventGroupId: "Async API Management",
    label: "Async API Share",
    value: "Async API Share"
  },
  {
    id: "68",
    eventGroupId: "Async API Management",
    label: "Async API Unshare",
    value: "Async API Unshare"
  },
  {
    id: "69",
    eventGroupId: "Async API Management",
    label: "Async API Subscription Deactivate",
    value: "Async API Subscription Deactivate"
  },
  {
    id: "70",
    eventGroupId: "Async API Management",
    label: "Async API Spec Update",
    value: "Async API Spec Update"
  },
  {
    id: "71",
    eventGroupId: "API Integration Management",
    label: "API Free Trial Override Date",
    value: "API Free Trial Override Date"
  },
  {
    id: "72",
    eventGroupId: "Organization Management",
    label: "Organization Registered Successfully",
    value: "Organization Registered Successfully"
  },
  {
    id: "73",
    eventGroupId: "Organization Management",
    label: "Failed To Create Organization",
    value: "Failed To Create Organization"
  },
  {
    id: "74",
    eventGroupId: "Organization Management",
    label: "User Successfully Removed From An Organization",
    value: "User Successfully Removed From An Organization"
  },
  {
    id: "75",
    eventGroupId: "Organization Management",
    label: "Failed To Remove User From An Organization",
    value: "Failed To Remove User From An Organization"
  },
  {
    id: "76",
    eventGroupId: "Organization Management",
    label: "Successfully Updated Organization Profile",
    value: "Successfully Updated Organization Profile"
  },
  {
    id: "77",
    eventGroupId: "Organization Management",
    label: "Failed To Update Organization Profile",
    value: "Failed To Update Organization Profile"
  },
  {
    id: "78",
    eventGroupId: "Organization Management",
    label: "User Added To Organization Successfully",
    value: "User Added To Organization Successfully"
  },
  {
    id: "79",
    eventGroupId: "Organization Management",
    label: "Successfully Assigned New Role To An User",
    value: "Successfully Assigned New Role To An User"
  },
  {
    id: "80",
    eventGroupId: "Organization Management",
    label: "Failed To Assign Role To An User",
    value: "Failed To Assign Role To An User"
  },
  {
    id: "81",
    eventGroupId: "Organization Management",
    label: "Successfully Unassigned Role To An user",
    value: "Successfully Unassigned Role To An user"
  },
  {
    id: "82",
    eventGroupId: "Organization Management",
    label: "Failed To Unassign Role To An User",
    value: "Failed To Unassign Role To An User"
  },
  {
    id: "83",
    eventGroupId: "Organization Management",
    label: "User Invited To Org Successfully",
    value: "User Invited To Org Successfully"
  },
  {
    id: "84",
    eventGroupId: "Organization Management",
    label: "Failed To Invite User To An Org",
    value: "Failed To Invite User To An Org"
  },
  {
    id: "85",
    eventGroupId: "Organization Management",
    label: "Successfully Sent Organization Join Request",
    value: "Successfully Sent Organization Join Request"
  },
  {
    id: "86",
    eventGroupId: "Organization Management",
    label: "Failed To Send Organization Join Request",
    value: "Failed To Send Organization Join Request"
  },
  {
    id: "87",
    eventGroupId: "Organization Management",
    label: "Successfully Accepted Organization Invite",
    value: "Successfully Accepted Organization Invite"
  },
  {
    id: "88",
    eventGroupId: "Organization Management",
    label: "Failed To Accept Organization Request",
    value: "Failed To Accept Organization Request"
  },
  {
    id: "89",
    eventGroupId: "Fortellis User Account Management",
    label: "User Registered Successfully On Fortellis",
    value: "User Registered Successfully On Fortellis"
  },
  {
    id: "90",
    eventGroupId: "Fortellis User Account Management",
    label: "User Account Password Recovery Mail Sent Successfully",
    value: "User Account Password Recovery Mail Sent Successfully"
  },
  {
    id: "91",
    eventGroupId: "Fortellis User Account Management",
    label: "User Account Password Changed Successfully",
    value: "User Account Password Changed Successfully"
  },
  {
    id: "92",
    eventGroupId: "Fortellis User Account Management",
    label: "Successfully Updated User Account Profile",
    value: "Successfully Updated User Account Profile"
  },
  {
    id: "93",
    eventGroupId: "Fortellis User Account Management",
    label: "User Account Is Successfully Verified",
    value: "User Account Is Successfully Verified"
  },
  {
    id: "94",
    eventGroupId: "Fortellis User Account Management",
    label: "User Account MFA Activated Successfully",
    value: "User Account MFA Activated Successfully"
  },
  {
    id: "95",
    eventGroupId: "Fortellis User Account Management",
    label: "User Account MFA Deactivated Successfully",
    value: "User Account MFA Deactivated Successfully"
  },
  {
    id: "96",
    eventGroupId: "Fortellis User Account Management",
    label: "User Password Recovered Successfully",
    value: "User Password Recovered Successfully"
  },
  {
    id: "97",
    eventGroupId: "APP Management",
    label: "New App Registered",
    value: "New App Registered"
  },
  {
    id: "98",
    eventGroupId: "APP Management",
    label: "App Removed",
    value: "App Removed"
  },
  {
    id: "99",
    eventGroupId: "APP Management",
    label: "New Api Integration Within an APP",
    value: "New Api Integration Within an APP"
  },
  {
    id: "100",
    eventGroupId: "APP Management",
    label: "Removed Api Integration From an APP",
    value: "Removed Api Integration From an APP"
  },
  {
    id: "101",
    eventGroupId: "APP Management",
    label: "Api Webhook Change",
    value: "Api Webhook Change"
  },
  {
    id: "102",
    eventGroupId: "APP Management",
    label: "Api Terms Accept",
    value: "Api Terms Accept"
  },
  {
    id: "103",
    eventGroupId: "APP Management",
    label: "Pricing Plan Select of an Api",
    value: "Pricing Plan Select of an Api"
  },
  {
    id: "104",
    eventGroupId: "APP Management",
    label: "App Credential Rotation Request",
    value: "App Credential Rotation Request"
  },
  {
    id: "105",
    eventGroupId: "Bulk Subscription",
    label: "Subscription Request Successful",
    value: "Subscription Request Successful"
  },
  {
    id: "106",
    eventGroupId: "Bulk Subscription",
    label: "Subscription Request Failed",
    value: "Subscription Request Failed"
  },
  {
    id: "107",
    eventGroupId: "Bulk Subscription",
    label: "Unsubscribe Request Successful",
    value: "Unsubscribe Request Successful"
  },
  {
    id: "108",
    eventGroupId: "Bulk Subscription",
    label: "Unsubscribe Request Failed",
    value: "Unsubscribe Request Failed"
  },
  {
    id: "109",
    eventGroupId: "Listing Fee Payment",
    label: "Listing Fee Payment Successful",
    value: "Listing Fee Payment Successful"
  },
  {
    id: "110",
    eventGroupId: "Listing Fee Payment",
    label: "Listing Fee Payment Failed",
    value: "Listing Fee Payment Failed"
  },
  {
    id: "111",
    eventGroupId: "Dealer Subscription",
    label: "Dealer Subscribed to App",
    value: "Dealer Subscribed to App"
  },
  {
    id: "112",
    eventGroupId: "Certification Management",
    label: "App Certification",
    value: "App Certification"
  },
  {
    id: "113",
    eventGroupId: "Certification Management",
    label: "App ReCertification",
    value: "App ReCertification"
  },
  {
    id: "114",
    eventGroupId: "App Listing Fee Changed",
    label: "App Listing Fee Changed Successfully",
    value: "App Listing Fee Changed Successfully"
  },
  {
    id: "115",
    eventGroupId: "App Listing Fee Changed",
    label: "Failed To Change App Listing Fee",
    value: "Failed To Change App Listing Fee"
  },
  {
    id: "116",
    eventGroupId: "Certification Payment Date Modification",
    label: "Certification Payment Date Modification Successful",
    value: "Certification Payment Date Modification Successful"
  },
  {
    id: "117",
    eventGroupId: "Certification Payment Date Modification",
    label: "Certification Payment Date Modification Failed",
    value: "Certification Payment Date Modification Failed"
  },
  {
    id: "118",
    eventGroupId: "Organization Management",
    label: "Updated Notification Domains Successfully",
    value: "Updated Notification Domains Successfully"
  },
  {
    id: "119",
    eventGroupId: "Organization Management",
    label: "Failed To Update Notification Domains",
    value: "Failed To Update Notification Domains"
  },
  {
    id: "120",
    eventGroupId: "Payment Terms Condition",
    label: "TnC Saved Successful",
    value: "TnC Saved Successful"
  },
  {
    id: "121",
    eventGroupId: "Payment Terms Condition",
    label: "TnC Saved Failure",
    value: "TnC Saved Failure"
  },
  {
    id: "122",
    eventGroupId: "Certification Fee Payment",
    label: "Certification Fee Payment Successful",
    value: "Certification Fee Payment Successful"
  },
  {
    id: "123",
    eventGroupId: "Certification Fee Payment",
    label: "Certification Fee Payment Failed",
    value: "Certification Fee Payment Failed"
  },
  {
    id: "124",
    eventGroupId: "Recertification Fee Payment",
    label: "Recertification Fee Payment Successful",
    value: "Recertification Fee Payment Successful"
  },
  {
    id: "125",
    eventGroupId: "Recertification Fee Payment",
    label: "Recertification Fee Payment Failed",
    value: "Recertification Fee Payment Failed"
  },
  {
    id: "126",
    eventGroupId: "App Cancel",
    label: "App Cancel Success",
    value: "App Cancel Success"
  },
  {
    id: "127",
    eventGroupId: "App Cancel",
    label: "App Cancel Failed",
    value: "App Cancel Failed"
  },
  {
    id: "128",
    eventGroupId: "Payment Method Edit",
    label: "Listing Fee Edit Successful",
    value: "Listing Fee Edit Successful"
  },
  {
    id: "129",
    eventGroupId: "Payment Method Edit",
    label: "Listing Fee Edit Failed",
    value: "Listing Fee Edit Failed"
  },
  {
    id: "130",
    eventGroupId: "Manage Dealer Cart",
    label: "API added to dealer cart",
    value: "API added to dealer cart"
  },
  {
    id: "131",
    eventGroupId: "Manage Dealer Cart",
    label: "Failed to add API to dealer cart",
    value: "Failed to add API to dealer cart"
  },
  {
    id: "132",
    eventGroupId: "Manage Dealer Cart",
    label: "APIs updated in dealer cart",
    value: "APIs updated in dealer cart"
  },
  {
    id: "133",
    eventGroupId: "Manage Dealer Cart",
    label: "Failed to update APIs in dealer cart",
    value: "Failed to update APIs in dealer cart"
  },
  {
    id: "134",
    eventGroupId: "Manage Dealer Cart",
    label: "API removed from dealer cart",
    value: "API removed from dealer cart"
  },
  {
    id: "135",
    eventGroupId: "Manage Dealer Cart",
    label: "Failed to remove API from dealer cart",
    value: "Failed to remove API from dealer cart"
  },
  {
    id: "136",
    eventGroupId: "Stripe Automatic Card Update",
    label: "Card details Update Successful",
    value: "Card details Update Successful"
  },
  {
    id: "137",
    eventGroupId: "Stripe Automatic Card Update",
    label: "Card details Update Failed",
    value: "Card details Update Failed"
  },
  {
    id: "138",
    eventGroupId: "Bundle Management",
    label: "Create New Bundle",
    value: "Create New Bundle"
  },
  {
    id: "139",
    eventGroupId: "Bundle Management",
    label: "Edit or Update a Bundle",
    value: "Edit or Update a Bundle"
  },
  {
    id: "140",
    eventGroupId: "Bundle Management",
    label: "Bundle Retired",
    value: "Bundle Retired"
  },
  {
    id: "141",
    eventGroupId: "Contract Management",
    label: "Create Contract",
    value: "Create Contract"
  },
  {
    id: "142",
    eventGroupId: "Bundle Management",
    label: "Bundle/Api(s) Shared",
    value: "Bundle/Api(s) Shared"
  },
  {
    id: "143",
    eventGroupId: "API Integration Management",
    label: "Admin - API Integration Plan Update",
    value: "Admin - API Integration Plan Update"
  },
  {
    id: "144",
    eventGroupId: "API Integration Management",
    label: "Admin - API Integration Plan Update Request",
    value: "Admin - API Integration Plan Update Request"
  },
  {
    id: "145",
    eventGroupId: "Dealer Subscription",
    label: "Field Selections Update",
    value: "Field Selections Update"
  },
  {
    id: "146",
    eventGroupId: "Organization Management",
    label: "Updated Organization Enhanced Flags",
    value: "Updated Organization Enhanced Flags"
  },
  {
    id: "147",
    eventGroupId: "Dealer Subscription",
    label: "Consumer Privacy Protection Updated",
    value: "Consumer Privacy Protection Updated"
  },
  {
    id: "148",
    eventGroupId: "Dealer Subscription",
    label: "DMS Update",
    value: "DMS Update"
  },
  {
    id: "149",
    eventGroupId: "Dealer Subscription",
    label: "App API Integration Status Update",
    value: "App API Integration Status Update"
  },
  {
    id: "150",
    eventGroupId: "Dealer Subscription",
    label: "Dealer Unsubscribed from App",
    value: "Dealer Unsubscribed from App"
  },
  {
    id: "151",
    eventGroupId: "API Management",
    label: "API Field Management Enabled",
    value: "API Field Management Enabled"
  },
  {
    id: "152",
    eventGroupId: "Async API Management",
    label: "Async API Field Management Enabled",
    value: "Async API Field Management Enabled"
  },
];

const eventTypeOptions = [
  {
    id: "1",
    eventGroupId: "Subscription Management",
    label: "APPROVE_MANUAL_SUBSCRIPTION",
    value: "Manual Subscription Approval by Publisher"
  },
  {
    id: "2",
    eventGroupId: "Subscription Management",
    label: "DENIED_MANUAL_SUBSCRIPTION",
    value: "Manual Subscription Denied by Publisher"
  },
  {
    id: "3",
    eventGroupId: "API Management",
    label: "API_REGISTER",
    value: "Register API"
  },
  {
    id: "4",
    eventGroupId: "API Management",
    label: "API_REMOVE",
    value: "Remove API"
  },
  {
    id: "5",
    eventGroupId: "API Management",
    label: "API_PUBLIC_PRIVATE_VISIBILITY_CHANGE",
    value: "API Visibility Change"
  },
  {
    id: "6",
    eventGroupId: "API Management",
    label: "API_ACTIVATION_MODEL_CHANGE",
    value: "API Activation Model Change"
  },
  {
    id: "7",
    eventGroupId: "API Management",
    label: "API_SHARE",
    value: "API Share"
  },
  {
    id: "8",
    eventGroupId: "API Management",
    label: "API_UNSHARE",
    value: "API Unshare"
  },
  {
    id: "9",
    eventGroupId: "API Management",
    label: "API_PRICING_PLAN_ADD",
    value: "API Pricing Plan Add"
  },
  {
    id: "10",
    eventGroupId: "API Management",
    label: "API_PRICING_PLAN_UPDATE",
    value: "API Pricing Plan Update"
  },
  {
    id: "11",
    eventGroupId: "API Management",
    label: "API_PRICING_PLAN_RETIRE",
    value: "API Pricing Plan Retire"
  },
  {
    id: "12",
    eventGroupId: "API Management",
    label: "API_TERMS_UPDATE",
    value: "API Terms Update"
  },
  {
    id: "13",
    eventGroupId: "API Management",
    label: "API_ADDENDUM_UPDATE",
    value: "API Addendum Update"
  },
  {
    id: "14",
    eventGroupId: "API Management",
    label: "API_REVISION_REGISTER",
    value: "API Revision Register"
  },
  {
    id: "15",
    eventGroupId: "API Management",
    label: "API_REVISION_REMOVE",
    value: "API Revision Remove"
  },
  {
    id: "16",
    eventGroupId: "API Management",
    label: "API_REVISION_SPEC_UPDATE",
    value: "API Revision Spec Update"
  },
  {
    id: "17",
    eventGroupId: "API Management",
    label: "API_REVISION_TARGET_URL_CHANGE",
    value: "API Revision Target URL Change"
  },
  {
    id: "18",
    eventGroupId: "API Management",
    label: "API_REVISION_ACCESS_MODEL_CHANGE",
    value: "API Revision Access Model Change"
  },
  {
    id: "19",
    eventGroupId: "API Management",
    label: "API_REVISION_SHOW_IN_DIRECTORY_CHANGE",
    value: "API Revision Show In Directory Change"
  },
  {
    id: "20",
    eventGroupId: "Solution Submission",
    label: "APP_LISTING_SUBMIT_SUCCESSFUL",
    value: "Solution Submitted Successfully"
  },
  {
    id: "21",
    eventGroupId: "Solution Submission",
    label: "APP_LISTING_SUBMIT_FAILED",
    value: "Solution Submission Failed"
  },
  {
    id: "22",
    eventGroupId: "Lead Capture",
    label: "APP_LISTING_NEW_LEAD_SUCCESSFUL",
    value: "Lead Captured Successfully"
  },
  {
    id: "23",
    eventGroupId: "Lead Capture",
    label: "APP_LISTING_NEW_LEAD_FAILED",
    value: "Lead Capture Failed"
  },
  {
    id: "24",
    eventGroupId: "App Listing Version Create",
    label: "APP_LISTING_VERSION_CREATE_SUCCESS",
    value: "App Listing Created Successfully"
  },
  {
    id: "25",
    eventGroupId: "App Listing Version Create",
    label: "APP_LISTING_VERSION_CREATE_FAILED",
    value: "Failed To Create App Listing"
  },
  {
    id: "26",
    eventGroupId: "App Submitted In Review",
    label: "APP_LISTING_SUBMITTED_IN_REVIEW_SUCCESSFUL",
    value: "App Submitted In Review Successfully"
  },
  {
    id: "27",
    eventGroupId: "App Submitted In Review",
    label: "APP_LISTING_SUBMITTED_IN_REVIEW_FAILED",
    value: "Failed To Submit App In Review"
  },
  {
    id: "28",
    eventGroupId: "App Listing Approve",
    label: "APP_LISTING_APPROVE_SUCCESSFUL",
    value: "App Approved Suceessfully"
  },
  {
    id: "29",
    eventGroupId: "App Listing Approve",
    label: "APP_LISTING_APPROVE_FAILED",
    value: "Failed To Approve App"
  },
  {
    id: "30",
    eventGroupId: "App Listing Publish",
    label: "APP_LISTING_PUBLISH_SUCCESSFUL",
    value: "App Published Successfully"
  },
  {
    id: "31",
    eventGroupId: "App Listing Publish",
    label: "APP_LISTING_PUBLISH_FAILED",
    value: "Failed To Publish App"
  },
  {
    id: "32",
    eventGroupId: "App Listing Withdrawn",
    label: "APP_LISTING_WITHDRAWN_SUCCESSFUL",
    value: "App Withdrawn Successfully"
  },
  {
    id: "33",
    eventGroupId: "App Listing Withdrawn",
    label: "APP_LISTING_WITHDRAWN_FAILD",
    value: "Failed To Withdraw App"
  },
  {
    id: "34",
    eventGroupId: "App Listing Recall",
    label: "APP_LISTING_RECALL_SUCCESSFUL",
    value: "App Recall Was Successful"
  },
  {
    id: "35",
    eventGroupId: "App Listing Recall",
    label: "APP_LISTING_RECALL_FAILED",
    value: "Failed To Recall App"
  },
  {
    id: "36",
    eventGroupId: "App Listing Reject",
    label: "APP_LISTING_REJECT_SUCCESSFUL",
    value: "App Rejected Successfully"
  },
  {
    id: "37",
    eventGroupId: "App Listing Reject",
    label: "APP_LISTING_REJECT_FAILED",
    value: "Failed To Reject App"
  },
  {
    id: "38",
    eventGroupId: "App Contract Notification Change",
    label: "APP_CONTRACT_NOTIFICATION_CHANGED_SUCCESSFUL",
    value: "App Contract Notification Changed Successfully"
  },
  {
    id: "39",
    eventGroupId: "App Contract Notification Change",
    label: "APP_CONTRACT_NOTIFICATION_CHANGED_FAILED",
    value: "Failed To Change App Contract Notifications"
  },
  {
    id: "40",
    eventGroupId: "App Subscription Enablement Change",
    label: "APP_SUB_ENABLEMENT_CHANGE_SUCCESSFUL",
    value: "App Subscription Enablement Changed Successfully"
  },
  {
    id: "41",
    eventGroupId: "App Subscription Enablement Change",
    label: "APP_SUB_ENABLEMENT_CHANGE_FAILED",
    value: "Failed To Change Subscription Enablement For An App"
  },
  {
    id: "42",
    eventGroupId: "App Data Rights Enablement Change",
    label: "APP_DATA_RIGHTS_ENABLEMENT_CHANGE_SUCCESSFUL",
    value: "App Data Rights Enablement Changed Successfully"
  },
  {
    id: "43",
    eventGroupId: "App Data Rights Enablement Change",
    label: "APP_DATA_RIGHTS_ENABLEMENT_CHANGE_FAILED",
    value: "Failed To Change Data Rights Enablement For An App"
  },
  {
    id: "44",
    eventGroupId: "App Featured Status Change",
    label: "APP_FEATURED_STATUS_CHANGE_SUCCESSFUL",
    value: "Successfully Changed App Featured Status"
  },
  {
    id: "45",
    eventGroupId: "App Featured Status Change",
    label: "APP_FEATURED_STATUS_CHANGE_FAILED",
    value: "Failed To Change Featured Status For An App"
  },
  {
    id: "46",
    eventGroupId: "App Vision Case Enablement Change",
    label: "APP_VISION_CASE_ENABLEMENT_CHANGE_SUCCESSFUL",
    value: "Successfully Changed Vision Case Enablement For An App"
  },
  {
    id: "47",
    eventGroupId: "App Vision Case Enablement Change",
    label: "APP_VISION_CASE_ENABLEMENT_CHANGE_FAILED",
    value: "Failed To Change Vision Case Enablement For An App"
  },
  {
    id: "48",
    eventGroupId: "App Catalog Number Change",
    label: "APP_CATALOG_NUMBER_CHANGE_SUCCESSFUL",
    value: "Successfully Changed Catalog Number For An App"
  },
  {
    id: "49",
    eventGroupId: "App Catalog Number Change",
    label: "APP_CATALOG_NUMBER_CHANGE_FAILED",
    value: "Failed To Change Catalog Number For An App"
  },
  {
    id: "50",
    eventGroupId: "App Dealer Consent Change",
    label: "APP_DEALER_CONSENT_CHANGE_SUCCESSFUL",
    value: "Successfully Changed Dealer Consent Form For An App"
  },
  {
    id: "51",
    eventGroupId: "App Dealer Consent Change",
    label: "APP_DEALER_CONSENT_CHANGE_FAILED",
    value: "Failed To Change Dealer Consent Form For An App"
  },
  {
    id: "52",
    eventGroupId: "App Data Integration Change",
    label: "APP_ORG_DATA_INTEGRATION_CHANGE_SUCCESSFUL",
    value: "Successfully Changed Data Integration For An App"
  },
  {
    id: "53",
    eventGroupId: "App Data Integration Change",
    label: "APP_ORG_DATA_INTEGRATION_CHANGE_FAILED",
    value: "Failed To Change Data Integration For An App"
  },
  {
    id: "54",
    eventGroupId: "App API Integration Change",
    label: "APP_API_INTEGRATION_CHANGE_SUCCESSFUL",
    value: "Suceessfully Updated APIS Into An App"
  },
  {
    id: "55",
    eventGroupId: "App API Integration Change",
    label: "APP_API_INTEGRATION_CHANGE_FAILED",
    value: "Failed To Update API Integration For An App"
  },
  {
    id: "56",
    eventGroupId: "App Listing Revoke",
    label: "APP_LISTING_REVOKE_SUCCESSFUL",
    value: "App Listing Revoked Successfully"
  },
  {
    id: "57",
    eventGroupId: "App Listing Revoke",
    label: "APP_LISTING_REVOKE_FAILED",
    value: "Failed To Revoke An App"
  },
  {
    id: "58",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_REGISTER",
    value: "Async API Register"
  },
  {
    id: "59",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_REMOVE",
    value: "Remove Async API"
  },
  {
    id: "60",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_PUBLIC_PRIVATE_VISIBILITY_CHANGE",
    value: "Async API Visibility Change"
  },
  {
    id: "61",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_SHOW_IN_DIRECTORY_CHANGE",
    value: "Async API Show In Directory Change"
  },
  {
    id: "62",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_PRICING_PLAN_ADD",
    value: "Async API Pricing Plan Add"
  },
  {
    id: "63",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_PRICING_PLAN_UPDATE",
    value: "Async API Pricing Plan Update"
  },
  {
    id: "64",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_PRICING_PLAN_RETIRE",
    value: "Async API Pricing Plan Retire"
  },
  {
    id: "65",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_TERMS_UPDATE",
    value: "Async API Terms Update"
  },
  {
    id: "66",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_ADDENDUM_UPDATE",
    value: "Async API Addendum Update"
  },
  {
    id: "67",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_SHARE",
    value: "Async API Share"
  },
  {
    id: "68",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_UNSHARE",
    value: "Async API Unshare"
  },
  {
    id: "69",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_SUBSCRIPTION_DEACTIVATE",
    value: "Async API Subscription Deactivate"
  },
  {
    id: "70",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_SPEC_UPDATE",
    value: "Async API Spec Update"
  },
  {
    id: "71",
    eventGroupId: "API Integration Management",
    label: "API_FREE_TRIAL_OVERRIDE",
    value: "API Free Trial Override Date"
  },
  {
    id: "72",
    eventGroupId: "Organization Management",
    label: "ORG_REGISTER_SUCCESSFUL",
    value: "Organization Registered Successfully"
  },
  {
    id: "73",
    eventGroupId: "Organization Management",
    label: "ORG_REGISTER_FAILED",
    value: "Failed To Create Organization"
  },
  {
    id: "74",
    eventGroupId: "Organization Management",
    label: "ORG_USER_REMOVAL_SUCCESSFUL",
    value: "User Successfully Removed From An Organization"
  },
  {
    id: "75",
    eventGroupId: "Organization Management",
    label: "ORG_USER_REMOVAL_FAILED",
    value: "Failed To Remove User From An Organization"
  },
  {
    id: "76",
    eventGroupId: "Organization Management",
    label: "ORG_UPDATE_SUCCESSFUL",
    value: "Successfully Updated Organization Profile"
  },
  {
    id: "77",
    eventGroupId: "Organization Management",
    label: "ORG_UPDATE_FAILED",
    value: "Failed To Update Organization Profile"
  },
  {
    id: "78",
    eventGroupId: "Organization Management",
    label: "ORG_USER_ADD_SUCCESS",
    value: "User Added To Organization Successfully"
  },
  {
    id: "79",
    eventGroupId: "Organization Management",
    label: "ORG_USER_ROLE_ASSIGN_SUCCESSFUL",
    value: "Successfully Assigned New Role To An User"
  },
  {
    id: "80",
    eventGroupId: "Organization Management",
    label: "ORG_USER_ROLE_ASSIGN_FAILED",
    value: "Failed To Assign Role To An User"
  },
  {
    id: "81",
    eventGroupId: "Organization Management",
    label: "ORG_USER_ROLE_UNASSIGN_SUCCESSFUL",
    value: "Successfully Unassigned Role To An user"
  },
  {
    id: "82",
    eventGroupId: "Organization Management",
    label: "ORG_USER_ROLE_UNASSIGN_FAILED",
    value: "Failed To Unassign Role To An User"
  },
  {
    id: "83",
    eventGroupId: "Organization Management",
    label: "ORG_USER_INVITE_SUCCESSFUL",
    value: "User Invited To Org Successfully"
  },
  {
    id: "84",
    eventGroupId: "Organization Management",
    label: "ORG_USER_INVITE_FAILED",
    value: "Failed To Invite User To An Org"
  },
  {
    id: "85",
    eventGroupId: "Organization Management",
    label: "ORG_JOIN_REQUEST_SUCCESSFUL",
    value: "Successfully Sent Organization Join Request"
  },
  {
    id: "86",
    eventGroupId: "Organization Management",
    label: "ORG_JOIN_REQUEST_FAILED",
    value: "Failed To Send Organization Join Request"
  },
  {
    id: "87",
    eventGroupId: "Organization Management",
    label: "ORG_INVITE_ACCEPT_SUCCESSFUL",
    value: "Successfully Accepted Organization Invite"
  },
  {
    id: "88",
    eventGroupId: "Organization Management",
    label: "ORG_INVITE_ACCEPT_FAILED",
    value: "Failed To Accept Organization Request"
  },
  {
    id: "89",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_REGISTER_SUCCESSFUL",
    value: "User Registered Successfully On Fortellis"
  },
  {
    id: "90",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_PWD_RECOVERY_EMAIL_SUCCESSFUL",
    value: "User Account Password Recovery Mail Sent Successfully"
  },
  {
    id: "91",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_PWD_CHANGE_SUCCESSFUL",
    value: "User Account Password Changed Successfully"
  },
  {
    id: "92",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_ACCOUT_PROFILE_CHANGE_SUCCESSFUL",
    value: "Successfully Updated User Account Profile"
  },
  {
    id: "93",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_ACCOUNT_VERIFIED_SUCCESSFUL",
    value: "User Account Is Successfully Verified"
  },
  {
    id: "94",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_ACCOUNT_MFA_ACTIVATE_SUCCESSFUL",
    value: "User Account MFA Activated Successfully"
  },
  {
    id: "95",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_ACCOUNT_MFA_DEACTIVATE_SUCCESSFUL",
    value: "User Account MFA Deactivated Successfully"
  },
  {
    id: "96",
    eventGroupId: "Fortellis User Account Management",
    label: "USER_PWD_RECOVERY_SUCCESSFUL",
    value: "User Password Recovered Successfully"
  },
  {
    id: "97",
    eventGroupId: "APP Management",
    label: "APP_REGISTER",
    value: "New App Registered"
  },
  {
    id: "98",
    eventGroupId: "APP Management",
    label: "APP_REMOVE",
    value: "App Removed"
  },
  {
    id: "99",
    eventGroupId: "APP Management",
    label: "APP_API_INTEGRATION_ADD",
    value: "New Api Integration Within an APP"
  },
  {
    id: "100",
    eventGroupId: "APP Management",
    label: "APP_API_INTEGRATION_REMOVE",
    value: "Removed Api Integration From an APP"
  },
  {
    id: "101",
    eventGroupId: "APP Management",
    label: "APP_WEBHOOK_CHANGE",
    value: "Api Webhook Change"
  },
  {
    id: "102",
    eventGroupId: "APP Management",
    label: "APP_API_TERMS_ACCEPT",
    value: "Api Terms Accept"
  },
  {
    id: "103",
    eventGroupId: "APP Management",
    label: "APP_API_PRICING_SELECT",
    value: "Pricing Plan Select of an Api"
  },
  {
    id: "104",
    eventGroupId: "APP Management",
    label: "APP_CREDENTIAL_ROTATION_REQUEST",
    value: "App Credential Rotation Request"
  },
  {
    id: "105",
    eventGroupId: "Bulk Subscription",
    label: "BULK_SUBSCRIPTION_SUCCESSFUL",
    value: "Subscription Request Successful"
  },
  {
    id: "106",
    eventGroupId: "Bulk Subscription",
    label: "BULK_SUBSCRIPTION_FAILURE",
    value: "Subscription Request Failed"
  },
  {
    id: "107",
    eventGroupId: "Bulk Subscription",
    label: "BULK_UNSUBSCRIBE_SUCCESSFUL",
    value: "Unsubscribe Request Successful"
  },
  {
    id: "108",
    eventGroupId: "Bulk Subscription",
    label: "BULK_UNSUBSCRIBE_FAILURE",
    value: "Unsubscribe Request Failed"
  },
  {
    id: "109",
    eventGroupId: "Listing Fee Payment",
    label: "LISTING_FEE_PAYMENT_SUCCESSFUL",
    value: "Listing Fee Payment Successful"
  },
  {
    id: "110",
    eventGroupId: "Listing Fee Payment",
    label: "LISTING_FEE_PAYMENT_FAILED",
    value: "Listing Fee Payment Failed"
  },
  {
    id: "111",
    eventGroupId: "Dealer Subscription",
    label: "NEW_SUBSCRIPTION",
    value: "Dealer Subscribed to App"
  },
  {
    id: "112",
    eventGroupId: "Certification Management",
    label: "Certification",
    value: "App Certification"
  },
  {
    id: "113",
    eventGroupId: "Certification Management",
    label: "ReCertification",
    value: "App ReCertification"
  },
  {
    id: "114",
    eventGroupId: "App Listing Fee Changed",
    label: "App Listing Fee Changed Successfully",
    value: "App Listing Fee Changed Successfully"
  },
  {
    id: "115",
    eventGroupId: "App Listing Fee Changed",
    label: "Failed To Change App Listing Fee",
    value: "Failed To Change App Listing Fee"
  },
  {
    id: "116",
    eventGroupId: "Certification Payment Date Modification",
    label: "Certification Payment Date Modification Successful",
    value: "Certification Payment Date Modification Successful"
  },
  {
    id: "117",
    eventGroupId: "Certification Payment Date Modification",
    label: "Certification Payment Date Modification Failed",
    value: "Certification Payment Date Modification Failed"
  },
  {
    id: "118",
    eventGroupId: "Organization Management",
    label: "UPDATE_NOTIFICATION_DOMAIN_SUCCESSFUL",
    value: "Updated Notification Domains Successfully"
  },
  {
    id: "119",
    eventGroupId: "Organization Management",
    label: "UPDATE_NOTIFICATION_DOMAIN_FAILED",
    value: "Failed To Update Notification Domains"
  },

  {
    id: "120",
    eventGroupId: "Payment Terms Condition",
    label: "TnC Saved Successful",
    value: "TnC Saved Successful"
  },
  {
    id: "121",
    eventGroupId: "Payment Terms Condition",
    label: "TnC Saved Failure",
    value: "TnC Saved Failure"
  },
  {
    id: "122",
    eventGroupId: "Certification Fee Payment",
    label: "Certification Fee Payment Successful",
    value: "Certification Fee Payment Successful"
  },
  {
    id: "123",
    eventGroupId: "Certification Fee Payment",
    label: "Certification Fee Payment Failed",
    value: "Certification Fee Payment Failed"
  },
  {
    id: "124",
    eventGroupId: "Recertification Fee Payment",
    label: "Recertification Fee Payment Successful",
    value: "Recertification Fee Payment Successful"
  },
  {
    id: "125",
    eventGroupId: "Recertification Fee Payment",
    label: "Recertification Fee Payment Failed",
    value: "Recertification Fee Payment Failed"
  },
  {
    id: "126",
    eventGroupId: "App Cancel",
    label: "App Cancel Success",
    value: "App Cancel Success"
  },
  {
    id: "127",
    eventGroupId: "App Cancel",
    label: "App Cancel Failed",
    value: "App Cancel Failed"
  },
  {
    id: "128",
    eventGroupId: "Payment Method Edit",
    label: "Listing Fee Edit Successful",
    value: "Listing Fee Edit Successful"
  },
  {
    id: "129",
    eventGroupId: "Payment Method Edit",
    label: "Listing Fee Edit Failed",
    value: "Listing Fee Edit Failed"
  },
  {
    id: "130",
    eventGroupId: "Manage Dealer Cart",
    label: "API added to dealer cart",
    value: "API added to dealer cart"
  },
  {
    id: "131",
    eventGroupId: "Manage Dealer Cart",
    label: "Failed to add API to dealer cart",
    value: "Failed to add API to dealer cart"
  },
  {
    id: "132",
    eventGroupId: "Manage Dealer Cart",
    label: "APIs updated in dealer cart",
    value: "APIs updated in dealer cart"
  },
  {
    id: "133",
    eventGroupId: "Manage Dealer Cart",
    label: "Failed to update APIs in dealer cart",
    value: "Failed to update APIs in dealer cart"
  },
  {
    id: "134",
    eventGroupId: "Manage Dealer Cart",
    label: "API removed from dealer cart",
    value: "API removed from dealer cart"
  },
  {
    id: "135",
    eventGroupId: "Manage Dealer Cart",
    label: "Failed to remove API from dealer cart",
    value: "Failed to remove API from dealer cart"
  },
  {
    id: "136",
    eventGroupId: "Stripe Automatic Card Update",
    label: "Card details Update Successful",
    value: "Card details Update Successful"
  },
  {
    id: "137",
    eventGroupId: "Stripe Automatic Card Update",
    label: "Card details Update Failed",
    value: "Card details Update Failed"
  },
  {
    id: "138",
    eventGroupId: "Bundle Management",
    label: "Create New Bundle",
    value: "Create New Bundle"
  },
  {
    id: "139",
    eventGroupId: "Bundle Management",
    label: "Edit or Update a Bundle",
    value: "Edit or Update a Bundle"
  },
  {
    id: "140",
    eventGroupId: "Bundle Management",
    label: "Bundle Retired",
    value: "Bundle Retired"
  },
  {
    id: "141",
    eventGroupId: "Contract Management",
    label: "Create Contract",
    value: "Create Contract"
  },
  {
    id: "142",
    eventGroupId: "Bundle Management",
    label: "Bundle/Api(s) Shared",
    value: "Bundle/Api(s) Shared"
  },
  {
    id: "143",
    eventGroupId: "API Integration Management",
    label: "Admin - API Integration Plan Update",
    value: "Admin - API Integration Plan Update"
  },
  {
    id: "144",
    eventGroupId: "API Integration Management",
    label: "Admin - API Integration Plan Update Request",
    value: "Admin - API Integration Plan Update Request"
  },
  {
    id: "145",
    eventGroupId: "Dealer Subscription",
    label: "FIELD_SELECTIONS_UPDATE",
    value: "Field Selections Update"
  },
  {
    id: "146",
    eventGroupId: "Organization Management",
    label: "ORG_ENHANCED_FLAGS_UPDATED",
    value: "Updated Organization Enhanced Flags"
  },
  {
    id: "147",
    eventGroupId: "Dealer Subscription",
    label: "CPP_UPDATED",
    value: "Consumer Privacy Protection Updated"
  },
  {
    id: "148",
    eventGroupId: "Dealer Subscription",
    label: "DMS_UPDATE",
    value: "DMS Update"
  },
  {
    id: "149",
    eventGroupId: "Dealer Subscription",
    label: "API_INTEGRATION_STATUS_UPDATE",
    value: "App API Integration Status Update"
  },
  {
    id: "150",
    eventGroupId: "Dealer Subscription",
    label: "UNSUBSCRIBED",
    value: "Dealer Unsubscribed from App"
  },
  {
    id: "151",
    eventGroupId: "API Management",
    label: "API_FIELD_MANAGEMENT_ENABLED",
    value: "API Field Management Enabled"
  },
  {
    id: "152",
    eventGroupId: "Async API Management",
    label: "ASYNC_API_FIELD_MANAGEMENT_ENABLED",
    value: "Async API Field Management Enabled"
  },
];

export const filtersConfig = [
  {
    keyName: "userEmailId",
    displayName: "User Email",
    type: types.TEXT_INPUT,
    menueSelection: false
  },
  {
    keyName: "entityId",
    displayName: "Entity ID",
    type: types.TEXT_INPUT_ORG,
    menueSelection: false
  },
  {
    keyName: "eventName",
    displayName: "Event Name",
    type: types.RADIO_GROUP,
    eventGroupOptions: eventGroupOptions,
    eventNameOptions: eventNameOptions,
    menueSelection: false
  },
  {
    keyName: "eventType",
    displayName: "Event Type",
    type: types.DROP_DOWN,
    eventGroupOptions: eventGroupOptions,
    eventTypeOptions: eventTypeOptions,
    menueSelection: false
  },
  {
    keyName: "Date",
    displayName: "Date range",
    endDateLabel: "End date",
    startDateLabel: "Start date",
    type: types.DATE_RANGE,
    menueSelection: false
  }
];

const FilterInput = ({ keyName, displayName, updateState, filterState }) => {
  const handleChange = event => {
    updateState({ [keyName]: event.target.value });
  };
  return (
    <div>
      {keyName === "entityId" && (
        <div className="info-class">
          Filter events by the specified Entity ID.
        </div>
      )}
      {keyName === "userEmailId" && (
        <div className="info-class">
          Filter events by the specified email address.
        </div>
      )}
      <Input
        label={displayName}
        name={displayName}
        onChange={handleChange}
        value={filterState[keyName] || ""}
      />
    </div>
  );
};

const FilterRadioGroup = ({
  keyName,
  displayName,
  eventGroupOptions,
  eventNameOptions,
  updateState,
  filterState
}) => {
  const [selectedGroup, setSelectedGroup] = useState(
    filterState.eventName || "Select event group"
  );
  const [selectedEventName, setSelectedEventName] = useState(
    filterState.eventType || "Select event name"
  );

  const handleGroupChange = event => {
    setSelectedGroup(event.value);
    setSelectedEventName("Select event name");
    updateState({
      [keyName]: event.value,
      eventType: null
    });
  };
  const handleEventNameChange = event => {
    setSelectedEventName(event.value);
    updateState({
      eventType: event.value
    });
  };
  let eventTypeOptionsLocal = [];
  if (!!filterState && filterState.eventName) {
    eventNameOptions.map(e => {
      if (e.eventGroupId === filterState.eventName) {
        eventTypeOptionsLocal.push(e);
      }
    });
  } else {
    eventTypeOptionsLocal = [...eventNameOptions];
  }

  return (
    <div>
      <DropdownMenu
        className="event-type-dropdown"
        label="Event group"
        onChange={handleGroupChange}
        options={eventGroupOptions}
        value={{
          label: "" + selectedGroup,
          value: selectedGroup
        }}
      />
      <br />
      <DropdownMenu
        className="event-type-dropdown"
        label="Event name"
        onChange={handleEventNameChange}
        options={eventTypeOptionsLocal}
        value={{
          label: "" + selectedEventName,
          value: selectedEventName
        }}
      />
    </div>
  );
};

const FilterDropDownGroup = ({
  keyName,
  displayName,
  eventGroupOptions,
  eventTypeOptions,
  updateState,
  filterState
}) => {
  let eventSelected = "Select event type";
  if (!!filterState && filterState.eventType) {
    eventSelected = eventTypeOptions.find(
      e => e.value === filterState.eventType
    ).label;
  }
  const [selectedGroup, setSelectedGroup] = useState(
    filterState.eventName || "Select event group"
  );
  const [selectedEventType, setSelectedEventType] = useState(eventSelected);
  const handleGroup = event => {
    setSelectedGroup(event.value);
    setSelectedEventType("Select event type");
    updateState({
      eventName: event.value,
      eventType: null
    });
  };
  const handleEventType = event => {
    setSelectedEventType(event.label);
    updateState({ eventType: event.value });
  };
  let eventTypeOptionsLocal = [];
  if (!!filterState && filterState.eventName) {
    eventTypeOptions.map(e => {
      if (e.eventGroupId === filterState.eventName) {
        eventTypeOptionsLocal.push(e);
      }
    });
  } else {
    eventTypeOptionsLocal = [...eventTypeOptions];
  }
  return (
    <div>
      <DropdownMenu
        className="event-type-dropdown"
        label="Event group"
        onChange={handleGroup}
        options={eventGroupOptions}
        value={{
          label: "" + selectedGroup,
          value: selectedGroup
        }}
      />
      <br />
      <DropdownMenu
        className="event-type-dropdown"
        label="Event type"
        onChange={handleEventType}
        options={eventTypeOptionsLocal}
        value={{
          label: "" + selectedEventType,
          value: selectedEventType
        }}
      />
    </div>
  );
};

const FilterDateRangePicker = ({
  keyName,
  endDateLabel,
  startDateLabel,
  updateState,
  filterState
}) => {
  const handleDateChange = ({
    endDate: momentEndDateObject,
    startDate: momentStartDateObject
  }) => {
    updateState({
      [`start${keyName}`]: momentStartDateObject,
      [`end${keyName}`]: momentEndDateObject
    });
  };

  return (
    <div>
      <DateRangePicker
        allowDaysInPast={true}
        blockedDateRanges={[
          {
            startDate: moment().add(1, "day"),
            endDate: moment().add(10, "years")
          }
        ]}
        endDate={filterState[`end${keyName}`]}
        endDateLabel={endDateLabel}
        id="date-range-picker-error"
        onDatesChange={handleDateChange}
        startDate={filterState[`start${keyName}`]}
        startDateLabel={startDateLabel}
      />
    </div>
  );
};

const findConfigItem = keyName => {
  return filtersConfig.find(item => item.keyName === keyName);
};

export const RenderFilterValues = ({ keyName, updateState, filterState }) => {
  const configItem = findConfigItem(keyName);

  switch (configItem.type) {
    case types.TEXT_INPUT:
      return (
        <React.Fragment>
          <FilterInput
            {...configItem}
            updateState={updateState}
            filterState={filterState}
          />
        </React.Fragment>
      );
    case types.TEXT_INPUT_ORG:
      return (
        <React.Fragment>
          <FilterInput
            {...configItem}
            updateState={updateState}
            filterState={filterState}
          />
        </React.Fragment>
      );
    case types.RADIO_GROUP:
      return (
        <React.Fragment>
          <div className="info-class">
            Filter events by selecting either a group name, an event name, or
            both.
          </div>
          <FilterRadioGroup
            {...configItem}
            updateState={updateState}
            filterState={filterState}
          />
        </React.Fragment>
      );
    case types.DROP_DOWN:
      return (
        <React.Fragment>
          <div className="info-class">
            Filter events by selecting either a group name, an event type, or
            both.
          </div>
          <FilterDropDownGroup
            {...configItem}
            updateState={updateState}
            filterState={filterState}
          />
        </React.Fragment>
      );
    case types.DATE_RANGE:
      return (
        <React.Fragment>
          <div className="info-class">
            Filter events by the selected timespan.
          </div>
          <FilterDateRangePicker
            {...configItem}
            updateState={updateState}
            filterState={filterState}
          />
        </React.Fragment>
      );
    default:
      return "";
  }
};
