import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";

const entityReqObj = {
  url: config.api.bulk_user_import_url,
  method: "POST"
};

export const actions = {
  bulkUserUpload: (accessToken, dataobject) => ({
    [CALL_API]: {
      ...entityReqObj,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      data: dataobject,
      types: [
        types.ENTITY_POST_REQUEST,
        types.ENTITY_POST_RECEIVE,
        types.ENTITY_POST_ERROR,
        types.MODEL_RESET
      ]
    }
  })
};
