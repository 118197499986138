import React, { Component } from "react";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { actions } from "./userUploadActions";
import { CSVLink } from "react-csv";
// UI COMPONENTS
import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { FluidItem } from "@cdk-uip/react-fluid";
import { LoadingButton } from "../components/LoadingButton";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { DropzoneArea } from "material-ui-dropzone/dist/index";
const csvData = [
  ["locationName", "firstName", "lastName", "email", "entityId", "roleName"],
  [
    "Test Organisation",
    "UserFirstName",
    "UserLastName",
    "sample.email@domain.com",
    "1fe2202c-4506-45fc-9772-71d772ac3b70",
    "Hailer-User"
  ]
];
const linkCSS = {
  float: "Right",
  "text-decoration": "none"
};
class UserUpload extends Component {
  constructor(props) {
    super(props);
    this.fileReader = new FileReader();
    this.state = {
      files: [],
      snack: false,
      submitVisibility: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {}

  onSubmit = event => {
    event.preventDefault();
    const {
      auth: { accessToken }
    } = this.props;
    const fileAsBinaryString = this.fileReader.result;
    const jsonData = [];
    const csv = require("csvtojson");
    csv({
      noheader: true,
      output: "json"
    })
      .fromString(fileAsBinaryString)
      .then(csvRows => {
        csvRows.forEach((aCsvRow, i) => {
          if (i !== 0) {
            const builtObject = {};

            Object.keys(aCsvRow).forEach(aKey => {
              const valueToAddInBuiltObject = aCsvRow[aKey];
              const keyToAddInBuiltObject = csvRows[0][aKey];
              builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
            });
            jsonData.push(builtObject);
          }
        });

        this.props.bulkUserUpload(accessToken, jsonData);
      });
  };

  handleChange(file) {
    console.log(file);
    if (this.validateFileExtension(file)) {
      this.handleUpload(file);
    } else {
      this.props.showSnackbar(`Invalid csv file ${file.name} uploaded.`);
    }
  }

  validateFileExtension(file) {
    if (!file) {
      return false;
    }
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    if (ext === "csv") {
      return true;
    } else {
      return false;
    }
  }

  onDropRejected(file) {
    this.props.showSnackbar(`Invalid csv file ${file[0].name} uploaded.`);
  }

  handleFileDelete(file) {
    this.setState({ submitVisibility: false });
  }

  render() {
    const { submitVisibility, disabled = false } = this.state;

    var acceptedMimeTypes = [
      "text/csv",
      "application/csv",
      "application/vnd.ms-excel",
      ""
    ];
    return (
      <React.Fragment>
        <form id="bulk-upload-form" onSubmit={this.onSubmit}>
          <LayoutGrid>
            <LayoutGridCell span={12} spanTablet={8} spanPhone={4}>
              <Elevation z={10} className="cdk-user-upload">
                <Card>
                  <CardHeader>
                    <CardTitle large>CSV Upload</CardTitle>
                    <CardSubtitle />
                    <CardSubtitle />
                  </CardHeader>
                  <CardText />

                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={acceptedMimeTypes}
                    showPreviewsInDropzone={false}
                    showPreviews={true}
                    showAlerts={false}
                    dropzoneText="Drag and drop an csv file here or click"
                    onDrop={this.handleChange}
                    onDelete={this.handleFileDelete}
                    onDropRejected={this.onDropRejected}
                  />
                </Card>
                <Card>
                  <FluidItem>
                    <LoadingButton
                      raised
                      primary
                      dense
                      // onClick={this.onSubmit}
                      disabled={submitVisibility ? disabled : true}
                      type="submit"
                    >
                      Submit
                    </LoadingButton>

                    <CSVLink
                      data={csvData}
                      filename={"Bulk_User_Onboarding.csv"}
                      uFEFF={false}
                      style={linkCSS}
                    >
                      Download Sample CSV
                    </CSVLink>
                  </FluidItem>
                </Card>
              </Elevation>
            </LayoutGridCell>
          </LayoutGrid>
        </form>
      </React.Fragment>
    );
  }
  handleUpload = file => {
    this.setState(
      {
        apiSpecFile: file,
        apiSpecLocation: file.name,
        validateSpecLoading: true
      },

      () => {
        try {
          this.fileReader.onload = () => {
            console.log(this.fileReader.result);
            this.setState({ submitVisibility: true });
          };

          this.fileReader.readAsText(file, "ISO-8859-1");
        } catch (err) {
          console.log(err.message);
          this.props.showSnackbar(`Trying to upload invalid csv file.`);
        }
      }
    );
  };
}

export default connect(
  state => ({
    bulkUserUploadManagement: state.bulkUserUploadManagement
  }),
  actions
)(withAuth(UserUpload));
