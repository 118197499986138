import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";

export const actions = {
  getApp: ({ accessToken, appId }) => ({
    [CALL_API]: {
      url: `${config.api.solutions_url}/${appId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [types.GET_APP_REQUEST, types.GET_APP_RECIEVE, types.GET_APP_ERROR]
    }
  }),
  getOrgApps: ({ accessToken, orgId }) => ({
    [CALL_API]: {
      url: `${config.api.solutions_url}?orgId=${orgId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.GET_ORG_APPS_REQUEST,
        types.GET_ORG_APPS_RECEIVE,
        types.GET_ORG_APP_ERROR
      ]
    }
  }),
  getAppSubscriptionReport: ({ accessToken, appId, displayName }) => ({
    [CALL_API]: {
      url: `${config.api.subscriptions_base_uri}/report?solutionId=${appId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        {
          appName: displayName,
          type: types.GET_APP_SUBSCRIPTION_REPORT_REQUEST
        },
        {
          appName: displayName,
          type: types.GET_APP_SUBSCRIPTION_REPORT_RECEIVE
        },
        { appName: displayName, type: types.GET_APP_SUBSCRIPTION_REPORT_ERROR }
      ]
    }
  })
};
