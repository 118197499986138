import React, { useState, useEffect, Fragment } from "react";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import BundleCard from "./BundleCard";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import axios from "axios";
import "./style.css";
import config from "../config/app.conf.json";

function BundleList({
  setBundleList,
  setUnAuthorizedUser,
  bundleList,
  entity = {},
  accessToken
}) {
  const [fetchingBundle, setFetchingBundle] = useState(false);

  useEffect(() => {
    setFetchingBundle(true);
    if (accessToken !== null) {
      axios
        .get(`${config.api.api_gateway_url}/v1/admin/bundles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          setFetchingBundle(false);
          setBundleList(
            response?.data?.items.sort(
              (a, b) => new Date(b.updated) - new Date(a.updated)
            )
          );
          setUnAuthorizedUser(false);
        })
        .catch(error => {
          setUnAuthorizedUser(true);
          setFetchingBundle(false);
        });
    }
  }, [accessToken]);
  return (
    <Fragment>
      {fetchingBundle ? (
        <div className="loading-container">
          <h3>Loading Bundle Details...</h3>
          <CircularProgress />
        </div>
      ) : (
        <div className="bundle-list_container">
          <Grid>
            <Row></Row>
            <Row>
              {bundleList.map((bundle, index) => (
                <Cell
                  desktopColumns={6}
                  phoneColumns={5}
                  tabletColumns={5}
                  key={index}
                >
                  <BundleCard
                    bundleList={bundleList}
                    apiBundle={bundle}
                    entity={entity}
                  />
                </Cell>
              ))}
            </Row>
          </Grid>
        </div>
      )}
    </Fragment>
  );
}
export default BundleList;