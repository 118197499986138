import { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  TableContainer,
  TableContent,
  TableHeader,
  TABLE_LAYOUT_OPTIONS,
  CELL_RENDERERS,
  usePreventTableScrollingRef,
  Button,
  BUTTON_VARIANTS,
} from "cdk-radial";
import classNames from "classnames";

const EllipsisCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">
    {value || ''}
  </div>
);

export default function SharedOrgsTable({orgs}) {
  const [data, setData] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const gridRef = useRef();
  const columns = [
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "orgName",
      headerName: "Organization Name",
      minWidth: 250,
      sortable: true,
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "location",
      headerName: "Location",
      sortable: true,
      minWidth: 300
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "orgId",
      headerName: "Org ID",
      minWidth: 300,
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "createdBy",
      headerName: "Created By",
      minWidth: 160,
    },
    {
      cellRendererFramework: EllipsisCellRenderer,
      field: "accessGiven",
      headerName: "Access Given",
      minWidth: 160,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      cellRendererFramework: (params) => {
        return <Button text="Remove" isDisabled={true} variant={BUTTON_VARIANTS.TEXT} />;
      },
    },
  ];
  const tableContentRef = usePreventTableScrollingRef();

  useEffect(() => {
    const tableData = [];
    orgs.forEach((org) => {
      tableData.push({
        orgName: org?.organizationName,
        orgId: org?.organizationId,
        location: org?.location,
        createdBy: org?.createdBy,
        accessGiven: org?.created?.split("T")[0],
        action: "remove",
      });
    });
    setData(tableData);
  }, [orgs]);

  const headerProps = {
    className: "",
    dataTestId: `table-header`,
    headerTitle: "Organizations with Bundle Access",
    hideBorder: false,
    hideTableHeader: false,
    hideTableTitle: false,
    id: `table-header`,
    isAlwaysExpanded: false,
    isSearchable: false,
    isPrintable: false,
    isDownloadable: false,
    overflowButtonProps: undefined,
    searchInputName: 'table-search-input-name',
    searchInputValue: '',
    searchPlaceholder: 'Search all columns'
  };

  const handleGridReady = (gridParams) => {
    setGridApi(gridParams.api);
    gridParams.api.sizeColumnsToFit();
    gridParams.api.refreshCells({
      force: true,
    });
  };

  return (
    <>
      <TableContainer
        id="ag-grid-container"
        className={classNames("select-apis-table", "ag-theme-balham")}
      >
        <TableHeader {...headerProps} />
        <TableContent
          data-testid="table-content"
          className="select-apis-table-content"
          ref={tableContentRef}
        >
          <AgGridReact
            columnDefs={columns}
            domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
            rowData={data}
            rowHeight={50}
            style={{ width: "100%" }}
            ref={gridRef}
            onGridReady={handleGridReady}
            suppressRowClickSelection={true}
          />
        </TableContent>
      </TableContainer>
    </>
  );
}
