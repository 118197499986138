import withNavigation from "./withNavigation";

const WithNavigationToHome = withNavigation([
  {
    text: "Back To Home",
    goTo: "/"
  }
]);

export default WithNavigationToHome;
