import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@cdk-uip/react-icon";
import { Button } from "@cdk-uip/react-button";
import { TextArea } from "@cdk-uip/react-text-area";
import { Typography } from "@cdk-uip/react-typography";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { FormattedMessage } from "react-intl";

class PendingUserNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialNotes: props.textAreaNotes,
      textAreaNotes: props.textAreaNotes
    };
  }

  static propTypes = {
    noteHandler: PropTypes.func.isRequired,
    errorHandler: PropTypes.func.isRequired
  };

  static defaultProps = {};

  isSaveDisabled = () => {
    if (this.props.updateStoredNoteReducer.fetchingId) {
      return true;
    }
    if (this.state.initialNotes === this.state.textAreaNotes) {
      return true;
    }
    return false;
  };

  updatePendingUserNoteContentHandler = () => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.noteHandler({
      uid: this.props.uid,
      accessToken,
      storedNote: this.state.textAreaNotes
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.updateStoredNoteReducer.fetchingId === this.props.uid &&
      this.props.updateStoredNoteReducer.fetchingId !== this.props.uid
      /*
      prevProps.updateStoredNoteReducer.isFetching === true &&
      this.props.updateStoredNoteReducer.isFetching === false
      */
    ) {
      if (!this.props.updateStoredNoteReducer.error) {
        const {
          auth: { accessToken }
        } = this.props;
        // Intent here is to fetch users on every successful approval or rejection,
        // that way the user list will always be latest
        // especially when there are multiple admins working on approvals.
        this.props.fetchusers({ accessToken });
      } else {
        console.log(
          "ERROR LOGGING: " + this.props.updateStoredNoteReducer.error
        );
        // THE FOLLOWING HAPPENS FOR **ALL** CARDS/NOTES/ERRORMODALS
        // #TODO: LIMIT / SUPPRESS BASED ON NOTE/UID BEING ACTED ON.
        this.props.errorHandler({
          message: this.props.userName + " Save Note Failed: Please Try Again."
        });
      }
    }
  }

  render() {
    const {
      updateStoredNoteReducer: { fetchingId, isFetching }
    } = this.props;
    return (
      <React.Fragment>
        <Typography
          className={"c-pending-user-note__"}
          type="subtitle1"
          element="p"
        >
          <Icon className={"c-card-pending-users__info-icon"}>
            edit {/* pencil icon */}
          </Icon>
          <FormattedMessage
            className={"TODO__BEM-this-thing"}
            id="PendingActivations.notesHeading"
            defaultMessage="Pending User Notes"
          />
        </Typography>
        <TextArea
          className={"TODO__BEM-this-thing"}
          id={this.props.uid}
          name="pendingUserNote"
          label={'Enter notes about this user, then "SAVE NOTE".'}
          rows="6"
          cols="20"
          fullWidth={true}
          dense={true}
          value={this.state.textAreaNotes}
          onChange={e => {
            this.setState({ textAreaNotes: e.target.value });
          }}
        />
        <Button
          className={"c-pending-user-note__button"}
          id={"save-" + this.props.userName}
          name={"pendingUserNoteSaveButton"}
          primary={true}
          dense={true}
          raised={true}
          disabled={this.isSaveDisabled()}
          onClick={this.updatePendingUserNoteContentHandler}
        >
          <Icon className={"c-pending-user-note__button-icon"}>
            edit {/* pencil icon */}
          </Icon>
          Save Note
        </Button>

        <hr />

        {/* #TODO: USER NOTES PROGRESS INDICATOR WHEN SAVING NOTES. */}

        {fetchingId === this.props.uid && isFetching ? (
          <div className="c-circular-progress--card-overlay-wrapper">
            <FormattedMessage
              className={"TODO__BEM-this-thing"}
              id="PendingActivations.loadingMessage"
              defaultMessage="Saving Note. Please wait..."
            />
            <br />
          </div>
        ) : (
          false
        )}
        {/* #END USER NOTES PROGRESS INDICATOR */}
      </React.Fragment>
    );
  }
}

export default withAuth(PendingUserNote);
