import ApprovedUsers from "../ApprovedUsers/ApprovedUsers";
import { connect } from "react-redux";
import { actions } from "../ApprovedUsers/ApprovedUsersAction";

export default connect(
  state => {
    return {
      approvedUsers: state.approvedUsersReducer.approvedUsers || {},
      nextUri: state.approvedUsersReducer.approvedUsers || {},
      userEntities: state.approvedUsersReducer.userEntities || {}
    };
  },
  actions
)(ApprovedUsers);
