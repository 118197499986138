import ExportEntitiesManagement from "./ExportEntitiesManagement";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions } from "./exportOrganizationsActions";

export default withRouter(
  connect(
    state => ({
      entityManagement: state.entityManagement
    }),
    actions
  )(ExportEntitiesManagement)
);
