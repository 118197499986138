const STATUS_TEXT = {
  ACTIVE: "Active",
  RETIRED: "Retired",
  IN_REVIEW: "In Review"
};
const STATUS_NUM = {
  ACTIVE: "active",
  RETIRED: "retired",
  IN_REVIEW: 'in_review'
};
const STATUS = {
  [STATUS_NUM.ACTIVE]: STATUS_TEXT.ACTIVE,
  [STATUS_NUM.RETIRED]: STATUS_TEXT.RETIRED,
  [STATUS_NUM.IN_REVIEW]: STATUS_TEXT.IN_REVIEW
};

const VISIBILITY_TEXT = {
  PUBLIC: "Public",
  PRIVATE: "Private"
};
const VISIBILITY_NUM = {
  PUBLIC: "public",
  PRIVATE: "private"
};

function formatStatus(status) {
  return STATUS[status] || String(status);
}

function typeForStatus(status) {
  switch (status) {
    case STATUS_NUM.IN_REVIEW:
      return "warning";
    case STATUS_NUM.ACTIVE:
      return "positive";
    case STATUS_NUM.RETIRED:
      return "negative";
    default:
      return "inactive";
  }
}

function paramsFromState({
  search,
  statusFilters,
  visibilityFilters,
  pageSize,
  page
}) {
  let visibility = undefined;
  if (
    visibilityFilters[VISIBILITY_TEXT.PUBLIC] &&
    !visibilityFilters[VISIBILITY_TEXT.PRIVATE]
  ) {
    visibility = VISIBILITY_NUM.PUBLIC;
  } else if (
    !visibilityFilters[VISIBILITY_TEXT.PUBLIC] &&
    visibilityFilters[VISIBILITY_TEXT.PRIVATE]
  ) {
    visibility = VISIBILITY_NUM.PRIVATE;
  }

  let status = undefined;
  if (
    statusFilters[STATUS_TEXT.ACTIVE] &&
    !statusFilters[STATUS_TEXT.RETIRED] &&
    !statusFilters[STATUS_TEXT.IN_REVIEW]
  ) {
    status = STATUS_NUM.ACTIVE;
  } else if (
    !statusFilters[STATUS_TEXT.ACTIVE] &&
    statusFilters[STATUS_TEXT.RETIRED] && 
    !statusFilters[STATUS_TEXT.IN_REVIEW]
  ) {
    status = STATUS_NUM.RETIRED;
  } else if (
    !statusFilters[STATUS_TEXT.ACTIVE] &&
    !statusFilters[STATUS_TEXT.RETIRED] && 
    statusFilters[STATUS_TEXT.IN_REVIEW]
  ) {
    status = STATUS_NUM.IN_REVIEW
  }

  return {
    text: search.input || undefined,
    visibility,
    status,
    pageSize,
    page
  };
}

export {
  STATUS_TEXT,
  VISIBILITY_TEXT,
  formatStatus,
  typeForStatus,
  paramsFromState
};
