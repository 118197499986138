export default {
  ALL_TERMS_AND_CONDITIONS_GET_REQUEST: "ALL_TERMS_AND_CONDITIONS_GET_REQUEST",
  ALL_TERMS_AND_CONDITIONS_GET_RECEIVE: "ALL_TERMS_AND_CONDITIONS_GET_RECEIVE",
  ALL_TERMS_AND_CONDITIONS_GET_ERROR: "ALL_TERMS_AND_CONDITIONS_GET_ERROR",

  GET_TERMS_AND_CONDITIONS_BY_ID_REQUEST:
    "GET_TERMS_AND_CONDITIONS_BY_ID_REQUEST",
  GET_TERMS_AND_CONDITIONS_BY_ID_RECEIVED:
    "GET_TERMS_AND_CONDITIONS_BY_ID_RECEIVED",
  GET_TERMS_AND_CONDITIONS_BY_ID_ERROR: "GET_TERMS_AND_CONDITIONS_BY_ID_ERROR"
};
