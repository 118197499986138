import AppMetrics from "./AppMetrics";
import { connect } from "react-redux";
import { actions } from "./AppMetricsActions";
import fetchAppCount from "./AppMetricsReducer";
export default connect(
  state => ({
    // mapStateToProps
    appCountData: fetchAppCount(state, "APP_GET_RECIEVE")
  }),
  actions // Inject all actions into the component
)(AppMetrics);
