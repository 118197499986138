import React from "react";
import PropTypes from "prop-types";
import { Chip, ChipContactText } from "@cdk-uip/react-chip";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
import {
  Card,
  CardAction,
  CardActions,
  CardText,
  CardHeader,
  CardTitle,
  CardSubtitle
} from "@cdk-uip/react-card";

import { FormattedMessage } from "react-intl";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "./WithNavigationToHome";
import { Confirm, ErrorModal } from "./Confirm";
import { getErrorMessage } from "../Utils/errorMessageHandler";
import PendingUserNote from "../containers/PendingUserNoteContainer";
import EntitySearchEnhanced from "./EntitySearchEnhanced";

const INDIVIDUAL = "individual";
const APPROVE = "Approve";
const REJECT = "Reject";

const ConfirmApprove = ({ selected: { name } }) => (
  <FormattedMessage
    id="PendingActivations.confirmApprove"
    defaultMessage="Approve {name}?"
    values={{ name: name }}
  />
);

const ConfirmReject = ({ selected: { name } }) => (
  <FormattedMessage
    id="PendingActivations.confirmReject"
    defaultMessage="Reject {name}?"
    values={{ name: name }}
  />
);

const getAPIError = e => {
  const { payload: { errors = [] } = {} } = e;
  const error = errors[0] || {};
  return error.message || e.message || "Unknown error";
};

class PendingActivations extends React.Component {
  static propTypes = {
    fetchusers: PropTypes.func.isRequired,
    updateRegistrationStatus: PropTypes.func.isRequired,
    fetchEntities: PropTypes.func.isRequired
  };

  static defaultProps = {};

  state = {
    saving: false,
    confirm: false,
    selected: {
      uid: "",
      entity: "",
      name: "",
      status: false,
      storedNote: ""
    },
    showErrorModal: false,
    failureReason: undefined,
    // pass it a string
    errorModalMessage: "",
    updated: []
  };

  componentDidMount() {
    this.fetchusers();
    this.fetchEntities();
  }

  componentDidUpdate(prevProps, prevState) {
    const { adminUpdateStatus: prevAdminUpdateStatus } = prevProps;
    const { adminUpdateStatus } = this.props;
    if (
      prevAdminUpdateStatus.isFetching === true &&
      adminUpdateStatus.isFetching === false
    ) {
      if (adminUpdateStatus.error) {
        console.log(
          "ERROR MESSAGE: " + getAPIError(this.props.adminUpdateStatus.error)
        );
        // Decide whether the failure was a Reject or Approve to properly display error message
        let type = "";
        if (prevState.confirm.type === REJECT) {
          type = "Rejecting";
        } else if (prevState.confirm.type === APPROVE) {
          type = "Approving";
        }
        this.setState({
          showErrorModal: true,
          errorModalMessage: `${type} ${
            prevState.selected.name
          } failed. ${getErrorMessage(getAPIError(adminUpdateStatus.error))}`,
          confirm: false
        });
        return;
      }
      this.setState({
        updated: [...prevState.updated, prevState.selected.uid]
      });
      //Intent here is to fetch users on every successful approval or rejection, that way the user list
      //will always be latest expecially when there are muliple admins working on approvals.
      this.fetchusers();
      this.setState({
        confirm: false
      });
    }
  }

  fetchusers = () => {
    const {
      auth: { accessToken },
      fetchusers
    } = this.props;
    fetchusers({ accessToken });
  };

  fetchEntities = () => {
    const {
      auth: { accessToken },
      fetchEntities
    } = this.props;
    fetchEntities({ accessToken });
  };

  promptApprovalConfirmation = name => {
    this.setState({
      confirm: {
        message: () => <ConfirmApprove selected={{ name }} />,
        onAccept: () => this.updateRegistration(true),
        type: APPROVE
      }
    });
  };

  promptRejectConfirmation = name => {
    this.setState({
      confirm: {
        message: () => <ConfirmReject selected={{ name }} />,
        onAccept: () => this.updateRegistration(false),
        type: REJECT
      }
    });
  };

  onAccept = () => {
    const {
      confirm: { onAccept }
    } = this.state;
    onAccept();
  };

  onCancel = () => {
    this.setState({ confirm: false, selected: {} });
  };

  onErrorModalClose = () => {
    this.setState({ showErrorModal: false });
  };

  updateRegistration = status => {
    const {
      auth: { accessToken },
      updateRegistrationStatus
    } = this.props;
    const {
      selected: { uid, entity }
    } = this.state;
    updateRegistrationStatus({ uid, entity, status: status, accessToken });
  };

  onApproveClick = ({ uid, name, selectedEntity }) => {
    let entity;
    try {
      entity = JSON.parse(selectedEntity);
    } catch (e) {
      entity = null;
    }

    this.setState({
      selected: {
        uid,
        name,
        status: true,
        entity: entity
      }
    });
    this.promptApprovalConfirmation(name);
  };

  onRejectClick = ({ uid, name }) => {
    this.setState({
      selected: {
        uid,
        name,
        status: false
      }
    });
    this.promptRejectConfirmation(name);
  };

  errorModalHandler = ({ message }) => {
    this.setState({
      showErrorModal: true,
      errorModalMessage: message
    });
  };

  render() {
    const {
      adminUpdateStatus: { isFetching: adminTransactionInProgress },
      pendingUsers: { list = [], isFetching: fetchingPendingUserList }
    } = this.props;
    const {
      selected: { name, status: approvalStatus, entity: selectedEntity },
      confirm,
      showErrorModal,
      errorModalMessage
    } = this.state;

    const IndividualChip = () => {
      return (
        <Chip
          className="c-entity-indicator c-entity-indicator--individual"
          contact={
            <ChipContactText className="c-entity-indicator__badge c-entity-indicator__badge--individual">
              I
            </ChipContactText>
          }
        >
          Individual
        </Chip>
      );
    };

    const OrganizationChip = () => {
      return (
        <Chip
          className="c-entity-indicator c-entity-indicator--org"
          contact={
            <ChipContactText className="c-entity-indicator__badge c-entity-indicator__badge--org">
              O
            </ChipContactText>
          }
        >
          Organization
        </Chip>
      );
    };

    const formattedUserList =
      list.length === 0
        ? list
        : list
            .filter(userObj => !this.state.updated.includes(userObj.id))
            .map(userObj => {
              const {
                id,
                created,
                status,
                profile: {
                  storedNote,
                  email,
                  firstName,
                  lastName,
                  streetAddress,
                  city,
                  state,
                  zipCode,
                  countryCode,
                  legalEntityType: entityType = "Individual",
                  legalEntityName: entityName,
                  primaryPhone: entityPhone,
                  legalEntityWebsite: entityWebsite,
                  streetAddress: entityAddress,
                  legalEntityTaxID: entityTaxID,
                  countryCode: entityCountry = "N/A"
                }
              } = userObj;
              return {
                id,
                status,
                storedNote,
                email,
                name: `${firstName} ${lastName}`,
                created,
                streetAddress,
                city,
                state,
                zipCode,
                countryCode,
                entityName,
                entityType,
                entityPhone,
                entityWebsite,
                entityAddress,
                entityTaxID,
                entityCountry,
                selectedEntity
              };
            });

    return (
      <React.Fragment>
        {fetchingPendingUserList || adminTransactionInProgress ? (
          <div className="c-circular-progress--overlay-wrapper">
            <Headline>
              Saving Data.
              <br />
              Refreshing Pending Users List.
              <br />
              Please wait...
            </Headline>
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        ) : (
          false
        )}
        {formattedUserList.map((userObj, index) => {
          return (
            <Card className="c-card-pending-users" key={userObj.id + index}>
              <CardHeader>
                <CardSubtitle>
                  {userObj.entityType.toLocaleLowerCase() === INDIVIDUAL ? (
                    <IndividualChip />
                  ) : (
                    <OrganizationChip />
                  )}
                </CardSubtitle>
                <CardTitle large>
                  {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                  {/*account_circle*/}
                  {/*</Icon>*/}
                  <i className="c-card-pending-users__info-icon cdk-icons cdkicon-account_circle" />
                  <FormattedMessage
                    id="Users.username"
                    defaultMessage="{username}"
                    values={{ username: userObj.name }}
                  />
                </CardTitle>
                <CardSubtitle>
                  {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                  {/*email*/}
                  {/*</Icon>*/}
                  <i className="c-card-pending-users__info-icon cdk-icons cdkicon-email" />
                  <FormattedMessage
                    id="Users.email"
                    defaultMessage="{email}"
                    values={{ email: userObj.email }}
                  />
                </CardSubtitle>
                {userObj.entityType.toLocaleLowerCase() === INDIVIDUAL && (
                  <React.Fragment>
                    <CardSubtitle>
                      {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                      {/*home*/}
                      {/*</Icon>*/}
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-home" />
                      {userObj.streetAddress}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.city}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.state}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.zipCode}
                    </CardSubtitle>
                    <CardSubtitle className="c-card-pending-users-address">
                      {userObj.countryCode}
                    </CardSubtitle>
                  </React.Fragment>
                )}
              </CardHeader>
              <CardText>
                {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                {/*keyboard /!* keyboard icon *!/*/}
                {/*</Icon>*/}
                <i className="c-card-pending-users__info-icon cdk-icons cdkicon-keyboard" />
                {`Created : ${new Date(userObj.created)}`}
              </CardText>

              {userObj.entityType.toLocaleLowerCase() !== INDIVIDUAL && (
                <React.Fragment>
                  <CardText>
                    {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                    {/*location_city /!* city buildings icon *!/*/}
                    {/*</Icon>*/}
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-location_city" />
                    {`Entity Name : ${userObj.entityName}`}
                  </CardText>
                  <CardText>
                    {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                    {/*phone /!* phone icon *!/*/}
                    {/*</Icon>*/}
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-phone" />
                    {`Phone : ${userObj.entityPhone}`}
                  </CardText>
                  <CardText>
                    {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                    {/*place /!* map pin icon *!/*/}
                    {/*</Icon>*/}
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-place" />
                    {`Address : ${userObj.entityAddress} ${userObj.city}, ${userObj.state}, ${userObj.zipCode}`}
                  </CardText>
                  <CardText>
                    {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                    {/*laptop_mac /!* laptop icon *!/*/}
                    {/*</Icon>*/}
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-laptop_mac" />
                    {`Website : ${userObj.entityWebsite}`}
                  </CardText>
                  <CardText>
                    {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                    {/*info /!* info circle icon *!/*/}
                    {/*</Icon>*/}
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-info" />
                    {`TaxID : ${userObj.entityTaxID}`}
                  </CardText>
                  <CardText>
                    {/*<Icon className={"c-card-pending-users__info-icon"}>*/}
                    {/*public /!* globe icon *!/*/}
                    {/*</Icon>*/}
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-public" />
                    {`Country : ${userObj.entityCountry}`}
                  </CardText>
                </React.Fragment>
              )}

              <CardText className="c-card-pending-user__note-wrapper">
                <PendingUserNote
                  id={userObj.id}
                  uid={userObj.id}
                  textAreaNotes={userObj.storedNote}
                  noteHandler={this.props.updateStoredNoteAction}
                  errorHandler={this.errorModalHandler}
                  userName={userObj.name}
                />
              </CardText>

              <CardText>
                {/* <EntitySelect
                  entities={response.entities}
                  fetchingEntities={fetchingEntities}
                  onChange={value => {
                    userObj.selectedEntity = value;
                  }}
                /> */}
                <EntitySearchEnhanced
                  onClick={selectedEntity => {
                    userObj.selectedEntity = selectedEntity;
                  }}
                  sId={userObj.id + index}
                />
              </CardText>

              <CardActions>
                <CardAction
                  onClick={() => {
                    this.onApproveClick({
                      uid: userObj.id,
                      name: userObj.name,
                      selectedEntity: userObj.selectedEntity
                    });
                  }}
                >
                  <i className="c-card-pending-users__button-icon cdk-icons cdkicon-favorite" />
                  Approve
                </CardAction>
                <CardAction
                  onClick={() => {
                    this.onRejectClick({
                      uid: userObj.id,
                      name: userObj.name
                    });
                  }}
                >
                  <i className="c-card-pending-users__button-icon cdk-icons cdkicon-remove_circle" />
                  Reject
                </CardAction>
              </CardActions>
            </Card>
          );
        })}

        <div>
          {
            <Confirm
              acceptLabel={
                confirm.type === APPROVE ? (
                  <FormattedMessage
                    id="AcceptLabel.approve"
                    defaultMessage="{accept}"
                    values={{ accept: confirm.type }}
                  />
                ) : (
                  <FormattedMessage
                    id="AcceptLabel.reject"
                    defaultMessage="{accept}"
                    values={{ accept: confirm.type }}
                  />
                )
              }
              open={!!confirm}
              onAccept={this.onAccept}
              onCancel={this.onCancel}
              message={confirm ? confirm.message() : false}
              type={confirm.type}
            />
          }
          <ErrorModal
            open={showErrorModal}
            onCancel={this.onErrorModalClose}
            name={name}
            approvalStatus={approvalStatus}
            message={errorModalMessage}
          />
        </div>
      </React.Fragment>
    );
  }
}

PendingActivations = WithNavigationToHome(PendingActivations);

class PendingActivationsContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <PendingActivations {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(PendingActivationsContainer);
