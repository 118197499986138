import UserProfile from "./UserProfile";
import { connect } from "react-redux";
import { actions } from "./UserProfileAction";

export default connect(
  state => {
    return {
      userProfile: state.userProfileReducer.userProfile || {},
      userEntities: state.userProfileReducer.userEntities || {},
      sendEmailToSignedUpUsers:
        state.userProfileReducer.resendEmailToVerify || {},
      adminUpdateStatus: state.userProfileReducer.adminUpdateStatus
    };
  },
  actions
)(UserProfile);
