import React from "react";
import { Button } from "@cdk-uip/react-button";

/**
 * @typedef {{ text: string; goTo: string; }} NavOption
 */

/**
 * Wrap a component to add a button to return to the homepage above it.
 *
 * You must wrap the resulting component with a React Router consumer, or
 * somehow supply a `history` prop for the active router to the resulting
 * component.
 *
 * @param navOptions {NavOption[]}
 * @returns {(Component: React.ComponentType<P>) => React.FC<P>}
 * A component wrapper. Invoke with one argument, the component to be wrapped.
 */
export default function withNavigation(navOptions = []) {
  return Component => props => (
    <>
      <br />
      {navOptions.map(({ text, goTo }) => (
        <Button
          key={goTo}
          raised
          onClick={() => props.history.push(goTo)}
          className="cdk-navigation-button"
        >
          {text}
        </Button>
      ))}
      <br />
      <br />
      <Component {...props} />
    </>
  );
}
