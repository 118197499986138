import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { IconButton, IconDelete } from "cdk-radial";
import classNames from 'classnames';
import './paymentCodeManagement.css'
import { Confirm } from "../components/Confirm";

import {
  TableHeaderCustomContent,
  TableContent,
  TablePagination,
  TableContainer,
  IconRenderer,
  TextRenderer,
  RatingRenderer,
  CELL_RENDERERS,
  usePreventTableScrollingRef,
  SearchInput,
  INPUT_SIZES,
  ButtonRenderer,
} from 'cdk-radial';

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [
  {
    label: 50,
    value: 50
  },
  {
    label: 70,
    value: 70
  },
  {
    label: 150,
    value: 150
  },
  {
    label: 200,
    value: 200
  }
];

function PaymentCodeTable({ couponAssociations, dissociateCoupon }) {
  const componentMainClass = `paymentCodeTable`;
  const agGridThemeClass = 'ag-theme-balham';
  const tableClass = classNames(componentMainClass, agGridThemeClass);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState();
  const [searchInputValue, setSearchInputValue] = useState('');
  const isAgGridReady = !!gridApi;
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const defaultPaginationPageSize = DEFAULT_ROWS_PER_PAGE_OPTIONS[0];
  const rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS;

  const buttonDelete = (props) => {
    return (
      <IconButton
        icon={<IconDelete />}
        onClick={() => {
          setSelectedRow(props?.data);
          setIsDeleting(true);
        }}
        isDisabled={props?.data?.status !== 'active'}
      />
    )
  }

  const columns = [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'name',
      headerName: 'Payment Code',
      width: 250,
      sort: 'desc',
      sortable: true,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'app_id',
      headerName: 'App ID',
      width: 250,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'app_name',
      headerName: 'App Name',
      width: 350,
      sort: 'desc',
      sortable: true,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'org_id',
      headerName: 'Org ID',
      width: 250,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'org_name',
      headerName: 'Org Name',
      width: 250,
      sortable: true,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'status',
      headerName: 'Payment Code Status',
      maxWidth: 300,
      sort: 'desc',
      sortable: true,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'created_by',
      headerName: 'Created By',
      width: 250,
      resizable: true,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'created',
      headerName: 'Created On',
      width: 250,
      resizable: true,
      sort: 'desc',
      sortable: true
    },
    {
      cellRendererFramework: buttonDelete,
      field: 'delete',
      headerName: '',
      minWidth: 75,
      width: 75,
      clickable: false,
      format: null
    }
  ];

  const tableContentRef = usePreventTableScrollingRef();
  const frameworkComponentsExtended = {
    textRenderer: TextRenderer,
    iconRenderer: IconRenderer,
    ratingRenderer: RatingRenderer,
    buttonRenderer: ButtonRenderer,
  };

  const onSearchInputChange = newValue => {
    setSearchInputValue(newValue);
  };

  const handleGridReady = gridParams => {
    setGridApi(gridParams.api);
    setTotalRows(gridParams.api.getDisplayedRowCount());
    gridParams.api.sizeColumnsToFit();
  };

  const handleSearchInputChange = searchInput => {
    onSearchInputChange(searchInput);

    if (gridApi) {
      gridApi.setQuickFilter(searchInput); // Refresh is needed for filtered cells highlighting to work properly

      gridApi.refreshCells({
        force: true
      });
      const displayedRowsCount = gridApi.getDisplayedRowCount();
      setTotalRows(displayedRowsCount);

      if (searchInput && displayedRowsCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  };

  const handleDelete = () => {
    dissociateCoupon({ appId: selectedRow.app_id, couponId: selectedRow.coupon_id });
    setIsDeleting(false);
  };

  const handlePaginationChanged = () => {
    if (!isAgGridReady) return;
    setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
    setTotalPages(gridApi.paginationGetTotalPages());
    setTotalRows(gridApi.getDisplayedRowCount());
  };
  const paginationPageSize = () => {
    if (isAgGridReady) {
      return gridApi.paginationGetPageSize();
    }
    if (
      defaultPaginationPageSize.value === DEFAULT_ROWS_PER_PAGE_OPTIONS[0].value
    ) {
      return rowsPerPageOptions[0].value;
    }
    if (
      rowsPerPageOptions.map(
        rowPerPageOption => rowPerPageOption === defaultPaginationPageSize.value
      )
    ) {
      return defaultPaginationPageSize.value;
    }
  };

  const getPageSummaryText = (currentPage, totalPages) => {
    return `Page ${currentPage} of ${totalPages}`;
  };
  const getRowsSummaryText = (currentPage, pageSize, totalRows) => {
    if (totalRows === 0) {
      return `${totalRows}-${totalRows} of ${totalRows}`;
    }
    const rowsFrom = (currentPage - 1) * pageSize + 1;
    const rowsTo = currentPage * pageSize;
    return `${rowsFrom}-${rowsTo > totalRows ? totalRows : rowsTo
      } of ${totalRows}`;
  };

  const paginationProps = {
    ariaLabel: 'Pagination',
    buttonLabels: {
      goToFirst: 'Go to first',
      goToLast: 'Go to last',
      goToNext: 'Go to next',
      goToPrevious: 'Go to previous'
    },
    className: '',
    dataTestId: `table-pagination`,
    defaultPaginationPageSize: DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
    gridApi,
    hideBorder: false,
    pageSizeDropdownId: `page-size-dropdown-table`,
    pageSummaryLabelId: `pagination-summary-label-table`,
    pageSummaryText: getPageSummaryText(
      totalPages === 0 ? 0 : currentPage,
      totalPages
    ),
    rowsPerPageLabel: 'Rows per page:',
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    rowsSummaryText: getRowsSummaryText(
      currentPage,
      paginationPageSize(),
      totalRows
    ),
    showGoToFirstLastPage: true
  };
  return (
    <>
      <TableContainer className={tableClass} tableLayout={'normal'}>
        <TableHeaderCustomContent
          className="custom-header"
          customHeaderContent={
            <div className="table-header-coupons">
              <SearchInput
                className="search-box-table"
                clearButtonLabel="clearButtonLabel"
                id="search-input-expanded"
                isAlwaysExpanded
                name="search-input-expanded"
                onChange={handleSearchInputChange}
                placeholder="Search payment codes"
                size={INPUT_SIZES.STANDARD}
                value={searchInputValue}
              />
            </div>
          }
        />

        <TableContent data-testid="table-content" ref={tableContentRef} >
          <div className="table-container-coupon">
            <AgGridReact
              defaultColDef={{
                sortable: true
              }}
              style={{
                height: '400px'
              }}
              frameworkComponents={frameworkComponentsExtended}
              columnDefs={columns}
              onGridReady={handleGridReady}
              rowData={couponAssociations || []}
              onPaginationChanged={handlePaginationChanged}
              pagination={true}
              paginationPageSize={paginationPageSize()}
              paginationAutoPageSize={false}
              suppressPaginationPanel
              rowHeight={50}
              resizable={true}
            />
          </div>
        </TableContent>
        <div className="table-pagination-bar">
          {isAgGridReady && (
            <TablePagination
              defaultPaginationPageSize={defaultPaginationPageSize}
              {...paginationProps}
            />
          )}
        </div>
      </TableContainer>
      <Confirm
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onAccept={handleDelete}
        message={
          "Are you sure you want to dissociate this payment code?"
        }
      />
    </>
  );
}

export default PaymentCodeTable;

