import React from "react";
import { Button } from "@cdk-uip/react-button";

/**
 * Superset of Button with additional functionality of changing the Button value when processing.
 *
 * loadingValue contains the value of the Button when isLoading is true.
 */
class LoadingButton extends React.Component {
  static defaultProps = {
    raised: true,
    primary: true,
    disabled: false,
    loadingValue: "Loading...",
    isLoading: false
  };

  render() {
    const {
      children,
      isLoading,
      loadingValue,
      disabled,
      ...other
    } = this.props;

    return (
      <Button disabled={isLoading ? true : disabled} {...other}>
        {isLoading ? loadingValue : children}
      </Button>
    );
  }
}

export { LoadingButton };
