import React from "react";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Select } from "@cdk-uip/react-select";
import { connect } from "react-redux";
import types from "../SubscriptionManagement/types";
import {
  Card,
  CardActions,
  CardActionButton,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Icon } from "@cdk-uip/react-icon";
import { TextField, TextFieldIcon } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import AppPanel from "./AppPanel";

//import PropTypes from "prop-types";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import EntitySearch from "../EntityManagement/EntitySearch";
import { Modal } from "../components/Confirm";

const ORG_ID = "orgId";
const APP_ID = "appId";

class SubscriptionReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idFromSearch: "",
      searchType: ORG_ID,
      renderSubFor: true,
      searchSubID: false,
      searchName: "",
      options: [],
      runBlur: true,
      resultIn: false,
      subReportLoading: false,
      show: false
    };
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
    if (change === APP_ID) {
      this.setState({
        renderSubFor: false,
        searchType: APP_ID
      });
    } else {
      this.setState({
        renderSubFor: false
      });
    }
  };

  getOrgAppsHandler = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (this.state.idFromSearch) {
      this.props.getOrgApps({
        accessToken,
        orgId: this.state.idFromSearch
      });
    } else {
      this.props.dispatch({
        type: types.ERROR,
        errorMessage: "Please enter a valid Organization Id"
      });
    }
  };

  getAppHandler = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (this.state.idFromSearch) {
      this.props.getApp({
        accessToken,
        appId: this.state.idFromSearch
      });
    } else {
      this.props.dispatch({
        type: types.ERROR,
        errorMessage: "Please enter a valid App Id"
      });
    }
  };

  downloadSubscriptionReport = app => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getAppSubscriptionReport({
      accessToken,
      appId: app.id,
      displayName: app.displayName
    });
  };

  onErrorModalClose = () => {
    this.props.dispatch({
      type: types.ERROR_RESET
    });
  };

  render() {
    const {
      subscriptionReports: {
        apps,
        error,
        fetchingApps,
        reportLoading,
        subscriptionReport,
        showErrorModal,
        appName
      }
    } = this.props;

    return (
      <React.Fragment>
        <LayoutGrid
          className={
            "s-content-route--subscription-management c-layout-section__dash-grid"
          }
        >
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <CardHeader>
                  <CardTitle large>
                    <Icon>settings</Icon> Subscription Reports
                  </CardTitle>
                  <CardSubtitle>
                    You'll be able to do the following things here:
                  </CardSubtitle>
                  <CardSubtitle>
                    #1) Find an OrgId by Organization Name or Email Domain. #2)
                    Find Apps for that Organization. #3) Download subscription
                    reports.
                  </CardSubtitle>
                </CardHeader>
                <CardText></CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <EntitySearch />
          </LayoutGridCell>
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--step1"
                }
              >
                <CardHeader>
                  <CardTitle large>Search Apps</CardTitle>
                  {this.state.searchType === ORG_ID ? (
                    <CardSubtitle>
                      Type or copy/paste an Organization ID to search Apps.
                    </CardSubtitle>
                  ) : (
                    <CardSubtitle>
                      Type or copy/paste an App ID to search an App.
                    </CardSubtitle>
                  )}
                </CardHeader>
                <CardText>
                  <Fluid valign="middle">
                    <FluidItem fill>
                      <TextField
                        className="c-search-input-subman-field"
                        autoFocus={"true"}
                        id={"idFromSearch"}
                        name={"idFromSearch"}
                        autoComplete={"off"}
                        autoCorrect={"off"}
                        spellCheck={"false"}
                        // COMPONENT PROPS
                        required
                        fullWidth
                        outlined
                        prefix="#"
                        type="string"
                        inputMode="text"
                        label="Search by ID"
                        value={this.state.idFromSearch}
                        onChange={e =>
                          this.setState({ idFromSearch: e.target.value })
                        }
                        trailingIcon={
                          <TextFieldIcon
                            title={"Clear Current Value"}
                            onClick={() => this.setState({ idFromSearch: "" })}
                          >
                            clear
                          </TextFieldIcon>
                        }
                      />
                    </FluidItem>
                    <FluidItem>
                      <Select
                        label="Search By:"
                        className="c-search-input-subman-type"
                        value={this.state.searchType}
                        onChange={e =>
                          this.onInputChange(e.target.value, "searchType")
                        }
                        allowEmpty="false"
                        required
                      >
                        <option value="orgId">Organization ID</option>
                        <option value="solutionId">App ID</option>
                      </Select>
                    </FluidItem>
                  </Fluid>
                </CardText>

                {this.state.searchType === ORG_ID ? (
                  <CardActions fullBleed>
                    <CardActionButton onClick={this.getOrgAppsHandler}>
                      <Icon>arrow_downward</Icon>
                      List Apps
                      <Icon>arrow_downward</Icon>
                    </CardActionButton>
                  </CardActions>
                ) : (
                  <CardActions fullBleed>
                    <CardActionButton onClick={this.getAppHandler}>
                      <Icon>arrow_downward</Icon>
                      List App
                      <Icon>arrow_downward</Icon>
                    </CardActionButton>
                  </CardActions>
                )}
              </Card>
            </Elevation>
          </LayoutGridCell>
          {!error && (
            <LayoutGridCell
              className={"c-layout-section__dash-grid-cell"}
              span={12}
              spanTablet={8}
              spanPhone={4}
            >
              <AppPanel
                apps={apps || []}
                fetchingApps={fetchingApps}
                downloadSubscriptionReport={this.downloadSubscriptionReport}
                reportLoading={reportLoading}
                subscriptionReport={subscriptionReport}
                appName={appName}
              />
            </LayoutGridCell>
          )}
        </LayoutGrid>
        <Modal
          open={showErrorModal}
          onClose={this.onErrorModalClose}
          message={error}
        />
      </React.Fragment>
    );
  }
}

SubscriptionReports = connect()(WithNavigationToHome(SubscriptionReports));
class SubscriptionReportsContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SubscriptionReports {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(SubscriptionReportsContainer);
