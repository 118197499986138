import types from "./types";
import config from "../config/app.conf.json";
import axios from "axios";

export const actions = {
  getAuditReport: ({ accessToken, filters }) => {
    return dispatch => {
      dispatch({
        type: types.GET_AUDIT_REPORT_REQUEST
      });
      axios
        .post(`${config.api.audit_service_url}fetch/events`, filters, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(accessToken)
          }
        })
        .then(res => {
          dispatch(getResult(types.GET_AUDIT_REPORT_RECEIVE, { ...res.data }));
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.GET_AUDIT_REPORT_ERROR, error.response));
          } else {
            dispatch(getResult(types.GET_AUDIT_REPORT_ERROR, {}));
          }
        });
    };
  }
};
const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};
