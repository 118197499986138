import config from "../config/app.conf.json";

export default (uid, storedNote) => ({
  url: config.api.pending_user_note,
  method: "PUT",
  data: {
    uid: uid,
    storedNote: storedNote
  }
});
