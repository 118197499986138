import React from "react";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import types from "./types";
import { connect } from "react-redux";
import { Card, CardHeader, CardTitle, CardText } from "@cdk-uip/react-card";
import { Icon } from "@cdk-uip/react-icon";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { Confirm, ErrorModal } from "../components/Confirm";

import RestoreEntitySearch from "./RestoreEntitySearch";

class RestoreEntity extends React.Component {
  constructor(props) {
    super(props);
  }

  onErrorModalClose = () => {
    this.props.dispatch({
      type: types.ERROR_RESET
    });
  };

  onSuccessModalClose = () => {
    this.props.dispatch({
      type: types.SUCCESS_RESET
    });
  };

  render() {
    const {
      restoreEntity: {
        showSuccessModal,
        successMessage,
        showErrorModal,
        errorMessage
      }
    } = this.props;
    return (
      <React.Fragment>
        <LayoutGrid
          id="restore-entity-grid"
          className="s-content-route--entity-management c-layout-section__dash-grid entity-management"
        >
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <CardHeader>
                  <CardTitle className="org-management-title" large>
                    <Icon>settings</Icon> Restore Organization
                  </CardTitle>
                </CardHeader>
                <CardText></CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
          {/* #START ENTITY SEARCH UI */}
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <RestoreEntitySearch />
          </LayoutGridCell>
        </LayoutGrid>

        <ErrorModal
          open={showErrorModal}
          onCancel={this.onErrorModalClose}
          message={errorMessage}
        />
        <Confirm
          open={showSuccessModal}
          onCancel={this.onSuccessModalClose}
          onAccept={this.toSubscriptions}
          message={successMessage}
        />
      </React.Fragment>
    );
  }
}

RestoreEntity = connect()(WithNavigationToHome(RestoreEntity));
class RestoreEntityContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <RestoreEntity {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(RestoreEntityContainer);
