import { useState } from "react";
import {
  TableContainer,
  TableHeader,
  TableHeaderCustomContent,
  TableContent,
  TablePagination,
  CELL_RENDERERS,
  TABLE_LAYOUT_OPTIONS,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  TextRenderer,
  usePreventTableScrollingRef,
  preventDefaultBehavior,
  focusLastCell,
  classNamePrefix
} from "cdk-radial";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { downloadCsv } from "./utils";

const defaultPaginationPageSize = DEFAULT_ROWS_PER_PAGE_OPTIONS[0];
const rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS;

const frameworkComponents = {
  [CELL_RENDERERS.TEXT]: TextRenderer,
  cellWithSubtitle: GridCellWithSubtitleRenderer
};

const columns = [
  {
    headerName: "App",
    cellRenderer: "cellWithSubtitle",
    field: "appName",
    subTextField: "appId",
    filterValueGetter: ({ data }) => data.appName,
    resizable: true,
    sortable: true,
    filter: true
  },
  {
    headerName: "Publisher",
    cellRenderer: CELL_RENDERERS.TEXT,
    field: "appPublisherName",
    resizable: true,
    sortable: true,
    filter: true
  },
  {
    headerName: "API",
    cellRenderer: "cellWithSubtitle",
    field: "apiName",
    subTextField: "apiId",
    filterValueGetter: ({ data }) => data.apiName,
    resizable: true,
    sortable: true,
    filter: true
  },
  {
    headerName: "Subscription",
    cellRenderer: CELL_RENDERERS.TEXT,
    field: "subscriptionId",
    resizable: true,
    sortable: true,
    filter: true
  },
  {
    headerName: "Last Transaction",
    cellRenderer: CELL_RENDERERS.TEXT,
    field: "lastTransaction",
    getQuickFilterText: () => "",
    resizable: true,
    sortable: true,
    width: 224,
    suppressSizeToFit: true
  }
];

const paginationProps = {
  ariaLabel: "Pagination",
  buttonLabels: {
    goToFirst: "Go to first",
    goToLast: "Go to last",
    goToNext: "Go to next",
    goToPrevious: "Go to previous"
  },
  dataTestId: `ltx-table-pagination`,
  defaultPaginationPageSize,
  hideBorder: false,
  pageSizeDropdownId: `ltx-page-size-dropdown-table`,
  pageSummaryLabelId: `ltx-pagination-summary-label-table`,
  rowsPerPageLabel: "Rows per page:",
  rowsPerPageOptions,
  showGoToFirstLastPage: true
};

function getPageSummaryText(currentPage, totalPages) {
  return `Page ${currentPage} of ${totalPages}`;
}

function getRowsSummaryText(currentPage, pageSize, totalRows) {
  if (totalRows === 0) {
    return `${totalRows}-${totalRows} of ${totalRows}`;
  }
  const rowsFrom = (currentPage - 1) * pageSize + 1;
  const rowsTo = currentPage * pageSize;
  return `${rowsFrom}-${
    rowsTo > totalRows ? totalRows : rowsTo
  } of ${totalRows}`;
}

function isValueInTable(value, quickFilterParts) {
  if (!quickFilterParts) return false;
  const filterValues = quickFilterParts.filter(filterPart => filterPart);
  const cellValue = value.toString().toUpperCase();
  return filterValues.some(filterPart => cellValue.includes(filterPart));
}

function GridCellWithSubtitleRenderer({ colDef, data, value, api }) {
  // same hack used in radial text renderer to determine current quick filter
  const highlight = isValueInTable(value, api.filterManager.quickFilterParts);
  return (
    <div className="ag-cell__text ag-cell__text--truncate ltx-two-line-cell">
      <div className="ag-cell__text--truncate">
        <span className={highlight ? "ag-cell__text--highlighted" : ""}>
          {value}
        </span>
      </div>
      <div className="ag-cell__text--truncate ltx-sub-value-line">
        {data[colDef.subTextField]}
      </div>
    </div>
  );
}

export default function LastTransactionsTable({ reportOrg, rows }) {
  const preventTableScrollingRef = usePreventTableScrollingRef();

  const [gridApi, setGridApi] = useState(null);
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState();
  const [searchValue, setSearchValue] = useState("");

  const handleGridReady = params => {
    setGridApi(params.api);
    setCurrentPage(params.api.paginationGetCurrentPage() + 1);
    setTotalPages(params.api.paginationGetTotalPages());
    setTotalRows(params.api.getDisplayedRowCount());
    params.api.sizeColumnsToFit();
  };

  const handlePaginationChanged = () => {
    if (!gridApi) return;
    setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
    setTotalPages(gridApi.paginationGetTotalPages());
    setTotalRows(gridApi.getDisplayedRowCount());
  };

  const handleSearchInputChange = value => {
    setSearchValue(value);
    if (gridApi) {
      gridApi.setQuickFilter(value);
      // Refresh is needed for filtered cells highlighting to work properly
      gridApi.refreshCells({
        force: true
      });
      const displayedRowsCount = gridApi.getDisplayedRowCount();
      setTotalRows(displayedRowsCount);
      if (value && displayedRowsCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  };

  const handleDownloadClick = () => {
    downloadCsv(rows);
  };

  const paginationPageSize = () => {
    if (gridApi) {
      return gridApi.paginationGetPageSize();
    }
    if (
      defaultPaginationPageSize.value === DEFAULT_ROWS_PER_PAGE_OPTIONS[0].value
    ) {
      return rowsPerPageOptions[0].value;
    }
    if (
      rowsPerPageOptions.map(
        rowPerPageOption => rowPerPageOption === defaultPaginationPageSize.value
      )
    ) {
      return defaultPaginationPageSize.value;
    }
  };

  const handleTabbingFromPagination = event => {
    const hasRowsToDisplay = gridApi.rowModel.rowsToDisplay.length > 0;
    if (hasRowsToDisplay) {
      preventDefaultBehavior(event);
      focusLastCell(gridApi);
    }
  };

  return (
    <TableContainer
      className={`ltx-table ${classNamePrefix}-table ag-theme-balham`}
    >
      <TableHeader
        id="ltx-table-header"
        searchInputName="ltx-table-search"
        headerTitle={reportOrg.name}
        isSearchable={true}
        isDownloadable={true}
        isPrintable={false}
        downloadButtonText="Download as CSV"
        disableDownloadButton={totalRows === 0}
        searchInputValue={searchValue}
        onSearchInputChange={handleSearchInputChange}
        onDownloadClick={handleDownloadClick}
      />
      <TableHeaderCustomContent customHeaderContent={reportOrg.id} />
      <TableContent ref={preventTableScrollingRef}>
        <AgGridReact
          domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
          rowHeight={44}
          frameworkComponents={frameworkComponents}
          columnDefs={columns}
          rowData={rows}
          pagination={true}
          paginationPageSize={paginationPageSize()}
          paginationAutoPageSize={false}
          suppressPaginationPanel={true}
          onGridReady={handleGridReady}
          onPaginationChanged={handlePaginationChanged}
        />
      </TableContent>
      {!!gridApi && (
        <TablePagination
          {...paginationProps}
          gridApi={gridApi}
          pageSummaryText={getPageSummaryText(
            totalPages === 0 ? 0 : currentPage,
            totalPages
          )}
          rowsSummaryText={getRowsSummaryText(
            currentPage,
            paginationPageSize(),
            totalRows
          )}
          onTabbingIntoTable={handleTabbingFromPagination}
        />
      )}
    </TableContainer>
  );
}
