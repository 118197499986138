import { parse } from "json2csv";
import { saveAs } from "file-saver";

export function downloadCsv(transactionReport) {
  const csv = parse(transactionReport, {
    fields: [
      { value: "appName", label: "App" },
      { value: "appId", label: "App ID" },
      { value: "appPublisherName", label: "Publisher" },
      { value: "apiName", label: "API" },
      { value: "apiId", label: "API ID" },
      { value: "orgName", label: "Dealer" },
      { value: "orgId", label: "Dealer Org ID" },
      { value: "subscriptionId", label: "Subscription ID" },
      { value: "lastTransaction", label: "Last Transaction" }
    ]
  });
  const csvFile = new File([csv], "last-transactions-report.csv", {
    type: "text/csv"
  });
  saveAs(csvFile);
}

export function extractApiErrorMessage(apierror) {
  const { payload, message } = apierror;
  const responseMessage =
    typeof payload === "string" ? payload : payload?.message;
  return responseMessage || message;
}
