import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { actions } from "../EntityManagement/entityActions";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
// UI COMPONENTS
import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { TextField } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { LoadingButton } from "./LoadingButton";
import { ButtonIcon } from "@cdk-uip/react-button";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";

const NAME_TYPE = "name";
const DOMAIN_TYPE = "domain";
const UNAVALIABLE = "n/a";

const sortByEntityName = (a, b) => {
  const aa = a.name.toLowerCase(),
    bb = b.name.toLowerCase();
  if (aa < bb) return -1;
  if (aa > bb) return 1;
  return 0;
};

class EntitySearchEnhanced extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchType: NAME_TYPE,
      searchInProgress: false,
      lastSearch: "",
      snack: false,
      isSerarchDisabled: true,
      selectedEntity: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      entityManagement,
      entityManagement: { searchDataNew, searchError }
    } = this.props;
    if (prevProps.entityManagement !== entityManagement) {
      if (
        prevProps.entityManagement.searchDataNew !==
        entityManagement.searchDataNew
      ) {
        if (searchDataNew.organizations.length > 0) {
          this.setState({
            searchString: "",
            lastSearch: prevState.searchString,
            searchInProgress: false,
            isSerarchDisabled: true
          });
        } else {
          this.setState({
            lastSearch: prevState.searchString,
            searchInProgress: false
          });
        }
      } else if (searchError) {
        this.setState({
          searchInProgress: false
        });
      }
    }
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  selectEntity = (entityID, entityName) => {
    const { onClick } = this.props;
    this.setState({
      selectedEntity: entityID
    });
    onClick(JSON.stringify({ id: entityID, name: entityName }));
  };

  onSubmit = () => {
    const { searchString, searchType } = this.state;
    const {
      auth: { accessToken },
      sId
    } = this.props;
    let trimmedSearch = searchString.trim();
    if (searchType.length) {
      if (searchType === NAME_TYPE) {
        this.props.searchEntityForUserActivation({
          accessToken,
          name: trimmedSearch,
          sourceId: sId
        });
        this.setState({ searchInProgress: true });
      } else if (searchType === DOMAIN_TYPE) {
        this.props.searchEntityForUserActivation({
          accessToken,
          domain: trimmedSearch,
          sourceId: sId
        });
        this.setState({ searchInProgress: true });
      }
    }
  };

  updateSnack = text => {
    this.setState({
      snack: text
    });
  };

  render() {
    const {
      entityManagement: { searchDataNew, sourceId = {} },
      sId
    } = this.props;
    const {
      searchInProgress,
      lastSearch,
      searchType,
      searchString,
      isSerarchDisabled
    } = this.state;
    return (
      <React.Fragment>
        {searchInProgress ? (
          <div className="c-circular-progress--overlay-wrapper">
            <Headline>
              Searching...
              <br />
              Showing related Organizations
              <br />
              Please wait...
            </Headline>
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        ) : (
          false
        )}
        <Elevation z={10} className={"c-dash-card__wrapper"}>
          <Card className="c-dash-card c-form-subscriptions c-form-subscriptions--start">
            <CardHeader>
              <CardTitle large>Search Organizations</CardTitle>
              <CardSubtitle>
                Search by name. Enter at least 3 characters to search.
              </CardSubtitle>
            </CardHeader>
            <CardText>
              <Fluid valign="middle">
                <FluidItem fill>
                  <TextField
                    id="entitySearch"
                    name="entitySearch"
                    className="c-search-input--field"
                    spellCheck={false}
                    type="string"
                    inputMode="text"
                    value={searchString}
                    onChange={e => {
                      this.onInputChange(e.target.value, "searchString");
                      this.setState({
                        isSerarchDisabled: e.target.value.trim().length < 3
                      });
                    }}
                    fullWidth
                  />
                </FluidItem>
                <FluidItem>
                  <LoadingButton
                    className="loadingButton-leftPadding c-search--submit"
                    raised
                    primary
                    dense
                    onClick={this.onSubmit}
                    isLoading={searchInProgress}
                    type="submit"
                    disabled={isSerarchDisabled}
                    loadingValue={"Searching..."}
                  >
                    <ButtonIcon>arrow_forward</ButtonIcon>
                    Search
                  </LoadingButton>
                </FluidItem>
              </Fluid>
              <div className="hint">
                {searchType === DOMAIN_TYPE ? (
                  <span>Enter full or partial email addresses or domains.</span>
                ) : (
                  <span>Enter full or partial names.</span>
                )}
              </div>
            </CardText>
            {searchDataNew && sourceId === sId ? (
              <CardText>
                <h2>Result Returned For: "{lastSearch}"</h2>
                {searchDataNew.organizations.length > 0 ? (
                  <div className="c-entity-search--container user-activation">
                    {searchDataNew.organizations
                      .sort(sortByEntityName)
                      .map(entity => (
                        <EntityInformation
                          entity={entity}
                          key={entity.id}
                          updateSnack={this.updateSnack}
                          selectEntity={this.selectEntity}
                          isSelected={this.state.selectedEntity === entity.id}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="cdk-entity-search--result">
                    <h3>{`${lastSearch} not found`}</h3>
                  </div>
                )}
              </CardText>
            ) : (
              ""
            )}
          </Card>
        </Elevation>
        <TemporarySnackbar
          show={this.state.snack.length}
          message={this.state.snack}
          onClose={() => this.setState({ snack: "" })}
          timeout={1000}
        />
      </React.Fragment>
    );
  }
}

const EntityInformation = ({
  entity,
  updateSnack,
  selectEntity,
  isSelected
}) => {
  const generateAddress = address => {
    const {
      street = "",
      postalCode = "",
      city = "",
      region = "",
      countryCode = ""
    } = address;
    return `${street}, ${postalCode} ${city} ${region} ${countryCode}`;
  };
  return (
    <div
      className={classnames("c-entity-search--result", {
        "c-entity-search--result--selected": isSelected
      })}
      onClick={e => {
        selectEntity(entity.id, entity.name);
      }}
    >
      <div className="c-entity-search--result-title">
        <h2>{entity.name}</h2>
      </div>

      <ul className="c-entity-search--result-items">
        <EntityListItem
          id={entity.id}
          text={entity.id}
          title="Organization ID"
        />
        {entity.emailDomains && (
          <EntityListItem
            text={
              typeof entity.emailDomains === "string"
                ? entity.emailDomains
                : entity.emailDomains.join(", ")
            }
            title="Email Domains"
          />
        )}

        <EntityListItem text={entity.phone} title="Phone Number" />
        <EntityListItem text={entity.website} title="Website" />
        <EntityListItem
          text={entity.address ? generateAddress(entity.address) : undefined}
          title="Address"
        />
        <EntityListItem text={entity.belongsTo} title="Belongs to" />
      </ul>
    </div>
  );
};

const EntityListItem = ({ text, title, id }) => (
  <li className="c-search-list-item">
    <Fluid halign="left">
      <div className="c-search-list-item__title">{title}</div>
      <div className="c-search-list-item__divider">:</div>
      <div className="c-search-list-item__text" id={id}>
        {text ? text : UNAVALIABLE}
      </div>
    </Fluid>
  </li>
);

export default connect(
  state => ({
    entityManagement: state.entityManagement
  }),
  actions
)(withAuth(EntitySearchEnhanced));
