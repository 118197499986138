import React from "react";
import {
  DataTable,
  DataTableBody,
  DataTablePagination
} from "@cdk-uip/react-data-table";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import moment from "moment";

export const PAGE_EVENTS = {
  FIRST: "first",
  PREVIOUS: "previous",
  NEXT: "next",
  LAST: "last"
};

export const DATA_FORMATS = {
  DATE: "Date",
  PLAIN_TEXT: "Plain Text"
};

const NO_DATA = "N/A";

// Reduced formatting for shortening year and seconds
export const formatDate = rawDate => {
  return rawDate
    ? moment(rawDate).format("M/DD/YY") + ", " + moment(rawDate).format("LT")
    : "";
};

const navigatePath = (obj, path) => {
  let curObj = obj;
  for (const key of path) {
    curObj = curObj[key];
  }
  return curObj;
};

export const ApiPaginatedTable = ({
  pageNumber,
  pageSize,
  pageInfo,
  items,
  RowComponent,
  header,
  loading,
  onPageUpdate
}) => {
  const pageListToStart = () => {
    onPageUpdate(PAGE_EVENTS.FIRST);
  };

  const pageListBackward = () => {
    if (pageNumber <= 1) return;
    onPageUpdate(PAGE_EVENTS.PREVIOUS);
  };

  const pageListForward = () => {
    if (pageNumber >= pageInfo.totalPages) return;
    onPageUpdate(PAGE_EVENTS.NEXT);
  };

  const pageListToEnd = () => {
    onPageUpdate(PAGE_EVENTS.LAST);
  };

  /**
   * @param data {*}
   * @param id {string}
   * @param format {function | string}
   * @param path {string[] | undefined}
   * @return {string|*}
   */
  const formatData = (data, id, format, path) => {
    let result = "";
    if (typeof format === "function") return format(data, id);

    let value = data[id];
    if (path && path.length) {
      value = navigatePath(data, path);
    }

    switch (format) {
      case DATA_FORMATS.DATE:
        result = formatDate(value);
        break;
      case DATA_FORMATS.PLAIN_TEXT:
        result = value;
        break;
      default:
        break;
    }
    return result || NO_DATA;
  };

  const pageStartIdx = (pageNumber - 1) * pageSize;
  const pageStart = pageInfo.totalItems ? pageStartIdx + 1 : 0;
  const pageEnd = pageStartIdx + items.length;

  return (
    <React.Fragment>
      <DataTable className="c-api-provider-table" sortable>
        {header()}
        <DataTableBody>
          {items.map(item => RowComponent(item, formatData))}
        </DataTableBody>
      </DataTable>
      <DataTablePagination>
        <div className="c-pagination-actions">
          <div>
            <Button
              onClick={pageListToStart}
              disabled={loading || pageNumber <= 1}
            >
              <ButtonIcon>chevron_left</ButtonIcon>
              First
            </Button>
            <Button
              onClick={pageListBackward}
              disabled={loading || pageNumber <= 1}
            >
              <ButtonIcon>chevron_left</ButtonIcon>
              Previous
            </Button>
          </div>
          <div className="page-count">
            {loading ? (
              <CircularProgress />
            ) : (
              `${pageStart} - ${pageEnd} of ${pageInfo.totalItems}`
            )}
          </div>
          <div>
            <Button
              onClick={pageListForward}
              disabled={loading || pageNumber >= pageInfo.totalPages}
            >
              Next
              <ButtonIcon>chevron_right</ButtonIcon>
            </Button>
            <Button
              onClick={pageListToEnd}
              disabled={loading || pageNumber >= pageInfo.totalPages}
            >
              Last
              <ButtonIcon>chevron_right</ButtonIcon>
            </Button>
          </div>
        </div>
      </DataTablePagination>
    </React.Fragment>
  );
};

export default {
  ApiPaginatedTable,
  DATA_FORMATS,
  formatDate
};
