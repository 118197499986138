import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import reducers from "./reducers";
import registrationMetricsReducer from "../Reporting/registrationMetricsReducer";
import subscriptionManagement from "../SubscriptionManagement/subscriptionsReducer";
import entityManagement from "../EntityManagement/entityReducer";
import restoreEntity from "../EntityManagement/restoreEntityReducer";

import bulkUserUploadManagement from "../BulkUserUpload/userUploadReducer.js";
import entityApproval from "../EntityApproval/entityApprovalReducer";
import specMetrics from "../SpecMetrics/specMetricsReducer";
import appMetrics from "../AppMetrics/AppMetricsReducer";
import apiAdmin from "../ApiGatewayAdmin/apiAdminReducer";
import specReview from "../SpecReview/specReviewReducer";
import apiProviders from "../ApiProviders/apiProvidersReducer";
import apiProviderManagement from "../ApiProviderManagement/apiProviderManagementReducer";
import { bulkSubscriptionsReducer } from "../BulkSubscriptions/reduxManagement";
import signedUpUsersReducer from "../SignedUpUsers/SignedUpUsersReducer";
import approvedUsersReducer from "../ApprovedUsers/ApprovedUsersReducer";
import allTermsAndConditionsReducer from "../Agreements/fortellisDeveloperTermsReducer";
import { apiUsageReducer } from "../ApiUsage/reduxManagement";
import TnCUploaderReducer from "../Agreements/TnCEditor/TnCUploaderReducer";
import thunk from "redux-thunk";
import { apiMiddleware } from "@cdkglobal/react-core-reduxapi";
import userSearchReducer from "../UserSearch/UserSearchReducer";
import userProfileReducer from "../UserProfileManager/UserProfileReducer";
import subscriptionReports from "../SubscriptionReports/SubscriptionReportsReducer";
import monetizationReducers from "../MonetizationReports/MonetizationReportReducer";
import auditReducers from "../AuditReports/AuditReportReducer";
import apiIntegrationManagement from "../ApiIntegrationManagement/ApiIntegrationManagementReducer";
import lastTransactions from "../LastTransactions/reducer";
import paymentCodes from "../PaymentCodeManagement/reducer.js";
// Support Redux dev tools Chrome extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default initialState =>
  createStore(
    combineReducers({
      ...reducers,
      registrationMetricsReducer,
      subscriptionManagement,
      entityManagement,
      restoreEntity,
      specMetrics,
      appMetrics,
      apiAdmin,
      specReview,
      apiProviders,
      entityApproval,
      apiProviderManagement,
      bulkUserUploadManagement,
      bulkSubscriptionsReducer,
      signedUpUsersReducer,
      allTermsAndConditionsReducer,
      TnCUploaderReducer,
      approvedUsersReducer,
      apiUsageReducer,
      userSearchReducer,
      userProfileReducer,
      subscriptionReports,
      monetizationReducers,
      auditReducers,
      apiIntegrationManagement,
      lastTransactions,
      paymentCodes
    }),
    initialState,
    composeEnhancers(applyMiddleware(thunk, apiMiddleware))
  );
