import React, { Fragment, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import {
  withEntityContext,
  PERSONAL_ACCOUNT_ID
} from "@cdk-prod/fortellis-entity-context";
import { triggerUserDownload } from "./DownloadButton/file-utils";
import { Body1 } from "@fortellis/typography";
import config from "../config/app.conf.json";
import { convertArrayToCSV } from "convert-array-to-csv";
import UsageReport from "./UsageReport";
import "./usage-report.scss";

// Variables
const METRICS_SERVICE_URL = config.api.metrics_service_url;
const API_GATEWAY_V2_URL = config.api.api_gateway_url+"/v2";
const ASYNC_API_SERVICE_BASE_URL = config.api.async_apis_control_url;
const CURRENT_MONTH = "current_month";
const LAST_MONTH = "last_month";

const TESTING_APP_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12} \bTesting App\b$/;
const uuidEx = /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i;

function UsageReportContainer({ entityContext, auth, selectedOrg }) {
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [metrics, setMetrics] = useState();
  const [apis, setApis] = useState();
  const [combinedMetrics, setCombinedMetrics] = useState();
  const [reportLoading, setReportLoading] = useState(false);
  const [reportPending, setReportPending] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [selectedOrgID, setSelectedOrgID] = useState("");

  function getMetrics(timeRange) {
    const authToken = auth.accessToken;
    const transactionMetricsPromise = axios({
      method: "POST",
      url: `${METRICS_SERVICE_URL}/v1/admin/metrics/consolidated-transactions`,
      data: {
        select: [
          "appName",
          "apiId",
          "apiName",
          "appOrgName",
          "apiInstanceEnvironment",
          "count(responseContentLength)",
          "sum(responseContentLength)"
        ],
        timeRange: timeRange,
        filter: [
          {
            operator: "in",
            attribute: "apiOrgId",
            value: [selectedOrgID]
          }
        ]
      },
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const eventMetricsPromise = axios({
      method: "POST",
      url: `${METRICS_SERVICE_URL}/v1/admin/metrics/events`,
      data: {
        select: [
          "appName",
          "apiId",
          "apiName",
          "apiEnvironment",
          "appOrgName",
          "count(messageSize)",
          "sum(messageSize)"
        ],
        timeRange: timeRange,
        filter: [
          {
            operator: "in",
            attribute: "apiOrgId",
            value: [selectedOrgID]
          }
        ]
      },
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    return Promise.all([transactionMetricsPromise, eventMetricsPromise]);
  }

  function getApis() {
    const authToken = auth.accessToken;
    const restApisPromise = axios({
      method: "GET",
      url: `${API_GATEWAY_V2_URL}/apis`,
      headers: { Authorization: `Bearer ${authToken}` }
    });
    const asyncApisPromise = axios({
      method: "GET",
      url: `${ASYNC_API_SERVICE_BASE_URL}/v1/async-apis`,
      headers: { Authorization: `Bearer ${authToken}` }
    });
    return Promise.all([restApisPromise, asyncApisPromise]);
  }

  const fetchUiReport = useCallback(() => {
    if (selectedOrgID && auth.accessToken) {
      const date = new Date();
      // Edge case: on the first of the month continue showing previous month report
      if (date.getDate() === 1) {
        date.setDate(0);
      }
      const orgId = selectedOrgID;
      const authToken = auth.accessToken;
      const reportDate = date.getDate();
      setReportError(false);

      getMetrics(`${reportDate}d`)
        .then(([transactionMetrics, eventMetrics]) => {
          const res = mergeTransactionEventMetrics(
            transactionMetrics.data,
            eventMetrics.data
          );
          if (res.status || res.location) {
            setReportPending(true);
          } else {
            setMetrics(res.result.attributes);
            setTotalTransactions(aggregateTransactions(res.result.attributes));
          }
          setMetricsLoading(false);
        })
        .catch(err => {
          setReportError(err.message || "Failed to load report");
          setMetricsLoading(false);
        });
      getApis()
        .then(([restApis, asyncApis]) => {
          const res = {
            ...restApis.data,
            ...{ items: [...restApis.data.items, ...asyncApis.data.items] }
          };
          setApis(res.items);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [auth.accessToken, entityContext.entity]);

  // Get metrics
  useEffect(() => {
    setSelectedOrgID(selectedOrg);
    setMetricsLoading(true);
    setMetrics([]);
    setTotalTransactions(0);
    setApis([]);
    fetchUiReport();
  }, [fetchUiReport, selectedOrg, selectedOrgID]);

  // Combine metrics
  useEffect(() => {
    if (metrics && apis) {
      setCombinedMetrics(formatMetrics(metrics, apis));
    }
  }, [metrics, apis]);

  async function downloadLastMonthReport() {
    const orgId = selectedOrgID;
    if (orgId !== PERSONAL_ACCOUNT_ID) {
      setReportLoading(true);
      let lastMonthStartAndEndDate = getLastMonthStartAndEndDate();
      getMetrics(
        `${lastMonthStartAndEndDate.formattedStartDate}~${lastMonthStartAndEndDate.formattedEndDate}`
      )
        .then(([transactionMetrics, eventMetrics]) => {
          const res = mergeTransactionEventMetrics(
            transactionMetrics.data,
            eventMetrics.data
          );
          downloadReport(
            formatMetrics(res.result.attributes, apis),
            CURRENT_MONTH
          );
        })
        .catch(err => {
          setMetricsLoading(false);
          console.log(err);
        });
    }
  }

  async function downloadMonthToDateReport() {
    downloadReport(combinedMetrics, LAST_MONTH);
  }

  function downloadReport(reportCombinedMetrics, month) {
    let csvData = [];
    let header = [
      "API Name",
      "App Name",
      "App Org Name",
      "Environment",
      "Total Traffic",
      "Payload Size"
    ];
    try {
      reportCombinedMetrics.map(metric => {
        if (metric.solutions.length > 0) {
          metric.solutions.forEach(solution => {
            let csvDataObj = {};
            csvDataObj["apiName"] = metric.apiName;
            csvDataObj["appName"] = solution.name;
            csvDataObj["appOrgName"] = solution.appOrgName;
            csvDataObj["environment"] = solution.environment;
            csvDataObj["transactions"] = solution.transactions;
            csvDataObj["payloadSize"] = solution.payloadSize;
            csvData.push(csvDataObj);
          });
        } else {
          let csvDataObj = {};
          csvDataObj["apiName"] = metric.apiName;
          csvDataObj["appName"] = "";
          csvDataObj["appOrgName"] = metric.appOrgName;
          csvDataObj["environment"] = "";
          csvDataObj["transactions"] = metric.transactions;
          csvDataObj["payloadSize"] = metric.payloadSize;
          csvData.push(csvDataObj);
        }
      });
      const csvMetricsDataObj = convertArrayToCSV(csvData, { header });
      setReportLoading(false);
      const date = new Date();
      const dateFormatterLong = new Intl.DateTimeFormat("en-US", {
        month: "long"
      });

      const monthName =
        month === CURRENT_MONTH
          ? dateFormatterLong.format(
              new Date(date.getFullYear(), date.getMonth(), 0)
            )
          : dateFormatterLong.format(
              new Date(date.getFullYear(), date.getMonth())
            );
      triggerUserDownload(`${monthName} Usage Report.csv`, csvMetricsDataObj);
    } catch (err) {
      setReportLoading(false);
      console.log(err);
    }
  }

  if (
    entityContext &&
    entityContext.entity &&
    entityContext.entity.id === PERSONAL_ACCOUNT_ID
  ) {
    return (
      <div className="fdn-metric--empty-space">
        <Body1>
          Implementation usage reports are only available for organizations.
        </Body1>
      </div>
    );
  }

  return (
    <UsageReport
      loading={metricsLoading}
      totalTransactions={totalTransactions}
      implementationMetrics={combinedMetrics}
      onDownloadLastMonthReport={downloadLastMonthReport}
      onDownloadMonthToDateReport={downloadMonthToDateReport}
      reportEmpty={metrics && Object.keys(metrics).length === 0}
      reportLoading={reportLoading}
      reportPending={reportPending}
      retryReport={fetchUiReport}
      reportError={reportError}
    />
  );
}

function getLastMonthStartAndEndDate() {
  const d = new Date();
  const enddate = new Date(d.getFullYear(), d.getMonth(), 0);
  const startDate = new Date(d.getFullYear(), d.getMonth() - 1, 1);
  return formatDate({ enddate, startDate });
}

function mergeTransactionEventMetrics(transactionMetrics, eventsMetrics) {
  eventsMetrics.result.attributes = eventsMetrics.result.attributes.map(
    metrics => {
      return {
        metrics: metrics.metrics.map(metric => {
          if (metric.name === "appName")
            return {
              name: "appName",
              value: metric.value === "-" ? "Async" : metric.value
            };
          else return metric;
        })
      };
    }
  );
  return {
    result: {
      attributeInfo: transactionMetrics.result.attributeInfo,
      attributes: [
        ...transactionMetrics.result.attributes,
        ...eventsMetrics.result.attributes
      ]
    }
  };
}

function formatDate(timeRange) {
  const formattedEndDate =
    getFormattedStartAndEndDate(timeRange.enddate) + " " + "23:59:59";
  const formattedStartDate =
    getFormattedStartAndEndDate(timeRange.startDate) + " " + "00:00:00";

  return {
    formattedEndDate,
    formattedStartDate
  };
}

function getFormattedStartAndEndDate(date) {
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return year + "-" + month + "-" + day;
}

function convertPayloadSize(bytes) {
  const K = 1024;
  const ZERO_MSG = "0 KB";
  const SMALL_MSG = "Less than 1 KB";
  const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (bytes === 0) return ZERO_MSG;

  if (bytes > 0 && bytes < K) return SMALL_MSG;

  const i = Math.floor(Math.log(bytes) / Math.log(K));

  return parseFloat((bytes / Math.pow(K, i)).toFixed(2)) + " " + SIZES[i];
}

function formatAttributeMetrics(attributes, apis) {
  let apiAttributes = [];
  let metricApis = [];

  attributes.map(attribute => {
    let apiMetricObj = {};
    attribute.metrics.forEach(metric => {
      apiMetricObj[metric.name] = metric.value;
    });
    apiAttributes.push(apiMetricObj);
    if (!metricApis.includes(apiMetricObj.apiName)) {
      metricApis.push(apiMetricObj.apiName);
    }
  });

  apis.forEach(api => {
    let apiObj = {};
    if (!metricApis.includes(api.name)) {
      apiObj["apiName"] = api.name;
      apiObj["count"] = 0;
      apiObj["sum"] = 0;
      apiAttributes.push(apiObj);
    }
  });
  return apiAttributes;
}

function formatAttributesForReport(apiAttributes) {
  let attributeMetrics = [];
  let visited = [];
  apiAttributes.map(metric => {
    if (!TESTING_APP_REGEX.test(metric.appName) && metric.appName !== "-") {
      if (!visited.includes(metric.apiName)) {
        let solutions = [];
        let attributeMetricsObj = {};
        let transactions = 0;
        let payloadSize = 0;
        apiAttributes.forEach(attr => {
          let solutionObj = {};
          if (
            attr.apiName === metric.apiName &&
            attr.appName &&
            !TESTING_APP_REGEX.test(attr.appName) &&
            attr.appName !== "-"
          ) {
            transactions = transactions + parseInt(attr.count);
            payloadSize = payloadSize + parseInt(attr.sum);
            solutionObj["name"] = attr.appName;
            solutionObj["environment"] = attr.apiInstanceEnvironment;
            solutionObj["transactions"] = parseInt(attr.count);
            solutionObj["payloadSize"] = parseInt(attr.sum);
            solutionObj["appOrgName"] =
              attr.appOrgName !== null || attr.appOrgName !== "-"
                ? attr.appOrgName
                : "";
            solutions.push(solutionObj);
          }
        });
        attributeMetricsObj["apiName"] = metric.apiName;
        attributeMetricsObj["transactions"] = transactions;
        attributeMetricsObj["payloadSize"] = payloadSize;
        attributeMetricsObj["solutions"] = solutions || [];
        attributeMetrics.push(attributeMetricsObj);
        visited.push(metric.apiName);
      }
    }
  });
  return attributeMetrics;
}

function formatMetrics(attributes, apis) {
  let formattedMetricsObj = formatAttributeMetrics(attributes, apis);
  let formatAttrObjForReport = formatAttributesForReport(formattedMetricsObj);
  return formatAttrObjForReport
    .map(apiMetric => {
      return {
        apiName: apiMetric.apiName,
        transactions: apiMetric.transactions,
        payloadSize: convertPayloadSize(apiMetric.payloadSize),
        solutions: apiMetric.solutions?.map(solution => {
          return {
            name: solution.name,
            transactions: solution.transactions,
            payloadSize: convertPayloadSize(solution.payloadSize),
            environment: solution.environment,
            appOrgName: solution.appOrgName
          };
        })
      };
    })
    .sort((a, b) => {
      const aTransactions = a.transactions || 0;
      const bTransactions = b.transactions || 0;
      return bTransactions - aTransactions;
    });
}

function aggregateTransactions(attributes) {
  let totalTransactions = 0;
  attributes.forEach(metric => {
    const appNameObj = metric.metrics.find(({ name }) => name === "appName");
    if (!TESTING_APP_REGEX.test(appNameObj.value) && appNameObj.value !== "-") {
      const transactions = metric.metrics.find(({ name }) => name === "count");
      totalTransactions = totalTransactions + parseInt(transactions.value);
    }
  });

  return totalTransactions;
}

export default withAuth(withEntityContext(UsageReportContainer));
