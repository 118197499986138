import React from "react";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import {
  Card,
  CardText,
  CardHeader,
  CardTitle,
  CardSubtitle
} from "@cdk-uip/react-card";
import { Dialog } from "@cdk-uip/react-dialog";
import { FormattedMessage } from "react-intl";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import SearchAndLinkEntityWithUser from "./SearchAndLinkEntityWithUser";
import { Button } from "@cdk-uip/react-button";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import AssociatedEntitiesContainer from "../ApprovedUsers/AssociatedEntitiesContainer";
import config from "../config/app.conf.json";
import SuspendUserDialog from "./SuspendUserDialog";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";

class SignedUpUsers extends React.Component {
  state = { openEntitySearch: false, showSuspendUserDialog: false };

  componentDidMount() {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchApprovedUsers(config.api.approved_users, accessToken);
  }

  fetchLinkedEntities = uid => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchLinkedEntities({ uid, accessToken });
    this.setState({ uid: uid });
  };

  onShowEntitySearch = userObj => {
    this.setState({
      openEntitySearch: true,
      uid: userObj.id,
      selectedUserObj: userObj
    });
  };

  onCloseEntitySearch = () => {
    this.setState({ openEntitySearch: false });
  };

  fetchNextUsers = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (this.props.approvedUsers.nextUri) {
      this.props.fetchApprovedUsersOnShowMore(
        `${config.api.approved_users}?retrieveNextUri=${this.props.approvedUsers.nextUri}`,
        true,
        accessToken
      );
    }
  };

  openSuspendUserDialog = ({ userId, name }) => {
    this.setState({
      showSuspendUserDialog: true,
      userToBeSuspended: name,
      userId: userId
    });
  };

  closeSuspendUserDialog = () => {
    this.setState({
      showSuspendUserDialog: false
    });
  };

  suspendUser = userId => {
    const {
      auth: { accessToken }
    } = this.props;

    this.props.suspendUser({ userId, accessToken });
  };

  render() {
    const {
      list: approvedUsersList,
      isFetching,
      error = ""
    } = this.props.approvedUsers;

    const { isAdding, entityId } = this.props.userEntities;

    if (!isFetching && error) {
      return <div>Unable to load Approved Users List.</div>;
    } else if (isFetching) {
      return (
        <div className="loading-container">
          <h3>Loading Approved Users List...</h3>
          <CircularProgress />
        </div>
      );
    }
    let formattedUserList;

    if (approvedUsersList && approvedUsersList.length) {
      formattedUserList =
        approvedUsersList.length === 0
          ? approvedUsersList
          : approvedUsersList.map(userObj => {
              const {
                id,
                created,
                status,
                profile: {
                  email,
                  firstName,
                  lastName,
                  streetAddress,
                  city,
                  state,
                  zipCode,
                  countryCode,
                  selectedEntity
                }
              } = userObj;
              return {
                id,
                status,
                email,
                name: `${firstName} ${lastName}`,
                created,
                streetAddress,
                city,
                state,
                zipCode,
                countryCode,
                selectedEntity
              };
            });
    }

    return (
      <React.Fragment>
        <Card className="signed-up-users-page-heading-card">
          <CardHeader>
            <CardTitle large className="signed-up-users-page-title">
              Approved Users List
            </CardTitle>
            <CardSubtitle className="signed-up-users-page-subtitle">
              Below is the list of users who are active in Fortellis. You can
              see the organisations associated with the users, associate new
              organisations with users and remove already associated
              organisations from users.
            </CardSubtitle>
          </CardHeader>
        </Card>
        {formattedUserList &&
          formattedUserList.map((userObj, index) => {
            return (
              <Card className="c-card-pending-users" key={userObj.id + index}>
                <LayoutGrid nested className={"c-layout-section__home-grid"}>
                  <LayoutGridCell
                    span={8}
                    className={
                      "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
                    }
                  >
                    <CardHeader>
                      <CardTitle large>
                        <i className="c-card-pending-users__info-icon cdk-icons cdkicon-account_circle" />
                        <FormattedMessage
                          id="Users.username"
                          defaultMessage="{username}"
                          values={{ username: userObj.name }}
                        />
                      </CardTitle>
                      <CardSubtitle>
                        <i className="c-card-pending-users__info-icon cdk-icons cdkicon-email" />
                        <FormattedMessage
                          id="Users.email"
                          defaultMessage="{email}"
                          values={{ email: userObj.email }}
                        />
                      </CardSubtitle>
                      <React.Fragment>
                        <CardSubtitle>
                          <i className="c-card-pending-users__info-icon cdk-icons cdkicon-account_circle" />
                          {userObj.id}
                        </CardSubtitle>
                        <CardSubtitle>
                          <i className="c-card-pending-users__info-icon cdk-icons cdkicon-home" />
                          {userObj.streetAddress}
                        </CardSubtitle>
                        <CardSubtitle className="c-card-pending-users-address">
                          {userObj.city}
                        </CardSubtitle>
                        <CardSubtitle className="c-card-pending-users-address">
                          {userObj.state}
                        </CardSubtitle>
                        <CardSubtitle className="c-card-pending-users-address">
                          {userObj.zipCode}
                        </CardSubtitle>
                        <CardSubtitle className="c-card-pending-users-address">
                          {userObj.countryCode}
                        </CardSubtitle>
                      </React.Fragment>
                    </CardHeader>
                    <CardText>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-keyboard" />
                      {`Created : ${new Date(userObj.created)}`}
                    </CardText>
                  </LayoutGridCell>
                  <LayoutGridCell>
                    <Button
                      className="add-new-entity-button resend-email-button-text"
                      outlined
                      onClick={() => {
                        this.fetchLinkedEntities(userObj.id, true);
                        this.onShowEntitySearch(userObj);
                      }}
                    >
                      Link user to Organisation
                    </Button>
                    <Button
                      className="add-new-entity-button resend-email-button-text"
                      outlined
                      onClick={() => {
                        this.fetchLinkedEntities(userObj.id);
                      }}
                    >
                      Show associated Organisations
                    </Button>
                    <Button
                      className="add-new-entity-button resend-email-button-text suspend-user-button"
                      outlined
                      onClick={() => {
                        this.openSuspendUserDialog({
                          userId: userObj.id,
                          name: userObj.name
                        });
                      }}
                    >
                      Suspend user
                    </Button>
                  </LayoutGridCell>
                </LayoutGrid>
                <AssociatedEntitiesContainer
                  show={this.state.uid === userObj.id}
                  uid={this.state.uid}
                />
                <Dialog
                  open={
                    this.state.openEntitySearch && this.state.uid === userObj.id
                  }
                  onCancel={this.onCloseEntitySearch}
                >
                  <SearchAndLinkEntityWithUser
                    onClick={selectedEntity => {
                      userObj.selectedEntity = selectedEntity;
                    }}
                    sId={userObj.id + index}
                    uid={this.state.uid}
                    addUserToEntity={this.props.addUserToEntity}
                    isAdding={isAdding}
                    entityId={entityId}
                  />
                </Dialog>
              </Card>
            );
          })}

        <Button
          raised
          primary
          onClick={this.fetchNextUsers}
          disabled={!this.props.approvedUsers.nextUri}
        >
          Show More
        </Button>
        <SuspendUserDialog
          open={this.state.showSuspendUserDialog}
          userName={this.state.userToBeSuspended}
          userId={this.state.userId}
          onClose={this.closeSuspendUserDialog}
          suspendUser={this.suspendUser}
        />
        <TemporarySnackbar
          show={this.props.approvedUsers.isUserSuspended}
          message={this.props.approvedUsers.userSuspendedMessage}
          timeout={5000}
          onClose={() => this.setState({ isUserSuspended: false })}
          onAction={() => this.setState({ isUserSuspended: false })}
        />
      </React.Fragment>
    );
  }
}

SignedUpUsers = WithNavigationToHome(SignedUpUsers);
class SignedUpUsersContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SignedUpUsers {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(SignedUpUsersContainer);
