import { SOLUTION_STATUS } from "../Utils/constants";

const modes = {
  BY_SOLUTION: "BY_SOLUTION",
  BY_PROVIDER: "BY_PROVIDER",
  CREATE: "CREATE",
  DEACTIVATE: "DEACTIVATE"
};

function cleanSolution({ appId, name, attributes, credentials }) {
  const displayName = attributes.find(item => item.name === "DisplayName")
    .value;
  const description = attributes.find(item => item.name === "description")
    .value;
  let apiProducts;

  if (credentials.length) {
    apiProducts = credentials[0].apiProducts;
  }

  return {
    name,
    displayName,
    description,
    apiProducts,
    id: appId
  };
}

function cleanProviders(response) {
  return response.reduce((acc, provider) => {
    const { api } = provider;
    if (!!provider.name) {
      if (acc[api]) {
        return { ...acc, [api]: [...acc[api], provider] };
      }
      return { ...acc, [api]: [provider] };
    } else {
      return acc;
    }
  }, {});
}

function isBodyValid(
  bulkSubMode,
  solutionMode,
  {
    solution,
    solutionId,
    providers,
    entities,
    entitiesError,
    solutionDeactDate,
    providerDeactId,
    providerDeactDate,
    providerDmsConfig
  }
) {
  if (bulkSubMode === modes.BY_SOLUTION) {
    // Missing solution
    if (!solution) {
      return false;
    }
    if (solutionMode === modes.CREATE || solutionMode === modes.DEACTIVATE) {
      // Has all fields
      if (
        !solutionId ||
        !Object.keys(providers).length ||
        Object.values(providers).every(provider => provider === "") ||
        (!entities && !providerDmsConfig.length)
      ) {
        return false;
      }
      // Has entities to attach
      return (entities.length && !entitiesError) || providerDmsConfig.length;
    }
    return false;
  } else if (bulkSubMode === modes.BY_PROVIDER) {
    return isValidId(providerDeactId) && providerDeactDate;
  }
  return false;
}

function isBulkAllowed(
  bulkSubMode,
  solutionMode,
  {
    solution,
    solutionId,
    solutionApiProducts,
    solutionAsyncApi,
    entities,
    entitiesError,
    missingDmsConfigentitiesError,
    solutionDeactDate,
    providerDeactId,
    providerDeactDate,
    providerDmsAttributes,
    isFileValid
  }
) {
  if (bulkSubMode === modes.BY_SOLUTION) {
    let restIntegrations = {};
    let asyncIntegrations = {};
    let providers = {};
    const rest = solutionApiProducts.filter(e => e.selected === true);
    rest.forEach(item => {
      restIntegrations[item.api] = item.api;
    });

    const async = solutionAsyncApi.filter(e => e.selected === true);
    async.forEach(item => {
      asyncIntegrations[item.async.id] = item.async.orgId;
    });
    providers = { ...restIntegrations, ...asyncIntegrations };
    // Missing solution
    if (!solution) {
      return false;
    }
    if(solution.status===SOLUTION_STATUS.CANCELLED){
      return false
    }
    if (solutionMode === modes.CREATE || solutionMode === modes.DEACTIVATE) {
      // Has all fields
      if (
        !solutionId ||
        !Object.keys(providers).length ||
        Object.values(providers).every(provider => provider === "") ||
        (!entities && !providerDmsAttributes.length)
      ) {
        return false;
      }
      // Has entities to attach
      return (
        Boolean(entities.length) && !entitiesError && !missingDmsConfigentitiesError && isFileValid
      );
    }
    return false;
  } else if (bulkSubMode === modes.BY_PROVIDER) {
    return isValidId(providerDeactId) && providerDeactDate;
  }
  return false;
}

function isValidId(id) {
  const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  return re.test(id?.trim());
}

function isValidListOfIds(src) {
  return src.split(",").every(item => isValidId(item));
}

function mapFromArray(keyField, arr) {
  if (!arr) return {};
  return arr.reduce((acc, item) => {
    if (!item[keyField]) return acc;
    return { ...acc, [item[keyField].toLowerCase()]: item };
  }, {});
}

export {
  modes,
  isValidId,
  isBodyValid,
  mapFromArray,
  cleanSolution,
  cleanProviders,
  isValidListOfIds,
  isBulkAllowed
};
