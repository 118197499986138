import React, { Component } from "react";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { actions } from "./entityActions";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
// UI COMPONENTS
import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Select } from "@cdk-uip/react-select";
import { TextField } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { Icon } from "@cdk-uip/react-icon";
import { LoadingButton } from "../components/LoadingButton";
import { ButtonIcon, Button } from "@cdk-uip/react-button";
import copy from "copy-to-clipboard";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import RestoreEntityDialog from "./RestoreEntityDialog";

const NAME_TYPE = "name";
const DOMAIN_TYPE = "domain";
const UNAVALIABLE = "n/a";

class RestoreEntitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchType: NAME_TYPE,
      searchInProgress: false,
      lastSearch: "",
      snack: false,
      isSearchDisabled: true,
      showAgreementDialog: false,
      showFeatureGatesDialog: false,
      showNameSpaceDialog: false,
      entityToEditNameSpace: null,
      agreementRequestForEntity: {},
      entityToEditFeatureGates: null,
      orgSearchData: null,
      verifiedOverrides: {},
      showRestoreOrganizationDialog: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      restoreEntity,
      restoreEntity: { searchData, searchError }
    } = this.props;
    if (prevProps.restoreEntity !== restoreEntity) {
      if (prevProps.restoreEntity.searchData !== restoreEntity.searchData) {
        if (searchData.organizations.length > 0) {
          this.setState({
            searchString: "",
            lastSearch: prevState.searchString,
            searchInProgress: false,
            isSearchDisabled: true,
            orgSearchData: restoreEntity.searchData
          });
        } else {
          this.setState({
            lastSearch: prevState.searchString,
            searchInProgress: false
          });
        }
      } else if (searchError) {
        this.setState({
          searchInProgress: false
        });
      }
    }
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  onSubmit = () => {
    const { searchString, searchType } = this.state;
    const {
      auth: { accessToken }
    } = this.props;
    let trimmedSearch = searchString.trim();
    if (searchType.length) {
      if (searchType === NAME_TYPE) {
        this.props.searchDeletedEntity({
          accessToken,
          name: trimmedSearch,
          deleted: true
        });
        this.setState({ searchInProgress: true });
      } else if (searchType === DOMAIN_TYPE) {
        this.props.searchDeletedEntity({
          accessToken,
          domain: trimmedSearch,
          deleted: true
        });
        this.setState({ searchInProgress: true });
      }
    }
  };

  restoreOrganization = entity => {
    const {
      auth: { accessToken }
    } = this.props;
    const data = {
      orgId: entity
    };

    this.props.restoreOrganization({ accessToken, data });
  };

  updateSnack = text => {
    this.setState({
      snack: text
    });
  };

  openRestoreOrganizationDialog = entity => {
    this.setState({
      showRestoreOrganizationDialog: true,
      entityToRestoreOrganization: entity
    });
  };

  closeRestoreOrganizationDialog = () => {
    this.setState({
      showRestoreOrganizationDialog: false
    });
  };

  render() {
    let searchData = this.state.orgSearchData;

    const {
      searchInProgress,
      lastSearch,
      searchType,
      searchString,
      isSearchDisabled
    } = this.state;
    return (
      <React.Fragment>
        {searchInProgress || this.props.restoreEntity.showUploadProgress ? (
          <div className="c-circular-progress--overlay-wrapper">
            {this.props.restoreEntity.showUploadProgress ? (
              <Headline>{this.props.restoreEntity.progressMessage}</Headline>
            ) : (
              <Headline>
                Searching...
                <br />
                Showing related Organizations
                <br />
                Please wait...
              </Headline>
            )}
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        ) : (
          false
        )}
        <Elevation z={10} className={"c-dash-card__wrapper"}>
          <Card className="c-dash-card c-form-subscriptions c-form-subscriptions--start">
            <CardHeader>
              <CardTitle large>Search Organizations</CardTitle>
              <CardSubtitle>Search by name or email domain.</CardSubtitle>
            </CardHeader>
            <CardText>
              <Fluid valign="middle">
                <FluidItem fill>
                  <TextField
                    id="entitySearch"
                    name="entitySearch"
                    className="c-search-input--field"
                    spellCheck={false}
                    type="string"
                    inputMode="text"
                    value={searchString}
                    onChange={e => {
                      this.onInputChange(e.target.value, "searchString");
                      this.setState({
                        isSearchDisabled: e.target.value.trim().length < 3
                      });
                    }}
                    fullWidth
                  />
                </FluidItem>
                <FluidItem>
                  <Select
                    label="Search By:"
                    className="c-search-input--type"
                    value={searchType}
                    onChange={e =>
                      this.onInputChange(e.target.value, "searchType")
                    }
                    allowEmpty={false}
                    required
                  >
                    <option value={NAME_TYPE}>Organization Name</option>
                    <option value={DOMAIN_TYPE}>Email Domain</option>
                  </Select>
                </FluidItem>
                <FluidItem>
                  <LoadingButton
                    className="loadingButton-leftPadding c-search--submit"
                    raised
                    primary
                    dense
                    onClick={this.onSubmit}
                    isLoading={searchInProgress}
                    type="submit"
                    disabled={isSearchDisabled}
                    loadingValue={"Searching..."}
                  >
                    <ButtonIcon>arrow_forward</ButtonIcon>
                    Search
                  </LoadingButton>
                </FluidItem>
              </Fluid>
              <div className="hint">
                {searchType === DOMAIN_TYPE ? (
                  <span>Enter full or partial email addresses or domains.</span>
                ) : (
                  <span>Enter full or partial names.</span>
                )}
              </div>
            </CardText>
            {searchData ? (
              <CardText>
                <h2>Result Returned For: "{lastSearch}"</h2>
                {!!searchData.organizations &&
                searchData.organizations.length > 0 ? (
                  <div className="c-entity-search--container">
                    {searchData.organizations.map(entity => (
                      <EntityInformation
                        entity={entity}
                        key={entity.id}
                        updateSnack={this.updateSnack}
                        restoreOrganization={this.openRestoreOrganizationDialog}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="cdk-entity-search--result">
                    <h3>{`${lastSearch} not found`}</h3>
                  </div>
                )}
              </CardText>
            ) : (
              ""
            )}
          </Card>
        </Elevation>
        <RestoreEntityDialog
          open={this.state.showRestoreOrganizationDialog}
          entity={this.state.entityToRestoreOrganization}
          onClose={this.closeRestoreOrganizationDialog}
          restoreOrganization={this.restoreOrganization}
        />
        <TemporarySnackbar
          show={this.state.snack.length}
          message={this.state.snack}
          onClose={() => this.setState({ snack: "" })}
          timeout={1000}
        />
      </React.Fragment>
    );
  }
}

const EntityInformation = ({ entity, updateSnack, restoreOrganization }) => {
  const copyToClipboard = id => {
    let result = copy(id);
    if (result) {
      updateSnack("Copied Organization ID to Clipboard");
    }
  };
  const generateAddress = address => {
    const {
      street = "",
      postalCode = "",
      city = "",
      region = "",
      countryCode = ""
    } = address;
    return `${street}, ${postalCode} ${city} ${region} ${countryCode}`;
  };
  return (
    <div className="c-entity-search--result">
      <div className="c-entity-search--result-title">
        <h2>{entity.name}</h2>
        <Icon
          onClick={() => copyToClipboard(entity.id)}
          title="Copy Organization ID"
        >
          content_copy
        </Icon>
      </div>

      <ul className="c-entity-search--result-items">
        <LayoutGrid nested className={"c-layout-section__home-grid"}>
          <LayoutGridCell
            span={8}
            className={
              "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
            }
          >
            <EntityListItem
              id={entity.id}
              text={entity.id}
              title="Organization ID"
            />
            {entity.emailDomains && (
              <EntityListItem
                text={
                  typeof entity.emailDomains === "string"
                    ? entity.emailDomains
                    : entity.emailDomains.join(", ")
                }
                title="Email Domains"
              />
            )}

            <EntityListItem text={entity.phone} title="Phone Number" />
            <EntityListItem text={entity.website} title="Website" />
            <EntityListItem
              text={
                entity.address ? generateAddress(entity.address) : undefined
              }
              title="Address"
            />
            <EntityListItem text={entity.belongsTo} title="Belongs to" />
          </LayoutGridCell>
          <LayoutGridCell>
            <Button
              className="manage_agreements-dialog-button"
              outlined
              onClick={() => restoreOrganization(entity.id)}
            >
              Restore Organization
            </Button>
          </LayoutGridCell>
        </LayoutGrid>
      </ul>
    </div>
  );
};

const EntityListItem = ({ text, title, id }) => (
  <li className="c-search-list-item">
    <Fluid halign="left">
      <div className="c-search-list-item__title">{title}</div>
      <div className="c-search-list-item__divider">:</div>
      <div className="c-search-list-item__text" id={id}>
        {text ? text : UNAVALIABLE}
      </div>
    </Fluid>
  </li>
);

export default connect(
  state => ({
    restoreEntity: state.restoreEntity
  }),
  actions
)(withAuth(RestoreEntitySearch));
