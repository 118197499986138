import React, { useEffect, useState } from "react";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import ApiIntegrationTable from "./ApiIntegrationTable";
import WithNavigationToHome from "../components/WithNavigationToHome";
import {
  Input,
  INPUT_ICON_POSITIONS,
  INPUT_SIZES,
  Button,
  BUTTON_VARIANTS,
  IconSearch,
  CardTitle,
  CardBody,
  CardWrapper,
  RadioGroup,
  RADIO_SIZES,
  Heading,
  HEADING_TYPES,
  ListItem,
  ListItemPrimaryText,
  ListItemText,
  ListWrapper,
  Popup,
  Loader,
  Dialog,
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS
} from "cdk-radial";
import "./style.css";
import moment from "moment";
import {
  DATE_FORMAT,
  SEARCH_CONST,
  PAGE_HEADING,
  INFO_TEXT,
  NO_SUGGESTION_MSG,
  NO_CONTENT_MSG,
  INTEGRATION_LOADING_TEXT,
  UPDATE_REST_ERROR_MSG,
  UPDATE_ASYNC_ERROR_MSG,
  UPDATE_INTEGRATION_ERROR,
  UPDATE_INTEGRATION_SUCCESSFUL,
  GET_INTEGRATION_ERROR,
  API_TYPE
} from "./constants";
import types from "./types";

const getRandomId = () => {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

function ApiIntegrationManagement(props) {
  const {
    apiIntegrationManagement: {
      searchSuggestions,
      searchSuggestionsLoading,
      noResultsFound,
      restApiIntegrations,
      restApiIntegrationsLoading,
      asyncApiIntegrations,
      asyncApiIntegrationLoading,
      getRestIntegartionError,
      getAsyncIntegrationError,
      awaitingRestResp,
      updateRestResp,
      updateRestError,
      awaitingAsyncResp,
      updateAsyncResp,
      updateAsyncError,
      appISVDetailsLoading,
      appISVDetails
    },
    auth: { accessToken },
    getAppsByName,
    getAppById,
    getAsyncApiIntegrations,
    getRestApiIntegrations,
    resetData,
    updateRestIntegrations,
    updateAsyncIntegrations,
    resetPatchApisData,
    resetSearchData,
    getAppISVDetails,
    freeTrialOverrideEmailNotification
  } = props;

  const [searchOption, setSearchOption] = useState(SEARCH_CONST.VALUE.APP_NAME);
  const [selectedSolution, setSelectedSolution] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [tableKey, setTableKey] = useState(getRandomId());
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState(TOAST_VARIANTS.POSITIVE);
  const searchOptionsForRadio = [
    {
      id: "radio-search-option-1",
      label: SEARCH_CONST.LABEL.APP_NAME,
      value: SEARCH_CONST.VALUE.APP_NAME
    },
    {
      id: "radio-search-option-2",
      label: SEARCH_CONST.LABEL.APP_ID,
      value: SEARCH_CONST.VALUE.APP_ID
    }
  ];

  useEffect(() => {
    if (getRestIntegartionError || getAsyncIntegrationError) {
      setToastContent(GET_INTEGRATION_ERROR);
      setToastVariant(TOAST_VARIANTS.NEGATIVE);
      setShowToast(true);
      return;
    }
    if (
      !getRestIntegartionError &&
      !restApiIntegrationsLoading &&
      !getAsyncIntegrationError &&
      !asyncApiIntegrationLoading &&
      showTable
    ) {
      prepareTableData();
    }
  }, [
    asyncApiIntegrationLoading,
    restApiIntegrationsLoading,
    getRestIntegartionError,
    getAsyncIntegrationError
  ]);

  useEffect(() => {
    resetData();
    setShowTable(false);
  }, [searchOption]);

  useEffect(() => {
    if (updateRestResp || updateRestError) {
      getRestApiIntegrations({ accessToken, appId: selectedSolution.id });
    }

    if (updateAsyncResp || updateAsyncError) {
      getAsyncApiIntegrations({ accessToken, appId: selectedSolution.id });
    }
  }, [updateAsyncError, updateRestError, updateAsyncResp, updateRestResp]);

  const handleRadioChange = event => {
    setSearchOption(event.target.value);
  };

  const onSearch = searchInput => {
    if (searchOption === SEARCH_CONST.VALUE.APP_NAME) {
      getAppsByName({ accessToken, appName: searchInput });
    } else if (searchOption === SEARCH_CONST.VALUE.APP_ID) {
      getAppById({ accessToken, appId: searchInput });
    }
  };

  const fetchApiIntegrationForApp = ({ id, name,displayName, developerEmail,createdBy,lastUpdatedBy }) => {
    if (developerEmail && developerEmail==='n/a') {
      getAppISVDetails({ accessToken, uid: createdBy });
      if (createdBy !== lastUpdatedBy) {
        getAppISVDetails({ accessToken, uid: lastUpdatedBy });
      }
    }
    getRestApiIntegrations({ accessToken, appId: id });
    getAsyncApiIntegrations({ accessToken, appId: id });
    setSelectedSolution({ id, name,displayName, developerEmail});
    setShowTable(true);
  };

  //get plan type
  const getPlanType = (planData = {}) => {
    if (planData.type && planData.type.toLowerCase) {
      switch (planData.type.toLowerCase()) {
        case "advanced":
          return "Subscription w/ Overage";
        case "tiered":
          return "Transactional";
        case "subscription":
          return "Subscription";
        case "free":
          return "Free";
        default:
          return "Unknown";
      }
    } else {
      return "Unknown";
    }
  };

  const prepareTableData = () => {
    const tableDataObj = {};
    restApiIntegrations.forEach(i => {
      let restdate = moment.utc(i.freeTrialOverrideEndDate).toDate();
      let localRestDate = moment(restdate).local();
      const integration = {
        apiName: i.apiName,
        apiId: i.apiId,
        apiType: API_TYPE.REST,
        integrationId: i.id,
        pricingPlanType: i.apiPricingPlan && i.apiPricingPlan.type,
        pricingPlanDisplayName: getPlanType(i.apiPricingPlan),
        originalFreeTrialEndDate:
          i.apiPricingPlan && i.apiPricingPlan.freeDays
            ? moment(i.apiIntegrationStartDate)
                .add(i.apiPricingPlan && i.apiPricingPlan.freeDays, "days")
                .format(DATE_FORMAT)
                .toString()
            : "N/A",
        freeTrialOverrideEndDate:
          i.freeTrialOverrideEndDate && localRestDate
      };
      tableDataObj[i.id] = integration;
    });

    asyncApiIntegrations.forEach(i => {
      let asyncDate = moment.utc(i.freeTrialOverrideEndDate).toDate();
      let localAsyncDate = moment(asyncDate).local();
      const asyncIntegration = {
        apiName: i.asyncApi.name,
        apiId: i.asyncApi.id,
        apiType: API_TYPE.ASYNC,
        integrationId: i.id,
        pricingPlanType:
          i.asyncApi &&
          i.asyncApi.apiPricingPlan &&
          i.asyncApi.apiPricingPlan.type,
        pricingPlanDisplayName: getPlanType(
          i.asyncApi && i.asyncApi.apiPricingPlan
        ),
        originalFreeTrialEndDate:
          i.asyncApi &&
          i.asyncApi.apiPricingPlan &&
          i.asyncApi.apiPricingPlan.freeDays
            ? moment(i.asyncApi && i.asyncApi.apiIntegrationStartDate)
                .add(i.asyncApi.apiPricingPlan.freeDays, "days")
                .format(DATE_FORMAT)
                .toString()
            : "N/A",
        freeTrialOverrideEndDate: Date.parse(i.freeTrialOverrideEndDate)
          ? localAsyncDate
          : null
      };
      tableDataObj[i.id] = asyncIntegration;
    });
    setTableKey(getRandomId());
    setTableData(tableDataObj);
    resetPatchApisData();
  };

  const onSave = async(restData, asyncData) => {
    let emailRecieptents = [];
    if (
      selectedSolution.developerEmail &&
      selectedSolution.developerEmail !== "n/a"
    ) {
      emailRecieptents.push({ email: selectedSolution.developerEmail });
    } else {
      emailRecieptents = appISVDetails.map((item) => ({
        email: item.login,
      }));
    }

    let promiseArray = [];
    if (restData.length)
      promiseArray.push(
        updateRestIntegrations({ accessToken, data: restData })
      );
    if (asyncData.length)
      promiseArray.push(
        updateAsyncIntegrations({ accessToken, data: asyncData })
      );
    const result = Promise.all(promiseArray);
    const apiDetails= [...restData, ...asyncData].filter(item => item.apiName && item.freeTrialEndDate);
    result
      .then(resp => {
        if(apiDetails.length > 0){
          freeTrialOverrideEmailNotification({accessToken, appName:selectedSolution.displayName,apiDetails:apiDetails,emailRecieptents:emailRecieptents})
        }
        const allSuccess =
          promiseArray.length > 1
            ? resp[0].type === types.UPDATE_REST_API_INTEGRATIONS_RECEIVE &&
              resp[1].type === types.UPDATE_ASYNC_API_INTEGRATIONS_RECEIVE
            : resp[0].type === types.UPDATE_REST_API_INTEGRATIONS_RECEIVE ||
              resp[0].type === types.UPDATE_ASYNC_API_INTEGRATIONS_RECEIVE;
        const allFail =
          promiseArray.length > 1
            ? resp[0].type === types.UPDATE_REST_API_INTEGRATIONS_ERROR &&
              resp[1].type === types.UPDATE_ASYNC_API_INTEGRATIONS_ERROR
            : resp[0].type === types.UPDATE_REST_API_INTEGRATIONS_ERROR ||
              resp[0].type === types.UPDATE_ASYNC_API_INTEGRATIONS_ERROR;
        if (allSuccess) {
          setToastContent(UPDATE_INTEGRATION_SUCCESSFUL);
          setToastVariant(TOAST_VARIANTS.POSITIVE);
          setShowToast(true);
        } else if (allFail) {
          setToastContent(UPDATE_INTEGRATION_ERROR);
          setToastVariant(TOAST_VARIANTS.NEGATIVE);
          setShowToast(true);
        } else if (resp[0].type === types.UPDATE_REST_API_INTEGRATIONS_ERROR) {
          setToastContent(UPDATE_REST_ERROR_MSG);
          setToastVariant(TOAST_VARIANTS.NEGATIVE);
          setShowToast(true);
        } else if (resp[1].type === types.UPDATE_ASYNC_API_INTEGRATIONS_ERROR) {
          setToastContent(UPDATE_ASYNC_ERROR_MSG);
          setToastVariant(TOAST_VARIANTS.NEGATIVE);
          setShowToast(true);
        }
      })
      .catch(error => console.error("ERROR", error));
  };

  return (
    <>
      <CardWrapper
        className={
          "s-content-route--subscription-management c-layout-section__dash-grid"
        }
      >
        <CardBody>
          <CardTitle>
            <Heading headingType={HEADING_TYPES.HEADING_2}>
              {PAGE_HEADING}
            </Heading>
          </CardTitle>
          <div style={{ marginTop: "8px" }}>{INFO_TEXT}</div>
        </CardBody>
      </CardWrapper>
      <CardWrapper style={{ minHeight: "700px" }}>
        <CardBody>
          <div className="search-container">
            <div className="search-option-radio">
              <Heading headingType={HEADING_TYPES.SUBTITLE_2}>
                Search By :
              </Heading>
              <RadioGroup
                dataTestId="radio-group"
                id="radio-group-search-options"
                hideLabel
                name="search-options"
                onChange={handleRadioChange}
                options={searchOptionsForRadio}
                value={searchOption}
                radioSize={RADIO_SIZES.LARGE}
              />
            </div>

            <TypeaheadSearch
              searchOption={searchOption}
              onSearch={onSearch}
              searchSuggestions={searchSuggestions}
              searchSuggestionsLoading={searchSuggestionsLoading}
              noResultsFound={noResultsFound}
              onSelect={fetchApiIntegrationForApp}
              resetSearchData={resetSearchData}
            />
          </div>

          <div className="table-container">
            {restApiIntegrationsLoading || asyncApiIntegrationLoading ? (
              <div className="body-loader">
                <Loader label={INTEGRATION_LOADING_TEXT} />
              </div>
            ) : (
              showTable &&
              (Object.keys(tableData).length ? (
                <ApiIntegrationTable
                  heading={selectedSolution}
                  tableData={tableData}
                  onSave={onSave}
                  key={tableKey}
                />
              ) : (
                <div className="body-loader">{NO_CONTENT_MSG}</div>
              ))
            )}
          </div>
        </CardBody>
      </CardWrapper>
      <Dialog
        hideHeader
        hideCloseButton
        id="uploading-dialog"
        isOpen={awaitingAsyncResp || awaitingRestResp}
        dataTestId="uploading-btn"
        title=""
        disableOverlayClicked={true}
      >
        <Loader
          label={"Updating data..."}
          style={{ display: "flex", textAlign: "center" }}
        />
      </Dialog>
      {showToast && (
        <Toast
          content={toastContent}
          id={"notification"}
          actions={[
            {
              onClick: () => {
                setShowToast(false);
              },
              text: "Dismiss"
            }
          ]}
          style={{ marginTop: "50px" }}
          onVisibilityDurationEnd={() => {
            setShowToast(false);
          }}
          position={TOAST_POSITIONS.FIXED}
          variant={toastVariant}
        />
      )}
    </>
  );
}

const TypeaheadSearch = ({
  searchOption,
  onSearch,
  searchSuggestions,
  searchSuggestionsLoading,
  noResultsFound,
  onSelect,
  resetSearchData
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, [searchSuggestions]);

  useEffect(() => {
    setSearchInput("");
  }, [searchOption]);

  useEffect(() => {
    if (noResultsFound) resetSearchData();
  }, [searchInput]);

  let searchPlaceholder;
  if (searchOption === SEARCH_CONST.VALUE.APP_NAME)
    searchPlaceholder = SEARCH_CONST.PLACEHOLDER.APP_NAME;
  else if (searchOption === SEARCH_CONST.VALUE.APP_ID)
    searchPlaceholder = SEARCH_CONST.PLACEHOLDER.APP_ID;
  const handleSelect = (id, name,displayName, developerEmail,createdBy,lastUpdatedBy) => {
    setSearchInput(searchOption === SEARCH_CONST.VALUE.APP_NAME ? name : id);
    setIsOpen(false);
    resetSearchData();
    onSelect({ id, name,displayName,developerEmail,createdBy,lastUpdatedBy });
  };

  return (
    <div>
      <div className="search-input-container">
        <Input
          onChange={event => setSearchInput(event.target.value)}
          placeholder={searchPlaceholder}
          value={searchInput}
          hideLablse={true}
          size={INPUT_SIZES.SMALL}
          icon={<IconSearch />}
          iconPosition={INPUT_ICON_POSITIONS.LEADING}
          hasClearButton={true}
          onInputClear={() => setSearchInput("")}
          data-testid="search-input"
          isDisabled={!searchOption}
        />
        <Button
          text={"Search"}
          variant={BUTTON_VARIANTS.PRIMARY}
          isDisabled={!searchInput.trim()}
          onClick={() => onSearch(searchInput.trim())}
          isLoading={searchSuggestionsLoading}
          style={{
            height: "2.5rem",
            fontSize: "16px",
            padding: "1rem 1rem",
            width: "12%"
          }}
        />
      </div>
      {searchSuggestions.length ? (
        <div className="search-suggestions-container">
          <Popup
            style={{ background: "#fff", zIndex: "5" }}
            dataTestId="popup"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => {}}
            wrappedComponentContent={<div />}
          >
            <ListWrapper
              style={{ width: "100%" }}
              className={isOpen ? "search-suggestions-popup" : null}
            >
              {searchSuggestions.map(item => (
                <ListItem
                  key={item.id}
                  onClick={() => {
                    handleSelect(item.id, item.name, item.displayName,item.developerEmail, item.createdBy, item.lastUpdatedBy)}
                  }
                >
                  <ListItemText>
                    {searchOption === SEARCH_CONST.VALUE.APP_NAME ? (
                      <ListItemPrimaryText>
                        <b>{item.name}</b> : {item.id}
                      </ListItemPrimaryText>
                    ) : (
                      <ListItemPrimaryText>
                        <b>{item.id}</b> : {item.name}
                      </ListItemPrimaryText>
                    )}
                  </ListItemText>
                </ListItem>
              ))}
            </ListWrapper>
          </Popup>
        </div>
      ) : null}
      {!searchSuggestions.length && noResultsFound && searchInput ? (
        <ListWrapper
          style={{ width: "42%" }}
          className={isOpen ? "search-suggestions-popup" : null}
        >
          <ListItem>
            <ListItemText>
              <ListItemPrimaryText>{NO_SUGGESTION_MSG}</ListItemPrimaryText>
            </ListItemText>
          </ListItem>
        </ListWrapper>
      ) : null}
    </div>
  );
};

ApiIntegrationManagement = WithNavigationToHome(ApiIntegrationManagement);
class createContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <ApiIntegrationManagement {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(ApiIntegrationManagement);
