import types from "./types";
import config from "../config/app.conf.json";
import axios from "axios";
import moment from "moment";
const DATE_FORMAT = "YYYY-MM-DD";

export const actions = {
  fetchApps: ({ accessToken, startDate, endDate }) => {
    //fetch data from api using POST method
    return dispatch => {
      dispatch({
        type: types.APP_POST_REQUEST
      });
      axios
        .post(
          `${config.api.marketplace_apps_url}/apps/count`,
          {
            from: moment(startDate, DATE_FORMAT)
              .startOf("day")
              .toISOString(),
            to: moment(endDate, DATE_FORMAT)
              .endOf("day")
              .toISOString()
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + accessToken
            }
          }
        )
        .then(res => {
          dispatch(getResult(types.APP_POST_RECIEVE, { ...res.data }));
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.APP_POST_ERROR, error.response));
          } else {
            dispatch(getResult(types.APP_POST_ERROR, {}));
          }
        });
    };
  }
};

const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};
