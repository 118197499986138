import AssociatedEntities from "./AssociatedEntities";
import { connect } from "react-redux";
import { actions } from "./UserProfileAction";

export default connect(state => {
  return {
    associatedEntities: state.approvedUsersReducer.userEntities.list || [],
    associatedEntitiesSpecs: state.approvedUsersReducer.userEntities || {},
    userRoles: state.userProfileReducer.userRoles || {}
  };
}, actions)(AssociatedEntities);
