import types from "./types";
import { combineReducers } from "redux";

const signedUpUsers = (state = { isFetching: false, error: "" }, action) => {
  switch (action.type) {
    case types.SIGNED_UP_USERS_GET_REQUEST:
      return { isFetching: true, error: false };
    case types.SIGNED_UP_USERS_GET_RECEIVE:
      return { isFetching: false, list: action.response, error: false };
    case types.SIGNED_UP_USERS_GET_ERROR:
      return { isFetching: false, error: "An error occured" };
    default:
      return state;
  }
};

const resendEmailToVerify = (
  state = { isSending: false, error: "" },
  action
) => {
  switch (action.type) {
    case types.SEND_EMAIL_POST_REQUEST:
      return { isSending: true, userEmail: action.email, error: false };
    case types.SEND_EMAIL_POST_RECEIVE:
      if (action.response.code === 202) {
        return {
          isSending: false,
          userEmail: action.email,
          error: false,
          message: "Email request queued successfully"
        };
      }
      return {
        isSending: false,
        userEmail: action.email,
        error: true,
        message: "Error while processing email request"
      };
    case types.SEND_EMAIL_POST_ERROR:
      return {
        isSending: false,
        userEmail: action.email,
        error: true,
        message: "Error while processing email request"
      };
    default:
      return state;
  }
};

export default combineReducers({
  signedUpUsers,
  resendEmailToVerify
});
