// External Modules
import React, { Component, Fragment } from "react";
// Internal Modules
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Card, CardText } from "@cdk-uip/react-card";
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
  DataTablePagination
} from "@cdk-uip/react-data-table";
import { LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
import { Chip, ChipSet } from "@cdk-uip/react-chips";
import { Elevation } from "@cdk-uip/react-elevation";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import saveAs from "file-saver";
import { STATUS_TEXT, formatStatus } from "../SpecReview/utils";

class AppPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 10,
      filteredApps: [],
      filters: {
        // true/false is based on selected in filter list
        [STATUS_TEXT.REGISTERED]: false,
        [STATUS_TEXT.SUBMITTED]: false,
        [STATUS_TEXT.IN_REVIEW]: false,
        [STATUS_TEXT.APPROVED]: false,
        [STATUS_TEXT.PUBLISHED]: false
      },
      searchFilter: "",
      reportDownloaded: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.apps !== this.props.apps) {
      this.setState({
        filteredApps: this.props.apps.filter(this.appFilter)
      });
    }
    if (
      prevState.filters !== this.state.filters ||
      prevState.typeFilters !== this.state.typeFilters ||
      prevState.searchFilter !== this.state.searchFilter
    ) {
      this.setState({
        filteredApps: this.props.apps.filter(this.appFilter),
        page: 0
      });
    }
    if (
      this.props.reportLoading === false &&
      this.props.subscriptionReport !== prevProps.subscriptionReport
    ) {
      this.downloadReport(this.props.subscriptionReport, this.props.appName);
    }
  }

  appSort(a, b) {
    if (a.displayName > b.displayName) return 1;
    if (a.displayName < b.displayName) return -1;
    return 0;
  }

  convertToArray(apps) {
    return Array.isArray(apps) ? apps : [apps];
  }

  downloadReport(file, filename) {
    const blob = new Blob([file], { type: "csv" });
    const blobName = `${filename}.csv`;
    saveAs(blob, blobName);
  }

  pageListForward = () => {
    const { page, pageSize, filteredApps: apis } = this.state;
    if (!apis.length) return;
    if (apis.length < (page + 1) * pageSize + 1) return;
    this.setState(({ page }) => ({
      page: page + 1
    }));
  };

  pageListBackward = () => {
    const { page, filteredApps: apis } = this.state;
    if (!apis.length) return;
    if (page - 1 < 0) return;
    this.setState(({ page }) => ({
      page: page - 1
    }));
  };

  toggleFilter = status => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [status]: !prevState.filters[status]
      }
    }));
  };

  toggleTypeFilter = type => {
    this.setState(prevState => ({
      typeFilters: {
        ...prevState.typeFilters,
        [type]: !prevState.typeFilters[type]
      }
    }));
  };

  appFilter = app => {
    const { filters, searchFilter } = this.state;
    // Filter based on search string
    const filterBySearch = app => {
      const sFilter = searchFilter.toLowerCase().trim();
      const inName = app.displayName
        ? app.displayName.toLowerCase().includes(sFilter)
        : sFilter === 0;
      return inName;
    };

    // Filter based on status of api
    const filterByStatus = app => {
      if (!Object.keys(filters).every(filter => filters[filter] === false)) {
        const appStatus = formatStatus(app.status);
        return filters[appStatus];
      }
      return true;
    };
    return filterByStatus(app) && filterBySearch(app);
  };

  render() {
    const { fetchingApps, reportLoading } = this.props;
    const { page, pageSize, filters, filteredApps, searchFilter } = this.state;

    return (
      <Fragment>
        <LayoutGridCell
          className="c-layout-section__dash-grid-cell"
          span={12}
          spanTablet={8}
          spanPhone={4}
        >
          {fetchingApps ? (
            <div className="loading-container">
              <h3>Loading Apps...</h3>
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <Elevation z={10} className="c-dash-chart__wrapper">
                <Card className="c-dash-chart">
                  <CardText>
                    <div className="c-datatable-wrapper">
                      <div className="filter-bar">
                        <div className="filter-chips-container">
                          <div className="filter-chips">
                            <p className="filter-hint">
                              Filter Apps by Their Status:
                            </p>
                            <FilterChips
                              filters={filters}
                              toggleFilter={this.toggleFilter}
                            />
                          </div>
                        </div>
                        <div className="filter-searchbar">
                          <p className="filter-hint">Search for App</p>
                          <input
                            value={searchFilter}
                            onChange={e =>
                              this.setState({ searchFilter: e.target.value })
                            }
                            placeholder="App name"
                          />
                        </div>
                      </div>
                      <DataTable className="c-apimetric-table">
                        <DataTableHeader>
                          <DataTableRow>
                            <DataTableHeaderCell
                              className="column-1"
                              nonNumeric
                            >
                              App Name
                            </DataTableHeaderCell>
                            <DataTableHeaderCell
                              className="column-2"
                              nonNumeric
                            >
                              Description
                            </DataTableHeaderCell>
                            <DataTableHeaderCell
                              className="column-3"
                              nonNumeric
                            >
                              Status
                            </DataTableHeaderCell>
                            <DataTableHeaderCell
                              className="column-4"
                              nonNumeric
                            >
                              Subscription Report
                            </DataTableHeaderCell>
                          </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                          {filteredApps
                            .sort(this.appSort)
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map(app => (
                              <DataTableRow key={app.id}>
                                <DataTableCell className="column-1" nonNumeric>
                                  {app.displayName}
                                </DataTableCell>
                                <DataTableCell className="column-2" nonNumeric>
                                  {app.description}
                                </DataTableCell>
                                <DataTableCell className="column-3" nonNumeric>
                                  {formatStatus(app.status)}
                                </DataTableCell>
                                <DataTableCell className="column-4" nonNumeric>
                                  {reportLoading &&
                                  this.props.appName === app.displayName ? (
                                    <CircularProgress />
                                  ) : (
                                    <Button
                                      disabled={reportLoading}
                                      raised
                                      className="margin-sides-5"
                                      onClick={() =>
                                        this.props.downloadSubscriptionReport(
                                          app
                                        )
                                      }
                                    >
                                      <ButtonIcon>get_app</ButtonIcon>
                                      Download Report
                                    </Button>
                                  )}
                                </DataTableCell>
                              </DataTableRow>
                            ))}
                        </DataTableBody>
                      </DataTable>
                      <DataTablePagination>
                        <div className="c-pagination-actions">
                          <Button
                            onClick={this.pageListBackward}
                            disabled={page - 1 < 0}
                          >
                            <ButtonIcon>chevron_left</ButtonIcon>
                            Previous
                          </Button>
                          <div className="page-count">
                            {page + 1}/
                            {Math.ceil(filteredApps.length / pageSize)}
                          </div>
                          <Button
                            onClick={this.pageListForward}
                            disabled={
                              filteredApps.length < (page + 1) * pageSize + 1
                            }
                          >
                            Next
                            <ButtonIcon>chevron_right</ButtonIcon>
                          </Button>
                        </div>
                      </DataTablePagination>
                    </div>
                  </CardText>
                </Card>
              </Elevation>
            </Fragment>
          )}
        </LayoutGridCell>
      </Fragment>
    );
  }
}

const FilterChips = ({ filters, toggleFilter }) => (
  <ChipSet>
    {Object.keys(filters).map(filter => (
      <Chip
        selected={filters[filter]}
        onClick={() => toggleFilter(filter)}
        key={filter}
      >
        {filter}
      </Chip>
    ))}
  </ChipSet>
);

export default withAuth(AppPanel);
