import FortellisDeveloperTerms from "./FortellisDeveloperTerms";
import { connect } from "react-redux";
import { actions } from "./fortellisDeveloperTermsActions";

export default connect(
  state => {
    return {
      allTermsAndConditionsData: state.allTermsAndConditionsReducer || {}
    };
  },
  actions
)(FortellisDeveloperTerms);
