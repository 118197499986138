import React from "react";
import {IconStatusDefault} from 'cdk-radial';
import { CERTIFICATION_STATUS, CERTIFICATION_STATUS_DISPLAY } from "./certificationConstants";

const STATUS_COLOR = {
    [CERTIFICATION_STATUS.READY_TO_CERTIFY]: '#074FAF',
    [CERTIFICATION_STATUS.IN_CERTIFICATION]: '#FA9C19',
    [CERTIFICATION_STATUS.CERTIFIED]: '#09AD00'
}

const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap:'8px'
}

const statusTextStyle = {fontFamily:'Open Sans', fontSize:'16px'}

export const StatusIndicator = ({status}) => {
 
    return (
        <div style={containerStyle}>
            <div style={statusTextStyle}>{CERTIFICATION_STATUS_DISPLAY[status] || 'Unknown'}</div>
            <IconStatusDefault style={{fill: STATUS_COLOR[status] || '#BFBFBF'}} height={18} width={18}/>
        </div>
    )
}