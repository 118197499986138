import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { List, ListGroup, ListSubheader } from "@cdk-uip/react-list";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Button } from "@cdk-uip/react-button";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { TnCOverviewDetails } from "./TnCOverviewDetails";
import { TnCDetailsDialog } from "./TnCDetailsDialog";

class FortellisDeveloperTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTnCDtlDialog: false,
      selectedTnC: {
        version: null,
        content: null,
        created_on: null
      }
    };
  }

  componentDidMount() {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchAllTermsAndConditions(accessToken);
  }

  gotoTnCUploader = async () => {
    this.props.history.push({
      pathname: "/agreements/fortellis-developer-terms/tncUploader",
      latestTnC: {
        version: await this.getLatestTnC()
      }
    });
  };

  getLatestTnC = () => {
    const {
      list: termsAndConditionsList
    } = this.props.allTermsAndConditionsData;
    let sortedTnCList = termsAndConditionsList.sort(function(a, b) {
      return b.version - a.version;
    });
    return sortedTnCList[0] ? sortedTnCList[0].version : 0.0;
  };

  fetchTnCDetails = async id => {
    const {
      list: termsAndConditionsList
    } = this.props.allTermsAndConditionsData;
    let tnc = termsAndConditionsList.find(term => term.id === id);
    await this.setState({ selectedTnC: tnc });
    this.setState({ showTnCDtlDialog: true });
  };

  render() {
    let archivedList,
      currentTncId,
      currentTncVersion,
      lastUpdatedOn,
      lastUpdatedBy;

    const {
      list: termsAndConditionsList,
      isFetching,
      error = ""
    } = this.props.allTermsAndConditionsData;

    if (!isFetching && error) {
      return <div>Unable to load terms and conditions.</div>;
    } else if (!termsAndConditionsList) {
      return (
        <div className="loading-container">
          <h3>Loading Terms and Conditions...</h3>
          <CircularProgress />
        </div>
      );
    } else if (termsAndConditionsList && termsAndConditionsList.length) {
      let sortedTnCList = termsAndConditionsList.sort(function(a, b) {
        return b.version - a.version;
      });
      currentTncVersion = sortedTnCList[0].version;
      currentTncId = sortedTnCList[0].id;
      lastUpdatedOn = sortedTnCList[0].created_on;
      lastUpdatedBy = sortedTnCList[0].created_by;
      archivedList = sortedTnCList.slice(1, sortedTnCList.length);
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle large className={"fortellis-developer-terms"}>
            Fortellis Developer Terms
          </CardTitle>
          <CardSubtitle className={"fortellis-developer-terms-subtitle"}>
            The most recent set of terms is at the top and the archived versions
            are below.
          </CardSubtitle>
        </CardHeader>
        <CardText>
          <LayoutGrid nested className={"c-layout-section__home-grid"}>
            <LayoutGridCell
              span={8}
              className={
                "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
              }
            >
              <ListGroup>
                <ListSubheader>
                  <div>
                    <span
                      style={{ display: "inline-block" }}
                      className={"current-terms-title"}
                    >
                      Current Terms
                    </span>
                  </div>
                </ListSubheader>
                <List>
                  <TnCOverviewDetails
                    tnc_version={currentTncVersion}
                    updatedOn={lastUpdatedOn}
                    updatedBy={lastUpdatedBy}
                    open={() => this.fetchTnCDetails(currentTncId)}
                  />
                </List>
              </ListGroup>

              <ListGroup>
                <ListSubheader className={"current-terms-title"}>
                  Archived Terms
                </ListSubheader>
                <List>
                  {archivedList && archivedList.length
                    ? archivedList.map(term => {
                        return (
                          <TnCOverviewDetails
                            key={term.id}
                            tnc_version={term.version}
                            updatedOn={term.created_on}
                            updatedBy={term.created_by}
                            open={() => this.fetchTnCDetails(term.id)}
                          />
                        );
                      })
                    : false}
                </List>
              </ListGroup>
            </LayoutGridCell>

            <LayoutGridCell>
              <Button
                id={"fortellisDeveloperTerms.uploadTnCBtn"}
                raised
                onClick={this.gotoTnCUploader}
              >
                ADD UPDATED TERMS
              </Button>
            </LayoutGridCell>
            <LayoutGridCell span={12} />
          </LayoutGrid>
        </CardText>
        <TnCDetailsDialog
          open={this.state.showTnCDtlDialog}
          onCancel={() => {
            this.setState({ showTnCDtlDialog: false });
          }}
          tnc_version={this.state.selectedTnC.version}
          tnc_text={this.state.selectedTnC.content}
          updated_on={this.state.selectedTnC.created_on}
        />
      </Card>
    );
  }
}

FortellisDeveloperTerms = WithNavigationToHome(FortellisDeveloperTerms);
class FortellisDeveloperTermsContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <FortellisDeveloperTerms {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(FortellisDeveloperTermsContainer);
