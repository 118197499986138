export default {
  API_GET_REQUEST: "API_GET_REQUEST",
  API_GET_RECEIVE: "API_GET_RECEIVE",
  API_GET_ERROR: "API_GET_ERROR",

  SPEC_GET_REQUEST: "SPEC_GET_REQUEST",
  SPEC_GET_RECEIVE: "SPEC_GET_RECEIVE",
  SPEC_GET_ERROR: "SPEC_GET_ERROR",

  SPEC_FILE_GET_REQUEST: "SPEC_FILE_GET_REQUEST",
  SPEC_FILE_GET_RECEIVE: "SPEC_FILE_GET_RECEIVE",
  SPEC_FILE_GET_ERROR: "SPEC_FILE_GET_ERROR",

  REVIEW_SPEC_POST_REQUEST: "REVIEW_SPEC_POST_REQUEST",
  REVIEW_SPEC_POST_RECEIVE: "REVIEW_SPEC_POST_RECEIVE",
  REVIEW_SPEC_POST_ERROR: "REVIEW_SPEC_POST_ERROR",

  RESET_SPEC_POST_REQUEST: "RESET_SPEC_POST_REQUEST",
  RESET_SPEC_POST_RECEIVE: "RESET_SPEC_POST_RECEIVE",
  RESET_SPEC_POST_ERROR: "RESET_SPEC_POST_ERROR",

  REVIEW_SPEC_PUT_REQUEST: "REVIEW_SPEC_PUT_REQUEST",
  REVIEW_SPEC_PUT_RECEIVE: "REVIEW_SPEC_PUT_RECEIVE",
  REVIEW_SPEC_PUT_ERROR: "REVIEW_SPEC_PUT_ERROR",

  APPROVE_SPEC_POST_REQUEST: "APPROVE_SPEC_POST_REQUEST",
  APPROVE_SPEC_POST_RECEIVE: "APPROVE_SPEC_POST_RECEIVE",
  APPROVE_SPEC_POST_ERROR: "APPROVE_SPEC_POST_ERROR",

  REJECT_SPEC_POST_REQUEST: "REJECT_SPEC_POST_REQUEST",
  REJECT_SPEC_POST_RECEIVE: "REJECT_SPEC_POST_RECEIVE",
  REJECT_SPEC_POST_ERROR: "REJECT_SPEC_POST_ERROR",

  UPDATE_NOTES_POST_REQUEST: "UPDATE_NOTES_POST_REQUEST",
  UPDATE_NOTES_POST_RECEIVE: "UPDATE_NOTES_POST_RECEIVE",
  UPDATE_NOTES_POST_ERROR: "UPDATE_NOTES_POST_ERROR",

  LABEL_SPEC_PUT_REQUEST: "LABEL_SPEC_PUT_REQUEST",
  LABEL_SPEC_PUT_RECEIVE: "LABEL_SPEC_PUT_RECEIVE",
  LABEL_SPEC_PUT_ERROR: "LABEL_SPEC_PUT_ERROR",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_MODAL: "CLEAR_MODAL"
};
