import types from "./types";
import getAPIError from "../Utils/getAPIError";

export default function userSearchReducer(
  state = { isFetching: false, users: [], error: false },
  action
) {
  switch (action.type) {
    case types.USER_SEARCH_GET_REQUEST:
      return { isFetching: true, users: [], error: false };
    case types.USER_SEARCH_GET_RECEIVE:
      return { isFetching: false, users: action.response, error: false };
    case types.USER_SEARCH_GET_ERROR:
      return {
        isFetching: false,
        users: [],
        error: true,
        errorMessage: getAPIError(action.apierror)
      };
    case types.USER_SEARCH_RESET:
      return Object.assign({}, state, {
        isFetching: false,
        users: [],
        error: false
      });
    default:
      return state;
  }
}
