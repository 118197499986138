import types from "./types";
import { combineReducers } from "redux";
import { actions as userProfileAction } from "./UserProfileAction";

const userProfile = (
  state = {
    isFetching: false,
    error: false,
    userDetails: null,
    isUserSuspended: false,
    userSuspendedMessage: "",
    isUserUnsuspended: false,
    userUnsuspendedMessage: ""
  },
  action
) => {
  switch (action.type) {
    case types.USER_PROFILE_GET_REQUEST:
      return Object.assign({}, state, { isFetching: true, error: false });
    case types.USER_PROFILE_GET_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        userDetails: action.response[0],
        error: false
      });
    case types.USER_PROFILE_GET_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: "An error occured while fetching user profile"
      });
    case types.SUSPEND_USER_REQUEST:
      return Object.assign({}, state, {
        userSuspendedMessage: false,
        isUserSuspended: false
      });

    case types.SUSPEND_USER_RECEIVE:
      let suspendUserSuccessMessage = "User suspended successfully";
      return Object.assign({}, state, {
        userSuspendedMessage: suspendUserSuccessMessage,
        isUserSuspended: true
      });
    case types.SUSPEND_USER_ERROR:
      let suspendUserErrorMessage = "Failed to suspend user";
      if (action.response.status === 403) {
        suspendUserErrorMessage = action.response.data;
      }
      return Object.assign({}, state, {
        userSuspendedMessage: suspendUserErrorMessage,
        isUserSuspended: true
      });
    case types.UNSUSPEND_USER_REQUEST:
      return Object.assign({}, state, {
        userSuspendedMessage: false,
        isUserSuspended: false
      });

    case types.UNSUSPEND_USER_RECEIVE:
      let unsuspendUserSuccessMessage = "User activated successfully";
      return Object.assign({}, state, {
        userUnsuspendedMessage: unsuspendUserSuccessMessage,
        isUserUnsuspended: true
      });
    case types.UNSUSPEND_USER_ERROR:
      let unsuspendUserErrorMessage = "Failed to activate user";
      if (action.response.status === 403) {
        unsuspendUserErrorMessage = action.response.data;
      }
      return Object.assign({}, state, {
        userUnsuspendedMessage: unsuspendUserErrorMessage,
        isUserUnsuspended: true
      });
    default:
      return state;
  }
};

const userEntities = (
  state = {
    isFetching: false,
    fetchingError: false,
    list: [],
    isRemoving: false,
    removingError: "",
    isAdding: false,
    isAdded: false,
    addingError: false
  },
  action
) => {
  switch (action.type) {
    case types.LINKED_ENTITIES_GET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        fetchingError: false,
        message: false
      });
    case types.LINKED_ENTITIES_GET_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.response.organizations,
        fetchingError: false,
        message: false
      });
    case types.LINKED_ENTITIES_GET_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        fetchingError: "An error occured while fetching linked entities",
        message: false
      });
    case types.REMOVE_USER_FROM_ENTITY_DELETE_REQUEST:
      return Object.assign({}, state, {
        isRemoving: true,
        removingError: false,
        message: false
      });
    case types.REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE:
      let newState = Object.assign({}, state, {});
      let list = newState.list.filter(entity => {
        return entity.id !== action.entityId;
      });
      return Object.assign({}, state, {
        isRemoving: false,
        list,
        response: action.response,
        removingError: false,
        message: "User removed from organisation successfully"
      });
    case types.REMOVE_USER_FROM_ENTITY_DELETE_ERROR:
      return Object.assign({}, state, {
        isRemoving: false,
        removingError: action.apierror.message,
        message: "User could not be removed from entity"
      });
    case types.ADD_USER_TO_ENTITY_GET_REQUEST:
      return Object.assign({}, state, {
        isAdding: true,
        entityId: action.entity.id,
        addingError: false,
        message: false
      });
    case types.ADD_USER_TO_ENTITY_GET_RECEIVE:
      return Object.assign({}, state, {
        isAdding: false,
        isAdded: true,
        entityId: action.entity.id,
        addingError: false,
        message: "User added to organisation successfully"
      });
    case types.ADD_USER_TO_ENTITY_GET_ERROR:
      return Object.assign({}, state, {
        isAdding: false,
        addingError: true,
        message: "User could not be added to organisation"
      });
    case types.RESET_IS_ADDED:
      return Object.assign({}, state, {
        isAdded: false
      });
    default:
      return state;
  }
};

const resendEmailToVerify = (
  state = { isSending: false, error: "", resendEmailMessage: false },
  action
) => {
  switch (action.type) {
    case types.RESEND_EMAIL_POST_REQUEST:
      return Object.assign({}, state, {
        isSending: true,
        error: false,
        resendEmailMessage: false
      });
    case types.RESEND_EMAIL_POST_RECEIVE:
      if (action.response.code === 202) {
        return Object.assign({}, state, {
          isSending: false,
          userEmail: action.email,
          error: false,
          resendEmailMessage: "Email request queued successfully"
        });
      }
      return Object.assign({}, state, {
        isSending: false,
        userEmail: action.email,
        error: true,
        resendEmailMessage: "Error while processing email request"
      });
    case types.RESEND_EMAIL_POST_ERROR:
      return Object.assign({}, state, {
        isSending: false,
        userEmail: action.email,
        error: true,
        resendEmailMessage: "Error while processing email request"
      });
    default:
      return state;
  }
};

const adminUpdateStatus = (
  state = { isFetching: false, userApproveMessage: false },
  action
) => {
  switch (action.type) {
    case types.APPROVE_USER_POST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        response: {},
        userApproveMessage: false
      });
    case types.APPROVE_USER_POST_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        response: action.response,
        userApproveMessage: "User approved successfully"
      });
    case types.APPROVE_USER_POST_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.apierror,
        userApproveMessage: "User could not be approved due to an error"
      });
    default:
      return state;
  }
};

const userRoles = (
  state = {
    orgId: "",
    isLoading: false,
    hasError: false,
    message: ""
  },
  action
) => {
  switch (action.type) {
    case types.ASSIGN_USER_ROLES_REQUEST:
      return Object.assign({}, state, {
        orgId: action.orgId,
        isLoading: true,
        message: ""
      });
    case types.ASSIGN_USER_ROLES_RECEIVE:
      return Object.assign({}, state, {
        orgId: action.orgId,
        isLoading: false,
        message: `Role assigned to user in ${action.orgName}`
      });
    case types.ASSIGN_USER_ROLES_ERROR:
      return Object.assign({}, state, {
        orgId: action.orgId,
        isLoading: false,
        hasError: true,
        message: `Failed to assign role: ${action.apierror.message}`
      });
    default:
      return state;
  }
};

export default combineReducers({
  userProfile,
  userEntities,
  userRoles,
  resendEmailToVerify,
  adminUpdateStatus
});
