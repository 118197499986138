import React from "react";
import { ListItem, ListItemGraphic } from "@cdk-uip/react-list";
import { FormattedMessage } from "react-intl";
import { Icon } from "@cdk-uip/react-icon";

const TnCOverviewDetails = ({ tnc_version, updatedOn, updatedBy, open }) => {
  return (
    <ListItem onClick={open}>
      <ListItemGraphic>
        <Icon>description</Icon>
      </ListItemGraphic>
      <FormattedMessage
        id="Home.action.fortellisDeveloperTerms"
        defaultMessage="Fortellis Developer Terms"
      />
      <div style={{ display: "inline-block" }} className={"version-number"}>
        {tnc_version.toFixed(2)}
      </div>
      <div style={{ display: "inline-block" }} className={"update-info"}>
        <div>Updated On: {updatedOn ? `${updatedOn} UTC` : "NA"}</div>
        <div>Updated by: {updatedBy ? updatedBy : "Unknown"}</div>
      </div>
    </ListItem>
  );
};

export { TnCOverviewDetails };
