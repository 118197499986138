import { Component } from "react";
import Connection from "./Connection";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
import { Tooltip } from "@cdk-uip/react-tooltip";
import { Icon } from "@cdk-uip/react-icon";
import types from "./types";
import {
  AutoExpansionPanel,
  AutoExpansionPanelGroup,
  ExpansionPanelHeader,
  ExpansionPanelContent,
  ExpansionPanelContentBody,
  ExpansionPanelHeaderDescription,
  ExpansionPanelHeaderTitle,
  ExpansionPanelHeaderSubtitle
} from "@cdk-uip/react-expansion-panel";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { FormattedMessage } from "react-intl";
import { Confirm } from "../components/Confirm";
import AsyncApi from "./AsyncApi";

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableToolbar,
  DataTableToolbarAction,
  DataTableToolbarActions,
  DataTableToolbarTitle,
  DataTableToolbarTitlePrimary,
  DataTableRow
} from "@cdk-uip/react-data-table";

import {
  SUBSCRIPTION_EVENTS,
  CONNECTION_STATES,
  ASYNC_ACCESS_GRANT_EVENTS
} from "./states";
import { APP_CANCELLED_TEXT, APP_CERTIFICATION_TEXT, SOLUTION_STATUS } from "../Utils/constants";

const ACTIVATE = "Activate";
const DEACTIVATE = "Deactivate";
const DEALER_PRICING_TEXT =
  "This app is integrated with a Dealer Pricing plan and the dealer needs to belong to the same dealer group that created the app";
const ConfirmActivate = ({ selected: { name } }) => (
  <FormattedMessage
    id="Subscription.activateConfirmation"
    defaultMessage="Activate {name}?"
    values={{ name: name }}
  />
);

const ConfirmDeactivate = ({ selected: { name } }) => (
  <FormattedMessage
    id="Subscription.deactivateConfirmation"
    defaultMessage="Deactivate {name}?"
    values={{ name: name }}
  />
);

class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EXPANDED: false,
      subscriptionId: props.subscriptionId,
      confirm: false,
      apiHasDealerPlan: false,
      isAppCertified: false
    };
  }

  static defaultProps = {
    onEvent: () => {},
    subscriptionId: ""
  };

  promptSubscriptionActivation = () => {
    this.setState({
      confirm: {
        message: () => (
          <ConfirmActivate selected={{ name: this.props.displayName }} />
        ),
        onAccept: () => this.activateSubscription(),
        type: ACTIVATE
      }
    });
  };

  promptSubscriptionDeactivation = () => {
    this.setState({
      confirm: {
        message: () => (
          <ConfirmDeactivate selected={{ name: this.props.displayName }} />
        ),
        onAccept: () => this.deactivateSubscription(),
        type: DEACTIVATE
      }
    });
  };

  onAccept = () => {
    const {
      confirm: { onAccept }
    } = this.state;
    onAccept();
  };

  onCancel = () => {
    this.setState({ confirm: false });
  };

  activateSubscription = () => {
    const { connections, subscriptionId } = this.props;
    if (!connections) {
      this.props.onEvent({
        event: types.ERROR,
        errorMessage:
          "All API connections must be active before subscription activation"
      });
    } else {
      const inactiveConnection = connections.find(
        con => con.status !== CONNECTION_STATES.ACTIVE
      );
      inactiveConnection
        ? this.props.onEvent({
            event: types.ERROR,
            errorMessage:
              "All API connections must be active before subscription activation"
          })
        : this.props.onEvent({
            event: SUBSCRIPTION_EVENTS.SUB_ACTIVATE,
            subscriptionId,
            displayName: this.props.displayName
          });
      this.setState({ confirm: false });
    }
  };

  deactivateSubscription = () => {
    this.props.onEvent({
      event: SUBSCRIPTION_EVENTS.SUB_DEACTIVATE,
      subscriptionId: this.props.subscriptionId,
      displayName: this.props.displayName
    });
    this.setState({ confirm: false });
  };

  onClickEvent = () => {
    if (!this.state.EXPANDED) {
      this.props.onEvent({
        event: SUBSCRIPTION_EVENTS.GET_SUB_CONNECTIONS,
        subscriptionId: this.state.subscriptionId
      });
      this.setState({ EXPANDED: true });
    } else {
      this.setState({ EXPANDED: false });
    }
  };

  asyncApiAccessGrantStatus = accessGrant => {
    let accessGrantstatus = CONNECTION_STATES.INACTIVE;
    if (
      accessGrant &&
      Object.keys(accessGrant).length &&
      accessGrant.status === ASYNC_ACCESS_GRANT_EVENTS.ENABLED
    ) {
      accessGrantstatus = CONNECTION_STATES.ACTIVE;
    }
    return accessGrantstatus;
  };

  checkDealerPricingPlan = solutionInfo => {
    let dealerPlanFlag = false;
    if (solutionInfo) {
      dealerPlanFlag = solutionInfo?.integrations?.some(integration => {
        return integration?.providers?.some(provider => {
          return provider?.pricingPlan?.dealerPriceChecked;
        });
      });
    }
    return dealerPlanFlag;
  };

  checkAppCertificationStatus = solutionInfo => {
    let appCertificationStatus = false;
    // certification status
    if(solutionInfo) {
      appCertificationStatus = solutionInfo?.certificationStatus && solutionInfo?.certificationStatus === 'certified';
    }
    if(appCertificationStatus){
      appCertificationStatus = false
    } {
      appCertificationStatus = true
    }
    return appCertificationStatus;
  }

  componentDidMount() {
    if (this.checkDealerPricingPlan(this.props.solutionInfo)) {
      this.setState({
        apiHasDealerPlan: true
      });
    }
    if(this.checkAppCertificationStatus(this.props.solutionInfo)) {
      this.setState({
        isAppCertified: true
      })
    }
  }

  render() {
    const {
      subscriptionId = "Not Available",
      status,
      displayName,
      connections,
      onEvent,
      apiProducts,
      providers,
      orgs,
      solutionInfo,
      asyncAccessGrants,
      idFromSearch
    } = this.props;
    const { mpAppName } = solutionInfo;
    const { confirm } = this.state;
    return (
      <>
        <AutoExpansionPanelGroup singleExpand={false}>
          <AutoExpansionPanel>
            <ExpansionPanelHeader
              onClick={() => {
                this.onClickEvent();
              }}
            >
              <Icon className={"c-icon__subscription-listitem"}>storage</Icon>
              <ExpansionPanelHeaderTitle>
                <div className="app-name-expansion-panel">
                  {displayName}
                  {this.state.apiHasDealerPlan && (
                    <>
                      <Icon
                        id={`tooltip_${solutionInfo.appId}`}
                        className={"c-icon__subscription-listitem"}
                      >
                        info
                      </Icon>
                      <Tooltip htmlFor={`tooltip_${solutionInfo.appId}`}>
                        <div className="dealer-pricing-tooltip">
                          {DEALER_PRICING_TEXT}
                        </div>
                      </Tooltip>{" "}
                    </>
                  )}
                 {solutionInfo?.status === SOLUTION_STATUS.CANCELLED ? <>
                      <Icon
                        id={`tooltip_${solutionInfo.displayName}`}
                        className={"c-icon__subscription-certification_text"}
                      >
                        info
                      </Icon>
                      <Tooltip htmlFor={`tooltip_${solutionInfo.displayName}`}>
                        <div className="dealer-pricing-tooltip">
                          {APP_CANCELLED_TEXT}
                        </div>
                      </Tooltip>{" "}
                    </> : this.state.isAppCertified && (
                    <>
                      <Icon
                        id={`tooltip_${solutionInfo.displayName}`}
                        className={"c-icon__subscription-certification_text"}
                      >
                        info
                      </Icon>
                      <Tooltip htmlFor={`tooltip_${solutionInfo.displayName}`}>
                        <div className="dealer-pricing-tooltip">
                          {APP_CERTIFICATION_TEXT}
                        </div>
                      </Tooltip>{" "}
                    </>
                  )}
                </div>
                {!!mpAppName && mpAppName.length > 0 && (
                  <span>
                    <strong>On Marketplace as</strong>{' '}
                    {mpAppName}
                  </span>
                )}
              </ExpansionPanelHeaderTitle>
              <ExpansionPanelHeaderSubtitle>
                {subscriptionId}
              </ExpansionPanelHeaderSubtitle>
              <ExpansionPanelHeaderDescription>
                {/* #TODO: MAKE COMPONENT FOR STATUS INDICATOR */}
                {/*http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react/#statusindicatorinline*/}
                <p
                  data-status={status}
                  className={
                    "c-status-indicator c-status-indicator--" + { status }
                  }
                >
                  <Icon>brightness_1</Icon>
                  {status}
                </p>
                {/* #END TODO: MAKE COMPONENT FOR STATUS INDICATOR */}
              </ExpansionPanelHeaderDescription>
              <ExpansionPanelHeaderDescription>
                {/* #TODO: OR */}
                {status === "inactive" ? (
                  <Button
                    raised
                    dense
                    primary
                    disabled = {solutionInfo?.status === SOLUTION_STATUS.CANCELLED}
                    onClick={this.promptSubscriptionActivation}
                  >
                    <ButtonIcon>flash_on</ButtonIcon>
                    Activate
                  </Button>
                ) : (
                  <Button
                    raised
                    dense
                    disabled = {solutionInfo?.status === SOLUTION_STATUS.CANCELLED}
                    onClick={this.promptSubscriptionDeactivation}
                  >
                    <ButtonIcon>flash_off</ButtonIcon>
                    Deactivate
                  </Button>
                )}
                {/* #END OR */}
              </ExpansionPanelHeaderDescription>
            </ExpansionPanelHeader>
            <ExpansionPanelContent expandedHeight={"auto"}>
              <ExpansionPanelContentBody>
                {/* #START CONNECTION LIST TABLE UI LAYOUT */}

                {/* #START TOOLBAR */}
                <DataTableToolbar>
                  <DataTableToolbarTitle>
                    <DataTableToolbarTitlePrimary>
                      API Connections
                    </DataTableToolbarTitlePrimary>
                  </DataTableToolbarTitle>
                  <DataTableToolbarActions>
                    <DataTableToolbarAction>
                      {/* #TODO: NOTHING AT THE MOMENT */}
                    </DataTableToolbarAction>
                  </DataTableToolbarActions>
                </DataTableToolbar>
                {/* #END TOOLBAR */}
                {/* #START DATATABLE */}
                <DataTable>
                  <DataTableHeader>
                    <DataTableRow>
                      <DataTableHeaderCell nonNumeric>
                        API Name
                      </DataTableHeaderCell>
                      <DataTableHeaderCell nonNumeric>
                        API Provider
                      </DataTableHeaderCell>
                      <DataTableHeaderCell nonNumeric>
                        API Status
                      </DataTableHeaderCell>
                      <DataTableHeaderCell nonNumeric>
                        Action
                      </DataTableHeaderCell>
                    </DataTableRow>
                  </DataTableHeader>
                  <DataTableBody>
                    {/* #START CONNECTION LIST ITEM COMPONENT UI LAYOUT */}
                    {/* #START REPEAT DATATABLE ROWS: 'DataTableRow' */}
                    {connections ? (
                      connections.map(connection => (
                        <Connection
                          key={connection.connectionId}
                          {...connection}
                          onEvent={onEvent}
                          apiProducts={apiProducts}
                          providers={providers}
                          solutionInfo ={solutionInfo}
                        />
                      ))
                    ) : (
                      <>
                        <DataTableRow>
                          <DataTableCell nonNumeric>
                            <CircularProgress />
                          </DataTableCell>
                        </DataTableRow>
                      </>
                    )}
                    {solutionInfo.asyncApiIntegrations &&
                      this.state.EXPANDED &&
                      solutionInfo.asyncApiIntegrations.map(integration => {
                        integration["asyncApiAccessGrants"] = asyncAccessGrants[
                          integration.id
                        ]
                          ? asyncAccessGrants[integration.id]
                          : {};
                        this.asyncApiAccessGrantStatus(
                          integration.asyncApiAccessGrants
                        );
                        return (
                          <AsyncApi
                            key={integration.id}
                            status={this.asyncApiAccessGrantStatus(
                              integration.asyncApiAccessGrants
                            )}
                            apiProvisioningStatus={
                            integration.asyncApiAccessGrants?.apiProvisioningStatus
                          }
                            asyncApiIntegration={integration}
                            onEvent={onEvent}
                            orgs={orgs}
                            idFromSearch={idFromSearch}
                            subscriptionId={subscriptionId}
                            solutionInfo ={solutionInfo}
                          />
                        );
                      })}
                    {/* #END REPEAT DATATABLE ROWS: 'DataTableRow' */}
                    {/* #END CONNECTION LIST ITEM COMPONENT UI LAYOUT */}
                  </DataTableBody>
                </DataTable>
                {/* #END DATATABLE */}

                {/* #END CONNECTION LIST TABLE UI LAYOUT */}
              </ExpansionPanelContentBody>
            </ExpansionPanelContent>
          </AutoExpansionPanel>
        </AutoExpansionPanelGroup>
        <Confirm
          open={!!confirm}
          onAccept={this.onAccept}
          onCancel={this.onCancel}
          message={confirm ? confirm.message() : false}
          type={confirm.type}
        />
      </>
    );
  }
}

export default Subscription;
