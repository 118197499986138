import EntityApproval from "./EntityApproval";
import { connect } from "react-redux";
import { actions } from "./entityApprovalActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      entityApproval: state.entityApproval
    }),
    // Inject all actions into the component
    actions
  )(EntityApproval)
);
