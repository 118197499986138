import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Elevation } from "@cdk-uip/react-elevation";
import {
  Card,
  CardActions,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Button, ButtonIcon } from "@cdk-uip/react-button";

import { validatePhoneNumber } from "../Utils/validation";
import FieldWithIcon from "./FieldWithIcon";

const EntityCard = ({ entity, approve }) => {
  const formatAddress = address => {
    return (
      <Fragment>
        <div>{address.street}</div>
        <div>{`${address.city || ""}, ${address.region ||
          ""} ${address.postalCode || ""}`}</div>
      </Fragment>
    );
  };

  const formatEmailDomain = domains => {
    if (!domains) {
      return "n/a";
    }
    if (typeof domains === "string") {
      return domains;
    }
    return domains.join(", ");
  };

  const formatPhone = phone => {
    if (typeof phone === "object") {
      phone = phone.phone;
    } else if (Object.prototype.toString.call(phone) === "[object Array]") {
      phone = phone[0].phone;
    }
    const valid = validatePhoneNumber(phone, true, true);
    return valid ? valid : phone;
  };

  const formatUser = () => {
    let user;
    try {
      user = JSON.parse(entity.createdBy);
      return (
        <Fragment>
          <div>{user.name}</div>
          <div>{user.email}</div>
        </Fragment>
      );
    } catch (err) {
      user = entity.createdBy;
      return <div>{user || "n/a"}</div>;
    }
  };

  return (
    <LayoutGridCell span={12} spanTablet={12} spanPhone={12}>
      <Elevation z={10}>
        <Card>
          <CardHeader>
            <CardTitle large>{entity.name}</CardTitle>
            <CardSubtitle>
              {entity?.id ? entity.id : 'Organization id not found'}
            </CardSubtitle>
            <CardSubtitle>{entity.type} Entity</CardSubtitle>
          </CardHeader>
          <CardText>
            <FieldWithIcon
              icon="home"
              value={formatAddress(entity.address)}
              type="Address"
            />
            <FieldWithIcon icon="face" value={formatUser()} type="Created By" />
            <FieldWithIcon
              icon="phone"
              value={formatPhone(entity.phone)}
              type="Phone Number"
            />
            <FieldWithIcon
              icon="email"
              value={formatEmailDomain(entity.emailDomains)}
              type="Email Domains"
            />
            <FieldWithIcon
              icon="language"
              value={entity.website || "n/a"}
              type="Website"
            />
            <FieldWithIcon
              icon="monetization_on"
              value={entity.taxId || "n/a"}
              type="Tax ID"
            />
          </CardText>
          <CardActions className="c-action-buttons">
            <Button
              className="c-action-button"
              onClick={() => approve(entity.id)}
              raised
              primary
            >
              <ButtonIcon>done</ButtonIcon>
              Verify
            </Button>
          </CardActions>
        </Card>
      </Elevation>
    </LayoutGridCell>
  );
};

EntityCard.propTypes = {
  entity: PropTypes.shape({
    address: PropTypes.object,
    phone: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),
    emailDomains: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    createdBy: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  approve: PropTypes.func.isRequired
  // deny: PropTypes.func.isRequired
};

export default EntityCard;
