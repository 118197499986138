import React from "react";
import PropTypes from "prop-types";
import PanelBodyButton from "./PanelBodyButton";
import { Loader } from "cdk-radial";

const PanelBodyDownload = ({
  id,
  text,
  icon,
  loading,
  children,
  onDownload,
  disableLoadingIcon,
  ...rest
}) => {
  const buttonIcon =
    !disableLoadingIcon && loading ? <Loader label="Loading..." /> : icon;

  return (
    <PanelBodyButton
      id={id}
      icon={buttonIcon}
      disabled={loading}
      onClick={onDownload}
      {...rest}
    >
      {text || children}
    </PanelBodyButton>
  );
};

PanelBodyDownload.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDownload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disableLoadingIcon: PropTypes.bool
};

PanelBodyDownload.defaultProps = {
  icon: "get_app",
  loading: false,
  disableLoadingIcon: false
};

export default PanelBodyDownload;
