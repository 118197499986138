import React, { Fragment } from "react";
import { Card, CardText } from "@cdk-uip/react-card";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { DataTablePagination } from "@cdk-uip/react-data-table";
import { IconButton } from "@cdk-uip/react-icon-button";

export default function EntityApprovalPagination({
  page,
  pageSize,
  total,
  loading,
  onPrevious,
  onNext,
  rightSide
}) {
  const pageStart = (page - 1) * pageSize + 1;
  const pageEnd = Math.min(page * pageSize, total);
  const nPages = Math.ceil(total / pageSize);

  return (
    <Card>
      <CardText>
        <DataTablePagination>
          <div className="c-pagination-actions">
            <div>
              <IconButton
                className="org-pagination-previous"
                disabled={page <= 1 || loading}
                onClick={onPrevious}
              >
                chevron_left
              </IconButton>
              <IconButton
                className="org-pagination-next"
                disabled={page >= nPages || loading}
                onClick={onNext}
              >
                chevron_right
              </IconButton>
              <span className="page-count inline-page-count">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Fragment>
                    {pageStart} - {pageEnd} of {total}
                  </Fragment>
                )}
              </span>
            </div>
            <div>{rightSide}</div>
          </div>
        </DataTablePagination>
      </CardText>
    </Card>
  );
}
