import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import { cleanProviders, mapFromArray } from "./helpers";
// Variables
import config from "../config/app.conf.json";

const SOLUTIONS_URL = config.api.subscriptions_solutions;
const SUBSCRIPTIONS_URL = config.api.subscriptions_base_uri;
const CONNECTIONS_URL = config.api.connections_base_uri;
const PROVIDERS_URL = `${config.api.provider_api_url}/providers`;
const BULK_SUBSCRIPTIONS_URL = config.api.bulk_subscription_base_uri;
const SOLUTION_FETCH_URL = config.api.solutions_url;
const ORG_SERVICE_BASE_URI = config.api.organizations_uri;

export const INITIAL_STATE = {
  solutions: {
    loading: false,
    data: [],
    map: {},
    error: undefined,
    noResults: false
  },
  solutionAsyncIntegrations: {
    loading: false,
    data: [],
    error: undefined
  },
  providers: {
    loading: false,
    data: undefined,
    error: undefined
  },
  submit: {
    loading: false,
    error: undefined,
    data: undefined
  },
  asyncApiIntegrationOrg: {
    loading: false,
    error: undefined,
    data: {}
  }
};

export const TYPES = {
  // Provider types
  GET_PROVIDERS_REQ: "GET_PROVIDERS_REQ",
  GET_PROVIDERS_RES: "GET_PROVIDERS_RES",
  GET_PROVIDERS_ERR: "GET_PROVIDERS_ERR",
  // Submit types
  SUBMIT_REQ: "SUBMIT_REQ",
  SUBMIT_RES: "SUBMIT_RES",
  SUBMIT_ERR: "SUBMIT_ERR",
  // Solutions types
  GET_SOLUTIONS_REQ: "GET_SOLUTIONS_REQ",
  GET_SOLUTIONS_RES: "GET_SOLUTIONS_RES",
  GET_SOLUTIONS_ERR: "GET_SOLUTIONS_ERR",
  GET_SOLUTION_BY_ID_REQ: "GET_SOLUTION_BY_ID_REQ",
  GET_SOLUTION_BY_ID_RES: "GET_SOLUTION_BY_ID_RES",
  GET_SOLUTION_BY_ID_ERR: "GET_SOLUTION_BY_ID_ERR",
  // ORG types
  GET_ORG_REQUEST: "GET_ORG_REQUEST",
  GET_ORG_RECEIVE: "GET_ORG_RECEIVE",
  GET_ORG_ERROR: "GET_ORG_ERROR"
};

export const ACTIONS = {
  getSolutions: function({ accessToken }) {
    return {
      [CALL_API]: {
        method: "GET",
        url: SOLUTIONS_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          TYPES.GET_SOLUTIONS_REQ,
          TYPES.GET_SOLUTIONS_RES,
          TYPES.GET_SOLUTIONS_ERR
        ]
      }
    };
  },
  getSolutionById: function({ accessToken, id }) {
    return {
      [CALL_API]: {
        method: "GET",
        url: `${SOLUTION_FETCH_URL}/${id}?expand=true&dmsInfo=true`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          TYPES.GET_SOLUTION_BY_ID_REQ,
          TYPES.GET_SOLUTION_BY_ID_RES,
          TYPES.GET_SOLUTION_BY_ID_ERR
        ]
      }
    };
  },
  getProviders: function({ accessToken, apis }) {
    return {
      [CALL_API]: {
        method: "POST",
        url: `${PROVIDERS_URL}/list`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        data: {
          apis
        },
        types: [
          TYPES.GET_PROVIDERS_REQ,
          TYPES.GET_PROVIDERS_RES,
          TYPES.GET_PROVIDERS_ERR
        ]
      }
    };
  },
  bulkSubmit: function({
    accessToken,
    solutionId,
    providers,
    providerDmsAttributes,
    entities,
    autoSubscriptionActivate,
    environment,
    userOrgId,
    appName,
    comment
  }) {
    return {
      [CALL_API]: {
        method: "POST",
        url: `${BULK_SUBSCRIPTIONS_URL}/jobs`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        data: {
          solutionId,
          providers,
          providerDmsAttributes,
          entities,
          autoSubscriptionActivate,
          environment,
          userOrgId,
          appName,
          comment
        },
        types: [TYPES.SUBMIT_REQ, TYPES.SUBMIT_RES, TYPES.SUBMIT_ERR]
      }
    };
  },
  submit: function({ accessToken, solutionId, providers, entities }) {
    return {
      [CALL_API]: {
        method: "POST",
        url: `${SUBSCRIPTIONS_URL}/bulk`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        data: {
          solutionId,
          providers,
          entities
        },
        types: [TYPES.SUBMIT_REQ, TYPES.SUBMIT_RES, TYPES.SUBMIT_ERR]
      }
    };
  },
  submitSolutionDeact: function({
    accessToken,
    solutionId,
    providers,
    entities,
    environment,
    userOrgId,
    appName,
    comment
  }) {
    return {
      [CALL_API]: {
        method: "PUT",
        url: `${BULK_SUBSCRIPTIONS_URL}/jobs/deactivate`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        data: {
          solutionId,
          providers,
          entities,
          environment,
          userOrgId,
          appName,
          comment
        },
        types: [TYPES.SUBMIT_REQ, TYPES.SUBMIT_RES, TYPES.SUBMIT_ERR]
      }
    };
  },
  submitConnectionDeact: function({ accessToken, providerId, date }) {
    return {
      [CALL_API]: {
        method: "DELETE",
        url: `${CONNECTIONS_URL}/bulk?providerId=${providerId}&date=${date}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [TYPES.SUBMIT_REQ, TYPES.SUBMIT_RES, TYPES.SUBMIT_ERR]
      }
    };
  },
  UpdateAsyncSolutionList: function(solutions = []) {
    return {
      type: TYPES.GET_SOLUTIONS_RES,
      response: { payload: solutions }
    };
  },
  getOrg: ({ accessToken, orgId }) => ({
    [CALL_API]: {
      url: `${ORG_SERVICE_BASE_URI}/${orgId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        { type: TYPES.GET_ORG_REQUEST },
        {
          type: TYPES.GET_ORG_RECEIVE,
          orgId
        },
        {
          type: TYPES.GET_ORG_ERROR,
          orgId
        }
      ]
    }
  })
};

function stateUpdater(state, obj, update) {
  return {
    ...state,
    [obj]: {
      ...state[obj],
      ...update
    }
  };
}

export function bulkSubscriptionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get solutions logic
    case TYPES.GET_SOLUTIONS_REQ:
      return stateUpdater(state, "solutions", { loading: true });
    case TYPES.GET_SOLUTIONS_RES:
      return stateUpdater(state, "solutions", {
        loading: false,
        data: action.response.payload,
        map: mapFromArray("id", action.response.payload),
        error: undefined,
        noResults: !action.response.payload?.length
      });
    case TYPES.GET_SOLUTIONS_ERR:
      return stateUpdater(state, "solutions", {
        loading: false,
        error: action.apierror.message
      });
    case TYPES.GET_SOLUTION_BY_ID_REQ:
      return stateUpdater(state, "solutionAsyncIntegrations", {
        loading: true
      });
    case TYPES.GET_SOLUTION_BY_ID_RES:
      return stateUpdater(state, "solutionAsyncIntegrations", {
        loading: false,
        data: action.response.asyncApiIntegrations,
        error: undefined
      });
    case TYPES.GET_SOLUTION_BY_ID_ERR:
      return stateUpdater(state, "solutionAsyncIntegrations", {
        loading: false,
        error: action.apierror.message
      });
    // Get providers logic
    case TYPES.GET_PROVIDERS_REQ:
      return stateUpdater(state, "providers", { loading: true });
    case TYPES.GET_PROVIDERS_RES:
      return stateUpdater(state, "providers", {
        loading: false,
        data: cleanProviders(action.response),
        error: undefined
      });
    case TYPES.GET_PROVIDERS_ERR:
      return stateUpdater(state, "providers", {
        loading: false,
        error: action.apierror.message
      });
    // Submit bulk subscriptions logic
    case TYPES.SUBMIT_REQ:
      return stateUpdater(state, "submit", { loading: true });
    case TYPES.SUBMIT_RES:
      return stateUpdater(state, "submit", {
        loading: false,
        data: action.response,
        error: undefined
      });
    case TYPES.SUBMIT_ERR:
      return stateUpdater(state, "submit", {
        loading: false,
        error: action.apierror.message || action.apierror.payload
      });
    case TYPES.GET_ORG_REQUEST:
      return stateUpdater(state, "asyncApiIntegrationOrg", { loading: true });
    case TYPES.GET_ORG_RECEIVE:
      return stateUpdater(state, "asyncApiIntegrationOrg", {
        loading: false,
        data: action.response,
        error: undefined
      });
    case TYPES.GET_ORG_ERROR:
      return stateUpdater(state, "asyncApiIntegrationOrg", {
        loading: false,
        error: action.apierror.message
      });
    default:
      return state;
  }
}
