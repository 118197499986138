import types from "./types";

const INITIAL_STATE = {
  verifyInProgress: {}
};

const ERROR_RESET = {
  errorMessage: "",
  showErrorModal: false,
  showInProgress: false,
  searchError: false
};

const returnStateWithErrorReset = function(state) {
  return Object.assign({}, state, ERROR_RESET);
};

export default function restoreEntity(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ERROR:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        showErrorModal: true
      });
    case types.DELETED_ENTITY_GET_REQUEST:
      return returnStateWithErrorReset(state);
    case types.DELETED_ENTITY_GET_RECEIVE:
      let newDeletedOrgState;
      if (action.sourceId)
        newDeletedOrgState = Object.assign({}, state, {
          searchData: action.response,
          sourceId: action.sourceId
        });
      else
        newDeletedOrgState = Object.assign({}, state, {
          searchData: action.response
        });
      return newDeletedOrgState;
    case types.DELETED_ENTITY_GET_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to get entities",
        showErrorModal: true,
        searchError: true
      });
    case types.RESTORE_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        showUploadProgress: true,
        progressMessage: "Restoring organization, Please wait..."
      });

    case types.RESTORE_ORGANIZATION_RECEIVE:
      let restoreOrganizationSearchData = state.searchData;
      let restoreOrgSearchDataToBeReloaded = restoreOrganizationSearchData.organizations.filter(
        item => item.id !== action.data.orgId
      );
      let orgSearchData = { organizations: restoreOrgSearchDataToBeReloaded };
      return Object.assign({}, state, {
        searchData: orgSearchData,
        showUploadProgress: false,
        showErrorModal: true,
        errorMessage: "Organization restore process has been initiated !"
      });
    case types.RESTORE_ORGANIZATION_ERROR:
      let restoreOrgErrorMessage = "Failed to restore organization";
      if (action.response.status === 403) {
        restoreOrgErrorMessage = action.response.data.Message;
      }
      return Object.assign({}, state, {
        errorMessage: restoreOrgErrorMessage,
        showErrorModal: true,
        showUploadProgress: false,
        progressMessage: ""
      });
    default:
      return returnStateWithErrorReset(state);
  }
}
