export default {
  USERS_GET_REQUEST: "USERS_GET_REQUEST",
  USERS_GET_RECEIVE: "USERS_GET_RECEIVE",
  USERS_GET_ERROR: "USERS_GET_ERROR",
  USERS_CLEAR: "USERS_CLEAR",
  USERS_CLEAR_ERROR: "USERS_CLEAR_ERROR",
  I18N_LANGUAGE: "I18N_LANGUAGE",
  I18N_LOCALE: "I18N_LOCALE",
  ADMIN_OPERATION_POST_REQUEST: "ADMIN_OPERATION_POST_REQUEST",
  ADMIN_OPERATION_POST_RECEIVE: "ADMIN_OPERATION_POST_RECEIVE",
  ADMIN_OPERATION_POST_ERROR: "ADMIN_OPERATION_POST_ERROR",
  PENDING_USER_NOTE_PUT_REQUEST: "PENDING_USER_NOTE_PUT_REQUEST",
  PENDING_USER_NOTE_PUT_RECEIVE: "PENDING_USER_NOTE_PUT_RECEIVE",
  PENDING_USER_NOTE_PUT_ERROR: "PENDING_USER_NOTE_PUT_ERROR",
  ENTITIES_GET_REQUEST: "ENTITIES_GET_REQUEST",
  ENTITIES_GET_RECEIVE: "ENTITIES_GET_RECEIVE",
  ENTITIES_GET_ERROR: "ENTITIES_GET_ERROR"
};
