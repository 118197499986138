import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import types from "./types";
import getApprovedUsers from "../api/getApprovedUsers";
import getLinkedOrganizations from "../api/getLinkedOrganizations";
import linkUserToOrganization from "../api/linkUserToOrganization";
import removeUserFromOrganization from "../api/removeUserFromOrganization";
import axios from "axios";
import config from "../config/app.conf.json";

export const actions = {
  fetchApprovedUsers: (uri, accessToken) => {
    return {
      [CALL_API]: {
        ...getApprovedUsers(uri),
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          types.APPROVED_USERS_GET_REQUEST,
          types.APPROVED_USERS_GET_RECEIVE,
          types.APPROVED_USERS_GET_ERROR
        ]
      }
    };
  },
  fetchApprovedUsersOnShowMore: (uri, showMore, accessToken) => {
    return {
      [CALL_API]: {
        ...getApprovedUsers(uri),
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          { type: types.APPROVED_USERS_GET_REQUEST_ON_SHOW_MORE, showMore },
          { type: types.APPROVED_USERS_GET_RECEIVE_ON_SHOW_MORE, showMore },
          { type: types.APPROVED_USERS_GET_ERROR_ON_SHOW_MORE, showMore }
        ]
      }
    };
  },
  fetchLinkedEntities: ({ uid, accessToken }) => ({
    [CALL_API]: {
      ...getLinkedOrganizations(uid),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.LINKED_ENTITIES_GET_REQUEST,
        types.LINKED_ENTITIES_GET_RECEIVE,
        types.LINKED_ENTITIES_GET_ERROR
      ]
    }
  }),
  addUserToEntity: ({ entity, uid, accessToken }) => ({
    [CALL_API]: {
      ...linkUserToOrganization(entity.id, uid),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        { type: types.ADD_USER_TO_ENTITY_GET_REQUEST, entity, uid },
        { type: types.ADD_USER_TO_ENTITY_GET_RECEIVE, entity, uid },
        { type: types.ADD_USER_TO_ENTITY_GET_ERROR, entity, uid }
      ]
    }
  }),
  unlinkUserFromEntity: ({ entityId, uid, accessToken }) => ({
    [CALL_API]: {
      ...removeUserFromOrganization(entityId, uid),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        { type: types.REMOVE_USER_FROM_ENTITY_DELETE_REQUEST, entityId },
        { type: types.REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE, entityId },
        { type: types.REMOVE_USER_FROM_ENTITY_DELETE_ERROR, entityId }
      ]
    }
  }),
  suspendUser: ({ userId, accessToken }) => {
    return dispatch => {
      dispatch({
        type: types.SUSPEND_USER_REQUEST
      });
      axios
        .put(`${config.api.admin_uri}/users/${userId}/suspend`, null, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch({
            ...getResult(types.SUSPEND_USER_RECEIVE, { ...res.data })
          });
          dispatch(
            actions.fetchApprovedUsers(config.api.approved_users, accessToken)
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.SUSPEND_USER_ERROR, error.response));
          } else {
            dispatch(getResult(types.SUSPEND_USER_ERROR, {}));
          }
        });
    };
  }
};

const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};
