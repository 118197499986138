import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import types from "./types";
import config from "../config/app.conf";
const ORG_ID = "fortellis";
const DOC_TEMPLATE_ID = "tnc";

export const actions = {
  fetchAllTermsAndConditions: (accessToken) => ({
    [CALL_API]: {
      url: `${config.api.documents_service_url}/${ORG_ID}/${DOC_TEMPLATE_ID}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ALL_TERMS_AND_CONDITIONS_GET_REQUEST,
        types.ALL_TERMS_AND_CONDITIONS_GET_RECEIVE,
        types.ALL_TERMS_AND_CONDITIONS_GET_ERROR
      ]
    }
  })
};
