import { useEffect, useRef, useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  TableContainer,
  TableContent,
  TableHeader,
  TABLE_LAYOUT_OPTIONS,
  usePreventTableScrollingRef,
  Checkbox,
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  THEMES,
} from "cdk-radial";
import classNames from "classnames";
import "./styles.css";

const EllipsisCellRenderer = ({ value }) => (
  <div className="ellipsis_cell_renderer">{value || ""}</div>
);

const tooltipProps1 = {
  id: "info-icon-tooltip",
  styles: { width: "200px", maxWidth: "350px" },
  text: "This API is already contracted",
  delay: 0,
  theme: THEMES.LIGHT,
};
const tooltipProps2 = {
  id: "info-icon-tooltip",
  styles: { width: "200px", maxWidth: "350px" },
  text: "This API is part of a bundle already selected",
  delay: 0,
  theme: THEMES.LIGHT,
};
const tooltipProps3 = {
  id: "info-icon-tooltip",
  styles: { width: "200px", maxWidth: "350px" },
  text: "This API is part of a bundle already contracted",
  delay: 0,
  theme: THEMES.LIGHT,
};

const checkboxRenderer = ({ data, onCheckboxChange }) => {
  if (
    data.isAlreadyContracted ||
    data.isPartOfSelectedBundle ||
    data.isPartOfContractedBundles
  ) {
    const CheckboxWithTooltip = data.isAlreadyContracted
      ? withTooltip(Checkbox, tooltipProps1)
      : data.isPartOfContractedBundles
      ? withTooltip(Checkbox, tooltipProps3)
      : withTooltip(Checkbox, tooltipProps2);

    return (
      <CheckboxWithTooltip
        type="checkbox"
        dataTestId="select_api_for_contract_checkbox"
        checked={
          data.isSelected ||
          data.isAlreadyContracted ||
          data.isPartOfSelectedBundle ||
          data.isPartOfContractedBundles
        }
        isDisabled={
          data.isAlreadyContracted ||
          data.isPartOfSelectedBundle ||
          data.isPartOfContractedBundles
        }
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onCheckboxChange(e, data)}
        horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT}
        verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
      />
    );
  } else
    return (
      <Checkbox
        type="checkbox"
        dataTestId="select_api_for_contract_checkbox"
        checked={
          data.isSelected ||
          data.isAlreadyContracted ||
          data.isPartOfSelectedBundle ||
          data.isPartOfContractedBundles
        }
        isDisabled={data.isAlreadyContracted || data.isPartOfSelectedBundle}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onCheckboxChange(e, data)}
      />
    );
};

const columns = (onCheckboxChange) => [
  {
    field: "checkbox",
    headerName: "",
    maxWidth: 55,
    minWidth: 55,
    resizable: false,
    cellRendererFramework: (params) =>
      checkboxRenderer({ ...params, onCheckboxChange }),
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "api_name",
    headerName: "API Name",
    minWidth: 350,
    sortable: true,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "api_type",
    headerName: "API Type",
    sortable: true,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "org_name",
    headerName: "Publisher",
    minWidth: 300,
  },
  {
    cellRendererFramework: EllipsisCellRenderer,
    field: "visibility",
    headerName: "Visibility",
    minWidth: 200,
  },
];

export default function ContractedApisTab({
  apiList,
  contractedApis,
  setSelectedApis,
  selectedApis,
  selectedApisFromBundles,
  apisFromContractedBundles,
  searchQuery
}) {
  const [data, setData] = useState([]);
  const gridRef = useRef();
  const tableContentRef = usePreventTableScrollingRef();
  const [gridApi, setGridApi] = useState(null);

  const initializeData = useCallback(() => {
    let sortedApiList = apiList.sort((a, b) =>
      a?.api_name?.toLowerCase().localeCompare(b.api_name?.toLowerCase())
    );
    const tableData =
      Array.isArray(sortedApiList) &&
      apiList.map((api) => ({
        api_name: api?.api_name,
        api_id: api?.api_id,
        api_type:
          api?.api_type === "api"
            ? "Rest"
            : api?.api_type === "async-api"
            ? "Async"
            : api?.api_type,
        visibility:
          api?.visibility?.toLowerCase() === "private" ? "Private" : "Public",
        org_name: api?.org_name,
        all_Details: api,
        isAlreadyContracted: contractedApis.some(
          (contractedApi) => contractedApi.contractId === api.api_id
        ),
        isSelected: selectedApis.some((a) => a.api_id === api.api_id),
        isPartOfSelectedBundle: selectedApisFromBundles.some(
          (selectedApiFromBundle) => selectedApiFromBundle.id === api.api_id
        ),
        isPartOfContractedBundles: apisFromContractedBundles.some(
          (apisFromContractedBundle) =>
            apisFromContractedBundle.apiId === api.api_id
        ),
      }));
    setData(tableData);
  }, [apiList, contractedApis,  selectedApisFromBundles]);

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const onCheckboxChange = useCallback(
    (e, row) => {
      const isChecked = e.target.checked;
      setSelectedApis((prevSelected) => {
        const updatedSelected = isChecked
          ? [...prevSelected, row.all_Details]
          : prevSelected.filter((api) => api.api_id !== row.all_Details.api_id);

        setData((prevData) =>
          prevData.map((api) =>
            api.api_id === row.api_id ? { ...api, isSelected: isChecked } : api
          )
        );
        return updatedSelected;
      });
    },
    [setSelectedApis]
  );
  const handleGridReady = useCallback((gridParams) => {
    setGridApi(gridParams.api);
    gridParams.api.sizeColumnsToFit();
    gridParams.api.refreshCells({ force: true });
  }, []);
  useEffect(()=>{
    if (gridApi) {
      gridApi.setQuickFilter(searchQuery);
      gridApi.refreshCells({ force: true })
  }},[searchQuery, gridApi])


  const headerProps = {
    dataTestId: `contracted-apis-table-header`,
    hideBorder: false,
    hideTableHeader: true,
    hideTableTitle: true,
    id: `contracted-apis-table-header`,
    isAlwaysExpanded: false,
    isSearchable: false,
    isPrintable: false,
    isDownloadable: false,
    overflowButtonProps: undefined,
  };

  return (
    <TableContainer
      id="ag-grid-container"
      className={classNames("contracted-apis_tab_grid", "ag-theme-balham")}
    >
      <TableHeader {...headerProps} />

      <TableContent
        data-testid="table-content"
        className="contracted-apis_tab_grid-content"
        ref={tableContentRef}
      >
        <AgGridReact
          columnDefs={columns(onCheckboxChange)}
          domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
          rowData={data}
          rowHeight={50}
          style={{ width: "100%" }}
          ref={gridRef}
          onGridReady={handleGridReady}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          suppressScrollOnNewData={true}
        />
      </TableContent>
    </TableContainer>
  );
}
