import { Component, Fragment } from "react";
import axios from "axios";
// Components
import {
  IconList,
  IconSubdirectoryArrowRight,
  Button,
  RadioGroup,
  Checkbox,
  Input,
  TextArea,
  StatusIndicator,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  CHECKBOX_SIZES,
  STATUS_INDICATOR_VARIANTS,
  MENU_PLACEMENT
} from "cdk-radial";
import {
  Card,
  CardText,
  CardTitle,
  CardHeader,
  CardActions,
  CardSubtitle
} from "@cdk-uip/react-card";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";
import { Icon } from "@cdk-uip/react-icon";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import FileUpload from "./components/CSVFileUpload";
import { Elevation } from "@cdk-uip/react-elevation";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { TextField, TextFieldHelperText } from "@cdk-uip/react-text-field";
import { DatePicker } from "@cdk-uip/react-date-picker";
import { DropdownMenu } from "../components/radial/DropdownMenu";
import AsyncTypeaheadMenu from "../components/radial/AsyncTypeaheadMenu";
import "../index.css";
// Helpers
import WithNavigationToHome from "../components/WithNavigationToHome";
import {
  modes,
  isBodyValid,
  isValidId,
  isValidListOfIds,
  isBulkAllowed
} from "./helpers";
import config from "../config/app.conf.json";
import { APP_CANCELLED_TEXT, APP_CERTIFICATION_TEXT, BULK_SUBSCRIPTION_APP_CANCELLED_TEXT, LOADING_TEXT, SOLUTION_STATUS } from "../Utils/constants";

const DEACT_DATE_FORMAT = "D MMMM YYYY";
const SOLUTION_SEARCH_URL = config.api.solutions_url_v2;
const DEALER_PRICING_TEXT =
  "This app is integrated with a Dealer Pricing plan and the dealer needs to belong to the same dealer group that created the app.";

const ENVIRONMENT_OPTIONS = {
  prod: { label: 'Production', value: 'prod' },
  test: { label: 'Test', value: 'test' }
};
const ENVIRONMENT_OPTIONS_LIST = Object.values(ENVIRONMENT_OPTIONS);

function Tooltip({ hoveredApiData, hoveredApi }) {
  const showApiId =
    hoveredApiData?.isApiHovered &&
    hoveredApiData.apiName === hoveredApi;

  return (
    <div className="tooltip" style={showApiId ? { visibility: "visible" } : {}}>
      {showApiId ? hoveredApiData?.apiId : ""}
    </div>
  );
}

function getCppFlagBool(cppFlag) {
  return cppFlag && cppFlag.toLowerCase() === 'true';
}

class BulkSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkSubMode: modes.BY_SOLUTION,
      solutionMode: modes.CREATE,
      solutionInput: "",
      solution: undefined,
      solutionAsyncApis: undefined,
      asyncIntegrationOrg: {},
      providers: {},
      organizations: "",
      entitiesError: "",
      solutionDeactDate: null,
      providerDeactId: "",
      providerDeactDate: null,
      submitDialog: false,
      resultIn: false,
      runBlur: true,
      options: [],
      autoSubscriptionActivate: false,
      solutionAsyncApisLoading: false,
      asyncIntegrationOrgName: undefined,
      orgDataLoading: false,
      selectedProvider: {},
      solutionApiProducts: [],
      solutionAsyncApi: [],
      ApiIntegrations: {},
      environment: "prod",
      apiHasDealerPlan: false,
      isAppCertified: false,
      loadingSolutionInfo: false,
      apiHoverInfo: {},
      providerDmsConfig: [],
      missingDmsConfigentitiesError: "",
      selectedApi: undefined,
      comment: "",
      isCsvUploaded: false,
      isFileValid: true,
      showTooltip:false
    };
  }

  searchForApps = text => {
    return axios.get(SOLUTION_SEARCH_URL, {
      params: {
        q: text
      },
      headers: {
        Authorization: `Bearer ${this.props.auth.accessToken}`
      }
    })
      .then(res => {
        this.props.UpdateAsyncSolutionList(
          res.data.solutions
        );
        return res.data.solutions.map(sol => ({
          label: sol.displayName,
          value: sol.id,
          mpAppName: sol.mpAppName
        }))
      })
  };

  setActiveApp = appId => {
    const {
      auth: { accessToken },
      store: {
        solutions: { map }
      }
    } = this.props;

    if (map[appId]) {
      this.setState({
        solution: map[appId],
        loadingSolutionInfo: true
      });
      this.props
        .getSolutionById({
          accessToken,
          id: appId
        })
        .then(res => {
          if (res.apierror) {
            throw res.apierror;
          }
          let api = [];
          let async = [];
          const apiProducts = res.response.integrations.filter(
            apiInt => apiInt.apiType === "api"
          );
          apiProducts.forEach(apiProduct => {
            let dmsConfigForAPI = res.response?.dmsConfigs?.filter(
              config => config?.apiId === apiProduct.id
            );
            const obj = {
              id: `api-v2-${apiProduct.id}`,
              api: apiProduct.displayName,
              privacyProtection: apiProduct.apiPrivacyProtection,
              selected: false
            };
            if (dmsConfigForAPI.length) {
              obj.dmsConfig = dmsConfigForAPI?.[0]?.dmsConfig;
            }
            api.push(obj);
          });
          res.response.asyncApiIntegrations.forEach(e => {
            let obj;
            obj = {
              async: e.asyncApi,
              privacyProtection: e.asyncApi.privacyProtection,
              selected: false
            };
            let dmsConfigForAPI = res.response?.dmsConfigs?.filter(
              config => config?.apiId === e.asyncApi.id
            );
            if (dmsConfigForAPI.length) {
              obj.dmsConfig = dmsConfigForAPI?.[0]?.dmsConfig;
            }
            async.push(obj);
          });
          this.setState({
            solutionAsyncApi: async,
            solutionApiProducts: api,
            loadingSolutionInfo: false
          });
          this.checkDealerPricingPlan(res.response);
          this.checkAppCertificationStatus(res.response);
          this.checkMpAppName(res.response);
        })
        .catch(e => console.error("Error in fetching solution details", e));
    } else {
      this.setState({
        solution: null,
        solutionApiProducts: [],
        solutionAsyncApi: []
      });
    }
  }

  checkDealerPricingPlan = solutionInfo => {
    let dealerPlanFlag = false;
    if (solutionInfo) {
      dealerPlanFlag = solutionInfo?.integrations?.some(integration => {
        return integration?.providers?.some(provider => {
          return provider?.pricingPlan?.dealerPriceChecked;
        });
      });
    }
    if (dealerPlanFlag)
      this.setState({
        apiHasDealerPlan: true
      });
    else
      this.setState({
        apiHasDealerPlan: false
      });
  };

  checkAppCertificationStatus = solutionInfo => {
    let appCertificationStatus = false;
    // certification status
    if(solutionInfo) {
      appCertificationStatus = solutionInfo?.certificationStatus && solutionInfo?.certificationStatus === 'certified';
    }
    if(appCertificationStatus) {
      this.setState({
        isAppCertified: false
      });
    } else {
      this.setState({
        isAppCertified: true
      });
    }
  }

  checkMpAppName = solutionInfo => {
    let mpAppName = "";
    if (solutionInfo) {
      mpAppName = solutionInfo.mpAppName ? solutionInfo.mpAppName : "";
    }
    if (mpAppName)
      this.setState({
        mpAppName: mpAppName
      });
  };

  setProviderDmsConfigAttributes = (ids, providerDms) => {
    let providerDmsAttributes = [];
    let missingDmsConfigEnities = new Set();
    for (const dms of providerDms) {
      let departmentsMap = {};
      let dmsConfigAttr = {};
      let departments = [];
      if (!dms.cppFlag && (!dms.departmentName || !dms.departmentId || !dms.dmsType)) {
        missingDmsConfigEnities.add(dms.entityId);
      } else {
        let findDuplicateEntity = providerDmsAttributes.find(
          dmsAttr => dmsAttr.entityId === dms.entityId
        );
        if (findDuplicateEntity) {
          let dmsAccounts = findDuplicateEntity.dmsConfig;
          departmentsMap.entityId = dms.entityId;
          departmentsMap.cppFlag = getCppFlagBool(dms.cppFlag);
          dmsConfigAttr.name = dms.departmentName;
          dmsConfigAttr.id = dms.departmentId;
          dmsConfigAttr.dmsType = dms.dmsType;
          if (dmsConfigAttr.id)
            dmsAccounts.push(dmsConfigAttr);
          findDuplicateEntity.dmsConfig = dmsAccounts;
        } else {
          departmentsMap.entityId = dms.entityId;
          departmentsMap.cppFlag = getCppFlagBool(dms.cppFlag);
          dmsConfigAttr.name = dms.departmentName;
          dmsConfigAttr.id = dms.departmentId;
          dmsConfigAttr.dmsType = dms.dmsType;
          if (dmsConfigAttr.id)
            departments.push(dmsConfigAttr);
          departmentsMap.dmsConfig = departments;
          providerDmsAttributes.push(departmentsMap);
        }
      }
    }
    if ([...missingDmsConfigEnities].length) {
      this.setState({
        missingDmsConfigentitiesError: `Missing required DMS config for organizations - ${[
          ...missingDmsConfigEnities
        ].join(",")}`
      });
    } else {
      this.setState({ missingDmsConfigentitiesError: "" });
    }

    return this.setState({
      providerDmsConfig: providerDmsAttributes,
      organizations: ids
    });
  };

  updateEntitiesList = src => {
    let ids = src;
    if (typeof ids !== "string") {
      const entitySet = new Set();
      for (const entity of ids) {
        if (entity.entityId) {
          entitySet.add(entity.entityId);
        }
      }
      ids = [...entitySet].join(", ");
    }

    if (!isValidListOfIds(ids)) {
      this.setState({
        entitiesError:
          "Organizations must be a comma separated list of valid IDs"
      });
    } else {
      this.setState({ entitiesError: "" });
    }

    // Set Provider DMS config attributes if an API that requires DMS config is selected.
    // Here, 'src' will be an object that contains entityId, departmentName, departmentId, and dmsType.
    if (typeof src !== "string") {
      this.setProviderDmsConfigAttributes(ids, src);
    }

    return this.setState({ organizations: ids });
  };

  handleSubmit = () => {
    const {
      bulkSubMode,
      solutionMode,
      solution,
      organizations: entities,
      solutionDeactDate,
      providerDeactId,
      providerDeactDate,
      solutionApiProducts,
      solutionAsyncApi,
      providerDmsConfig
    } = this.state;

    let restIntegrations = {};
    let asyncIntegrations = {};
    let providers = {};
    const rest = solutionApiProducts.filter(e => e.selected === true);
    rest.forEach(item => {
      restIntegrations[item.api] = item.api;
    });
    const async = solutionAsyncApi.filter(e => e.selected === true);
    async.forEach(item => {
      asyncIntegrations[item.async.id] = item.async.orgId;
    });

    providers = { ...restIntegrations, ...asyncIntegrations };

    if (
      isBodyValid(bulkSubMode, solutionMode, {
        solution,
        entities,
        providers,
        solutionId: solution ? solution.id : null,
        solutionDeactDate,
        providerDeactId,
        providerDeactDate,
        providerDmsConfig
      })
    ) {
      this.setState({ submitDialog: true });
    }
  };

  handleSubmitDialog = () => {
    const {
      submitSolutionDeact,
      submitConnectionDeact,
      bulkSubmit,
      auth: { accessToken }
    } = this.props;
    const {
      bulkSubMode,
      solutionMode,
      solution,
      organizations: entities,
      solutionDeactDate,
      providerDeactId,
      providerDeactDate,
      autoSubscriptionActivate,
      solutionAsyncApi,
      solutionApiProducts,
      environment,
      providerDmsConfig,
      comment
    } = this.state;

    let restIntegrations = {};
    let asyncIntegrations = {};
    let providers = {};
    const rest = solutionApiProducts.filter(e => e.selected === true);
    rest.forEach(item => {
      restIntegrations[item.id] = item.id;
    });

    const async = solutionAsyncApi.filter(e => e.selected === true);
    async.forEach(item => {
      asyncIntegrations[item.async.id] = item.async.orgId;
    });
    providers = { ...restIntegrations, ...asyncIntegrations };

    if (
      isBodyValid(bulkSubMode, solutionMode, {
        solution,
        entities,
        providers,
        solutionId: solution ? solution.id : null,
        solutionDeactDate,
        providerDeactId,
        providerDeactDate,
        providerDmsConfig
      })
    ) {
      if (bulkSubMode === modes.BY_SOLUTION) {
        const newEntities = entities.split(",").map(entity => entity.trim());
        if (solutionMode === modes.CREATE) {
          bulkSubmit({
            accessToken,
            providers: providers,
            providerDmsAttributes: providerDmsConfig,
            solutionId: solution.id,
            entities: newEntities,
            autoSubscriptionActivate,
            environment,
            userOrgId: this.props?.auth?.entityId,
            appName: this.state?.solution?.displayName,
            comment
          });
        } else if (solutionMode === modes.DEACTIVATE) {
          submitSolutionDeact({
            accessToken,
            providers: providers,
            solutionId: solution.id,
            entities: newEntities,
            environment,
            userOrgId: this.props?.auth?.entityId,
            appName: this.state?.solution?.displayName,
            comment
          });
        }
      } else if (bulkSubMode === modes.BY_PROVIDER) {
        submitConnectionDeact({
          accessToken,
          providerId: providerDeactId,
          date: providerDeactDate.format("YYYY-MM-DD")
        });
      }
      this.setState({ submitDialog: false });
    }
  };

  determineOutput = () => {
    const {
      store: { submit }
    } = this.props;
    if (submit.loading) {
      return "Running bulk task...";
    }
    if (submit.error) {
      return (
        <span className="error-text">
          {typeof submit.error === "string"
            ? submit.error
            : JSON.stringify(submit.error, null, 2)}
        </span>
      );
    }
    if (submit.data) {
      return JSON.stringify(submit.data, null, 2);
    }
  };

  handleEnvironmentChange = option => {
    this.setState({
      environment: option.value
    });
  };

  updateSolution = value =>
    this.setState({ solutionInput: value, resultIn: false });

  onFocusSolution = value => {
    this.setState({
      solutionInput: value.trim()
    });
  };

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  handleForceActivateCheckChange = value => {
    this.setState({
      autoSubscriptionActivate: value
    });
  };

  handleApiProductChange = (value, apiName) => {
    const { solutionApiProducts, solutionMode, solutionAsyncApi } = this.state;
    let selectedApi = {};
    let apiProducts = solutionApiProducts.map(e => {
      if (e.api === apiName) {
        e.selected = value;
        if (e.dmsConfig || e.privacyProtection) {
          selectedApi.api = apiName;
          selectedApi.dmsApi = value;
          selectedApi.privacyProtection = value;
        }
      }
      return e;
    });

    // Use case:
    // Activation can only be done to an API that requires DMS config individually. (one API per run)
    // If an Api that requires DMS config is selected, then clear other non DMS required APIs as activation should be done individually.
    if (solutionMode === modes.CREATE && selectedApi && (selectedApi.dmsApi || selectedApi.privacyProtection)) {
      apiProducts.forEach(api => {
        if (api.api !== apiName) {
          api.selected = false;
        }
      });
      solutionAsyncApi.forEach(asyncapi => asyncapi.selected = false)
    }
    this.setState({
      solutionApiProducts: apiProducts,
      solutionAsyncApi,
      selectedApi
    });
  };

  handleAsyncApiChange = (value, apiName) => {
    const { solutionAsyncApi,  solutionApiProducts, solutionMode } = this.state;
    let selectedApi = {};
    let asyncApiProducts = solutionAsyncApi.map((e) => {
      if (e.async.id === apiName) {
        e.selected = value;
        if (e.dmsConfig || e.async.privacyProtection) {
          selectedApi.api = apiName;
          selectedApi.dmsApi = value;
          selectedApi.privacyProtection = value;
        }
      }
      return e;
    });

    if (solutionMode === modes.CREATE && selectedApi && (selectedApi.dmsApi || selectedApi.privacyProtection)) {
      solutionAsyncApi.forEach(api => {
        if (api.async.id !== apiName) {
          api.selected = false;
        }
      });
      solutionApiProducts.forEach(api => api.selected = false)
    }
    this.setState({
      solutionAsyncApi: asyncApiProducts,
      selectedApi,
    });
  };

  handleMouseEnter = (apiId, apiName) => {
    this.setState({
      apiHoverInfo: {
        isApiHovered: true,
        apiId,
        apiName
      }
    });
  };

  handleMouseLeave = (apiId, apiName) => {
    this.setState({
      apiHoverInfo: {
        isApiHovered: false,
        apiId,
        apiName
      }
    });
  };

  render() {
    const {
      store: {
        solutions: {
          noResults: solutionsNoResults
        },
        solutionAsyncIntegrations: {
          loading: solutionFetchLoading,
          error: solutionFetchError
        },
        submit: { loading: submitLoading }
      }
    } = this.props;

    const {
      bulkSubMode,
      solutionMode,
      solution,
      solutionDeactDate,
      providerDeactId,
      providerDeactDate,
      solutionApiProducts,
      solutionAsyncApi,
      solutionInput,
      resultIn,
      loadingSolutionInfo,
      apiHasDealerPlan,
      isAppCertified,
      selectedApi,
      entitiesError,
      missingDmsConfigentitiesError
    } = this.state;

    const csvNeeded = solutionMode === modes.CREATE && (selectedApi?.dmsApi || selectedApi?.privacyProtection);

    return (
      <Fragment>
        <LayoutGrid>
          <LayoutGridCell span={8} spanTablet={12} spanPhone={12}>
            <Elevation z={10}>
              <Card className="cdk-bulksubs__card">
                {submitLoading && (
                  <div className="cdk-bulksubs__loading-cover">
                    Running Bulk Subscription...
                  </div>
                )}
                <CardActions>
                  <Button
                    text="Jobs List"
                    icon={<IconList />}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTON_SIZES.LARGE}
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/bulk-subscriptions/jobs"
                      });
                    }}
                    className="cdk-bulksubs__button"
                  />
                </CardActions>
                <CardHeader>
                  <CardTitle large>
                    <Icon>settings</Icon> Bulk Subscriptions
                  </CardTitle>
                  <CardSubtitle>
                    Create subscriptions for multiple organizations to a single
                    app, deactivate all active subscriptions for an app.
                  </CardSubtitle>
                </CardHeader>
                <CardText>
                  <div className="bulk-subscription-input-field">
                    <AsyncTypeaheadMenu
                      id="solution-typeahead"
                      name="solution-typeahead"
                      width="600px"
                      label="Select an App"
                      placeholder="Type to search"
                      placement={MENU_PLACEMENT.BOTTOM_START}
                      fetchOptions={this.searchForApps}
                      onSelect={option => {
                        this.setState({
                          resultIn: false
                        });
                        this.updateSolution(option.label);
                        this.setActiveApp(option.value);
                      }}
                      onClear={() => {
                        this.setState({
                          apiHasDealerPlan: false,
                          isAppCertified: false,
                          solution: undefined,
                          options: []
                        });
                      }}
                      renderMenuItemContent={option => (
                        <>
                          <strong>{option.label}</strong>{" "}
                          {option.mpAppName ? `(${option.mpAppName})` : null}
                          <br />
                          {option.value}
                        </>
                      )}
                    />
                    <DropdownMenu
                      inputId="bulk-subscription-select-environment"
                      dataTestId="bulk-subscription-select-environment"
                      label="Environment"
                      options={ENVIRONMENT_OPTIONS_LIST}
                      value={ENVIRONMENT_OPTIONS[this.state.environment]}
                      onChange={this.handleEnvironmentChange}
                      width="160px"
                    />
                  </div>
                  {bulkSubMode === modes.BY_SOLUTION ? (
                    <Fragment>
                      {this.state.options.length
                        ? this.state.options && (
                            <div className="s-result" width={600}>
                              {this.state.options.map(sol => (
                                <div
                                  key={sol.id}
                                  className="link-item"
                                  onMouseDown={() =>
                                    this.setState({
                                      solutionInput: sol.displayName,
                                      searchName: sol.displayName,
                                      options: [],
                                      runBlur: false
                                    })
                                  }
                                >
                                  <div className="link-description">
                                    {sol.mpAppName
                                      ? `${sol.displayName} (${sol.mpAppName}) : ${sol.id}`
                                      : `${sol.displayName} : ${sol.id}`}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        : null}
                      {solution && solutionFetchLoading ? (
                        <div className="small-loading-container bulk-subscription-input-helper-text">
                          <CircularProgress />
                          <div>{LOADING_TEXT}</div>
                        </div>
                      ) : null}
                      {!loadingSolutionInfo && solution && (
                        <Fragment>
                          {apiHasDealerPlan && (
                            <div className="bulk-subscription-input-helper-text">
                              <Icon
                                className={"c-icon__subscription-listitem"}
                              >
                                info
                              </Icon>
                              {DEALER_PRICING_TEXT}
                            </div>
                          )}
                          {solution?.status  === SOLUTION_STATUS.CANCELLED ? (
                              <div className="bulk-subscription-app-certification-text">
                                <Icon
                                  className={"c-icon__subscription-certification-info-icon"}
                                >
                                  info
                                </Icon>
                                {APP_CANCELLED_TEXT}
                              </div>
                            ):
                            isAppCertified  && (
                              <div className="bulk-subscription-app-certification-text">
                                <Icon
                                  className={"c-icon__subscription-certification-info-icon"}
                                >
                                  info
                                </Icon>
                                {APP_CERTIFICATION_TEXT}
                              </div>
                            )}
                          <div className="cdk-bulksubs__solution-info-header">
                            <h2>{solution.displayName}</h2>
                            {this.state?.mpAppName && (
                              <div className="solution-info-header">
                                <b>On Marketplace as: </b>
                                {this.state.mpAppName}
                              </div>
                            )}
                            <p>{solution.description}</p>
                          </div>
                          <div className="cdk-bulksubs__solution-info">
                            <RadioGroup
                              name="solution-mode"
                              value={solutionMode}
                              options={[
                                {
                                  id: "solution-mode-create",
                                  label: "Create/Activate",
                                  value: modes.CREATE
                                },
                                {
                                  id: "solution-mode-deactivate",
                                  label: "Deactivate",
                                  value: modes.DEACTIVATE
                                },
                              ]}
                              onChange={e =>
                                this.setState({
                                  solutionMode: e.target.value
                                })
                              }
                            />
                            <Fragment>
                              <p className="cdk-bulksubs-card__text">
                                Keep in mind that for both production and test
                                bulk operations, async APIs will be activated or
                                deactivated regardless of the environment.
                              </p>
                              <p className="cdk-bulksubs-card__text">
                                Select API Integrations to your app
                              </p>
                              <div className="cdk-bulk-subs__list">
                                {solution &&
                                solutionApiProducts &&
                                solutionApiProducts.length > 0
                                  ? solutionApiProducts.map((el, i) => (
                                      <div key={el.id} className="cdk-bulksubs-card__api-line">
                                        <Checkbox
                                          name={`select-api__${el.id}`}
                                          size={CHECKBOX_SIZES.LARGE}
                                          label={<>
                                            <span>{el.api}</span>
                                            {el.dmsConfig && (
                                              <StatusIndicator
                                                label="Requires DMS"
                                                ariaLabel="Requires DMS"
                                                variant={STATUS_INDICATOR_VARIANTS.WARNING}
                                                className="cdk-bulksubs-card__api-line-indicator"
                                              />
                                            )}
                                            {el.privacyProtection && (
                                              <StatusIndicator
                                                label="Privacy Protection"
                                                ariaLabel="Privacy Protection"
                                                variant={STATUS_INDICATOR_VARIANTS.INFORMATION}
                                                className="cdk-bulksubs-card__api-line-indicator"
                                              />
                                            )}
                                          </>}
                                          checked={el.selected}
                                          onChange={() => {
                                            let value = !el.selected;
                                            this.handleApiProductChange(
                                              value,
                                              el.api
                                            );
                                          }}
                                          onMouseEnter={() =>
                                            this.handleMouseEnter(el.id, el.api)
                                          }
                                          onMouseLeave={() =>
                                            this.handleMouseLeave(el.id, el.api)
                                          }
                                          isDisabled={
                                            solutionMode === modes.CREATE
                                              ? selectedApi &&
                                                el.api !== selectedApi.api &&
                                                (selectedApi.dmsApi || selectedApi.privacyProtection)
                                              : null
                                          }
                                        />
                                        <Tooltip
                                          hoveredApiData={
                                            this.state.apiHoverInfo
                                          }
                                          hoveredApi={el.api}
                                        />
                                      </div>
                                    ))
                                  : null}

                                {solutionAsyncApi &&
                                  solutionAsyncApi.length > 0 &&
                                  solutionAsyncApi.map((el) => {
                                    return (
                                      <div key={el.async.id} className="cdk-bulksubs-card__api-line">
                                        <Checkbox
                                          name={`select-async-api__${el.async.id}`}
                                          size={CHECKBOX_SIZES.LARGE}
                                          label={<>
                                            <span>{el.async.name}</span>
                                            <StatusIndicator
                                              label="Async"
                                              ariaLabel="Async API"
                                              variant={STATUS_INDICATOR_VARIANTS.DEFAULT}
                                              className="cdk-bulksubs-card__api-line-indicator"
                                            />
                                            {/* TODO change to the correct property once we know what that is */}
                                            {el.dmsConfig && (
                                              <StatusIndicator
                                                label="Requires DMS"
                                                ariaLabel="Requires DMS"
                                                variant={STATUS_INDICATOR_VARIANTS.WARNING}
                                                className="cdk-bulksubs-card__api-line-indicator"
                                              />
                                            )}
                                            {el.async.privacyProtection && (
                                              <StatusIndicator
                                                label="Privacy Protection"
                                                ariaLabel="Privacy Protection"
                                                variant={STATUS_INDICATOR_VARIANTS.INFORMATION}
                                                className="cdk-bulksubs-card__api-line-indicator"
                                              />
                                            )}
                                          </>}
                                          checked={el.selected}
                                          onChange={() => {
                                            let value = !el.selected;
                                            this.handleAsyncApiChange(
                                              value,
                                              el.async.id
                                            );
                                          }}
                                          onMouseEnter={() =>
                                            this.handleMouseEnter(
                                              el.async.id,
                                              el.async.name
                                            )
                                          }
                                          onMouseLeave={() =>
                                            this.handleMouseLeave(
                                              el.async.id,
                                              el.async.name
                                            )
                                          }
                                          isDisabled={
                                            solutionMode === modes.CREATE
                                              ? selectedApi &&
                                                el.async.id !== selectedApi.api &&
                                                (selectedApi.dmsApi || selectedApi.privacyProtection)
                                              : null
                                          }
                                        />
                                        <Tooltip
                                          hoveredApiData={
                                            this.state.apiHoverInfo
                                          }
                                          hoveredApi={el.async.name}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                              {solutionMode === modes.CREATE ? (
                                <>
                                  <div className="cdk-bulksubs-card__text">
                                    <Checkbox
                                      name="force-activate-providers"
                                      label={'Force activate API Providers that have "Manual" subscription activation model.'}
                                      size={CHECKBOX_SIZES.LARGE}
                                      checked={
                                        this.state.autoSubscriptionActivate
                                      }
                                      onChange={() => {
                                        let value = !this.state
                                          .autoSubscriptionActivate;
                                        this.handleForceActivateCheckChange(
                                          value
                                        );
                                      }}
                                    />
                                  </div>
                                  <p className="cdk-bulksubs-card__text">
                                    Add subscribing organizations
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="cdk-bulksubs-card__text">
                                    Add unsubscribing organizations
                                  </p>
                                </>
                              )}
                              <p className="cdk-bulksubs-card__text-sub">
                                Add organizations with a CSV file or by adding
                                comma separated values to the organizations
                                field. CSV file must contain valid organization
                                IDs in the first column to be read correctly.
                              </p>
                              <Input
                                id="subscribed-orgs"
                                name="subscribed-orgs"
                                label="Organizations"
                                value={this.state.organizations}
                                onChange={(e) =>
                                  this.updateEntitiesList(e.target.value)
                                }
                                helperText="List organization ID's separated by commas"
                                enableCustomValidation
                                hasError={entitiesError}
                                errorMessage={entitiesError}
                                isDisabled={csvNeeded || this.state.isCsvUploaded}
                                className="cdk-bulksubs__input"
                                maxLength=''
                              />
                              <div className="cdk-bulksubs__input">
                                <FileUpload
                                  onChange={e => {
                                      this.setState({ isCsvUploaded: true });
                                      this.updateEntitiesList(e);
                                    }
                                  }
                                  setIsFileValid={isFileValid => this.setState({ isFileValid })}
                                  solutionApiProducts={solutionApiProducts}
                                  solutionAsyncApi={solutionAsyncApi}
                                />
                              </div>
                              {csvNeeded && (
                                <>
                                  {entitiesError && (
                                    <p className="file-upload__error-message">
                                      {entitiesError}
                                    </p>
                                  )}
                                  {missingDmsConfigentitiesError && (
                                    <p className="file-upload__error-message">
                                      {missingDmsConfigentitiesError}
                                    </p>
                                  )}
                                </>
                              )}
                              <TextArea
                                id="bulk-subs-comment"
                                className="cdk-bulksubs__input"
                                name="comment"
                                label="Comment"
                                helperText="Enter any remarks for the audit log"
                                value={this.state.comment}
                                maxLength={1000}
                                hasCharacterCount
                                onChange={e => this.onInputChange(e.target.value, "comment")}
                              />
                            </Fragment>
                          </div>
                        </Fragment>
                      )}
                      {!solutionFetchLoading && solutionFetchError && (
                        <div>
                          Error getting app details: {solutionFetchError}
                        </div>
                      )}
                      {solutionInput && resultIn && solutionsNoResults && (
                        <div>No APP Found</div>
                      )}
                    </Fragment>
                  ) : null}
                  {bulkSubMode === modes.BY_PROVIDER ? (
                    <Fragment>
                      <div>
                        <TextField
                          type="string"
                          inputMode="text"
                          autoCorrect="off"
                          spellCheck={false}
                          className="cdk-bulksubs__input"
                          label="Provider ID"
                          helperText={
                            <TextFieldHelperText persistent>
                              Deactivate all connections for this provider.
                            </TextFieldHelperText>
                          }
                          invalid={!isValidId(providerDeactId)}
                          fullWidth
                          value={providerDeactId}
                          onChange={(e) =>
                            this.setState({ providerDeactId: e.target.value })
                          }
                        />
                      </div>
                      <div>
                        <DatePicker
                          label="Deactivation Date"
                          date={providerDeactDate}
                          onChange={(date) =>
                            this.setState({ providerDeactDate: date })
                          }
                        />
                      </div>
                    </Fragment>
                  ) : null}
                </CardText>
                <CardActions>
                  <div
                    className="bulk_subs_btn"
                    onMouseEnter={() =>
                      solution?.status === SOLUTION_STATUS.CANCELLED &&
                      this.setState({ showTooltip: true })
                    }
                    onMouseLeave={() => this.setState({ showTooltip: false })}
                  >
                    <Button
                      text="Send Bulk Request"
                      icon={<IconSubdirectoryArrowRight />}
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTON_SIZES.LARGE}
                      onClick={this.handleSubmit}
                      className="cdk-bulksubs__button"
                      isDisabled={
                        !isBulkAllowed(bulkSubMode, solutionMode, {
                          solution,
                          solutionApiProducts: this.state.solutionApiProducts,
                          solutionAsyncApi: this.state.solutionAsyncApi,
                          entities: this.state.organizations,
                          environemnt: this.state.environment,
                          entitiesError: this.state.entitiesError,
                          missingDmsConfigentitiesError:
                            this.state.missingDmsConfigentitiesError,
                          providerDmsAttributes: this.state.providerDmsConfig,
                          solutionId: solution ? solution.id : "",
                          solutionDeactDate,
                          providerDeactId,
                          providerDeactDate,
                          isFileValid: this.state.isFileValid,
                        })
                      }
                    />
                    {this.state.showTooltip && (
                    <div
                    className="bulk_sub_tooltip"
                    >
                     {BULK_SUBSCRIPTION_APP_CANCELLED_TEXT}
                    </div>

                  )}
                  </div>
                </CardActions>
              </Card>
            </Elevation>
          </LayoutGridCell>
          <LayoutGridCell span={4} spanTablet={12} spanPhone={12}>
            <Elevation z={10}>
              <Card>
                <CardHeader>
                  <CardTitle>Output</CardTitle>
                  <CardSubtitle>
                    Displays output of bulk subscription process.
                  </CardSubtitle>
                </CardHeader>
                <CardText>
                  <p className="cdk-bulksubs__output">
                    {this.determineOutput()}
                  </p>
                </CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>
        <Dialog open={this.state.submitDialog}>
          <DialogHeader>Submit Bulk Request?</DialogHeader>
          <DialogBody>
            {bulkSubMode === modes.BY_SOLUTION &&
            solutionMode === modes.CREATE ? (
              <Fragment>
                This will attempt to create subscriptions for{" "}
                {this.state.organizations.split(",").length} organizations for
                the {solution?.displayName} solution.
              </Fragment>
            ) : null}
            {bulkSubMode === modes.BY_SOLUTION &&
            solutionMode === modes.DEACTIVATE ? (
              <Fragment>
                This will attempt to deactivate subscriptions for{" "}
                {this.state.organizations.split(",").length} organizations for
                the {solution?.displayName} solution.
              </Fragment>
            ) : null}
            {bulkSubMode === modes.BY_PROVIDER ? (
              <Fragment>
                This will attempt to deactivate all connections to the provider
                with ID {providerDeactId}, effective{" "}
                {providerDeactDate
                  ? providerDeactDate.format(DEACT_DATE_FORMAT)
                  : null}
                .
              </Fragment>
            ) : null}
          </DialogBody>
          <DialogFooter>
            <DialogFooterButton
              onClick={() => this.setState({ submitDialog: false })}
            >
              Cancel
            </DialogFooterButton>
            <DialogFooterButton onClick={this.handleSubmitDialog}>
              Submit
            </DialogFooterButton>
          </DialogFooter>
        </Dialog>
      </Fragment>
    );
  }
}

BulkSubscriptions = WithNavigationToHome(BulkSubscriptions);

function BulkSubscriptionsContainer(props) {
  return props.auth.isAuthenticated ? (
    <BulkSubscriptions {...props} />
  ) : (
    <div></div>
  );
}

export default withAuth(BulkSubscriptionsContainer);
