import types from "./types";
import { combineReducers } from "redux";

const userRegistrations = (
  state = { isFetching: false, error: "", list: "" },
  action
) => {
  switch (action.type) {
    case types.REGISTRATIONS_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.REGISTRATIONS_GET_RECEIVE:
      return {
        isFetching: false,
        list: action.response.payload,
        error: ""
      };
    case types.REGISTRATIONS_GET_ERROR:
      return { isFetching: false, error: action.apierror.message };
    default:
      return state;
  }
};

const appRegistrations = (
  state = { isFetching: false, error: "", apps: "" },
  action
) => {
  switch (action.type) {
    case types.APP_REGISTRATIONS_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.APP_REGISTRATIONS_GET_RECEIVE:
      return {
        isFetching: false,
        apps: action.response.payload,
        error: ""
      };
    case types.APP_REGISTRATIONS_GET_ERROR:
      return { isFetching: false, error: action.apierror.message };
    default:
      return state;
  }
};

const allTermsAndConditionsVersions = (
  state = { isFetching: false, error: "", list: "", selectedVersion: null },
  action
) => {
  switch (action.type) {
    case types.TERMS_AND_CONDITIONS_VERSIONS_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.TERMS_AND_CONDITIONS_VERSIONS_GET_RECEIVE:
      let tnc_versionList =
        action &&
        action.response &&
        action.response.documents &&
        action.response.documents.map(termsAndCondition => {
          return termsAndCondition.version;
        });
      tnc_versionList = tnc_versionList.sort(function(a, b) {
        return b - a;
      });
      let selectedVersion = tnc_versionList[0];
      return {
        isFetching: false,
        list: tnc_versionList,
        selectedVersion,
        error: ""
      };
    case types.TERMS_AND_CONDITIONS_VERSIONS_GET_ERROR:
      return {
        isFetching: false,
        error: "An error occurred",
        selectedVersion: null
      };
    case types.SET_SELECTED_VERSION:
      return Object.assign({}, state, {
        selectedVersion: action.selectedVersion
      });
    default:
      return state;
  }
};

const userCount = (
  state = { isFetching: false, error: "", count: null },
  action
) => {
  switch (action.type) {
    case types.USERS_COUNT_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.USERS_COUNT_GET_RECEIVE:
      return {
        isFetching: false,
        count: action.response.status.Count,
        error: ""
      };
    case types.USERS_COUNT_GET_ERROR:
      return { isFetching: false, error: "An error occurred", count: null };
    default:
      return state;
  }
};

const totalUserCount = (
  state = { isFetching: false, error: "", totalUserCount: null },
  action
) => {
  switch (action.type) {
    case types.TOTAL_USERS_COUNT_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.TOTAL_USERS_COUNT_GET_RECEIVE:
      return {
        isFetching: false,
        totalUserCount: action.response.status.Count,
        error: ""
      };
    case types.TOTAL_USERS_COUNT_GET_ERROR:
      return { isFetching: false, error: "An error occurred", count: null };
    default:
      return state;
  }
};

export default combineReducers({
  userRegistrations,
  appRegistrations,
  allTermsAndConditionsVersions,
  userCount,
  totalUserCount
});
