import types from "./types";
import { combineReducers } from "redux";

// APIs
import getPendingUsers from "../api/getPendingUsers";
import approveUser from "../api/approveUser";
import updatePendingUserNote from "../api/updatePendingUserNote";
import getOrganizations from "../api/getOrganizations";

import { CALL_API } from "@cdkglobal/react-core-reduxapi";

// The list of users fetched via an API call
// const list = (state = [], action) => {
//   switch (action.type) {
//     case types.USERS_GET_RECEIVE:
//       return action.response || [];
//     case types.USERS_CLEAR:
//       return [];
//     default:
//       return state;
//   }
// };

// The API call status
// REDUCER:
const pendingUsers = (state = { isFetching: false, error: "" }, action) => {
  switch (action.type) {
    case types.USERS_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.USERS_GET_RECEIVE:
      return { isFetching: false, list: action.response, error: "" };
    case types.USERS_GET_ERROR:
      return { isFetching: false, error: action.apierror.message };
    case types.USERS_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

// REDUCER:
const adminUpdateStatus = (state = { isFetching: false }, action) => {
  switch (action.type) {
    case types.ADMIN_OPERATION_POST_REQUEST:
      return { isFetching: true, response: {} };
    case types.ADMIN_OPERATION_POST_RECEIVE:
      return { isFetching: false, response: action.response };
    case types.ADMIN_OPERATION_POST_ERROR:
      return { isFetching: false, error: action.apierror };
    default:
      return state;
  }
};

// REDUCER:
const updateStoredNoteReducer = (
  state = { isFetching: false, fetchingId: "" },
  action
) => {
  switch (action.type) {
    case types.PENDING_USER_NOTE_PUT_REQUEST:
      return { isFetching: true, response: {}, fetchingId: action.id };
    case types.PENDING_USER_NOTE_PUT_RECEIVE:
      return {
        isFetching: false,
        response: action.response
      };
    case types.PENDING_USER_NOTE_PUT_ERROR:
      console.log(
        "ERROR LOGGING: updateStoredNoteReducer types.PENDING_USER_NOTE_PUT_ERROR"
      );
      return {
        isFetching: false,
        error: action.apierror
      };
    default:
      return state;
  }
};

// REDUCER:
const entities = (state = { isFetching: false }, action) => {
  switch (action.type) {
    case types.ENTITIES_GET_REQUEST:
      return { isFetching: true };
    case types.ENTITIES_GET_RECEIVE:
      return { isFetching: false, response: action.response };
    case types.ENTITIES_GET_ERROR:
      console.log("ERROR LOGGING: entities types.ENTITIES_GET_ERROR");
      return {
        isFetching: false,
        error: action.apierror
      };
    default:
      return state;
  }
};

// REDUCERS COMBINED:
export default combineReducers({
  pendingUsers,
  adminUpdateStatus,
  updateStoredNoteReducer,
  entities
});

// ACTIONS:
export const actions = {
  // ACTION:
  fetchusers: ({ accessToken }) => ({
    [CALL_API]: {
      // API:
      ...getPendingUsers(),
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.USERS_GET_REQUEST,
        types.USERS_GET_RECEIVE,
        types.USERS_GET_ERROR
      ]
    }
  }),
  // ACTION:
  updateRegistrationStatus: ({ uid, entity, status, accessToken }) => ({
    [CALL_API]: {
      // API:
      ...approveUser(uid, entity, status),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ADMIN_OPERATION_POST_REQUEST,
        types.ADMIN_OPERATION_POST_RECEIVE,
        types.ADMIN_OPERATION_POST_ERROR
      ]
    }
  }),
  // ACTION:
  updateStoredNoteAction: ({ uid, storedNote, accessToken }) => ({
    [CALL_API]: {
      // API:
      ...updatePendingUserNote(uid, storedNote),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        { type: types.PENDING_USER_NOTE_PUT_REQUEST, id: uid },
        { type: types.PENDING_USER_NOTE_PUT_RECEIVE, id: uid },
        { type: types.PENDING_USER_NOTE_PUT_ERROR, id: uid }
      ]
    }
  }),
  // ACTION:
  fetchEntities: ({ accessToken }) => ({
    [CALL_API]: {
      // API:
      ...getOrganizations(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        { type: types.ENTITIES_GET_REQUEST },
        { type: types.ENTITIES_GET_RECEIVE },
        { type: types.ENTITIES_GET_ERROR }
      ]
    }
  }),
  clearError: () => ({ type: types.STATES_CLEAR_ERROR }),
  clear: () => ({ type: types.STATES_CLEAR })
};
