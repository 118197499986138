export default {
  REGISTRATIONS_GET_REQUEST: "REGISTRATIONS_GET_REQUEST",
  REGISTRATIONS_GET_RECEIVE: "REGISTRATIONS_GET_RECEIVE",
  REGISTRATIONS_GET_ERROR: "REGISTRATIONS_GET_ERROR",
  APP_REGISTRATIONS_GET_REQUEST: "APP_REGISTRATIONS_GET_REQUEST",
  APP_REGISTRATIONS_GET_RECEIVE: "APP_REGISTRATIONS_GET_RECEIVE",
  APP_REGISTRATIONS_GET_ERROR: "APP_REGISTRATIONS_GET_ERROR",
  TERMS_AND_CONDITIONS_VERSIONS_GET_REQUEST:
    "TERMS_AND_CONDITIONS_VERSIONS_GET_REQUEST",
  TERMS_AND_CONDITIONS_VERSIONS_GET_RECEIVE:
    "TERMS_AND_CONDITIONS_VERSIONS_GET_RECEIVE",
  TERMS_AND_CONDITIONS_VERSIONS_GET_ERROR:
    "TERMS_AND_CONDITIONS_VERSION_GET_ERROR",
  USERS_COUNT_GET_REQUEST: "USERS_COUNT_GET_REQUEST",
  USERS_COUNT_GET_RECEIVE: "USERS_COUNT_GET_RECEIVE",
  USERS_COUNT_GET_ERROR: "USERS_COUNT_GET_ERROR",
  SET_SELECTED_VERSION: "SET_SELECTED_VERSION",
  TOTAL_USERS_COUNT_GET_REQUEST: "TOTAL_USERS_COUNT_GET_REQUEST",
  TOTAL_USERS_COUNT_GET_RECEIVE: "TOTAL_USERS_COUNT_GET_RECEIVE",
  TOTAL_USERS_COUNT_GET_ERROR: "TOTAL_USERS_COUNT_GET_ERROR"
};
