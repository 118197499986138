import { useState } from "react";

import {
  IndeterminateProgressCircle,
  TextArea,
  Button,
  BUTTON_VARIANTS,
  LOADER_SIZE
} from "cdk-radial";
import Flex from "../components/Flex";

export default function NotesEditor({
  initialNotes,
  saveAndClose,
  close,
  notesSaving,
  notesError
}) {
  const [notes, setNotes] = useState(initialNotes);

  return (
    <>
      <TextArea
        id="api-info-notes-edit"
        name="api-info-notes"
        label="Notes"
        value={notes}
        onChange={e => setNotes(e.target.value)}
        rows={14}
        maxLength={1000000}
      />
      <Flex
        className="api-info-notes-actions"
        align="center"
        justify="flex-end"
        gap="0.5rem"
      >
        {notesError && (
          <div className="margin-sides-5">Failed to update notes.</div>
        )}
        {notesSaving && (
          <div className="margin-sides-5">
            <IndeterminateProgressCircle size={LOADER_SIZE.SMALL} />
          </div>
        )}
        <Button
          text="Cancel"
          variant={BUTTON_VARIANTS.SECONDARY}
          isDisabled={notesSaving}
          onClick={() => close(initialNotes)}
        />
        <Button
          text="Save"
          variant={BUTTON_VARIANTS.PRIMARY}
          isDisabled={notesSaving}
          onClick={() => saveAndClose(notes)}
        />
      </Flex>
    </>
  );
}
