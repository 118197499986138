// External Modules
import React, { Component, Fragment } from "react";
// Internal Modules
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Card, CardText } from "@cdk-uip/react-card";
import { DataTableCell, DataTableRow } from "@cdk-uip/react-data-table";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { IconButton } from "@cdk-uip/react-icon-button";
import { Chip, ChipSet } from "@cdk-uip/react-chips";
import { Elevation } from "@cdk-uip/react-elevation";

// Project Modules
import WithNavigationToHome from "../components/WithNavigationToHome";
import StatusIconButton from "./StatusIconButton";
import { ToastNotification } from "./ToastNotification";
import Header from "../HeaderComponents/Header";
import { ApiPaginatedTable, PAGE_EVENTS } from "./ApiPaginatedTable";
import { TypeaheadSearch } from "../components/TypeaheadSearch";
import { API_ADMIN, CUSTOM_FORMATS } from "./constants";
import { STATUS_TEXT, VISIBILITY_TEXT, paramsFromState } from "./utils";
import ApiEditDialog from "./ApiEditDialogRadial";

class ApiAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSort: {
        ascending: true,
        category: "",
        sortType: ""
      },
      // state values for the Date Range Picker
      startDate: null,
      endDate: null,
      page: 1,
      pageSize: 10,
      orgFilter: {
        id: "",
        name: ""
      },
      statusFilters: {
        // true/false is based on selected in filter list
        [STATUS_TEXT.ACTIVE]: false,
        [STATUS_TEXT.RETIRED]: false,
        [STATUS_TEXT.IN_REVIEW]: false
      },
      visibilityFilters: {
        [VISIBILITY_TEXT.PUBLIC]: false,
        [VISIBILITY_TEXT.PRIVATE]: false
      },
      // searchOptions: [],
      search: {
        options: [],
        input: "",
        results: []
      },
      // API being edited by the dialog
      editedApi: null,
      showToastNotif: false,
      failureReason: undefined,
      // pass it a string
      toastMessage: "",
      toastVariant: "",
      columns: API_ADMIN
    };
  }

  /// API calls ///

  fetchApis = async params => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchApis({ accessToken, params });
    this.props.fetchAsyncApis({ accessToken, params });
  };

  fetchApiPage = async path => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchApiPage({ accessToken, path });
  };

  fetchAsyncApiPage = async path => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchAsyncApiPage({ accessToken, path });
  };

  updateApiMetadata = async (
    apiId,
    featured,
    weight,
    catalogs,
    systemSettings,
    isAsyncApi,
    isPublicListingApproved
  ) => {
    const {
      auth: { accessToken }
    } = this.props;
    return this.props
      .updateApi({
        accessToken,
        apiId,
        featured,
        weight,
        catalogs,
        systemSettings,
        isAsyncApi,
        isPublicListingApproved
      })
      .catch(err => console.error(err));
  };

  // unused for now
  searchOrgs = async name => {
    const {
      auth: { accessToken }
    } = this.props;
    await this.props.searchEntity({ accessToken, name });
  };

  /// Component lifecycle ///

  componentDidMount() {
    this.fetchApis({
      pageSize: this.state.pageSize
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.apiAdmin.apis !== this.props.apiAdmin.apis) {
      this.updateCustomFormats();
    }
    if (
      prevProps.apiAdmin.entitySearchRequest &&
      !this.props.apiAdmin.entitySearchRequest
    ) {
      // org search came back, populate options (unused for now)
      const { entitySearchResponse } = this.props.apiAdmin;
      if (entitySearchResponse) {
        const { organizations } = entitySearchResponse;
        this.setState(state => ({
          search: {
            ...state.search,
            options: organizations ? organizations.map(org => org.name) : []
          }
        }));
      }
    }
    if (prevState.search.input !== this.state.search.input) {
      // fetch new typeahead results (unused for now)
      // this.searchOrgs(this.state.search.input);
    }
    if (
      prevState.statusFilters !== this.state.statusFilters ||
      prevState.visibilityFilters !== this.state.visibilityFilters ||
      prevState.search.input !== this.state.search.input ||
      prevState.headerSort.category !== this.state.headerSort.category ||
      prevState.headerSort.ascending !== this.state.headerSort.ascending
    ) {
      this.setState({
        page: 1
      });
      this.fetchApis(paramsFromState(this.state));
    }
  }

  /// Response modal ///

  showToastNotif = (message, variant) => {
    this.setState({
      showToastNotif: true,
      toastMessage: message,
      toastVariant: variant
    });
  };

  closeToastNotif = () => {
    this.setState({ showToastNotif: false });
  };

  /// API info page ///

  openApiInfoPage = async api => {
    if (!api.isAsyncApi) {
      await this.props.addApiForInfoPage(api);
      this.props.history.push(`/api-admin/${api.id}`);
    }
  };

  /// Search info edit dialog ///

  openEditDialog = editedApi => {
    this.setState({
      apiEditDialogOpen: true,
      editedApi
    });
  };

  closeEditDialog = () => {
    this.setState({
      apiEditDialogOpen: false,
      editedApi: null
    });
  };

  closeEditDialogFromSave = () => {
    const { links } = this.props.apiAdmin.apisPage;
    const { links: asyncLinks } = this.props.apiAdmin.asyncApisPage;
    this.setState({
      apiEditDialogOpen: false,
      editedApi: null
    });
    this.props.clearAfterApiUpdate();
    // refresh our current page
    this.fetchApiPage(links.self.href);
    this.fetchAsyncApiPage(asyncLinks.self.href);
  };

  /// Search and filtering ///

  handleSearch = (input, results) => {
    this.setState(prevState => {
      return {
        ...prevState,
        search: {
          ...prevState.search,
          input,
          results
        }
      };
    });
  };

  toggleStatusFilter = status => {
    const statusFilters = {...this.state.statusFilters};
    Object.keys(statusFilters).forEach(filter =>
      statusFilters[filter] = filter === status
    );
    this.setState(() => ({
      statusFilters
    }));
  };

  toggleVisibilityFilter = access => {
    this.setState(prevState => ({
      visibilityFilters: {
        ...prevState.visibilityFilters,
        [access]: !prevState.visibilityFilters[access]
      }
    }));
  };

  /// Sorting (backend support pending) ///

  handleHeaderSortClick = (id, state, type) => {
    this.setState({
      headerSort: {
        category: id,
        ascending: state,
        sortType: type
      }
    });
  };

  /// Pagination update ///

  handlePageUpdate = async pageEvent => {
    const { totalPages } = this.props.apiAdmin.apisPage;
    const { totalPages: asyncTotalPages } = this.props.apiAdmin.asyncApisPage;

    let newPage;
    let currentPage = this.state.page || 1;
    switch (pageEvent) {
      case PAGE_EVENTS.FIRST:
        newPage = 1;
        break;
      case PAGE_EVENTS.PREVIOUS:
        newPage = currentPage - 1;
        break;
      case PAGE_EVENTS.NEXT:
        newPage = currentPage + 1;
        break;
      case PAGE_EVENTS.LAST:
        newPage = Math.max(totalPages, asyncTotalPages);
        break;
      default:
        newPage = currentPage;
    }
    await this.fetchApis(paramsFromState({ ...this.state, page: newPage }));

    this.setState({ page: newPage });
  };

  /// Table formatting ///

  formatHeader = () => {
    return (
      <Header
        onClick={this.handleHeaderSortClick}
        categories={this.state.columns}
        ascending={false /*this.state.headerSort.ascending*/}
        previousClicked={null /*this.state.headerSort.category*/}
      />
    );
  };

  /*  Represents the internal formatting of the row for API Admin.
   *  Can be improved so that visibility is another property in column objects
   */
  formatTableRow = (api, applyFormat) => {
    return (
      <React.Fragment key={api.id}>
        <DataTableRow>
          {this.state.columns.map(column => (
            <DataTableCell
              className={
                (column.className ? column.className + " " : "") +
                (column.clickable
                  ? "table-with-pagination__clickable-column"
                  : "table-with-pagination__column ")
              }
              onClick={
                column.clickable ? () => this.openApiInfoPage(api) : null
              }
              key={column.id}
              nonNumeric
              style={column.style}
            >
              {applyFormat(api, column.id, column.format, column.path)}
            </DataTableCell>
          ))}
        </DataTableRow>
      </React.Fragment>
    );
  };

  /*  Custom formats of columns that are reliant on state or have
   *  alternative values if something is missing be updated.
   */
  updateCustomFormats = () => {
    let columns = [...this.state.columns];
    columns.forEach(column => {
      switch (column.id) {
        case CUSTOM_FORMATS.STATUS:
          column.format = api => {
            return <StatusIconButton apiStatus={api.status} apiId={api.id} />;
          };
          break;
        case CUSTOM_FORMATS.EDIT:
          column.format = api => {
            return (
              <IconButton onClick={() => this.openEditDialog(api)}>
                edit
              </IconButton>
            );
          };
          break;
        default:
          break;
      }
    });
    this.setState({
      columns: columns
    });
  };

  render() {
    const {
      apiAdmin: {
        fetchingApis,
        apisPage,
        apis,
        fetchingAsyncApis,
        asyncApisPage,
        asyncApis
      }
    } = this.props;
    const { page, statusFilters, visibilityFilters, search } = this.state;

    let apisWithinPage = page <= apisPage.totalPages ? apis : [];
    let asyncApisWithinPage = page <= asyncApisPage.totalPages ? asyncApis : [];
    let allApis = apisWithinPage.concat(asyncApisWithinPage);

    const totalItems =
      (apisPage.totalItems || 0) + (asyncApisPage.totalItems || 0);
    const totalPages = Math.max(apisPage.totalPages, asyncApisPage.totalPages);

    return (
      <Fragment>
        <LayoutGrid className="c-layout-section__dash-grid">
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className="c-dash-chart__wrapper">
              <Card className="c-dash-chart">
                <CardText>
                  <div className="c-datatable-wrapper">
                    <div className="filter-bar">
                      <div className="filter-chips-container">
                        <div className="filter-chips">
                          <p className="filter-hint">Filter by Status</p>
                          <FilterChips
                            filters={statusFilters}
                            toggleFilter={this.toggleStatusFilter}
                          />
                        </div>
                        <div className="filter-chips">
                          <p className="filter-hint">Filter by Visibility</p>
                          <FilterChips
                            filters={visibilityFilters}
                            toggleFilter={this.toggleVisibilityFilter}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="filter-searchbar">
                          <TypeaheadSearch
                            placeholder={"API name or description"}
                            search={search.input}
                            onUpdate={this.handleSearch}
                            searchOptions={search.options}
                          />
                        </div>
                        {/*
                          <div className="date-bar">
                            <div className="filter-date-range">
                              <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                rangeEndDate={tomorrow}
                                onChange={(startDate, endDate) =>
                                  this.setState({
                                    startDate: startDate,
                                    endDate: endDate
                                  })
                                }
                                format={"MM/DD/YY"}
                              />
                            </div>
                            <Button
                              raised
                              onClick={() =>
                                this.setState({
                                  startDate: null,
                                  endDate: null
                                })
                              }
                            >
                              RESET DATE
                            </Button>
                          </div>
                        */}
                      </div>
                    </div>
                    <ApiPaginatedTable
                      pageNumber={page}
                      pageSize={this.state.pageSize}
                      pageInfo={{
                        totalItems,
                        totalPages
                      }}
                      items={allApis}
                      RowComponent={this.formatTableRow}
                      header={this.formatHeader}
                      loading={fetchingApis || fetchingAsyncApis}
                      onPageUpdate={this.handlePageUpdate}
                      fetchPage={this.fetchApiPage}
                    />
                  </div>
                </CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>
        <ApiEditDialog
          open={this.state.apiEditDialogOpen}
          close={this.closeEditDialog}
          closeAfterSave={this.closeEditDialogFromSave}
          editedApi={this.state.editedApi}
          awaitingResponse={this.props.apiAdmin.awaitingResponse}
          errorMessage={this.props.apiAdmin.errorMessage}
          apiEditResponse={this.props.apiAdmin.apiEditResponse}
          apiEditError={this.props.apiAdmin.apiEditError}
          updateApiMetadata={this.updateApiMetadata}
          showToastNotif={this.showToastNotif}
          clearApiUpdateData={this.props.clearAfterApiUpdate}
        />
        <ToastNotification
          open={this.state.showToastNotif}
          onCancel={this.closeToastNotif}
          message={this.state.toastMessage}
          variant={this.state.toastVariant}
        />
      </Fragment>
    );
  }
}

const FilterChips = ({ filters, toggleFilter }) => (
  <ChipSet>
    {Object.keys(filters).map(filter => (
      <Chip
        selected={filters[filter]}
        onClick={() => toggleFilter(filter)}
        key={filter}
      >
        {filter}
      </Chip>
    ))}
  </ChipSet>
);

ApiAdmin = WithNavigationToHome(ApiAdmin);
class ApiAdminContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <ApiAdmin {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(ApiAdminContainer);
