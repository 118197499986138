import React, { Component } from "react";
import "@cdk-uip/icons";
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from "@cdk-uip/react-data-table";
import {
  Dialog,
  DialogHeader,
  DialogFooterButton
} from "@cdk-uip/react-dialog";
import { Button } from "@cdk-uip/react-button";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";

class AssociatedEntities extends Component {
  state = { openDialog: false };

  onRemoveUserFromEntity = (entityId, uid) => {
    this.setState({ openDialog: true, entityId: entityId, uid: uid });
  };

  unlinkUserFromEntity = (entityId, uid) => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.unlinkUserFromEntity({ entityId, uid, accessToken });
    this.setState({ entityId: entityId, uid: uid });
  };

  generateAddress = address => {
    const {
      street = "",
      postalCode = "",
      city = "",
      region = "",
      countryCode = ""
    } = address;
    return `${street}, ${postalCode} ${city} ${region} ${countryCode}`;
  };

  render() {
    const { show, uid } = this.props;
    const {
      isFetching,
      fetchingError,
      isRemoving
    } = this.props.associatedEntitiesSpecs;
    const { message = "" } = this.props.associatedEntitiesSpecs;

    if (show && fetchingError) {
      return (
        <div>
          <h4>{fetchingError}</h4>
        </div>
      );
    } else if (show && isFetching) {
      return (
        <div className="loading-container">
          <h4>Loading Associated Organisations</h4>
          <CircularProgress />
        </div>
      );
    }
    return (
      show && (
        <DataTable className="table-list">
          <DataTableHeader>
            <DataTableRow>
              <DataTableHeaderCell className="table-cell-20">
                Organisation ID
              </DataTableHeaderCell>
              <DataTableHeaderCell className="table-cell-20">
                Name
              </DataTableHeaderCell>
              <DataTableHeaderCell className="table-cell-20">
                Address
              </DataTableHeaderCell>
              <DataTableHeaderCell className="table-cell-20">
                Phone no and Website
              </DataTableHeaderCell>
              <DataTableHeaderCell className="table-cell-20">
                Action
              </DataTableHeaderCell>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {this.props.associatedEntities.length ? (
              this.props.associatedEntities.map(entity => {
                return (
                  <DataTableRow>
                    <DataTableCell className="table-cell-20">
                      {entity.id}
                    </DataTableCell>
                    <DataTableCell className="table-cell-20">
                      {entity.name}
                    </DataTableCell>
                    <DataTableCell className="table-cell-20">
                      {entity.address
                        ? this.generateAddress(entity.address)
                        : undefined}
                    </DataTableCell>
                    <DataTableCell className="table-cell-20">
                      {typeof entity.phone === "object" ? "n/a" : entity.phone}
                      <br />
                      {entity.website}
                    </DataTableCell>
                    <DataTableCell>
                      {isRemoving && entity.id === this.state.entityId ? (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <Button
                          className="remove-entity-button resend-email-button-text"
                          outlined
                          onClick={() => {
                            this.onRemoveUserFromEntity(entity.id, uid);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </DataTableCell>
                  </DataTableRow>
                );
              })
            ) : (
              <div>No associated organisations found for this user</div>
            )}
          </DataTableBody>
          <Dialog
            open={this.state.openDialog}
            onAccept={() => {
              this.unlinkUserFromEntity(this.state.entityId, this.state.uid);
              this.setState({ openDialog: false });
            }}
            onCancel={() => {
              this.setState({ openDialog: false });
            }}
          >
            <DialogHeader>
              Do you want to remove user from this organisation?
            </DialogHeader>
            <DialogFooterButton accept outlined id={"confirmation"}>
              Yes, Remove
            </DialogFooterButton>
            <DialogFooterButton cancel outlined id={"rejection"}>
              Cancel
            </DialogFooterButton>
          </Dialog>
          <div>
            <TemporarySnackbar
              show={message && show}
              message={message}
              timeout={10000}
            />
          </div>
        </DataTable>
      )
    );
  }
}
export default withAuth(AssociatedEntities);
