import React, { useEffect, useState } from 'react';
import {
  Button,
  CardWrapper,
  CardBody,
  fortellisTheme,
  Loader,
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS,
  BUTTON_VARIANTS,
  IconClose,
  ICON_SIZE
} from 'cdk-radial';
import { ThemeProvider } from "styled-components";
import { useHistory } from "react-router-dom";
import PaymentCodeTable from './PaymentCodeTable';
import WithNavigationToHome from "../components/WithNavigationToHome"
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import './paymentCodeManagement.css'

const UNKNOWN_COUPON_NAME = 'N/A';

function PaymentCodeManagement({
  auth,
  getCoupons,
  getCouponAssociations,
  dissociateCoupon,
  resetAssociationState,
  paymentCodes: {
    coupons,
    fetchingCoupons,
    fetchingCouponsErrorMessage,
    couponAssociations,
    fetchingCouponAssociations,
    fetchingCouponAssociationsErrorMessage,
    dissociatedCoupon,
    isDissociatingCoupon,
    dissociatingCouponErrorMessage,
    associateCouponResponse,
    isAssociatingCoupon,
    associatingCouponErrorMessage,
  }
}) {
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [couponsMap, setCouponsMap] = useState();
  const [showAssociateSuccess, setShowAssociateSuccess] = useState(false);
  const [tableData, setTableData] = useState();
  const history = useHistory();

  useEffect(() => {
    if (auth.accessToken) {
      getCoupons({accessToken: auth.accessToken});
      getCouponAssociations({accessToken: auth.accessToken});
    }
  }, [auth]);

  useEffect(() => {
    if (coupons) {
      setCouponsMap(
        Object.fromEntries(
          coupons.map(coupon => [coupon.id, coupon])
        )
      );
    }
  }, [coupons]);

  useEffect(() => {
    if (couponAssociations?.length && couponsMap) {
      setTableData(
        couponAssociations.map(association => ({
          ...association,
          name: couponsMap[association.coupon_id]?.name || UNKNOWN_COUPON_NAME
        }))
      );
    }
  }, [couponAssociations, couponsMap]);

  useEffect(() => {
    if (dissociatedCoupon) {
      getCouponAssociations({accessToken: auth.accessToken});
    }
  }, [dissociatedCoupon]);

  useEffect(() => {
    setShowAssociateSuccess(Boolean(associateCouponResponse));
  }, [associateCouponResponse]);

  useEffect(() => {
    if (!fetchingCouponAssociations && fetchingCouponAssociationsErrorMessage) {
      setErrorToastMessage(fetchingCouponAssociationsErrorMessage);
    } else if (!isDissociatingCoupon && dissociatingCouponErrorMessage) {
      setErrorToastMessage(dissociatingCouponErrorMessage);
    } else if (!isAssociatingCoupon && associatingCouponErrorMessage) {
      setErrorToastMessage(associatingCouponErrorMessage);
    } else if (!fetchingCoupons && fetchingCouponsErrorMessage) {
      setErrorToastMessage(fetchingCouponsErrorMessage)
    } else {
      setErrorToastMessage('');
    }
  }, [
    fetchingCouponAssociations,
    fetchingCouponAssociationsErrorMessage,
    isDissociatingCoupon,
    dissociatingCouponErrorMessage,
    isAssociatingCoupon,
    associatingCouponErrorMessage
  ]);

  const handleCreatePaymentCode = () => {
    history.push({
      pathname: "/payment-codes/create"
    });
    resetAssociationState();
  };

  const handleCloseAssociationToast = () => {
    setShowAssociateSuccess(false);
    resetAssociationState();
  };

  return (
    <ThemeProvider theme={fortellisTheme}>
      <CardWrapper>
        <CardBody>
          <div>
            <Button
              className="coupon-associate-button"
              text="Associate Payment Code"
              onClick={handleCreatePaymentCode}
            />
            {fetchingCouponAssociations ? (
              <div className="body-loader"><Loader label={<span style={{ fontSize: '18px', fontWeight: '600' }}>Loading...</span>} /></div>
            ) : (
              <>
                <PaymentCodeTable
                  couponAssociations={tableData}
                  dissociateCoupon={({ appId, couponId }) => dissociateCoupon({ accessToken: auth.accessToken, appId, couponId })}
                />
              </>
            )}
          </div>
        </CardBody>
      </CardWrapper>
      {errorToastMessage && (
        <Toast
          id="coupon-error-toast"
          content={`Error has occured: ${errorToastMessage}`}
          variant={TOAST_VARIANTS.NEGATIVE}
          position={TOAST_POSITIONS.FIXED}
          visibilityDuration={TOAST_VISIBILITY_DURATIONS.INFINITE}
          actions={[
            {
              variant: BUTTON_VARIANTS.ICON,
              text: "Dismiss",
              icon: <IconClose />,
              iconSize: ICON_SIZE.SMALL,
              onClick: () => setErrorToastMessage('')
            }
          ]}
        />
      )}
      {showAssociateSuccess && (
        <Toast
          id="coupon-associate-success-toast"
          content={`Successfully associated coupon`}
          variant={TOAST_VARIANTS.POSITIVE}
          position={TOAST_POSITIONS.FIXED}
          visibilityDuration={3000}
          onVisibilityDurationEnd={handleCloseAssociationToast}
          actions={[
            {
              variant: BUTTON_VARIANTS.ICON,
              text: "Dismiss",
              icon: <IconClose />,
              iconSize: ICON_SIZE.SMALL,
              onClick: handleCloseAssociationToast
            }
          ]}
        />
      )}
    </ThemeProvider>
  );
}

PaymentCodeManagement = WithNavigationToHome(PaymentCodeManagement);

function PaymentCodeManagementContainer(props) {
  return props.auth.isAuthenticated ? (
    <PaymentCodeManagement {...props} />
  ) : (
    <div></div>
  );
}

export default withAuth(PaymentCodeManagementContainer);