import React from "react";
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton,
  DialogHeader
} from "@cdk-uip/react-dialog";
import { FormattedMessage } from "react-intl";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
// import { getErrorMessage } from "../Utils/errorMessageHandler";

const Confirm = ({ open, onAccept, onCancel, message, type, acceptLabel }) => (
  <Dialog open={open} onAccept={onAccept} onCancel={onCancel} style={{zIndex: 99}}>
    <DialogHeader>{message}</DialogHeader>
    <DialogFooter>
      <DialogFooterButton cancel id="confirm-cancel">
        <FormattedMessage
          id="ConfirmationDialog.cancel"
          defaultMessage="Cancel"
        />
      </DialogFooterButton>
      <DialogFooterButton accept id="confirm-accept">
        {acceptLabel ? (
          acceptLabel
        ) : (
          <FormattedMessage
            id="ConfirmationDialog.accept"
            defaultMessage="{accept}"
            values={{ accept: type }}
          />
        )}
      </DialogFooterButton>
    </DialogFooter>
  </Dialog>
);

const Modal = ({ open, onClose, message = "" }) => {
  return (
    <Dialog open={open} onCancel={onClose}>
      <DialogHeader>{message}</DialogHeader>
      <DialogFooter>
        <DialogFooterButton action onClick={onClose}>
          <FormattedMessage id="ModalDialog.dismiss" defaultMessage="Dismiss" />
        </DialogFooterButton>
      </DialogFooter>
    </Dialog>
  );
};

const LoadingModal = ({ open, message }) => {
  return (
    <Dialog open={open}>
      <DialogBody>
        <div className="loading-container small">
          <h3>{message}</h3>
          <CircularProgress />
        </div>
      </DialogBody>
      <DialogFooter />
    </Dialog>
  );
};

const ErrorModal = ({ open, onCancel, message = "" }) => (
  <Dialog open={open} onCancel={onCancel}>
    {/*{approvalStatus ? (*/}
    {/*<DialogHeader>*/}
    {/*{`Approving ${name} failed. ` + getErrorMessage(reason)}*/}
    {/*</DialogHeader>*/}
    {/*) : (*/}
    {/*<DialogHeader>*/}
    {/*{`Rejecting ${name} failed. ` + getErrorMessage(reason)}*/}
    {/*</DialogHeader>*/}
    {/*)}*/}
    <DialogHeader>{message}</DialogHeader>
    <DialogFooter>
      <DialogFooterButton cancel id="confirm-cancel">
        <FormattedMessage id="ConfirmationDialog.ok" defaultMessage="Dismiss" />
      </DialogFooterButton>
    </DialogFooter>
  </Dialog>
);

export { Confirm, ErrorModal, Modal, LoadingModal };
