// External Modules
import React, { Component, Fragment } from "react";
// Internal Modules
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import {
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardSubtitle
} from "@cdk-uip/react-card";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Elevation } from "@cdk-uip/react-elevation";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import WithNavigationToHome from "../components/WithNavigationToHome";
import NumberWithTitle from "../components/NumberWithTitle";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import { Button } from "@cdk-uip/react-button";
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from "@cdk-uip/react-data-table";
import { Icon } from "@cdk-uip/react-icon";
import { TextField } from "@cdk-uip/react-text-field";
class AppMetrics extends Component {
  state = {
    appCountData: {
      publicFortellisApps: 0,
      privateFortellisApps: 0,
      publicListingApps: 0,
      privateListingApps: 0,
      fetchingAppCount: false,
      errorMessage: ""
    },
    startDate: "",
    endDate: ""
  };

  static propTypes = {
    fetchApps: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      auth: { accessToken }
    } = this.props;
    const { startDate, endDate } = this.state;
    if (!(startDate && endDate)) {
      let currentDate = new Date();
      let end = `${currentDate.getFullYear()}-${currentDate.getMonth() +
        1}-${currentDate.getDate()}`;
      currentDate.setDate(currentDate.getDate() - 30);
      let start = `${currentDate.getFullYear()}-${currentDate.getMonth() +
        1}-${currentDate.getDate()}`;
      this.props.fetchApps({
        accessToken,
        startDate: start,
        endDate: end
      });
      this.setState({
        startDate: start,
        endDate: end
      });
    }
  }

  fetchAppDetails = () => {
    const {
      auth: { accessToken }
    } = this.props;
    const { startDate, endDate } = this.state;
    this.props.fetchApps({
      accessToken,
      startDate,
      endDate
    });
  };

  totalAppCount = () => {
    // Computing total app count
    const {
      publicFortellisApps,
      privateFortellisApps,
      publicListingApps,
      privateListingApps
    } = this.props.appCountData.appMetrics;
    return (
      publicFortellisApps +
      privateFortellisApps +
      publicListingApps +
      privateListingApps
    );
  };

  privateAppCount = () => {
    // Computing private app count
    const {
      privateFortellisApps,
      privateListingApps
    } = this.props.appCountData.appMetrics;
    return privateFortellisApps + privateListingApps;
  };

  render() {
    const {
      publicFortellisApps,
      privateFortellisApps,
      publicListingApps,
      privateListingApps,
      fetchingAppCount,
      errorMessage = ""
    } = this.props.appCountData.appMetrics;

    let chartLabels = ["Public", "Private"];
    let customListingChartLabels = [];
    let customFortellisChartLabels = [];
    chartLabels.forEach(label => {
      if (label === "Public") {
        customListingChartLabels.push(
          `Public- ${this.props.appCountData.appMetrics.publicListingApps}`
        );
        customFortellisChartLabels.push(
          `Public- ${this.props.appCountData.appMetrics.publicFortellisApps}`
        );
      } else if (label === "Private") {
        customListingChartLabels.push(
          `Private- ${this.props.appCountData.appMetrics.privateListingApps}`
        );
        customFortellisChartLabels.push(
          `Private- ${this.props.appCountData.appMetrics.privateFortellisApps}`
        );
      }
    });

    const listingChart = {
      labels: customListingChartLabels,
      datasets: [
        {
          data: [publicListingApps, privateListingApps],
          backgroundColor: ["#81285d", "#5A6375"]
        }
      ]
    };

    const fortellisChart = {
      labels: customFortellisChartLabels,
      datasets: [
        {
          data: [publicFortellisApps, privateFortellisApps],
          backgroundColor: ["#81285d", "#5A6375"]
        }
      ]
    };

    if (!fetchingAppCount && errorMessage) {
      return <div>Unable to load metrics.</div>;
    } else if (fetchingAppCount) {
      return (
        <div className="loading-container">
          <h3>Loading APP Metrics...</h3>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <Fragment>
          <LayoutGrid className="c-layout-section__dash-grid">
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={12}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle>Report by Date Range</CardTitle>
                  </CardHeader>
                  <CardText>
                    <React.Fragment>
                      <DataTable>
                        <DataTableHeader>
                          <DataTableRow>
                            <DataTableHeaderCell nonNumeric>
                              Start Date: {this.state.startDate}
                            </DataTableHeaderCell>
                            <DataTableHeaderCell nonNumeric>
                              End Date: {this.state.endDate}
                            </DataTableHeaderCell>
                            <DataTableHeaderCell nonNumeric />
                          </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                          <DataTableRow>
                            <DataTableCell nonNumeric>
                              <TextField
                                dense
                                type="date"
                                label=""
                                leadingIcon={
                                  // #TODO: DEV COMMENT: WILL CONVERT TO 'TextFieldIcon' as-soon-as WE UPDATE THEMEING, SO THAT FONT OVERRIDES STOP BREAKING THE LIB.
                                  <Icon>today{/* calendar/day icon */}</Icon>
                                  // <TextFieldIcon>today{/* calendar/day icon */}</TextFieldIcon>
                                }
                                value={this.state.startDate}
                                onChange={e =>
                                  this.setState({ startDate: e.target.value })
                                }
                              />
                            </DataTableCell>
                            <DataTableCell nonNumeric>
                              <TextField
                                dense
                                type="date"
                                label=""
                                leadingIcon={
                                  // #TODO: DEV COMMENT: WILL CONVERT TO 'TextFieldIcon' as-soon-as WE UPDATE THEMEING, SO THAT FONT OVERRIDES STOP BREAKING THE LIB.
                                  <Icon>today{/* calendar/day icon */}</Icon>
                                  // <TextFieldIcon>today{/* calendar/day icon */}</TextFieldIcon>
                                }
                                value={this.state.endDate}
                                onChange={e =>
                                  this.setState({ endDate: e.target.value })
                                }
                              />
                            </DataTableCell>
                            <DataTableCell nonNumeric>
                              <Button
                                primary
                                raised
                                onClick={this.fetchAppDetails}
                              >
                                {/* #TODO: DEV COMMENT: WILL CONVERT TO 'ButtonIcon' as-soon-as WE UPDATE THEMEING, SO THAT FONT OVERRIDES STOP BREAKING THE LIB.*/}
                                <Icon
                                  className={
                                    "c-card-pending-users__button-icon"
                                  }
                                >
                                  insert_chart {/* chart icon */}
                                </Icon>
                                Update Dashboard
                              </Button>
                            </DataTableCell>
                          </DataTableRow>
                        </DataTableBody>
                      </DataTable>
                    </React.Fragment>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES*/}
            </LayoutGridCell>

            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={12}
              spanTablet={8}
              spanPhone={4}
            >
              <Fragment>
                <Elevation z={10} className="c-dash-chart__wrapper">
                  <Card className="c-dash-chart spaced">
                    <CardHeader />
                    <CardText>
                      <AppMetricsCards
                        totalApps={this.totalAppCount()}
                        privateApps={this.privateAppCount()}
                      />
                    </CardText>
                  </Card>
                </Elevation>
              </Fragment>
            </LayoutGridCell>
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Listing Apps</CardTitle>
                    <CardSubtitle>Public or Private</CardSubtitle>
                  </CardHeader>
                  <CardText>
                    <Doughnut
                      height={100}
                      data={listingChart}
                      options={{
                        legend: { display: true, position: "bottom" },
                        maintainAspectRatio: true,
                        tooltips: {
                          callbacks: {
                            title: function(tooltipItem, data) {
                              return data["labels"][tooltipItem[0]["index"]];
                            },
                            label: function(tooltipItem, data) {
                              // function to generate run time blank/null label for chart
                              return "";
                            }
                          }
                        }
                      }}
                    />
                  </CardText>
                </Card>
              </Elevation>
            </LayoutGridCell>
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Integrated Apps</CardTitle>
                    <CardSubtitle>Public or Private</CardSubtitle>
                  </CardHeader>
                  <CardText>
                    <Doughnut
                      height={100}
                      data={fortellisChart}
                      options={{
                        legend: { display: true, position: "bottom" },
                        maintainAspectRatio: true,
                        tooltips: {
                          callbacks: {
                            title: function(tooltipItem, data) {
                              return data["labels"][tooltipItem[0]["index"]];
                            },
                            label: function(tooltipItem, data) {
                              // function to generate run time blank/null label for chart
                              return "";
                            }
                          }
                        }
                      }}
                    />
                  </CardText>
                </Card>
              </Elevation>
            </LayoutGridCell>
          </LayoutGrid>
        </Fragment>
      );
    }
  }
}

const AppMetricsCards = ({ totalApps, privateApps }) => (
  <div className="api-metrics-cards">
    <AppMetricsCard title="Total Apps Published" content={totalApps} />
    <AppMetricsCard title="Private Apps Published" content={privateApps} />
    <AppMetricsCard
      title="Public Apps Published"
      content={totalApps - privateApps}
    />
  </div>
);

const AppMetricsCard = ({ title, content }) => (
  <div className="api-metrics-card">
    <NumberWithTitle title={title} number={content} />
  </div>
);

AppMetrics = WithNavigationToHome(AppMetrics);
class AppMetricsContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <AppMetrics {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(AppMetricsContainer);
