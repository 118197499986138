import types from "./types";

const INITIAL_STATE = {
  verifyInProgress: {}
};

const IN_PROGRESS = {
  showInProgress: true
};

const ERROR_RESET = {
  errorMessage: "",
  showErrorModal: false,
  showInProgress: false,
  searchError: false
};

const SUCCESS_RESET = {
  successMessage: "",
  showSuccessModal: false,
  showInProgress: false
};

const returnStateWithSuccessReset = function(state) {
  return Object.assign({}, state, SUCCESS_RESET);
};

const returnStateWithErrorReset = function(state) {
  return Object.assign({}, state, ERROR_RESET);
};

export default function entityManagement(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ERROR:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        showErrorModal: true
      });
    case types.ENTITY_POST_REQUEST:
      return Object.assign({}, state, IN_PROGRESS);
    case types.ENTITY_POST_RECEIVE:
      return Object.assign({}, state, {
        data: action.response,
        successMessage:
          "Entity id: {" +
          action.response.id +
          "}...Navigate to subscriptions?",
        showSuccessModal: true,
        legalEntityID: action.response.id,
        entity: {
          id: action.response.id
        }
      });
    case types.ENTITY_POST_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to post entity",
        showErrorModal: true
      });
    case types.ENTITY_GET_REQUEST:
      return returnStateWithErrorReset(state);
    case types.ENTITY_GET_RECEIVE:
      let newState;
      if (action.sourceId)
        newState = Object.assign({}, state, {
          searchData: action.response,
          sourceId: action.sourceId
        });
      else newState = Object.assign({}, state, { searchData: action.response });
      return newState;
    case types.ENTITY_GET_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to get entities",
        showErrorModal: true,
        searchError: true
      });
    case types.ENTITY_VERIFY_PUT_REQUEST:
      console.log(action);
      return Object.assign({}, state, ERROR_RESET, {
        verifyInProgress: {
          ...state.verifyInProgress,
          [action.orgId]: true
        }
      });
    case types.ENTITY_VERIFY_PUT_RECEIVE:
      return Object.assign({}, state, {
        verifyInProgress: {
          ...state.verifyInProgress,
          [action.orgId]: undefined
        },
        verifyEntityId: action.response.id,
        verifyIsVerified: action.response.isVerified
      });
    case types.ENTITY_VERIFY_PUT_ERROR:
      return Object.assign({}, state, {
        verifyInProgress: {
          ...state.verifyInProgress,
          [action.orgId]: undefined
        },
        errorMessage: "Unable to verify organization",
        showErrorModal: true
      });

    case types.ENTITY_GET_REQUEST_NEW:
      return returnStateWithErrorReset(state);
    case types.ENTITY_GET_RECEIVE_NEW:
      let stateNew;
      if (action.sourceId)
        stateNew = Object.assign({}, state, {
          searchDataNew: action.response,
          sourceId: action.sourceId
        });
      else
        stateNew = Object.assign({}, state, { searchDataNew: action.response });
      return stateNew;

    case types.ENTITY_GET_ERROR_NEW:
      return Object.assign({}, state, {
        errorMessage: "Unable to get entities",
        showErrorModal: true,
        searchError: true
      });
    case types.SUCCESS_RESET:
      return returnStateWithSuccessReset(state);
    case types.ERROR_RESET:
      return returnStateWithErrorReset(state);
    case types.ADD_AGREEMENT_REQUEST:
      return Object.assign({}, state, {
        shouldShow: false,
        snackbarMessage: "",
        showUploadProgress: true,
        progressMessage: "Uploading Agreement, Please wait..."
      });

    case types.ADD_AGREEMENT_RECEIVE:
      let newEntity = action.response.entity;
      let agreementDetails = {};
      agreementDetails.exist = true;
      agreementDetails.fileName = action.response.payload.fileName;
      newEntity[action.response.payload.agreementType] = agreementDetails;
      return Object.assign({}, state, {
        snackbarMessage: "Agreement uploaded successfully!",
        shouldShow: true,
        agreementRequestForEntity: newEntity,
        showUploadProgress: false,
        progressMessage: ""
      });

    case types.ADD_AGREEMENT_ERROR:
      let message = "Failed to upload agreement";
      if (action.response.status === 403) {
        message = action.response.data;
      }
      return Object.assign({}, state, {
        snackbarMessage: message,
        shouldShow: true,
        showUploadProgress: false,
        progressMessage: ""
      });
    case types.ENABLE_FEATURE_GATE_REQUEST:
      return Object.assign({}, state, {
        showUploadProgress: true,
        progressMessage: "Updating feature gates..."
      });
    case types.ENABLE_FEATURE_GATE_RECEIVE:
      return Object.assign({}, state, {
        showUploadProgress: false,
        progressMessage: "Updating feature gates..."
      });
    case types.ENABLE_FEATURE_GATE_ERROR:
      return Object.assign({}, state, {
        errorMessage: "",
        showErrorModal: true,
        showUploadProgress: false,
        progressMessage: ""
      });
    case types.ENABLE_NAMESPACE_REQUEST:
      return Object.assign({}, state, {
        showUploadProgress: true,
        progressMessage: "Updating Namespace..."
      });
    case types.ENABLE_NAMESPACE_RECEIVE:
      return Object.assign({}, state, {
        showUploadProgress: false,
        progressMessage: "Updating Namespace..."
      });
    case types.ENABLE_NAMESPACE_ERROR:
      return Object.assign({}, state, {
        errorMessage: "",
        showErrorModal: true,
        showUploadProgress: false,
        progressMessage: ""
      });
    case types.ENABLE_ENHANCED_FLAGS_REQUEST:
      return Object.assign({}, state, {
        showUploadProgress: true,
        progressMessage: "Updating Enhanced Flags..."
      });
    case types.ENABLE_ENHANCED_FLAGS_RECEIVE:
      return Object.assign({}, state, {
        showUploadProgress: false,
        progressMessage: "Updating Enhanced Flags..."
      });
    case types.ENABLE_ENHANCED_FLAGS_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to update flags",
        showErrorModal: true,
        showUploadProgress: false,
        progressMessage: ""
      });
    case types.AGREEMENT_STATUS_REQUEST:
      return Object.assign({}, state, {
        showUploadProgress: true,
        progressMessage: "Fetching agreement details..."
      });

    case types.AGREEMENT_STATUS_RECEIVE:
      let reqEntity = action.response.entity;
      if (action.response.payload.apiAgreement) {
        reqEntity["apiAgreement"] = action.response.payload.apiAgreement;
      } else {
        reqEntity["apiAgreement"] = { exist: false };
      }
      if (action.response.payload.appAgreement) {
        reqEntity["appAgreement"] = action.response.payload.appAgreement;
      } else {
        reqEntity["appAgreement"] = { exist: false };
      }
      return Object.assign({}, state, {
        showUploadProgress: false,
        progressMessage: "",
        agreementRequestForEntity: reqEntity,
        showAgreementDialog: true
      });

    case types.AGREEMENT_STATUS_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to fetch agreement details",
        showErrorModal: true,
        showUploadProgress: false,
        progressMessage: ""
      });

    case types.CLOSE_AGREEMENT_DIALOG:
      return Object.assign({}, state, {
        showAgreementDialog: false,
        agreementRequestForEntity: {}
      });

    case types.GET_AGREEMENT_REQUEST:
      return Object.assign({}, state, {
        shouldShow: false,
        snackbarMessage: "",
        showRetrieveProgress: true,
        progressMessage: "Fetching agreement..."
      });

    case types.GET_AGREEMENT_RECEIVE:
      window.open(action.response.payload.preSignedUrl);
      return Object.assign({}, state, {
        showRetrieveProgress: false,
        progressMessage: ""
      });

    case types.GET_AGREEMENT_ERROR:
      let errorMessage = "Unable to fetch agreement";
      if (action.response.status === 403) {
        errorMessage = action.response.data;
      }
      return Object.assign({}, state, {
        snackbarMessage: errorMessage,
        shouldShow: true,
        showRetrieveProgress: false,
        progressMessage: ""
      });

    case types.DELETE_AGREEMENT_REQUEST:
      return Object.assign({}, state, {
        shouldShow: false,
        snackbarMessage: "",
        showDeleteProgress: true,
        progressMessage: "Deleting agreement, Please wait..."
      });

    case types.DELETE_AGREEMENT_RECEIVE:
      let deletedEntity = action.response.entity;
      if (action.response.payload.agreementType === "apiAgreement") {
        deletedEntity["apiAgreement"] = { exist: false };
      }
      if (action.response.payload.agreementType === "appAgreement") {
        deletedEntity["appAgreement"] = { exist: false };
      }
      return Object.assign({}, state, {
        snackbarMessage: "Agreement deleted successfully!",
        shouldShow: true,
        agreementRequestForEntity: deletedEntity,
        showDeleteProgress: false,
        progressMessage: ""
      });

    case types.DELETE_AGREEMENT_ERROR:
      let deleteErrorMessage = "Failed to delete agreement";
      if (action.response.status === 403) {
        deleteErrorMessage = action.response.data;
      }
      return Object.assign({}, state, {
        snackbarMessage: deleteErrorMessage,
        shouldShow: true,
        showDeleteProgress: false,
        progressMessage: ""
      });

    case types.REPLACE_AGREEMENT_REQUEST:
      return Object.assign({}, state, {
        shouldShow: false,
        snackbarMessage: "",
        showReplaceProgress: true,
        progressMessage: "Replacing Agreement, Please wait..."
      });

    case types.REPLACE_AGREEMENT_RECEIVE:
      let newReplacedEntity = action.response.entity;
      let agreementDetailsReplace = {};
      agreementDetailsReplace.exist = true;
      agreementDetailsReplace.fileName = action.response.payload.fileName;
      newReplacedEntity[
        action.response.payload.agreementType
      ] = agreementDetailsReplace;
      return Object.assign({}, state, {
        snackbarMessage: `Agreement replaced successfully!`,
        shouldShow: true,
        agreementRequestForEntity: newReplacedEntity,
        showReplaceProgress: false,
        progressMessage: ""
      });

    case types.REPLACE_AGREEMENT_ERROR:
      let replaceErrormessage = "Failed to replace agreement";
      if (action.response.status === 403) {
        replaceErrormessage = action.response.data;
      }
      return Object.assign({}, state, {
        snackbarMessage: replaceErrormessage,
        shouldShow: true,
        showReplaceProgress: false,
        progressMessage: ""
      });
    case types.DELETE_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        showUploadProgress: true,
        progressMessage: "Deleting organization, Please wait..."
      });

    case types.DELETE_ORGANIZATION_RECEIVE:
      let organizationSearchData = state.searchData;
      let orgSearchDataToBeReloaded = organizationSearchData.organizations.filter(
        item => item.id !== action.entity
      );
      let orgData = { organizations: orgSearchDataToBeReloaded };
      return Object.assign({}, state, {
        searchData: orgData,
        showUploadProgress: false,
        showErrorModal: true,
        errorMessage: "Organization delete process has been initiated !"
      });

    case types.DELETE_ORGANIZATION_ERROR:
      let deleteOrgErrorMessage = "Failed to delete organization";
      if (action.response.status === 403) {
        deleteOrgErrorMessage = action.response.data.Message;
      }
      return Object.assign({}, state, {
        errorMessage: deleteOrgErrorMessage,
        showErrorModal: true,
        showUploadProgress: false,
        progressMessage: ""
      });

    default:
      return returnStateWithErrorReset(state);
  }
}
