import React from "react";
import { PersistentSearchBox } from "@cdk-uip/react-persistent-search-box";
import types from "./types";
import { connect } from "react-redux";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";

class UserSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  handleClear() {
    this.setState({ value: "" });
  }

  handleFetch() {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getUserSearchResults(this.state.value, accessToken);
  }

  handleBack() {
    this.props.dispatch({
      type: types.USER_SEARCH_RESET
    });
  }

  goToUserProfileManager = userObj => {
    this.props.history.push(`/userprofile/${userObj.profile.email}`);
  };

  handleClick(value, index) {
    this.goToUserProfileManager(this.props.userSearchResults.users[index]);
  }

  onEnterKeyPressInSearch = event => {
    let code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      this.handleFetch(event.target.value);
    }
  };

  render() {
    return (
      <div
        className="user-search-field-container"
        style={{ display: "inline-block", float: "right" }}
      >
        <PersistentSearchBox
          label="Search using email-id"
          value={this.state.value}
          results={
            this.props.userSearchResults.users &&
            this.props.userSearchResults.users.map(user => user.profile.email)
          }
          onChange={e => {
            this.handleChange(e);
          }}
          onClear={() => {
            this.handleClear();
          }}
          onBack={() => {
            this.handleBack();
          }}
          onClick={(value, index) => {
            this.handleClick(value, index);
          }}
          onKeyPress={e => this.onEnterKeyPressInSearch(e)}
        />

        {this.props.userSearchResults.isFetching && (
          <div className="c-circular-progress--overlay-wrapper">
            <Headline>
              Searching Users
              <br />
              Please wait...
            </Headline>
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        )}

        <Dialog
          open={this.props.userSearchResults.error}
          onCancel={() => {
            this.props.dispatch({
              type: types.USER_SEARCH_RESET
            });
          }}
          style={{ textAlign: "center" }}
        >
          <DialogHeader>Error searching the user</DialogHeader>
          <DialogBody>{this.props.userSearchResults.errorMessage}</DialogBody>
          <DialogFooter>
            <DialogFooterButton
              onClick={() => {
                this.props.dispatch({
                  type: types.USER_SEARCH_RESET
                });
              }}
            >
              OK
            </DialogFooterButton>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default withAuth(connect()(UserSearch));
