import { useRef, useState } from "react";
import styled from "styled-components";
import {
  Autocomplete,
  IconArrowDropDown,
  IconArrowDropUp,
  Input,
  KEY_VALUES,
  MenuListItem,
  IndeterminateProgressCircle,
  LOADER_SIZE
} from "cdk-radial";
import useDebounce from "../../hooks/debounce";

const TallMenuListItem = styled(MenuListItem)`
  height: 3rem;
`;

// https://svc-radial-storybook.ext.nebula.connectcdk.com/?path=/docs/components-menus-autocomplete--autocomplete

/**
 * An autocomplete/typeahead menu whose options are provided by a user-provided
 * asynchronous function.
 *
 * The `fetchOptions` prop is required, and accepts a function, which should
 * take 1 argument (the current text in the input) and return a Promise for
 * an array of option objects.
 *
 * An option object contains:
 * * A `label` (required). This shows in the input when the user selects the
 *   option.
 * * A boolean `disabled` (optional). If `true`, the option cannot be selected.
 * * Any other properties you want. The entire option object is passed to
 *   `onSelect` when the option is selected.
 *
 * Supply a function to `renderMenuItemContent` to customize the display
 * of the menu items. It should take an options object and return a node
 * that will be displayed in the menu item for that option.
 */
export default function AsyncTypeaheadMenu({
  id,
  name,
  dataTestId,
  width,
  label,
  helperText,
  placeholder,
  fetchOptions,
  placement,
  onSelect = () => {},
  onClear = () => {},
  renderMenuItemContent,
  debounceDelay = 200
}) {
  const { BACKSPACE, ENTER, SPACE, TAB } = KEY_VALUES;
  const defaultOptions = [];

  const targetRef = useRef();
  const dimensionRef = useRef();

  const [options, setOptions] = useState(defaultOptions);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ label: "" });
  const [inputValue, setInputValue] = useState("");

  useDebounce(debounceDelay, () => {
    if (inputValue.length && isOpen) {
      setOptionsLoading(true);
      fetchOptions(inputValue)
        .then(results => {
          setOptionsLoading(false);
          if (results.length) {
            setOptions(results);
          } else {
            setOptions([{ label: "No Results", disabled: true }]);
          }
        })
        .catch(err => {
          setOptionsLoading(false);
          setOptions([
            { label: `Error: ${err.message || err}`, disabled: true }
          ]);
        });
    }
  }, [inputValue]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelect = option => {
    handleClose();
    setSelectedOption(option);
    setInputValue(option.label);
    onSelect(option);
    targetRef.current.focus();
  };

  const handleInputChange = event => {
    const inputText = event.target.value;
    setInputValue(inputText);
    setIsOpen(true);

    if (!inputText.length) {
      setOptions(defaultOptions);
    }
  };

  const handleClear = (event) => {
    event.preventDefault();
    setInputValue("");
    setSelectedOption({ label: "" });
    setOptions(defaultOptions);
    onClear();
  };

  const handleMenu = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const handleKeyDown = (e) => {
    if (e.key === ENTER) {
      if (inputValue.length > 0 && options.length > 0 && !options[0].disabled) {
        setSelectedOption(options[0]);
        setInputValue(options[0].label);
        onSelect(options[0]);
        handleMenu();
      }
    }
    if (e.key === SPACE) {
      handleMenu();
    }
    if (e.key === BACKSPACE && selectedOption.label !== "") {
      handleClear(e);
    }
    if (e.key === TAB && selectedOption.label === "") {
      setInputValue("");
      setOptions(defaultOptions);
    }
  };

  return (
    <>
      <div style={{ width }} data-testid={dataTestId}>
        <Input
          autoComplete="off"
          dataTestId={dataTestId}
          hasClearButton={selectedOption.label !== ""}
          helperText={helperText}
          icon={
            optionsLoading
              ? (
                <div>
                  <IndeterminateProgressCircle size={LOADER_SIZE.SMALL} />
                </div>
              )
              : isOpen
                ? <IconArrowDropUp/>
                : <IconArrowDropDown/>
          }
          id={id} // this id needs to be unique when using multiple autocomplete components
          label={label}
          labelRef={dimensionRef}
          name={name}
          onChange={handleInputChange}
          onClick={handleMenu}
          onInputClear={handleClear}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={targetRef}
          value={inputValue}
        />
      </div>
      <Autocomplete
        dataTestId={dataTestId}
        dimensionRef={dimensionRef}
        labelRef={targetRef}
        style={{ width, ...(width && { maxWidth: width }) }}
        onClose={handleClose}
        onChange={handleInputChange}
        onOpen={handleOpen}
        isOpen={isOpen}
        onUnselect={selectedOption.label === ""}
        placement={placement}
        isAuto={!placement}
      >
        {options.map((option, ind) => (
          <TallMenuListItem
            dataTestId={`${id}-list-item-${ind}`}
            key={`${id}-${JSON.stringify(option)}`}
            isSelected={selectedOption.label === option.label}
            onClick={() => handleSelect(option)}
            isDisabled={option.disabled}
          >
            {renderMenuItemContent
              ? renderMenuItemContent(option)
              : option.label}
          </TallMenuListItem>
        ))}
      </Autocomplete>
    </>
  );
};
