import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../../config/app.conf.json";
const ORG_ID = "fortellis";
const DOC_TEMPLATE_ID = "tnc";

export const actions = {
  postTnC: (accessToken, tncDetail) => ({
    [CALL_API]: {
      url: `${config.api.documents_service_url}/${ORG_ID}/${DOC_TEMPLATE_ID}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      data: tncDetail,
      types: [
        types.POST_TNC_REQUEST,
        types.POST_TNC_RESPONSE,
        types.POST_TNC_ERROR
      ]
    }
  })
};
