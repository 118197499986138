import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";

const EXCLUDED_EIS_ENTITY_TYPE = "Group";

const actions = {
  getAllEntities: ({ accessToken }, pageSize, page = 1, sort = "desc") => ({
    [CALL_API]: {
      url: `${config.api.organizations_uri}?status=approved&isVerified=false&excludeType=${EXCLUDED_EIS_ENTITY_TYPE}&sort=created:${sort}&pageSize=${pageSize}&page=${page}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.REQUEST_PENDING_ENTITIES,
        types.RECEIVE_PENDING_ENTITIES,
        types.ERROR_PENDING_ENTITIES
      ]
    }
  }),
  loadMorePendingOrgs: (
    { accessToken },
    pageSize,
    page = 1,
    sort = "desc"
  ) => ({
    [CALL_API]: {
      url: `${config.api.organizations_uri}?status=approved&isVerified=false&excludeType=${EXCLUDED_EIS_ENTITY_TYPE}&sort=created:${sort}&pageSize=${pageSize}&page=${page}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.REQUEST_MORE_PENDING_ENTITIES,
        types.RECEIVE_MORE_PENDING_ENTITIES,
        types.ERROR_MORE_PENDING_ENTITIES
      ]
    }
  }),
  setEntityVerified: ({ accessToken }, orgId, isVerified) => ({
    [CALL_API]: {
      url: `${config.api.organizations_uri}/${orgId}/verify`,
      method: "PUT",
      data: { isVerified },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.ENTITY_VERIFY_PUT_REQUEST,
        types.ENTITY_VERIFY_PUT_RECEIVE,
        types.ENTITY_VERIFY_PUT_ERROR
      ]
    }
  }),
  dismissError: () => {
    return { type: types.DISMISS_ERROR };
  }
};

export { actions };
