import { useState, useEffect } from "react";
import {
  Button,
  SecondaryButton,
  Card,
  MENU_PLACEMENT,
  DropdownMenu
} from 'cdk-radial';
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import WithNavigationToHome from "../components/WithNavigationToHome"
import { useHistory } from "react-router-dom";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import AsyncTypeaheadMenu from "../components/radial/AsyncTypeaheadMenu";
import axios from "axios";

import "./paymentCodeManagement.css";
import config from "../config/app.conf.json";

const SOLUTION_SEARCH_URL = config.api.solutions_url_v2;

function PaymentCodeCreate({
  auth,
  getCoupons,
  associateCoupon,
  paymentCodes: {
    coupons,
  }
}) {
  const history = useHistory();
  const [selectedApp, setSelectedApp] = useState();
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [availableCouponOptions, setAvailableCouponOptions] = useState([]);

  useEffect(() => {
    if (auth.accessToken) {
      getCoupons({ accessToken: auth.accessToken });
    }
  }, [auth]);

  useEffect(() => {
    if (coupons) {
      const options = coupons.map(coupon => ({
        label: coupon.name,
        value: coupon.id
      }));
      setAvailableCouponOptions(options);
    }
  }, [coupons]);

  const searchForApps = async text => {
    return axios.get(SOLUTION_SEARCH_URL, {
      params: {
        q: text
      },
      headers: {
        Authorization: `Bearer ${auth.accessToken}`
      }
    })
      .then(res => {
        return res.data?.solutions?.map(sol => ({
          label: sol.displayName,
          value: sol.id,
          mpAppName: sol.mpAppName,
          orgId: sol.orgId,
        }));
      })
  };

  const handleCouponChange = (event) => {
    setSelectedCoupon({
      label: event.label,
      value: event.value
    });
  };

  const handleCreate = async () => {
    await associateCoupon({
      accessToken: auth.accessToken,
      appId: selectedApp.value,
      couponId: selectedCoupon.value
    });
    history.push({
      pathname: "/payment-codes"
    });
  };

  const handleCancel = () => {
    history.push({
      pathname: "/payment-codes"
    });
  };

  return (
    <>
      <Card>
        <LayoutGrid
          className={
            "s-content-route--subscription-management c-layout-section__dash-grid"
          }
        >
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <label>
              <span style={{ fontWeight: 'bold' }}>App</span>
            </label>
            <div className="coupon-association-app-search">
              <AsyncTypeaheadMenu
                id="solution-typeahead"
                name="solution-typeahead"
                width="600px"
                label="Select an App"
                placeholder="Type to search"
                placement={MENU_PLACEMENT.BOTTOM_START}
                fetchOptions={searchForApps}
                onSelect={option => {
                  setSelectedApp(option);
                }}
                onClear={() => {
                  setSelectedApp(null)
                }}
                renderMenuItemContent={option => (
                  <>
                    <strong>{option.label}</strong>{" "}
                    {option.mpAppName ? `(${option.mpAppName})` : null}
                    <br />
                    {option.value}
                  </>
                )}
              />
            </div>
          </LayoutGridCell>
          {
            selectedApp && (
              <LayoutGridCell
                className={"c-layout-section__dash-grid-cell"}
                span={12}
                spanTablet={8}
                spanPhone={4}
              >
                <div className="coupon-create-app-details">
                  <strong>ID: </strong> {selectedApp.value}
                  <br />
                  <strong>Display Name: </strong> {selectedApp.label}
                  <br />
                  <strong>MP App Name: </strong> {selectedApp.mpAppName}
                  <br />
                  <strong>Org ID: </strong> {selectedApp.orgId}
                </div>
              </LayoutGridCell>
            )}
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <label>
              <span style={{ fontWeight: 'bold' }}>Payment Code</span>
            </label>
            <div className="coupon-selection-dropdown">
              <DropdownMenu
                label="Active codes"
                options={availableCouponOptions}
                onChange={handleCouponChange}
              />
            </div>
          </LayoutGridCell>
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <div className="coupon-association-create-footer">
              <SecondaryButton
                className="coupon-association-cancel-button"
                text="Cancel"
                onClick={handleCancel}
              />
              <Button
                className="coupon-association-create-button"
                text="Associate"
                onClick={handleCreate}
              />
            </div>
          </LayoutGridCell>
        </LayoutGrid>
      </Card>
    </>
  )
}

PaymentCodeCreate = WithNavigationToHome(PaymentCodeCreate);

function PaymentCodeCreateContainer(props) {
  return props.auth.isAuthenticated ? (
    <PaymentCodeCreate {...props} />
  ) : (
    <div></div>
  );
}

export default withAuth(PaymentCodeCreateContainer);