import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
// Helpers
import { isValidId } from "../helpers";
// Variables
let PARSE_CONFIG = {
  delimiter: ",",
  skipEmptyLines: true
};
const INVALID_MESSAGE = "File must be a valid csv document";
const CPP_DISABLED_MESSAGE = 'Data for cppFlag was given, but a selected API does not have privacy protection enabled';
const CPP_HEADER_NAME = 'cppFlag';

function validator({ type }) {
  return type === "text/csv";
}

class FileUpload extends Component {
  state = { valid: true, errorMessage: "" };

  uploadFile = e => {
    this.setState(() => ({ valid: true }));
    const file = e.target.files[0];
    if (!file) {
      this.setState(() => ({ valid: false }));
      return;
    }

    const reader = new FileReader();

    const handleRead = e => {
      let parseConfig = PARSE_CONFIG;
      if (
        validator({
          type: file.type
        })
      ) {
        // Parse uploaded file
        let selectedApis = this.props.solutionApiProducts.filter(
          api => api.selected === true
        );
        if(selectedApis.length ===0 ){
          selectedApis =  this.props.solutionAsyncApi.filter(e => e.selected === true);
        }
        const hasCppHeader = e.target.result?.split('\n')[0]?.includes(CPP_HEADER_NAME);
        if (hasCppHeader || (selectedApis.length === 1 && selectedApis[0].dmsConfig)) {
          parseConfig = { ...PARSE_CONFIG, header: true };
        }
        const { data, errors } = Papa.parse(e.target.result, parseConfig);

        if (errors.length) {
          this.setState({
            valid: false,
            errorMessage: errors.map(err => err.message).join(", ")
          });
          this.props.setIsFileValid(false);
          return;
        }

        if (
          (data.some(d => d[CPP_HEADER_NAME]?.toLowerCase() === 'true')) &&
            selectedApis.some(api => !api.privacyProtection)
          ) {
            this.setState({
              valid: false,
              errorMessage: CPP_DISABLED_MESSAGE
            });
            this.props.setIsFileValid(false);
            return;
        }

        // Gather the columns of the parsed document
        let apiRequiresDms = false;
        const entities = data
          .map(line => {
            if (!line) {
              return undefined;
            }
            let id;
            if (
              !hasCppHeader &&
              selectedApis.length >= 1 &&
              selectedApis.every(api => !api.dmsConfig)
            ) {
              id = line[0].trim();
            }
            // For APIs that require DMS config, activation is done per api. So selected API will be 1 always.
            else if (hasCppHeader || (selectedApis.length === 1 && selectedApis[0].dmsConfig)) {
              id = line.entityId;
              apiRequiresDms = true;
            }
            if (!isValidId(id)) {
              return undefined;
            }
            return apiRequiresDms ? line : id;
          })
          .filter(item => item);

        // Send data as string to parent
        this.setState({ valid: true, errorMessage: "" });
        this.props.setIsFileValid(true);
        this.props.onChange(apiRequiresDms ? entities : entities.join(", "));
      } else {
        this.setState(() => ({ valid: false }));
      }
    };

    reader.onload = handleRead;
    reader.readAsText(file);
  };

  render() {
    return (
      <Fragment>
        <input type="file" name="upload-entities" data-testid="upload-entities-input" onChange={this.uploadFile} />
        {!this.state.valid && (
          <p className="file-upload__error-message">
            {this.state.errorMessage
              ? this.state.errorMessage
              : INVALID_MESSAGE}
          </p>
        )}
      </Fragment>
    );
  }
}

FileUpload.propTypes = {
  onChange: PropTypes.func,
  invalidMessage: PropTypes.string,
  setIsFileValid: PropTypes.func
};

export default FileUpload;
