const triggerUserDownload = (filename = "file", data) => {
  if (typeof data === "object") {
    data = JSON.stringify(data, null, "\t");
  }
  const blob = new Blob([data]);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(blob);
};

export { triggerUserDownload };
