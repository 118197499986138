import { useState, useEffect, useRef } from "react";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import axios from "axios";
import styled from "styled-components";
import {
  SecondaryButton,
  IconFileDownload,
  IconCancelOutlined
} from "cdk-radial";
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
} from "@cdk-uip/react-data-table";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import {
  LayoutGrid,
  LayoutGridCell
} from "@cdk-uip/react-layout-grid";
import { CSVLink } from "react-csv";
import { PageHeaderTitle, PageHeader } from "@cdk-uip/react-page-header";
import moment from "moment";
import config from "../config/app.conf.json";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";
import withNavigation from "../components/withNavigation";

const BULK_SUBSCRIPTIONS_URL = config.api.bulk_subscription_base_uri;

const JobActionButton = styled(SecondaryButton)`
  margin-right: 0.75rem;
`

function BulkSubscriptionsList({ auth }) {
  const [jobs, setJobs] = useState([]);
  const [deactivateJobs, setDeactivateJobs] = useState([]);
  const [isJobLoading, setIsJobLoading] = useState(false);
  const [isDeactivateJobLoading, setIsDeactivateJobLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [showSnackbar, setShowSnackBar] = useState(false);
  const [message, setMessage] = useState(false);

  const { accessToken } = auth;
  const csvLink = useRef();

  useEffect(() => {
    setIsJobLoading(true);
    axios
      .get(`${BULK_SUBSCRIPTIONS_URL}/jobs`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then(response => {
        response.data.Items.map(jobObj => (jobObj.job = "Activate"));
        setJobs(response.data.Items);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsJobLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsDeactivateJobLoading(true);
    axios
      .get(`${BULK_SUBSCRIPTIONS_URL}/jobs/deactivate`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      .then(response => {
        response.data.Items.map(jobObj => (jobObj.job = "Deactivate"));
        setDeactivateJobs(response.data.Items);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsDeactivateJobLoading(false);
      });
  }, []);

  let mergedJobs;
  if (jobs && deactivateJobs) {
    mergedJobs = [].concat(jobs, deactivateJobs);
  }
  const getJobByID = async (id, jobType) => {
    await axios
      .get(
        `${BULK_SUBSCRIPTIONS_URL}/jobs/${id}?jobType=${jobType.toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(response => {
        response.data.data.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        setJobData(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
    csvLink.current.link.click();
  };

  const cancelJobByID = async job => {
    const id = job.id;
    job.cancelStatus = true;

    await axios
      .post(
        `${BULK_SUBSCRIPTIONS_URL}/jobs/cancel/${id}?jobType=${job.job.toLowerCase()}`,
        {
          //...data
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(response => {
        console.log("job canceled", response.data.message);
        shouldShow(response.data.message);
      })
      .catch(error => {
        job.cancelStatus = false;
        console.log(error);
        shouldShow(error.message);
      });
  };

  const shouldShow = message => {
    setMessage(message);
    setShowSnackBar(true);
  };

  const hideShow = () => {
    setMessage("");
    setShowSnackBar(false);
  };

  return (
    <>
      <LayoutGrid className="c-layout-section__dash-grid">
        <LayoutGridCell
          className="c-layout-section__dash-grid-cell"
          span={12}
          spanTablet={8}
          spanPhone={4}
        >
          <div className="content-flex">
            <PageHeader className="bulkSubscriptionPageHeader">
              <PageHeaderTitle>Bulk Subscription List</PageHeaderTitle>
            </PageHeader>
            <DataTable className="c-apimetric-table">
              <DataTableHeader>
                <DataTableRow>
                  <DataTableHeaderCell className="column-1" nonNumeric>
                    Job Type
                  </DataTableHeaderCell>
                  <DataTableHeaderCell className="column-1" nonNumeric>
                    Job ID
                  </DataTableHeaderCell>
                  <DataTableHeaderCell className="column-2" nonNumeric>
                    Application ID
                  </DataTableHeaderCell>
                  <DataTableHeaderCell className="column-3" nonNumeric>
                    Created At
                  </DataTableHeaderCell>
                  <DataTableHeaderCell className="column-4" nonNumeric>
                    User Name
                  </DataTableHeaderCell>
                  <DataTableHeaderCell className="column-5" nonNumeric>
                    Actions
                  </DataTableHeaderCell>
                </DataTableRow>
              </DataTableHeader>

              <DataTableBody>
                {mergedJobs && mergedJobs.length > 0 ? (
                  [...mergedJobs]
                    .sort((a, b) => {
                      //  descending
                      if (a.createdAt < b.createdAt) return 1;
                      if (a.createdAt > b.createdAt) return -1;
                      return 0;
                    })
                    .map(job => (
                      <DataTableRow key={job.id}>
                        <DataTableCell className="column-1" nonNumeric>
                          {job.job}
                        </DataTableCell>
                        <DataTableCell className="column-1" nonNumeric>
                          {job.id}
                        </DataTableCell>
                        <DataTableCell className="column-2" nonNumeric>
                          {job.applicationId}
                        </DataTableCell>
                        <DataTableCell className="column-3" nonNumeric>
                          {moment(job.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </DataTableCell>
                        <DataTableCell className="column-4" nonNumeric>
                          {job.userName}
                        </DataTableCell>
                        <DataTableCell className="column-5" nonNumeric>
                          <JobActionButton
                            text="Download"
                            icon={<IconFileDownload />}
                            onClick={() => getJobByID(job.id, job.job)}
                          />
                          <CSVLink
                            data={jobData}
                            filename="bulkjobdata.csv"
                            className="hidden"
                            ref={csvLink}
                            target="_blank"
                          />
                          <JobActionButton
                            text="Cancel"
                            icon={<IconCancelOutlined />}
                            onClick={() => cancelJobByID(job)}
                            isDisabled={job.cancelStatus}
                          />
                        </DataTableCell>
                      </DataTableRow>
                    ))
                ) : (
                  <div className="loading-container">
                    {isJobLoading && isDeactivateJobLoading ? (
                      <div>
                        <h3>Loading...</h3>
                        <CircularProgress />
                      </div>
                    ) : (
                      <h3>No Job Exists</h3>
                    )}
                  </div>
                )}
              </DataTableBody>
            </DataTable>
          </div>
        </LayoutGridCell>
      </LayoutGrid>
      <TemporarySnackbar
        show={showSnackbar}
        message={message}
        onClose={hideShow}
      />
    </>
  );
}

const BulkSubscriptionsListFn = withNavigation([
  {
    text: "Back to Home",
    goTo: "/"
  },
  {
    text: "Back to Bulk Subscriptions",
    goTo: "/bulk-subscriptions"
  }
])(BulkSubscriptionsList);

function BulkSubscriptionsListContainer(props) {
  return props.auth.isAuthenticated ? (
    <BulkSubscriptionsListFn {...props} />
  ) : (
    <div />
  );
}

export default withAuth(BulkSubscriptionsListContainer);
