const DATE_FORMAT = "MM/DD/YYYY";
const SEARCH_CONST = {
  VALUE: {
    APP_NAME: "appName",
    APP_ID: "appId"
  },
  LABEL: {
    APP_NAME: "DNP App Name",
    APP_ID: "DNP App ID"
  },
  PLACEHOLDER: {
    APP_NAME: "Search App by DNP App Name",
    APP_ID: "Search App by App ID"
  }
};
const PAGE_HEADING = "API Integration Management";
const INFO_TEXT = `You can search for a list of active API integrations for an app using a DNP App Name or App ID. Type in a date to override the existing free trial duration for any API with a Subscription with Overages Plan.`;

const NO_SUGGESTION_MSG = "No Results Found";
const NO_CONTENT_MSG = "No Integrations Found";
const INTEGRATION_LOADING_TEXT = "Fetching active API Integrations...";
const API_TYPE = {
  REST: "rest",
  ASYNC: "async"
};
const UPDATE_REST_ERROR_MSG = "Error in updating  Rest API Integrations(s)";
const UPDATE_ASYNC_ERROR_MSG = "Error in updating  Async API Integration(s)";
const UPDATE_INTEGRATION_ERROR = "Error in updating API Integrations";
const UPDATE_INTEGRATION_SUCCESSFUL = "Updated API Integration(s) successfully";
const UPDATE_REST_SUCCESSFUL = "Updated Rest API Integration(s) successfully";
const UPDATE_ASYNC_SUCCESSFUL = "Updated Async API Integration(s) successfully";
const GET_INTEGRATION_ERROR = "Error in fetching API Integration(s)";
const SAVE_DIALOG_TITLE = "Save API Integration(s) changes";
const SAVE_DIALOG_MSG =
  "Are you sure you want to save these API Integration(s) changes?";
const DATE_PICKER_HELPER_TEXT = "Select Free Trial End Date";
const DATE_SAVE_PENDING_TEXT = "Hit 'Save' to update Date";
const sendEmailCSMG = `
      mutation(
        $to: [csmsgEmailInput]
        $cc: [csmsgEmailInput]
        $bcc: [csmsgEmailInput]
        $subject: String!
        $withTemplate: withTemplateCmd
      ) {
        sendEmailCSMG(
          command: {
            to: $to
            cc: $cc
            bcc:$bcc
            subject: $subject
            withTemplate: $withTemplate
          }
        ) {
          emailResponse
        }
      }
    `;

export {
  DATE_FORMAT,
  SEARCH_CONST,
  PAGE_HEADING,
  INFO_TEXT,
  NO_SUGGESTION_MSG,
  NO_CONTENT_MSG,
  INTEGRATION_LOADING_TEXT,
  API_TYPE,
  UPDATE_REST_ERROR_MSG,
  UPDATE_ASYNC_ERROR_MSG,
  UPDATE_INTEGRATION_ERROR,
  UPDATE_INTEGRATION_SUCCESSFUL,
  GET_INTEGRATION_ERROR,
  SAVE_DIALOG_TITLE,
  SAVE_DIALOG_MSG,
  DATE_PICKER_HELPER_TEXT,
  DATE_SAVE_PENDING_TEXT,
  sendEmailCSMG
};
