import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";
import types from "./types";

export const actions = {
  setReportOrg: reportOrg => ({ type: types.SET_REPORT_ORG, reportOrg }),
  searchForOrgs: ({ accessToken, query }) => ({
    [CALL_API]: {
      url: `${config.api.organizations_uri}?searchBy=${encodeURIComponent(
        query
      )}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.SEARCH_ORGS_REQUEST,
        types.SEARCH_ORGS_RECEIVE,
        types.SEARCH_ORGS_ERROR
      ]
    }
  }),
  getLastTransactionsReport: ({ accessToken, orgId }) => ({
    [CALL_API]: {
      url: `${config.api.metrics_service_url}/v1/admin/last-transactions`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      data: { orgIds: [orgId] },
      types: [
        types.GET_TRANSACTIONS_REPORT_REQUEST,
        types.GET_TRANSACTIONS_REPORT_RECEIVE,
        types.GET_TRANSACTIONS_REPORT_ERROR
      ]
    }
  })
};
