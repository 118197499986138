import React from "react";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { TextField, TextFieldHelperText } from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { LoadingButton } from "../components/LoadingButton";
import { actions } from "./exportOrganizationsActions";
import { Elevation } from "@cdk-uip/react-elevation";

import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";

class ExportEntities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      searchInProgress: false,
      isChildEntitiesNotFound: false
    };
  }

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
  };

  onSubmit = async () => {
    const { searchString } = this.state;
    const {
      auth: { accessToken }
    } = this.props;
    let trimmedSearch = searchString.trim();
    let childrenEntitiesResponse = await this.props.getChildrenOrganizations({
      accessToken,
      id: trimmedSearch
    });
    if (childrenEntitiesResponse.response.organizations.length > 0) {
      this.setState({ isChildEntitiesNotFound: false });
      this.exportEntitiesIntoCSV(
        childrenEntitiesResponse.response.organizations
      );
    } else {
      this.setState({ isChildEntitiesNotFound: true });
    }
  };

  exportEntitiesIntoCSV(childrenEntities) {
    const { Parser } = require("json2csv");
    const fields = [
      {
        label: "Organization Name",
        value: "name"
      },
      {
        label: "Organization ID",
        value: "id"
      },
      {
        label: "Parent Organization ID",
        value: "belongsTo"
      }
    ];
    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(childrenEntities);
    let csvContent = "data:text/csv;charset=utf-8,";
    let encodedUri = encodeURI(csvContent + csv);
    window.open(encodedUri);
  }

  render() {
    const {
      searchInProgress,
      searchString,
      isChildEntitiesNotFound
    } = this.state;
    return (
      <React.Fragment>
        <Elevation z={10} className={"c-dash-card__wrapper"}>
          <Card className="c-dash-card c-form-subscriptions c-form-subscriptions--start">
            <CardHeader>
              <CardTitle large>Export Organizations</CardTitle>
              <CardSubtitle>
                Export child Organizations by parent Organization ID.{" "}
              </CardSubtitle>
            </CardHeader>
            <CardText>
              <Fluid valign="middle">
                <FluidItem fill>
                  <TextField
                    id="exportEntities"
                    name="exportEntities"
                    className="c-search-input--field"
                    spellCheck={false}
                    type="string"
                    inputMode="text"
                    value={searchString}
                    invalid={isChildEntitiesNotFound}
                    helperText={
                      <TextFieldHelperText validation>
                        No child orgaziations found.
                      </TextFieldHelperText>
                    }
                    onChange={e =>
                      this.onInputChange(e.target.value, "searchString")
                    }
                    fullWidth
                  />
                </FluidItem>
                <FluidItem>
                  <LoadingButton
                    className="loadingButton-leftPadding c-search--submit"
                    raised
                    primary
                    dense
                    onClick={this.onSubmit}
                    isLoading={searchInProgress}
                    type="submit"
                    loadingValue={"Searching..."}
                  >
                    Export
                  </LoadingButton>
                </FluidItem>
              </Fluid>
            </CardText>
          </Card>
        </Elevation>
      </React.Fragment>
    );
  }
}
export default connect(
  state => ({
    entityManagement: state.entityManagement
  }),
  actions
)(withAuth(ExportEntities));
