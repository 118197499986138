import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./containers/AppContainer";
import { BrowserRouter as Router } from "react-router-dom";
import initStore from "./reducers/store";
import { Provider } from "react-redux";

// VISUAL ASSETS:
// NORMALIZE CSS
import "normalize-css";
// MDC BASE FONT
import "typeface-roboto";
// UX/UI ##FORTELLIS## FONTS:
// Load Raleway typeface
import "typeface-raleway";
// Load Montserrat typeface
import "typeface-montserrat";
// CDK UIP React Web Components Lib [UX GUS]
// http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react
// Load CDK UIP Lib Icons [GUS]
import "@cdk-uip/icons";
// Load CDK UIP Lib CSS [GUS]
import "@cdk-uip/react/cdk.css";
// MAIN CSS
import "./index.css";
// --

// Create the redux store
const store = initStore({
  pendingUsers: {
    updateStoredNoteReducer: {
      fetchingList: [] // Need to setup empty list to prevent undefined cases on first renders
    }
  }
});

// Render the app
ReactDOM.render(
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <AppContainer />
    </Router>
  </Provider>,
  document.getElementById("root")
);
