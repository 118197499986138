import config from "../config/app.conf.json";

export default (uid, entity, approve) => ({
  url: config.api.admin_approval_uri,
  method: "PUT",
  data: {
    uid: uid,
    entity: entity,
    isApproved: approve
  }
});
