import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import getLinkedOrganizations from "../api/getLinkedOrganizations";
import types from "./types";
import getUser from "../api/getUser";
import resendEmail from "../api/resendEmail";
import approveUserApi from "../api/approveUser";
import assignRolesToUser from "../api/assignRolesToUser";
import axios from "axios";
import config from "../config/app.conf.json";

export const actions = {
  getUserDetails: (userEmail, accessToken) => ({
    [CALL_API]: {
      ...getUser(userEmail),
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.USER_PROFILE_GET_REQUEST,
        types.USER_PROFILE_GET_RECEIVE,
        types.USER_PROFILE_GET_ERROR
      ]
    }
  }),

  fetchLinkedEntities: ({ uid, accessToken }) => ({
    [CALL_API]: {
      ...getLinkedOrganizations(uid),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.LINKED_ENTITIES_GET_REQUEST,
        types.LINKED_ENTITIES_GET_RECEIVE,
        types.LINKED_ENTITIES_GET_ERROR
      ]
    }
  }),

  addUserToEntity: ({ entity, uid, accessToken }) => {
    var entityId = entity.id;
    return dispatch => {
      dispatch({
        type: types.ADD_USER_TO_ENTITY_GET_REQUEST,
        entity
      });
      return axios({
        url:
          config.api.organizations_url +
          "/organizations/" +
          entityId +
          "/users",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        },
        data: {
          userIds: [uid]
        }
      })
        .then(res => {
          dispatch(setResponse(res.status, entity));
        })
        .catch(error => {
          if (error.response) {
            dispatch(setResponse(error.response.status));
          } else dispatch(setResponse(""));
        });
    };
  },

  unlinkUserFromEntity: ({ entityId, uid, accessToken }) => {
    return dispatch => {
      dispatch({
        type: types.REMOVE_USER_FROM_ENTITY_DELETE_REQUEST,
        entityId
      });
      return axios({
        url:
          config.api.organizations_url +
          "/organizations/" +
          entityId +
          "/users/" +
          uid,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      })
        .then(res => {
          dispatch(dispatchAction(res.status, entityId));
        })
        .catch(error => {
          if (error.response) {
            dispatch(dispatchAction(error.response.status));
          } else dispatch(dispatchAction(""));
        });
    };
  },

  assignRolesToUser: ({ uid, orgId, orgName, roleIds, accessToken }) => {
    return {
      [CALL_API]: {
        ...assignRolesToUser(uid, orgId, roleIds),
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          { type: types.ASSIGN_USER_ROLES_REQUEST, orgId },
          { type: types.ASSIGN_USER_ROLES_RECEIVE, orgId, orgName },
          { type: types.ASSIGN_USER_ROLES_ERROR, orgId }
        ]
      }
    };
  },

  resendEmailToSignedUpUsers: ({ email, accessToken }) => {
    return {
      [CALL_API]: {
        ...resendEmail(email),
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          { type: types.RESEND_EMAIL_POST_REQUEST, email },
          { type: types.RESEND_EMAIL_POST_RECEIVE, email },
          { type: types.RESEND_EMAIL_POST_ERROR, email }
        ]
      }
    };
  },

  approveUser: ({ uid, status, accessToken }) => {
    return {
      [CALL_API]: {
        ...approveUserApi(uid, null, status),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          types.APPROVE_USER_POST_REQUEST,
          types.APPROVE_USER_POST_RECEIVE,
          types.APPROVE_USER_POST_ERROR
        ]
      }
    };
  },
  suspendUser: ({ userId, email, accessToken }) => {
    return dispatch => {
      dispatch({
        type: types.SUSPEND_USER_REQUEST
      });
      axios
        .put(`${config.api.admin_uri}/users/${userId}/suspend`, null, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch({
            ...getResult(types.SUSPEND_USER_RECEIVE, { ...res.data })
          });
          dispatch(actions.getUserDetails(email, accessToken));
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.SUSPEND_USER_ERROR, error.response));
          } else {
            dispatch(getResult(types.SUSPEND_USER_ERROR, {}));
          }
        });
    };
  },
  unsuspendUser: ({ userId, email, accessToken }) => {
    return dispatch => {
      dispatch({
        type: types.UNSUSPEND_USER_REQUEST
      });
      axios
        .put(`${config.api.admin_uri}/users/${userId}/unsuspend`, null, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        })
        .then(res => {
          dispatch({
            ...getResult(types.UNSUSPEND_USER_RECEIVE, { ...res.data })
          });
          dispatch(actions.getUserDetails(email, accessToken));
        })
        .catch(error => {
          if (error.response) {
            dispatch(getResult(types.UNSUSPEND_USER_ERROR, error.response));
          } else {
            dispatch(getResult(types.UNSUSPEND_USER_ERROR, {}));
          }
        });
    };
  }
};

const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};

export function defaultResponse() {
  return dispatch => {
    dispatch({
      type: "default"
    });
  };
}

export function setResponse(statusCode, entity) {
  switch (statusCode) {
    case 200:
      return {
        type: types.ADD_USER_TO_ENTITY_GET_RECEIVE,
        entity
      };
    default:
      return {
        type: types.ADD_USER_TO_ENTITY_GET_ERROR
      };
  }
}

export function dispatchAction(statusCode, entityId) {
  switch (statusCode) {
    case 200:
      return {
        type: types.REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE,
        entityId
      };
    default:
      return {
        type: types.REMOVE_USER_FROM_ENTITY_DELETE_ERROR
      };
  }
}
