import types from "../EntityManagement/types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";

export const actions = {
  getChildrenOrganizations: ({ accessToken, id }) => ({
    [CALL_API]: {
      url: `${config.api.organizations_url}/organizations/${id}/children`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [types.ENTITY_GET_ERROR, types.ENTITY_GET_REQUEST]
    }
  })
};
