import ApiIntegrationManagement from "./ApiIntegrationManagement";
import { connect } from "react-redux";
import { actions } from "./ApiIntegrationManagementActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      apiIntegrationManagement: state.apiIntegrationManagement
    }),
    actions
  )(ApiIntegrationManagement)
);
