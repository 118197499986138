import React from "react";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
//import { func, string } from "prop-types";
import { DataTableCell, DataTableRow } from "@cdk-uip/react-data-table";
import { Icon } from "@cdk-uip/react-icon";
import { FormattedMessage } from "react-intl";
import { Confirm } from "../components/Confirm";

import { Select } from "@cdk-uip/react-select";
import { CONNECTION_STATES, CONNECTION_EVENTS } from "./states";
import { SOLUTION_STATUS } from "../Utils/constants";

const ACTIVATE = "Activate";
const DEACTIVATE = "Deactivate";

const ConfirmActivate = ({ selected: { name } }) => (
  <FormattedMessage
    id="Connection.activateConfirmation"
    defaultMessage="Activate conncetion for {name}?"
    values={{ name: name }}
  />
);

const ConfirmDeactivate = ({ selected: { name } }) => (
  <FormattedMessage
    id="Connection.deactivateConfirmation"
    defaultMessage="Deactivate connection for {name}?"
    values={{ name: name }}
  />
);

const verifyAndFetchProvidersOrProviderName = ({
  status,
  onEvent,
  api,
  apiProducts,
  providerId,
  providers
} = {}) => {
  if (status === CONNECTION_STATES.INACTIVE && !apiProducts[api]) {
    onEvent({
      apis: [api],
      event: CONNECTION_EVENTS.GET_PROVIDERS_FOR_API
    });
  }
  if (status !== CONNECTION_STATES.INACTIVE && !providers[providerId]) {
    onEvent({
      event: CONNECTION_EVENTS.GET_PROVIDER_BY_ID,
      providerId
    });
  }
};

/**
 * Create an `<option>` component for a V1 provider or V2 API.
 * For V2 API providers, the `api` field starts with `api-v2-`.
 * @param provider the provider information
 * @param providerId the provider ID from the `Connection`'s props
 * @param addProps additional props to add to the `option`
 * @return {JSX.Element} the `option`
 */
function optionForProvider(provider, providerId, addProps = {}) {
  return (
    <option value={provider.id} key={providerId} {...addProps}>
      {`${provider.name} - ${provider.productName}`}
    </option>
  );
}

class Connection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionId: props.connectionId,
      providerId: "",
      selectedProviderId: "",
      confirm: false
    };
  }
  static defaultProps = {};

  promptConnectionActivation = () => {
    this.setState({
      confirm: {
        message: () => <ConfirmActivate selected={{ name: this.props.api }} />,
        onAccept: () => this.activateConnection(),
        type: ACTIVATE
      }
    });
  };

  promptConnectionDeactivation = () => {
    this.setState({
      confirm: {
        message: () => (
          <ConfirmDeactivate selected={{ name: this.props.api }} />
        ),
        onAccept: () => this.deactivateConnection(),
        type: DEACTIVATE
      }
    });
  };

  activateConnection = () => {
    this.props.onEvent({
      event: CONNECTION_EVENTS.ACTIVATE,
      providerId: this.state.selectedProviderId,
      connectionId: this.state.connectionId,
      api: this.props.api
    });
    this.setState({ confirm: false });
  };

  deactivateConnection = () => {
    this.props.onEvent({
      event: CONNECTION_EVENTS.DEACTIVATE,
      connectionId: this.state.connectionId,
      api: this.props.api
    });
    this.setState({ confirm: false });
  };

  onAccept = () => {
    const {
      confirm: { onAccept }
    } = this.state;
    onAccept();
  };

  onCancel = () => {
    this.setState({ confirm: false });
  };

  onProviderChange = event => {
    this.setState({ selectedProviderId: event.target.value });
  };

  componentDidMount() {
    verifyAndFetchProvidersOrProviderName(this.props);
  }

  componentDidUpdate(prevProps) {
    //Fetch provider name or list of supported providers on activate / deactivate
    if (
      (prevProps.providerId && !this.props.providerId) ||
      (!prevProps.providerId && this.props.providerId) ||
      prevProps.status !== this.props.status // v2 doesn't change the providerId
    ) {
      verifyAndFetchProvidersOrProviderName(this.props);
    }
  }

  render() {
    const { api, status, providerId, apiProducts, providers, solutionInfo } = this.props;
    const { confirm } = this.state;

    const shouldDisable =
      status !== CONNECTION_STATES.INACTIVE ? { disabled: true } : void 0;
    const providerName = providers[providerId]
      ? `${providers[providerId].name} - ${providers[providerId].productName}`
      : "Loading";
    const hasProvider = status !== CONNECTION_STATES.INACTIVE;

    return (
      <DataTableRow key={api}>
        <DataTableCell nonNumeric>
          <Icon className={"c-icon__connection-listitem"}>settings</Icon>
          {api}
          <p className={"sm-api-type-subtitle"}>
            API Type:
            <p
              style={{
                fontWeight: "bold",
                display: "inline-block",
                margin: "0px"
              }}
            >
              &nbsp;{"REST"}
            </p>
          </p>
        </DataTableCell>
        <DataTableCell nonNumeric data-testid='api-provider-dropdown-cell'>
          <Select
            className={
              hasProvider
                ? "c-provider-select--disabled"
                : "c-provider-select--enabled"
            }
            data-testid='api-provider-dropdown'
            fullWidth
            allowEmpty={false}
            label={hasProvider ? "Current Provider" : "Select a Provider"}
            {...shouldDisable}
            value={hasProvider ? providerName : this.state.selectedProviderId}
            onChange={this.onProviderChange}
          >
            {/* Display provider name when the connections are active/pending */}
            {this.props.status !== CONNECTION_STATES.INACTIVE ? (
              providers[providerId] ? (
                optionForProvider(providers[providerId], providerId, {
                  selected: true
                })
              ) : (
                <option value={"Loading"}>{"Loading"}</option>
              )
            ) : apiProducts[api] ? (
              apiProducts[api].length > 0 ? (
                apiProducts[api].map(provider =>
                  optionForProvider(provider, providerId)
                )
              ) : (
                <option value="NotAvailable">Not Available</option>
              )
            ) : (
              <option value="Loading">Loading</option>
            )}
          </Select>
        </DataTableCell>
        <DataTableCell nonNumeric>
          {/* #TODO: MAKE COMPONENT FOR STATUS INDICATOR */}
          {/*http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react/#statusindicatorinline*/}
          <p
            data-status={status}
            className={"c-status-indicator c-status-indicator--" + { status }}
          >
            <Icon>brightness_1</Icon>
            {status}
          </p>
          {/* #END TODO: MAKE COMPONENT FOR STATUS INDICATOR */}
        </DataTableCell>
        <DataTableCell nonNumeric>
          {/* #TODO: OR */}
          {status === "inactive" ? (
            <Button
              raised
              dense
              primary
              disabled = {solutionInfo?.status === SOLUTION_STATUS.CANCELLED}
              onClick={this.promptConnectionActivation}
            >
              <ButtonIcon>flash_on</ButtonIcon>
              Activate
            </Button>
          ) : (
            <Button raised dense disabled = {solutionInfo?.status === SOLUTION_STATUS.CANCELLED}  onClick={this.promptConnectionDeactivation}>
              <ButtonIcon>flash_off</ButtonIcon>
              Deactivate
            </Button>
          )}
          {/* #END OR */}
          <Confirm
            open={!!confirm}
            onAccept={this.onAccept}
            onCancel={this.onCancel}
            message={confirm ? confirm.message() : false}
            type={confirm.type}
          />
        </DataTableCell>
      </DataTableRow>
    );
  }
}

export default Connection;
