import React, { useEffect, useState } from "react";
import { Button } from "@cdk-uip/react-button";
import {
  BUTTON_VARIANTS, Dialog, Checkbox, TextArea, Input, PrimaryButton, Card, IconInfo,
  withTooltip, TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS, THEMES, Loader
} from "cdk-radial";

import "./style.css";
import axios from "axios";
import config from "../config/app.conf.json";
import ApisListTable from './ApisListTable'

function Create({ auth, reservedBundles, setShowSuccessToast, setScreen }) {
  const [bundleName, setBundleName] = useState("");
  const [bundleDescription, setBundleDescription] = useState("");
  const [bundleNameErrorMessage, setBundleNameErrorMessage] = useState("");
  const [
    bundleDescriptionErrorMessage,
    setBundleDescriptionErrorMessage
  ] = useState("");

  const [selectedApis, setSelectedApis] = useState([]);

  const [hasNameError, setHasNameError] = useState(false);
  const [hasDescError, setHasDescError] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [createErrorMessage, setCreateErrorMessage] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isVisibilityDisabled, setIsVisibilityDisabled] = useState(false);
  const [fetchingApis, setFetchingApis] = useState(false);
  const [errorFetchingApis, setErrorFetchingApis] = useState(false);
  const [apis, setApis] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { accessToken } = auth;
  const tooltipProps = {
    id: 'info-icon-tooltip',
    styles: { width: '350px', maxWidth: '350px' },
    text: 'Your bundle is automatically private if it has Private APIs in it',
    delay: 0,
    theme: THEMES.LIGHT
  };

  const ComponentToRender = ({
    ...props
  }) => {
    return <IconInfo {...props} />;
  };

  const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);

  useEffect(() => {
    setFetchingApis(true);
    fetchApis()
  }, [])

  const fetchApis = () => {
    axios.get(
      `${config.api.api_gateway_url}/v1/admin/bundle-apis`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
      .then(response => {
        setFetchingApis(false);
        setApis(response?.data);
      })
      .catch(() => {
        setFetchingApis(false);
        setErrorFetchingApis(true);
      });
  }

  const handleBundleNameChange = event => {
    setBundleName(event.target.value);
  };
  const handleBundleNameBlur = e => {
    if (Boolean(e?.target?.value)) {
      if (reservedBundles.find(b => b === e?.target?.value)) {
        setBundleNameErrorMessage("Bundle name already exist");
        setHasNameError(true);
      } else {
        setBundleNameErrorMessage("");
        setHasNameError(false);
      }
    } else {
      setBundleNameErrorMessage("please add Bundle name");
      setHasNameError(true);
    }
  };
  const onDescriptionChange = event => {
    setBundleDescription(event.target.value);
  };
  const onDescriptionBlur = e => {
    if (Boolean(e?.target?.value)) {
      setBundleDescriptionErrorMessage("");
      setHasDescError(false);
    } else {
      setBundleDescriptionErrorMessage("please add Bundle description");
      setHasDescError(true);
    }
  };

  const handleCreateBundle = () => {
    setIsDialogOpen(true);
  }

  const handleCreateBundleConfirm = () => {
    const apis = selectedApis.map(api => {
      return { id: api?.api_id, type: api?.api_type === 'Rest' ? 'api' : 'async-api' }
    })
    axios.post(
      `${config.api.api_gateway_url}/v2/admin/bundles`,
      {
        name: bundleName,
        description: bundleDescription,
        status: 'active',
        visibility: isPrivate ? 'private' : 'public',
        apis: apis
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
      .then(() => {
        setIsDialogOpen(false);
        setScreen("View Bundles");
        setShowSuccessToast(true);
      })
      .catch(error => {
        setIsDialogOpen(false);
        setCreateError(true);
        setCreateErrorMessage(error?.response?.data?.message || 'Failed to create the bundle');
      });
  }

  return (
    <>
      {errorFetchingApis ?
        <div className="error-div">
          Something went wrong, please try again.
        </div>
        :
        <Card>
          <div className="create-bundle-card">
            <div className="create-bundle-title">Create a bundle</div>
            <Input
              dataTestId="input-api-bundle-name"
              enableCustomValidation={true}
              enableIsRequiredValidation
              errorMessage={bundleNameErrorMessage}
              id="api-name-input"
              hasError={hasNameError}
              label="Bundle Name"
              name={"api-name-input-panel"}
              isRequired
              value={bundleName}
              onBlur={handleBundleNameBlur}
              onChange={handleBundleNameChange}
            />
            <TextArea
              enableCustomValidation
              id="description-input"
              isRequired
              label="Bundle Description"
              maxLength="500"
              name="description"
              placeholder="Add Bundle Description"
              onBlur={onDescriptionBlur}
              onChange={onDescriptionChange}
              value={bundleDescription}
              hasError={hasDescError}
              errorMessage={bundleDescriptionErrorMessage}
              hasCharacterCount
            />
            <div className="bundle-visibility-div">
              <Checkbox
                dataTestId="bundle-visibility"
                id="bundle-visibility"
                label="Change bundle to Private"
                labelPosition="after"
                name="visibility"
                onChange={() => setIsPrivate(prev => !prev)}
                size="standard"
                checked={isPrivate}
                isDisabled={isVisibilityDisabled}
                className='visibility-checkbox'
              />
              <ComponentWithTooltip horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT} verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP} />
            </div>

            {!!fetchingApis ?
              <Loader
                dataTestId="fectching-apis-loader"
                size="small"
                label="Fetching Apis..."
              />
              : <>
                <div>
                  <ApisListTable apis={selectedApis} isViewOnly={true} />
                </div>

                <div>
                  <ApisListTable apis={apis} setSelectedApis={setSelectedApis} isViewOnly={false} setIsPrivate={setIsPrivate} setIsVisibilityDisabled={setIsVisibilityDisabled} />
                </div>
              </>
            }

            <div className="api-details-panel-save-button">
              <PrimaryButton
                dataTestId='create-bundle'
                text="Create Bundle"
                onClick={handleCreateBundle}
                isDisabled={
                  !(
                    !!bundleName &&
                    !hasNameError &&
                    !!bundleDescription &&
                    selectedApis?.length
                  )
                }
              />
            </div>
            {createError ? (
              <Dialog
                className="create-api-dialog"
                buttonsProps={[
                  {
                    id: "cancel-action",
                    onClick: () => setCreateError(false),
                    text: "Cancel",
                    variant: BUTTON_VARIANTS.SECONDARY
                  }
                ]}
                id="share-api-dialog"
                isOpen={createError}
                onClose={() => setCreateError(false)}
                title="Bundle Creation Failed"
              >
                {!! createErrorMessage && <div>{createErrorMessage}</div> }
              </Dialog>
            ) : null}
          </div>
        </Card>
      }
     { !!isDialogOpen && <Dialog
        buttonsProps={[
          {
            id: 'dialog-action-1',
            onClick: () => setIsDialogOpen(false),
            text: 'Cancel',
            variant: BUTTON_VARIANTS.SECONDARY
          },
          {
            id: 'dialog-action-2',
            onClick: handleCreateBundleConfirm,
            text: 'Confirm',
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        dataTestId="bundle-create-dialog"
        id="bundle-create-dialog"
        onClose={() => setIsDialogOpen(false)}
        title="Confirmation"
        isOpen={isDialogOpen}
      >
        Are you sure you want to add these {selectedApis?.length} selected API’s into a bundle?
      </Dialog>}
    </>
  );
}
class createContainer extends React.Component {
  render() {
    return (
      <>
        <br />
        <Button
          raised
          onClick={() => {
            this.props.setScreen("View Bundles")
          }}
          className="cdk-navigation-button"
        >
          Back to Bundles
        </Button>

        <br />
        <br />
        <Create {...this.props} />
      </>
    )
  }
}
export default createContainer;
