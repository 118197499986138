const businessTypes = {
  ISV: "Independent Software Vendor (ISV)",
  OEM: "Original Equipment Manufacturer (OEM)"
};

const country = {
  US: "United States",
  CA: "Canada"
};

const validate = (re, str, canBeEmpty = false) => {
  if (str.length) {
    return re.test(str.trim());
  }
  return canBeEmpty;
};

/* Validates a phone number and returns a properly formatted number or false
 * ex: +1(999)999-9999 or (999)999-9999
 */
const validatePhoneNumber = (phone, canBeEmpty = false, format = false) => {
  // Regex from great answer on SO: https://stackoverflow.com/a/16702965
  const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  if (format) {
    const groups = re.exec(phone);
    return groups
      ? `${groups[1] ? `+${groups[1]}` : ""}(${groups[2]})${groups[3]}-${
          groups[4]
        }`
      : false;
  }
  return validate(re, phone, canBeEmpty);
};

/* Validates a GUID, returns GUID if valid or false */
const validateGuid = (guid, canBeEmpty = false) => {
  const re = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return validate(re, guid, canBeEmpty);
};

const validateDomain = (domain, canBeEmpty = false) => {
  const re = /^\w+\.[A-Za-z]+$/;
  return validate(re, domain, canBeEmpty);
};

const validateUrl = (url, canBeEmpty = false) => {
  const re = /^((http|https):\/\/)?(www)?[a-z0-9-_]+([.]{1}[a-z0-9-_]+){1,}(\/[a-z0-9-_]*)*$/;
  return validate(re, url, canBeEmpty);
};

module.exports = {
  businessTypes,
  country,
  validate,
  validatePhoneNumber,
  validateGuid,
  validateDomain,
  validateUrl
};
