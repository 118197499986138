// Component import
import { useEffect, useRef, useState } from "react";
import { BaseInputField, IconArrowDropDown, IconArrowDropUp, Input, Menu, MenuListItem } from "cdk-radial";
import styled from "styled-components";

// style the input with the cursor:pointer using styled components
const SelectInput = styled(Input)`
  ${BaseInputField} {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;

// export for use in your application (optional)
export function DropdownMenu({
  options,
  onChange,
  value,
  isDisabled,

  width = "224px",
  dataTestId,
  inputId,
  name,
  label,
  placeholder = "Select",
  enableCustomValidation,
  hasError
}) {
  const targetRef = useRef();
  const dimensionRef = useRef();
  const menuContainerRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const handleOpen = event => {
    setIsOpen(true);
  };

  const handleClose = event => {
    setIsOpen(false);
  };

  const handleSelect = option => {
    setSelectedOption(option);
    onChange?.(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const setIsOpenFromMenu = event => {
      if (event.detail.ref === menuContainerRef) setIsOpen(event.detail.openMenu);
    };
    document.addEventListener("customMenuOpenEvent", setIsOpenFromMenu);
    return () => document.removeEventListener("customMenuOpenEvent", setIsOpenFromMenu);
  }, []);

  return (
    <>
      <div data-testid="single-select-dropdown" style={{ width }}>
        <SelectInput
          dataTestId={dataTestId}
          icon={isOpen ? <IconArrowDropUp /> : <IconArrowDropDown />}
          id={inputId}
          label={label}
          labelRef={dimensionRef}
          name={name}
          onChange={() => {}}
          placeholder={placeholder}
          ref={targetRef}
          value={selectedOption.label ?? ""}
          style={{ caretColor: "transparent" }}
          enableCustomValidation={enableCustomValidation}
          hasError={hasError}
          isDisabled={isDisabled}
        />
      </div>
      <Menu
        dataTestId={`${dataTestId}-menu`}
        dimensionRef={dimensionRef}
        isOpen={isOpen}
        labelRef={targetRef}
        onClose={handleClose}
        onOpen={handleOpen}
        style={{ width }}
        isAuto={false}
        ref={menuContainerRef}
      >
        {options.map((option, ind) => (
          <MenuListItem
            dataTestId={`${dataTestId}-menu-item-${ind}`}
            isDisabled={option.disabled}
            isSelected={option.value === selectedOption.value}
            key={ind}
            onClick={() => handleSelect(option)}
            hideSelectedItemCheckmark={false}
          >
            {option.label}
          </MenuListItem>
        ))}
      </Menu>
    </>
  );
}
