import { DATA_FORMATS } from "../components/TableWithPagination";

const API_ADMIN = [
  {
    name: "Name",
    id: "name",
    format: DATA_FORMATS.PLAIN_TEXT,
    clickable: true
  },
  {
    name: "Description",
    id: "description",
    format: DATA_FORMATS.PLAIN_TEXT,
    clickable: true
  },
  {
    name: "Visibility",
    id: "visibility",
    format: DATA_FORMATS.PLAIN_TEXT,
    clickable: true
  },
  {
    name: "Date Created",
    id: "created",
    format: DATA_FORMATS.DATE,
    clickable: true
  },
  {
    name: "Date Modified",
    id: "updated",
    format: DATA_FORMATS.DATE,
    clickable: true
  },
  {
    name: "Status",
    id: "status",
    format: null,
    clickable: true
  },
  {
    name: "Edit",
    id: "editColumn",
    format: null,
    clickable: false,
    style: { width: 80 }
  }
];

const CUSTOM_FORMATS = {
  STATUS: "status",
  EDIT: "editColumn"
};

const ACTIVATION_MODELS = {
  AUTO: "auto",
  MANUAL: "manual",
  ADMIN_API: "admin_api"
};

const ACTIVATION_TEXT = {
  [ACTIVATION_MODELS.AUTO]: "Automatic",
  [ACTIVATION_MODELS.MANUAL]: "Manual",
  [ACTIVATION_MODELS.ADMIN_API]: "Admin API"
};

export { API_ADMIN, CUSTOM_FORMATS, ACTIVATION_MODELS, ACTIVATION_TEXT };
