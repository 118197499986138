import types from "./types";

const apiSubmissionsInitialState = {
  apis: [],
  fetchingApis: false,
  spec: "",
  fetchingSpec: false,
  specFile: "",
  fetchingSpecFile: false,
  errorMessage: "",
  specReceived: false,
  showSuccessModal: false,
  response: "",
  reviewResponse: false,
  reviewResetResponse: false,
  awaitingResponse: false,
  awaitingLabelChange: false,
  reviewError: false,
  reviewResetError: false,
  approveError: false,
  rejectError: false,
  notesError: false,
  labelError: false,
  reviewInitiated: false
};

const apiSubmissions = (state = apiSubmissionsInitialState, action) => {
  //fails w/ unexpected end of json input on 200, needs action.apierror.message
  // 422 requires: action.apierror.payload.error
  const getErrorMessage = apierror => {
    if (apierror.hasOwnProperty("payload")) {
      return apierror.payload.error || apierror.payload.message;
    } else if (apierror.hasOwnProperty("message")) {
      return apierror.message;
    } else {
      return "Error";
    }
  };

  switch (action.type) {
    case types.API_GET_REQUEST:
      return {
        ...state,
        fetchingApis: true,
        errorMessage: ""
      };
    case types.API_GET_RECEIVE:
      return {
        ...state,
        apis: action.response,
        fetchingApis: false
      };
    case types.API_GET_ERROR:
      return {
        ...state,
        fetchingApis: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    case types.SPEC_GET_REQUEST:
      return {
        ...state,
        fetchingSpec: true,
        errorMessage: ""
      };
    case types.SPEC_GET_RECEIVE:
      return {
        ...state,
        spec: action.response,
        fetchingSpec: false,
        specReceived: true
      };
    case types.SPEC_GET_ERROR:
      return {
        ...state,
        fetchingSpec: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    case types.SPEC_FILE_GET_REQUEST:
      return {
        ...state,
        fetchingSpecFile: true,
        errorMessage: ""
      };
    case types.SPEC_FILE_GET_RECEIVE:
      return {
        ...state,
        specFile: action.response,
        fetchingSpecFile: false
      };
    case types.SPEC_FILE_GET_ERROR:
      return {
        ...state,
        fetchingSpecFile: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    case types.REVIEW_SPEC_POST_REQUEST:
      return {
        ...state,
        reviewError: false,
        reviewResponse: null,
        reviewResetResponse: null,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.REVIEW_SPEC_POST_RECEIVE:
      return {
        ...state,
        reviewResponse: action.response,
        awaitingResponse: false,
        reviewInitiated: true
      };
    case types.REVIEW_SPEC_POST_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        reviewError: true
      };
    case types.RESET_SPEC_POST_REQUEST:
      return {
        ...state,
        reviewResetError: false,
        reviewResponse: null,
        reviewResetResponse: null,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.RESET_SPEC_POST_RECEIVE:
      return {
        ...state,
        reviewResetResponse: action.response,
        awaitingResponse: false
      };
    case types.RESET_SPEC_POST_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        reviewResetError: true
      };
    case types.REVIEW_SPEC_PUT_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.REVIEW_SPEC_PUT_RECEIVE:
      return {
        ...state,
        reviewResponse: action.response,
        awaitingResponse: false,
        reviewInitiated: false
      };
    case types.REVIEW_SPEC_PUT_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        reviewError: true
      };
    case types.APPROVE_SPEC_POST_REQUEST:
      return {
        ...state,
        approveError: false,
        reviewResponse: null,
        reviewResetResponse: null,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.APPROVE_SPEC_POST_RECEIVE:
      return {
        ...state,
        response: action.response,
        awaitingResponse: false,
        showSuccessModal: true
      };
    case types.APPROVE_SPEC_POST_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        approveError: true
      };
    case types.REJECT_SPEC_POST_REQUEST:
      return {
        ...state,
        rejectError: false,
        reviewResponse: null,
        reviewResetResponse: null,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.REJECT_SPEC_POST_RECEIVE:
      return {
        ...state,
        response: action.response,
        awaitingResponse: false
      };
    case types.REJECT_SPEC_POST_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        rejectError: true
      };
    case types.UPDATE_NOTES_POST_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        notesSaving: true,
        notesError: false,
        errorMessage: ""
      };
    case types.UPDATE_NOTES_POST_RECEIVE:
      return {
        ...state,
        response: action.response,
        awaitingResponse: false,
        notesSaving: false
      };
    case types.UPDATE_NOTES_POST_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        notesSaving: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        notesError: true
      };
    case types.LABEL_SPEC_PUT_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.LABEL_SPEC_PUT_RECEIVE:
      return {
        ...state,
        response: action.response,
        awaitingResponse: false
      };
    case types.LABEL_SPEC_PUT_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        labelError: true
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        reviewError: false,
        reviewResetError: false,
        approveError: false,
        rejectError: false,
        notesError: false,
        labelError: false
      };
    case types.CLEAR_MODAL:
      return {
        ...state,
        showSuccessModal: false
      };

    default:
      return state;
  }
};

export default apiSubmissions;
