import React, { Component } from "react";
import { Button } from "@cdk-uip/react-button";
import { Divider } from "@cdk-uip/react-divider";
import { Headline } from "@cdk-uip/react-typography";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { withRouter } from "react-router-dom";
import { parse as parseQuery, stringify as stringifyQuery } from "query-string";

class FakeLogin extends Component {
  onLogin = () => {
    const { history } = this.props;
    const query = {
      id_token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZW1haWwiOiJqb2huLmRvZUBjZGsuY29tIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjQxMDI0NDQ3OTl9.mX12qpV6ZN0PvSq2mQ-ZFDuzHH1iiZlm_gD9Jm8DFUU",
      access_token: "xyz",
      token_type: "Bearer",
      expires_in: 3600,
      scope: "openid+email+profile",
      state: parseQuery(history.location.search).state
    };
    window.location = `/#${stringifyQuery(query)}`;
  };
  render() {
    return (
      <LayoutGrid>
        <LayoutGridCell span={12}>
          <Headline adjustMargin>Login</Headline>
          <Divider />
        </LayoutGridCell>
        <LayoutGridCell span={12}>
          <Button id="login-submit" primary raised onClick={this.onLogin}>
            Log in
          </Button>
        </LayoutGridCell>
      </LayoutGrid>
    );
  }
}

export default withRouter(FakeLogin);
