import { connect } from "react-redux";
import { withRouter } from "react-router";

import ApiUsage from "./ApiUsage";
import { ACTIONS } from "./reduxManagement";

export default withRouter(
  connect(
    state => {
      return { store: state.apiUsageReducer };
    },
    ACTIONS
  )(ApiUsage)
);
