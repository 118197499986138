import React from "react";

import { Toast, TOAST_POSITIONS, TOAST_VARIANTS } from "cdk-radial";

const FOUR_SECONDS = "4000";

export function ToastNotification({ open, onCancel, message, variant }) {
  return open && (
    <Toast
      content={message}
      id={"notification-api-edit"}
      style={{ marginTop: "50px", zIndex: "9999" }}
      onVisibilityDurationEnd={() => {
        onCancel();
      }}
      position={TOAST_POSITIONS.FIXED}
      variant={variant || TOAST_VARIANTS.NEGATIVE}
      visibilityDuration={FOUR_SECONDS}
    />
  );
}
