const DATE_FORMAT = 'MMM DD YYYY'
const API_TYPE = {
    'api': 'REST API',
    'async-api': 'Async API'
}
const CERTIFICATION_STATUS = {
    READY_TO_CERTIFY: "ready-to-certify",
    IN_CERTIFICATION: "in-certification",
    CERTIFIED: "certified"
}

const CERTIFICATION_STATUS_DISPLAY = {
    [CERTIFICATION_STATUS.READY_TO_CERTIFY]: "Ready to Certify",
    [CERTIFICATION_STATUS.IN_CERTIFICATION]: "In Certification",
    [CERTIFICATION_STATUS.CERTIFIED]: "Certified"
}

const DIALOG_CONST = {
    [CERTIFICATION_STATUS.IN_CERTIFICATION] : {
        TITLE: 'Certify this app',
        MSG: (appName) => <div>This action completes the certification of the app (<b>{appName}</b>).<br/>Do you want to proceed?</div>
    },
    [CERTIFICATION_STATUS.CERTIFIED]: {
        TITLE: 'Request Recertification',
        MSG: (appName) => <div>By requesting recertification, you are moving the app (<b>{appName}</b>) back to ready to certify state.<br/>Do you want to proceed?</div>
    }
}

const TOAST_MSG = {
    [CERTIFICATION_STATUS.CERTIFIED]: 'App certified successfully.',
    [CERTIFICATION_STATUS.READY_TO_CERTIFY]: 'App recertification requested successfully.',
    ["CERTIFICATION_TRIGGER_DATE_UPDATE"]: 'Certification Fee Due Date changed successfully.'
}

const ACTION_BUTTONS = {
    [CERTIFICATION_STATUS.IN_CERTIFICATION]: 'Certify',
    [CERTIFICATION_STATUS.CERTIFIED]: 'Request Recertification'
}

const ERROR_MSG = {
    GET_APP: 'Error in fetching app data, try again later.',
    UPDATE_APP: 'Error in updating app. Try again later.',
    CERTIFICATION_TRIGGER_DATE_UPDATE: 'Error in updating Certification Fee Due Date.',
    REST_INTEGRATIONS_FETCH_FAILED: 'Failed to fetch rest api integrations.',
    ASYNC_INTEGRATIONS_FETCH_FAILED: 'Failed to fetch async api integrations.'
}

const CERTIFICATION_STATUS_CHIPS = [
    "ready-to-certify", "in-certification", "certified"
]

const INPUT_LABEL = "Reason for Requesting Recertification"
const CERTIFICATION_MANAGEMENT_APP_CANCELLED_TEXT = "You cannot view a cancelled app.";
const CERTIFICATION_MANAGEMENT_APP_ERROR_TEXT = "You cannot view an app in error state.";
const CERTIFICATION_MANAGEMENT_APP_PROVISIONING_TEXT = "You cannot view an app in provisioning state.";
const  SOLUTION_STATUS ={
    CANCELLED:"Cancelled",
    ERROR:"Error",
    PROVISIONING: "Provisioning"
    }
const CERTIFICATION_MANAGEMENT_CDK_ORG_APP_TEXT = "Certification Fee Due Date does not apply to apps in CDK Orgs.";

export {DATE_FORMAT, API_TYPE, CERTIFICATION_STATUS, CERTIFICATION_STATUS_DISPLAY, DIALOG_CONST, TOAST_MSG, ACTION_BUTTONS, ERROR_MSG, CERTIFICATION_STATUS_CHIPS, INPUT_LABEL, CERTIFICATION_MANAGEMENT_APP_CANCELLED_TEXT, SOLUTION_STATUS, CERTIFICATION_MANAGEMENT_CDK_ORG_APP_TEXT, CERTIFICATION_MANAGEMENT_APP_ERROR_TEXT, CERTIFICATION_MANAGEMENT_APP_PROVISIONING_TEXT};


