import RegistrationMetrics from "./RegistrationMetrics";
import { connect } from "react-redux";
import { actions } from "./registrationMetricsActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      registrationsData: state.registrationMetricsReducer.userRegistrations,
      appRegistrationsData: state.registrationMetricsReducer.appRegistrations,
      allTermsAndConditionsVersionsData:
        state.registrationMetricsReducer.allTermsAndConditionsVersions,
      userCountData: state.registrationMetricsReducer.userCount,
      totalUserCountData: state.registrationMetricsReducer.totalUserCount
    }),
    // Inject all actions into the component
    actions
  )(RegistrationMetrics)
);

//export default RegistrationMetrics;
