import React from "react";
// Components
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell
} from "@rmwc/data-table";
import { Button } from "@rmwc/button";
import BarGraph from "./graph/BarGraph";
import { PrimaryButton, IconRefresh } from "cdk-radial";
import { useHistory } from "react-router-dom";
import {
  Headline5,
  Headline6,
  Body1,
  Body2,
  Caption
} from "@fortellis/typography";
import DownloadButton from "./DownloadButton/PanelBodyDownload";
import { Grid, GridRow as Row, GridCell as Cell } from "@rmwc/grid";
import { CircularProgress } from "@rmwc/circular-progress";
import { Card, CardPrimaryAction } from "@rmwc/card";
import { PageHeader, PageContent, PageSection } from "@cdk-prod/fortellis-page";
// Styles
import "./usage-report.scss";

function calculateReportDateRange() {
  const endDate = new Date();
  endDate.setDate(endDate.getDate());
  // Edge case: on the first of the month continue to show previous month report
  const startDay = endDate.getDate() > 1 ? 1 : 0;
  const startDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    startDay
  );
  return [startDate, endDate];
}

function UsageReport({
  loading,
  totalTransactions,
  implementationMetrics,
  onDownloadLastMonthReport,
  onDownloadMonthToDateReport,
  reportLoading,
  reportPending,
  reportEmpty,
  retryReport,
  reportError
}) {
  const history = useHistory();
  const [startDate, endDate] = calculateReportDateRange();
  const dateFormatter = new Intl.DateTimeFormat("en-US", { month: "short" });
  const dateString = `${dateFormatter.format(
    startDate
  )} ${startDate.getDate()} - ${dateFormatter.format(
    endDate
  )} ${endDate.getDate()}, ${endDate.getFullYear()}`;

  return (
    <>
      <Card className="fdn-card__implementation-metrics-header">
        <PageHeader className="usage-report-page-header">
          <PageSection
            title="Usage Report"
            className="page-section__usage-report"
            description={`Report for ${dateString}`}
            action={[
              <DownloadButton
                onDownload={onDownloadLastMonthReport}
                loading={reportLoading}
                isDisabled={reportLoading}
              >
                Last Month's Usage Report
              </DownloadButton>,
              <DownloadButton
                onDownload={onDownloadMonthToDateReport}
                loading={reportLoading}
                isDisabled={reportLoading}
                className="month-to-date-usage-report-button"
              >
                Month to date Usage Report
              </DownloadButton>
            ]}
          />
        </PageHeader>
      </Card>

      {loading && (
        <div className="fdn-metric--empty-space">
          <CircularProgress loading={loading.toString()} />
        </div>
      )}

      {!loading && reportError && (
        <div className="fdn-metric--empty-space">
          <Body1>
            There was an issue generating your report. Please contact Fortellis
            if this issue persists.
          </Body1>
          <Body2>{reportError}</Body2>
          <PrimaryButton
            icon={<IconRefresh />}
            text="Refresh"
            onClick={retryReport}
          />
        </div>
      )}

      {!loading && reportPending && (
        <div className="fdn-metric--empty-space">
          <Body1>
            Your report takes a few minutes to generate. Please reload the page.
          </Body1>
          <Button
            onClick={retryReport}
            className="fdn-metric--refresh"
            icon="refresh"
            raised
          >
            Refresh
          </Button>
          <PrimaryButton
            icon={<IconRefresh />}
            text="Refresh"
            onClick={retryReport}
          />
        </div>
      )}

      {!loading && implementationMetrics && (
        <PageContent className="fdn-metric__content_usage-report">
          <Card className="fdn-card__primary-content-bar-graph">
            {reportEmpty && (
              <div
                className={`fdn-metric__overlay ${
                  implementationMetrics.length ? "" : "empty"
                }`}
              >
                <Headline6>
                  There is no transactional data for {dateString}.
                </Headline6>
                <Body2>
                  Traffic generated for API implementations is displayed here.
                </Body2>
              </div>
            )}
            <Grid>
              <Row>
                <Cell span={2}>
                  <div className="fdn-metric--flex-container">
                    <div className="fdn-metric large">
                      <p className="fdn-metric--header">Total Transactions</p>
                      <p className="fdn-metric--content">
                        {totalTransactions.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </Cell>
                <Cell span={10}>
                  <BarGraph
                    items={implementationMetrics.map(implementation => ({
                      value: implementation.transactions,
                      label: implementation.apiName
                    }))}
                  />
                </Cell>
              </Row>
            </Grid>

            <Caption className="fdn-metric__note">
              Note: API Usage reports are for informational purposes only. API
              Providers are responsible for their own billing reports at this
              time.
            </Caption>
            <br />
          </Card>

          {implementationMetrics && implementationMetrics.length > 0 && (
            <Grid>
              <Row>
                <Cell span={12}>
                  <Headline5 style={{ marginBottom: "16px" }}>
                    Usage Per API Implementation
                  </Headline5>
                </Cell>
              </Row>
              {implementationMetrics.map(implementation => {
                return (
                  <Row>
                    <Cell span={12}>
                      <Card className="fdn-card__implementation-metrics">
                        <CardPrimaryAction className="fdn-card__primary-content">
                          <div className="fdn-split-flex">
                            <Headline6>{implementation.apiName}</Headline6>
                          </div>
                          <div style={{ display: "flex", margin: "16px 0px" }}>
                            <div className="fdn-metric">
                              <p className="fdn-metric--header">
                                Total Transactions
                              </p>
                              <p className="fdn-metric--content">
                                {implementation.transactions
                                  ? implementation.transactions.toLocaleString()
                                  : 0}
                              </p>
                            </div>
                            <div className="fdn-metric--divider" />
                            <div>
                              <p className="fdn-metric--header">
                                Total Payload Size
                              </p>
                              <p className="fdn-metric--content">
                                {implementation.payloadSize
                                  ? implementation.payloadSize
                                  : "0 KB"}
                              </p>
                            </div>
                          </div>
                          {implementation.solutions &&
                            implementation.solutions.length > 0 &&
                            implementation.solutions[0].name !== "async" && (
                              <DataTable className="fdn-table--fullwidth">
                                <DataTableContent className="fdn-table--fullwidth">
                                  <DataTableHead>
                                    <DataTableRow>
                                      <DataTableHeadCell
                                        className="fdn-metric__table-col-1"
                                        align="left"
                                      >
                                        App
                                      </DataTableHeadCell>
                                      <DataTableHeadCell
                                        className="fdn-metric__table-col-2"
                                        align="left"
                                      >
                                        Environment
                                      </DataTableHeadCell>
                                      <DataTableHeadCell
                                        className="fdn-metric__table-col-3"
                                        align="left"
                                      >
                                        App Org Name
                                      </DataTableHeadCell>
                                      {/* <DataTableHeadCell>
                                      Subscriptions
                                    </DataTableHeadCell> */}
                                      <DataTableHeadCell
                                        className="fdn-metric__table-col-4"
                                        align="left"
                                      >
                                        Transactions
                                      </DataTableHeadCell>
                                      <DataTableHeadCell
                                        className="fdn-metric__table-col-5"
                                        align="left"
                                      >
                                        Payload Size
                                      </DataTableHeadCell>
                                    </DataTableRow>
                                  </DataTableHead>
                                  <DataTableBody>
                                    {implementation.solutions.map(sol => (
                                      <DataTableRow key={sol.name}>
                                        <DataTableCell className="fdn-metric__table-col-1">
                                          {sol.name}
                                        </DataTableCell>
                                        <DataTableCell className="fdn-metric__table-col-2">
                                          {sol.environment}
                                        </DataTableCell>
                                        <DataTableCell className="fdn-metric__table-col-3">
                                          {sol.appOrgName}
                                        </DataTableCell>
                                        {/* <DataTableCell>
                                        {sol.subscriptionCount}
                                      </DataTableCell> */}
                                        <DataTableCell>
                                          {sol.transactions.toLocaleString()}
                                        </DataTableCell>
                                        <DataTableCell>
                                          {sol.payloadSize}
                                        </DataTableCell>
                                      </DataTableRow>
                                    ))}
                                  </DataTableBody>
                                </DataTableContent>
                              </DataTable>
                            )}
                        </CardPrimaryAction>
                      </Card>
                    </Cell>
                  </Row>
                );
              })}
            </Grid>
          )}
        </PageContent>
      )}
    </>
  );
}

export default UsageReport;
