import React from "react";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import {
  Card,
  CardText,
  CardHeader,
  CardTitle,
  CardSubtitle
} from "@cdk-uip/react-card";
import { Button } from "@cdk-uip/react-button";
import { FormattedMessage } from "react-intl";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";

class SignedUpUsers extends React.Component {
  state = { isEmailSent: false };

  componentDidMount() {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.fetchSignedUpUsers(accessToken);
  }

  resendEmail = email => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.resendEmailToSignedUpUsers({ email, accessToken });
  };

  render() {
    const {
      list: signedUpUsersList,
      isFetching,
      error = ""
    } = this.props.signedUpUsers;

    const { userEmail, isSending } = this.props.sendEmailToSignedUpUsers;

    if (!isFetching && error) {
      return <div>Unable to load Signed Up Users List.</div>;
    } else if (!signedUpUsersList) {
      return (
        <div className="loading-container">
          <h3>Loading Signed Up Users List...</h3>
          <CircularProgress />
        </div>
      );
    }

    const formattedUserList =
      signedUpUsersList.length === 0
        ? signedUpUsersList
        : signedUpUsersList.map(userObj => {
            const {
              id,
              created,
              status,
              profile: {
                email,
                firstName,
                lastName,
                streetAddress,
                city,
                state,
                zipCode,
                countryCode
              }
            } = userObj;
            return {
              id,
              status,
              email,
              name: `${firstName} ${lastName}`,
              created,
              streetAddress,
              city,
              state,
              zipCode,
              countryCode
            };
          });

    return (
      <React.Fragment>
        <Card className="signed-up-users-page-heading-card">
          <CardHeader>
            <CardTitle large className="signed-up-users-page-title">
              Signed Up Users List
            </CardTitle>
            <CardSubtitle className="signed-up-users-page-subtitle">
              Below is the list of users who have signed up to Fortellis but
              have not verified their email address.
            </CardSubtitle>
          </CardHeader>
        </Card>
        {formattedUserList.map((userObj, index) => {
          return (
            <Card className="c-card-pending-users" key={userObj.id + index}>
              <LayoutGrid nested className={"c-layout-section__home-grid"}>
                <LayoutGridCell
                  span={8}
                  className={
                    "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
                  }
                >
                  <CardHeader>
                    <CardTitle large>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-account_circle" />
                      <FormattedMessage
                        id="Users.username"
                        defaultMessage="{username}"
                        values={{ username: userObj.name }}
                      />
                    </CardTitle>
                    <CardSubtitle>
                      <i className="c-card-pending-users__info-icon cdk-icons cdkicon-email" />
                      <FormattedMessage
                        id="Users.email"
                        defaultMessage="{email}"
                        values={{ email: userObj.email }}
                      />
                    </CardSubtitle>
                    <React.Fragment>
                      <CardSubtitle>
                        <i className="c-card-pending-users__info-icon cdk-icons cdkicon-home" />
                        {userObj.streetAddress}
                      </CardSubtitle>
                      <CardSubtitle className="c-card-pending-users-address">
                        {userObj.city}
                      </CardSubtitle>
                      <CardSubtitle className="c-card-pending-users-address">
                        {userObj.state}
                      </CardSubtitle>
                      <CardSubtitle className="c-card-pending-users-address">
                        {userObj.zipCode}
                      </CardSubtitle>
                      <CardSubtitle className="c-card-pending-users-address">
                        {userObj.countryCode}
                      </CardSubtitle>
                    </React.Fragment>
                  </CardHeader>
                  <CardText>
                    <i className="c-card-pending-users__info-icon cdk-icons cdkicon-keyboard" />
                    {`Created : ${new Date(userObj.created)}`}
                  </CardText>
                </LayoutGridCell>
                <LayoutGridCell>
                  {isSending && userObj.email === userEmail ? (
                    <div className="loading-container">
                      <CircularProgress />
                    </div>
                  ) : (
                    <Button
                      className="resend-email-button resend-email-button-text"
                      outlined
                      onClick={() => {
                        this.resendEmail(userObj.email);
                        this.setState({ isEmailSent: true });
                      }}
                    >
                      RESEND EMAIL
                    </Button>
                  )}
                </LayoutGridCell>
              </LayoutGrid>
            </Card>
          );
        })}
        <div>
          {this.props.sendEmailToSignedUpUsers.message && (
            <TemporarySnackbar
              show={this.state.isEmailSent}
              message={this.props.sendEmailToSignedUpUsers.message}
              timeout={5000}
              actionText="Close"
              onClose={() => this.setState({ isEmailSent: false })}
              onAction={() => this.setState({ isEmailSent: false })}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

SignedUpUsers = WithNavigationToHome(SignedUpUsers);
class SignedUpUsersContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SignedUpUsers {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(SignedUpUsersContainer);
