import React, { useMemo } from "react";
import { findMax, round } from "./utils";
// styles
import "./graph.scss";
// Variables
const MAX_ITEMS = 10;
const DEFAULT_COLORS = [
  "#198ccb",
  "#534698",
  "#5bb99b",
  "#eec55e",
  "#f1613b",
  "#879fc6",
  "#acc387",
  "#c9a54e",
  "#3d679d",
  "#7fb220"
];

function BarGraph({ items, colors = DEFAULT_COLORS, maxItems = MAX_ITEMS }) {
  const max = useMemo(() => {
    return round(findMax(items));
  }, [items]);

  const formattedItems = useMemo(() => {
    const sorted = items.sort((a, b) => b.value - a.value);
    return sorted.slice(0, maxItems).map(item => {
      const height = Math.ceil((item.value / max) * 100);
      return { ...item, height: height > 2 ? height : 2 };
    });
  }, [items, max, maxItems]);

  return (
    <div className="fdn-barchart">
      {/* Guides Y labels */}
      <div className="fdn-barchart--guidebox">
        <div className="fdn-barchart--guidelabel top">
          {max.toLocaleString()}
        </div>
        <div className="fdn-barchart--guidelabel half">
          {(max / 2).toLocaleString()}
        </div>
        <div className="fdn-barchart--guidelabel base">{0}</div>
      </div>
      <div className="fdn-barchart--container">
        {/* Guides */}
        <div className="fdn-barchart--guide top"></div>
        <div className="fdn-barchart--guide half"></div>
        {/* Bars */}
        {formattedItems.map(({ height }, i) => (
          <div
            key={colors[i]}
            className="fdn-barchart--bar"
            style={{ height: `${height}%`, backgroundColor: colors[i] }}
          ></div>
        ))}
      </div>
      <div className="fdn-barchart--key">
        <ul>
          {formattedItems.map(({ label }, i) => (
            <li key={label}>
              <div
                className="fdn-barchart--key-icon"
                style={{ backgroundColor: colors[i] }}
              ></div>
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BarGraph;
