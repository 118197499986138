export default {
  ERROR: "ERROR",
  ERROR_RESET: "ERROR_RESET",
  ALERT_RESET: "ALERT_RESET",
  SOLUTIONS_GET_REQUEST: "SOLUTIONS_GET_REQUEST",
  SOLUTIONS_GET_RECEIVE: "SOLUTIONS_GET_RECEIVE",
  SOLUTIONS_GET_ERROR: "SOLUTIONS_GET_ERROR",
  CREATE_SUB_POST_REQUEST: "CREATE_SUB_POST_REQUEST",
  CREATE_SUB_POST_RECEIVE: "CREATE_SUB_POST_RECEIVE",
  CREATE_SUB_POST_ERROR: "CREATE_SUB_POST_ERROR",
  GET_ENTITY_SUB_REQUEST: "GET_ENTITY_SUB_REQUEST",
  GET_ENTITY_SUB_RECEIVE: "GET_ENTITY_SUB_RECEIVE",
  GET_ENTITY_SUB_ERROR: "GET_ENTITY_SUB_ERROR",
  GET_SUB_SOLUTION_REQUEST: "GET_SUB_SOLUTION_REQUEST",
  GET_SUB_SOLUTION_RECEIVE: "GET_SUB_SOLUTION_RECEIVE",
  GET_SUB_SOLUTION_ERROR: "GET_SUB_SOLUTION_ERROR",
  GET_SUB_CONNECTIONS_REQUEST: "GET_SUB_CONNECTIONS_REQUEST",
  GET_SUB_CONNECTIONS_RECEIVE: "GET_SUB_CONNECTIONS_RECEIVE",
  GET_SUB_CONNECTIONS_ERROR: "GET_SUB_CONNECTIONS_ERROR",
  GET_PROVIDERS_FOR_API_REQUEST: "GET_PROVIDERS_FOR_API_REQUEST",
  GET_PROVIDERS_FOR_API_RECEIVE: "GET_PROVIDERS_FOR_API_RECEIVE",
  GET_PROVIDERS_FOR_API_ERROR: "GET_PROVIDERS_FOR_API_ERROR",
  GET_PROVIDER_BY_ID_REQUEST: "GET_PROVIDER_BY_ID_REQUEST",
  GET_PROVIDER_BY_ID_RECEIVE: "GET_PROVIDER_BY_ID_RECEIVE",
  GET_PROVIDER_BY_ID_ERROR: "GET_PROVIDER_BY_ID_ERROR",
  ACTIVATE_CONNECTION_REQUEST: "ACTIVATE_CONNECTION_REQUEST",
  ACTIVATE_CONNECTION_RECEIVE: "ACTIVATE_CONNECTION_RECEIVE",
  ACTIVATE_CONNECTION_ERROR: "ACTIVATE_CONNECTION_ERROR",
  DISABLE_CONNECTION_REQUEST: "DISABLE_CONNECTION_REQUEST",
  DISABLE_CONNECTION_RECEIVE: "DISABLE_CONNECTION_RECEIVE",
  DISABLE_CONNECTION_ERROR: "DISABLE_CONNECTION_ERROR",
  POST_ENABLE_SUB_REQUEST: "POST_ENABLE_SUB_REQUEST",
  POST_ENABLE_SUB_RECEIVE: "POST_ENABLE_SUB_RECEIVE",
  POST_ENABLE_SUB_ERROR: "POST_ENABLE_SUB_ERROR",
  POST_DISABLE_SUB_REQUEST: "POST_DISABLE_SUB_REQUEST",
  POST_DISABLE_SUB_RECEIVE: "POST_DISABLE_SUB_RECEIVE",
  POST_DISABLE_SUB_ERROR: "POST_DISABLE_SUB_ERROR",
  GET_SUB_BY_ID_REQUEST: "GET_SUB_BY_ID_REQUEST",
  GET_SUB_BY_ID_RECEIVE: "GET_SUB_BY_ID_RECEIVE",
  GET_SUB_BY_ID_ERROR: "GET_SUB_BY_ID_ERROR",
  RESET_SUBSCRIPTIONS: "RESET_SUBSCRIPTIONS",
  GET_ORG_REQUEST: "GET_ORG_REQUEST",
  GET_ORG_RECEIVE: "GET_ORG_RECEIVE",
  GET_ORG_ERROR: "GET_ORG_ERROR",
  GET_ENTITY_ASYNC_ACCESS_GRANTS_REQUEST:
    "GET_ENTITY_ASYNC_ACCESS_GRANTS_REQUEST",
  GET_ENTITY_ASYNC_ACCESS_GRANTS_RECEIVE:
    "GET_ENTITY_ASYNC_ACCESS_GRANTS_RECEIVE",
  GET_ENTITY_ASYNC_ACCESS_GRANTS_ERROR: "GET_ENTITY_ASYNC_ACCESS_GRANTS_ERROR",
  UPSERT_ASYNC_ACCESS_GRANTS_REQUEST: "UPSERT_ASYNC_ACCESS_GRANTS_REQUEST",
  UPSERT_ASYNC_ACCESS_GRANTS_RECEIVE: "UPSERT_ASYNC_ACCESS_GRANTS_RECEIVE",
  UPSERT_ASYNC_ACCESS_GRANTS_ERROR: "UPSERT_ASYNC_ACCESS_GRANTS_ERROR",
  DISABLE_ASYNC_ACCESS_GRANTS_REQUEST: "DISABLE_ASYNC_ACCESS_GRANTS_REQUEST",
  DISABLE_ASYNC_ACCESS_GRANTS_RECEIVE: "DISABLE_ASYNC_ACCESS_GRANTS_RECEIVE",
  DISABLE_ASYNC_ACCESS_GRANTS_ERROR: "DISABLE_ASYNC_ACCESS_GRANTS_ERROR"
};
