import RestoreEntity from "./RestoreEntity";
import { connect } from "react-redux";
import { actions } from "./entityActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      restoreEntity: state.restoreEntity
    }),
    // Inject all actions into the component
    actions
  )(RestoreEntity)
);
