import AssociatedEntities from "../ApprovedUsers/AssociatedEntities";
import { connect } from "react-redux";
import { actions } from "../ApprovedUsers/ApprovedUsersAction";

export default connect(
  state => {
    return {
      associatedEntities: state.approvedUsersReducer.userEntities.list || [],
      associatedEntitiesSpecs: state.approvedUsersReducer.userEntities || {}
    };
  },
  actions
)(AssociatedEntities);
