import React, { useState } from 'react';
import ViewBundles from './ViewBundles';
import ApiBundleCreate from './CreateBundle';
import ShareBundle from './ShareBundle';
import EditBundles from './EditBundle';
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Toast, TOAST_POSITIONS, TOAST_VARIANTS } from 'cdk-radial'

function ApiBundleContainer(props) {


    const [screen,setScreen] = useState("View Bundles")
    const [reservedBundles, setReservedBundles] = useState([]);
    const [bundleShareName, setBundleShareName] = useState('');
    const [bundleShareId, setBundleShareId] = useState('');
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [selectedBundle, setSelectedBundle] = useState({});
    const { auth } = props;

    const removeToast = () => {
        setShowSuccessToast(false)
        setSelectedBundle()
    }

    const renderComponent = () => {
        switch (screen) {
            case "View Bundles":
                return <ViewBundles
                    {...props}
                    setScreen = {setScreen}
                    setReservedBundles={setReservedBundles}
                    setBundleShareName={setBundleShareName}
                    setBundleShareId={setBundleShareId}
                    setSelectedBundle = {setSelectedBundle}
                />
            case "Create Bundles":
                return <ApiBundleCreate
                    setScreen = {setScreen}
                    setShowSuccessToast={setShowSuccessToast}
                    reservedBundles={reservedBundles}
                    auth={auth}
                />
            case "Share Bundles":
                return <ShareBundle
                    setScreen = {setScreen}
                    bundleShareId
                    bundleName={bundleShareName}
                    bundleId={bundleShareId}
                    auth={auth}
                />
            case "Edit Bundles":
                return <EditBundles
                auth={auth}
                setScreen = {setScreen}
                selectedBundle = {selectedBundle}
                reservedBundles={reservedBundles}
                setSelectedBundle={setSelectedBundle}
                setShowSuccessToast = {setShowSuccessToast}
                />

            default:
                return <>Something went wrong,Please Try Again!</>
        }

    }

    return (
        auth?.isAuthenticated ? (
            <div>
                {renderComponent()}
                {!!showSuccessToast &&

                    <Toast actions={[{
                        onClick: removeToast,
                        text: 'Dismiss'
                    }]} 
                    position = {TOAST_POSITIONS.FIXED}
                    variant = {TOAST_VARIANTS.POSITIVE}
                    onVisibilityDurationEnd = {removeToast}
                    content= {selectedBundle?.name ? `Your bundle ${selectedBundle?.name} has been updated.`  : "Your bundle has been created and added to the list."} 
                    id="bundle-create-toast" />}

            </div>

        )
            : <div>

            </div>)
};


export default withAuth(ApiBundleContainer);