export default {
  GET_APPS_REQUEST: "GET_APPS_REQUEST",
  GET_APPS_RECEIVE: "GET_APPS_RECEIVE",
  GET_APPS_ERROR: "GET_APPS_ERROR",
  GET_APP_BY_ID_REQUEST: "GET_APP_BY_ID_REQUEST",
  GET_APP_BY_ID_RECEIVE: "GET_APP_BY_ID_RECEIVE",
  GET_APP_BY_ID_ERROR: "GET_APP_BY_ID_ERROR",
  GET_REST_API_INTEGRATIONS_REQUEST: "GET_REST_API_INTEGRATIONS_REQUEST",
  GET_REST_API_INTEGRATIONS_RECEIVE: "GET_REST_API_INTEGRATIONS_RECEIVE",
  GET_REST_API_INTEGRATIONS_ERROR: "GET_REST_API_INTEGRATIONS_ERROR",
  GET_ASYNC_API_INTEGRATIONS_REQUEST: "GET_ASYNC_API_INTEGRATIONS_REQUEST",
  GET_ASYNC_API_INTEGRATIONS_RECEIVE: "GET_ASYNC_API_INTEGRATIONS_RECEIVE",
  GET_ASYNC_API_INTEGRATIONS_ERROR: "GET_ASYNC_API_INTEGRATIONS_ERROR",
  GET_APP_ISV_DETAILS_REQUEST: "GET_APP_ISV_DETAILS_REQUEST",
  GET_APP_ISV_DETAILS_RECEIVE: "GET_APP_ISV_DETAILS_RECEIVE",
  GET_APP_ISV_DETAILS_ERROR: "GET_APP_ISV_DETAILS_ERROR",
  SEND_EMAIL_REQUEST:"SEND_EMAIL_REQUEST",
  SEND_EMAIL_RECEIVE:"SEND_EMAIL_RECEIVE",
  SEND_EMAIL_ERROR:"SEND_EMAIL_ERROR",
  RESET_ALL: "RESET_ALL",
  UPDATE_REST_API_INTEGRATIONS_REQUEST: "UPDATE_REST_API_INTEGRATIONS_REQUEST",
  UPDATE_REST_API_INTEGRATIONS_RECEIVE: "UPDATE_REST_API_INTEGRATIONS_RECEIVE",
  UPDATE_REST_API_INTEGRATIONS_ERROR: "UPDATE_REST_API_INTEGRATIONS_ERROR",
  UPDATE_ASYNC_API_INTEGRATIONS_REQUEST:
    "UPDATE_ASYNC_API_INTEGRATIONS_REQUEST",
  UPDATE_ASYNC_API_INTEGRATIONS_RECEIVE:
    "UPDATE_ASYNC_API_INTEGRATIONS_RECEIVE",
  UPDATE_ASYNC_API_INTEGRATIONS_ERROR: "UPDATE_ASYNC_API_INTEGRATIONS_ERROR",
  RESET_PATCH_API_DATA: "RESET_PATCH_API_DATA",
  RESET_SEARCH_DATA: "RESET_SEARCH_DATA"
};
