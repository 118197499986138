import React from "react";
import { ButtonIcon } from "@cdk-uip/react-button";
import { LoadingButton } from "../components/LoadingButton";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Select } from "@cdk-uip/react-select";
import { connect } from "react-redux";
import types from "./types";
import {
  Card,
  CardActions,
  CardActionButton,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import {
  AutoExpansionPanel,
  AutoExpansionPanelGroup,
  ExpansionPanelHeader,
  ExpansionPanelContent,
  ExpansionPanelContentActions,
  ExpansionPanelContentBody,
  // ExpansionPanelHeaderDescription,
  ExpansionPanelHeaderTitle,
  ExpansionPanelHeaderSubtitle
} from "@cdk-uip/react-expansion-panel";
import { Icon } from "@cdk-uip/react-icon";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import {
  TextField,
  TextFieldIcon,
  TextFieldHelperText
} from "@cdk-uip/react-text-field";
import { Fluid, FluidItem } from "@cdk-uip/react-fluid";
import { AsyncTypeaheadFilter } from "@fortellis/typeahead";
import config from "../config/app.conf.json";

//import PropTypes from "prop-types";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { Confirm, Modal } from "../components/Confirm";
//import { getErrorMessage } from "../Utils/errorMessageHandler";
import Subscription from "./Subscription";
import {
  SUBSCRIPTION_EVENTS,
  CONNECTION_EVENTS,
  ASYNC_ACCESS_GRANT_EVENTS
} from "./states";
import { FormattedMessage } from "react-intl";
import "../index.css";
import EntitySearch from "../EntityManagement/EntitySearch";
import { TypeaheadSearch } from "../components/TypeaheadSearch";
import APIActivationDeactivationErrorModal from "./APIActivationDeactivationErrorModal";
import { APP_CANCELLED_TEXT, APP_CERTIFICATION_TEXT, LOADING_TEXT, SOLUTION_STATUS } from "../Utils/constants";

const ORG_ID = "orgId";
const SUB_ID = "subId";
const SOLUTION_SEARCH_URL = config.api.solutions_url_v2;
const DEALER_PRICING_TEXT =
  "This app is integrated with a Dealer Pricing plan and the dealer needs to belong to the same dealer group that created the app.";
class SubscriptionMangement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddSubscriptionModal: props.showAddSubscriptionModal,
      selectedSolutionId: "",
      selectedSolutionName: "",
      idFromSearch:
        (props.location && props.location.entity && props.location.entity.id) ||
        "",
      search: {
        options: [],
        input: "",
        results: []
      },
      createSubscriptionLoading: false,
      searchType: ORG_ID,
      renderSubFor: true,
      searchSubID: false,
      searchName: "",
      options: [],
      runBlur: true,
      resultIn: false,
      environment: "prod",
      apiHasDealerPlan: false,
      isAppCertified: false,
      loadingSolutionInfo: false,
      selectedSolutionStatus:""
    };
  }

  static defaultProps = {
    showAddSubscriptionModal: false
  };

  onInputChange = (change, field) => {
    this.setState({
      [field]: change
    });
    if (change === SUB_ID) {
      this.setState({
        renderSubFor: false
      });
    } else {
      this.setState({
        renderSubFor: true
      });
    }
  };

  handleChange = e => {
    this.setState({
      environment: e.target.value
    });
  };

  createSubscription = () => {
    const {
      auth: { accessToken }
    } = this.props;
    this.setState({ createSubscriptionLoading: true }, () => {
      this.props
        .createSubscription({
          accessToken,
          entityId: this.state.idFromSearch,
          solutionId: this.state.selectedSolutionId,
          environment: this.state.environment,
          displayName: this.state.selectedSolutionName || "Not Available"
        })
        .finally(() => {
          this.setState({ createSubscriptionLoading: false });
        });
    });
  };

  getEntitySubscriptions = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (this.state.idFromSearch) {
      this.props.getEntitySubscriptions({
        accessToken,
        entityId: this.state.idFromSearch
      });
      this.getEntityAsyncApiAccessGrants();
    } else {
      this.props.dispatch({
        type: types.ERROR,
        errorMessage: "Please enter a valid Organization Id"
      });
    }
  };

  getEntityAsyncApiAccessGrants = () => {
    const {
      auth: { accessToken }
    } = this.props;
    this.props.getEntityAsyncApiAccessGrants({
      accessToken,
      dataOwnerOrgId: this.state.idFromSearch
    });
  };

  getSubscriptionInfo = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (this.state.idFromSearch) {
      this.setState({ searchSubID: true });
      this.props
        .getSubscriptionInfo({
          accessToken,
          subscriptionId: this.state.idFromSearch
        })
        .finally(() => {
          this.setState({ searchSubID: false });
        });
    } else {
      this.props.dispatch({
        type: types.ERROR,
        errorMessage: "Please enter a valid Subscription ID"
      });
    }
  };

  getSolutionInfoForSubscriptions = () => {
    const {
      auth: { accessToken },
      subscriptionManagement: { solutions }
    } = this.props;
    const subscriptions = this.props.subscriptionManagement.subscriptions;
    if (subscriptions) {
      const promises = Object.values(subscriptions).map(({ solutionId }) => {
        if (!solutions || !solutions[solutionId]) {
          return this.props.getSubSolutionInfo({ accessToken, solutionId });
        }
      });
      const result = Promise.all(promises);
      result
        .then(solns => {
          solns.forEach(sol => {
            if (sol.type === "GET_SUB_SOLUTION_ERROR") {
              this.props.getSubSolutionInfo({
                accessToken,
                solutionId: sol.solutionId
              });
            }
          });
        })
        .catch(e => {
          console.error("ERROR", e);
        });
    }
  };

  susbcriptionManagmentEventHandler = ({
    event = "UNKNOWN_EVENT",
    ...data
  }) => {
    const {
      auth: { accessToken }
    } = this.props;
    switch (event) {
      case SUBSCRIPTION_EVENTS.GET_SUB_CONNECTIONS:
        this.props.getSubConnectionInfo({
          accessToken,
          ...data
        });
        break;
      case SUBSCRIPTION_EVENTS.GET_ORG:
        this.props.getOrg({
          accessToken,
          ...data
        });
        break;
      case SUBSCRIPTION_EVENTS.CREATE_SUB:
        this.props.createSubscription({
          accessToken,
          ...data
        });
        break;
      case CONNECTION_EVENTS.GET_PROVIDERS_FOR_API:
        this.props.getprovidersForAPIProduct({
          accessToken,
          ...data
        });
        break;
      case CONNECTION_EVENTS.GET_PROVIDER_BY_ID:
        this.props.getProviderById({
          accessToken,
          ...data
        });
        break;
      case CONNECTION_EVENTS.ACTIVATE:
        this.props.activateConnectionWithProvider({
          accessToken,
          ...data
        });
        break;
      case CONNECTION_EVENTS.DEACTIVATE:
        this.props.disableConnection({
          accessToken,
          ...data
        });
        break;
      case SUBSCRIPTION_EVENTS.SUB_ACTIVATE:
        this.props.enableSubscription({
          accessToken,
          ...data
        });
        break;
      case SUBSCRIPTION_EVENTS.SUB_DEACTIVATE:
        this.props.disableSubscription({
          accessToken,
          ...data
        });
        break;
      case ASYNC_ACCESS_GRANT_EVENTS.ACTIVATE:
        this.props.upsertAsyncAccessGrants({
          accessToken,
          ...data
        });
        break;
      case ASYNC_ACCESS_GRANT_EVENTS.DEACTIVATE:
        this.props.disableAsyncAccessGrants({
          accessToken,
          ...data
        });
        break;
      case types.ERROR:
        this.props.dispatch({
          type: types.ERROR,
          errorMessage: data.errorMessage
        });
        break;
      default:
        console.error({
          message: "UNKNOWN EVENT in Subscription Management",
          entityId: this.state.entity.id,
          type: "error"
        });
        break;
    }
  };

  onErrorModalClose = () => {
    this.props.dispatch({
      type: types.ERROR_RESET
    });
  };

  onAlertModalClose = () => {
    this.setState({
      selectedSolutionId: "",
      selectedSolutionName: ""
    });
    this.props.dispatch({
      type: types.ALERT_RESET
    });
  };

  getSolutionInfo = async solutionId => {
    const {
      auth: { accessToken }
    } = this.props;
    this.setState({ loadingSolutionInfo: true });
    const res = await this.props.getSubSolutionInfo({
      accessToken,
      solutionId
    });
    if (res.type === "GET_SUB_SOLUTION_ERROR")
      console.error("ERROR IN FETCHING SOLUTION DETAILS", res.apierror);
    else if (res.response) {
      this.setState({ loadingSolutionInfo: false });
      this.checkDealerPricingPlan(res.response);
      this.checkAppCertificationStatus(res.response);
    }
  };

  checkDealerPricingPlan = solutionInfo => {
    let dealerPlanFlag = false;
    if (solutionInfo) {
      dealerPlanFlag = solutionInfo?.integrations?.some(integration => {
        return integration?.providers?.some(provider => {
          return provider?.pricingPlan?.dealerPriceChecked;
        });
      });
    }
    if (dealerPlanFlag)
      this.setState({
        apiHasDealerPlan: true
      });
    else
      this.setState({
        apiHasDealerPlan: false
      });
  };

  checkAppCertificationStatus = solutionInfo => {
    let appCertificationStatus = false;
    // certification status
    if(solutionInfo) {
      appCertificationStatus = solutionInfo?.certificationStatus && solutionInfo?.certificationStatus === 'certified';
    }
    if(appCertificationStatus) {
      this.setState({
        isAppCertified: false
      });
    } else {
      this.setState({
        isAppCertified: true
      });
    } 
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.subscriptionManagement.loadedEntityId !==
        this.props.subscriptionManagement.loadedEntityId &&
      this.props.subscriptionManagement.loadedEntityId
    ) {
      // subscriptions downloaded, kick off solution download
      this.getSolutionInfoForSubscriptions();
    }

    if (prevState.renderSubFor !== this.state.renderSubFor) {
      this.props.resetSubscriptions();
    }

    if (prevState.search.search !== this.state.search.search) {
      this.props.handleSearch();
    }

    if (
      this.state.searchName &&
      prevState.selectedSolutionName !== this.state.selectedSolutionName &&
      this.state.searchName === this.state.selectedSolutionName
    ) {
      const solutionInfo = this.props.subscriptionManagement.solutions[
        this.state.selectedSolutionId
      ];
      if (solutionInfo) {
        this.checkDealerPricingPlan(solutionInfo);
        this.checkAppCertificationStatus(solutionInfo);
      } else {
        this.getSolutionInfo(this.state.selectedSolutionId);
      }
    }
  }

  handleSearch = (input, results) => {
    this.setState(prevState => {
      return {
        ...prevState,
        search: {
          ...prevState.search,
          input,
          results
        }
      };
    });
  };

  render() {
    const {
      subscriptionManagement: {
        subscriptions,
        apiProducts,
        providers,
        solutions,
        asyncAccessGrants,
        orgs,
        showErrorModal,
        errorMessage,
        showAlertModal,
        alertMessage,
        apiActivationDeactivation
      }
    } = this.props;
    const {
      auth: { accessToken }
    } = this.props;

    const { search } = this.state;

    return (
      <React.Fragment>
        {/* #START GRID */}
        <LayoutGrid
          className={
            "s-content-route--subscription-management c-layout-section__dash-grid"
          }
        >
          {/* #START GRID CELL */}
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            {/* #START DASH MODULES */}
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <CardHeader>
                  <CardTitle large>
                    <Icon>settings</Icon> Subscription Management
                  </CardTitle>
                  <CardSubtitle>
                    From here, you can perform these actions:
                  </CardSubtitle>
                  <CardSubtitle>
                    #1) Find Fortellis Organizations by organization name or
                    email domain. #2) Search for subscriptions by Subscription
                    ID or Organization ID. #3) Create new subscriptions to an
                    application, or disable/modify existing subscriptions.
                  </CardSubtitle>
                </CardHeader>
                <CardText>
                  {/* #START CHART DATA */}

                  {/* #END CHART DATA */}
                </CardText>
              </Card>
            </Elevation>
            {/* #END DASH MODULES*/}
          </LayoutGridCell>
          {/* #END GRID CELL */}
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <EntitySearch />
          </LayoutGridCell>
          {/* #START GRID CELL */}
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            {/* #START DASH MODULES */}
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--step1"
                }
              >
                <CardHeader>
                  <CardTitle large>Search by ID:</CardTitle>
                  {this.state.renderSubFor ? (
                    <CardSubtitle>
                      Type or copy/paste an Organization ID to manage it's
                      Subscriptions & Connections.
                    </CardSubtitle>
                  ) : (
                    <CardSubtitle>
                      Type or copy/paste an Subscription ID to manage that
                      Subscription.
                    </CardSubtitle>
                  )}
                </CardHeader>
                <CardText>
                  {/* #START CHART DATA */}
                  {/* #START: Entity ID */}
                  <Fluid valign="middle">
                    <FluidItem fill>
                      <TextField
                        className="c-search-input-subman-field"
                        // HTML ATTRS PASSED THRU
                        autoFocus={"true"}
                        id={"idFromSearch"}
                        name={"idFromSearch"}
                        autoComplete={"off"}
                        autoCorrect={"off"}
                        spellCheck={"false"}
                        // COMPONENT PROPS
                        required
                        fullWidth
                        outlined
                        prefix="#"
                        // #TODO: reg pattern?
                        // pattern=""
                        type="string"
                        inputMode="text"
                        label="Search by ID"
                        value={this.state.idFromSearch}
                        onChange={(e) =>
                          this.setState({ idFromSearch: e.target.value.replaceAll(/\s/g,'') })
                        }
                        trailingIcon={
                          <TextFieldIcon
                            title={"Clear Current Value"}
                            onClick={() => this.setState({ idFromSearch: "" })}
                          >
                            clear
                          </TextFieldIcon>
                        }
                        helperText={
                          <TextFieldHelperText persistent>
                            HELP TEXT ABOUT: reg pattern, Organization IDs, Web
                            IDs, or Store IDs...?
                          </TextFieldHelperText>
                        }
                      />
                    </FluidItem>
                    <FluidItem>
                      <Select
                        label="Search By:"
                        className="c-search-input-subman-type"
                        value={this.state.searchType}
                        onChange={(e) =>
                          this.onInputChange(e.target.value, "searchType")
                        }
                        allowEmpty="false"
                        required
                      >
                        <option value="orgId">Organization ID</option>
                        <option value="subId">Subscription ID</option>
                      </Select>
                    </FluidItem>
                  </Fluid>
                  {/* #END CHART DATA */}
                </CardText>

                {this.state.renderSubFor ? (
                  <CardActions fullBleed>
                    <CardActionButton onClick={this.getEntitySubscriptions}>
                      <Icon>arrow_downward</Icon>
                      List Subscriptions
                      <Icon>arrow_downward</Icon>
                    </CardActionButton>
                  </CardActions>
                ) : (
                  <LoadingButton
                    raised
                    primary
                    disabled={!this.state.idFromSearch}
                    onClick={this.getSubscriptionInfo}
                    isLoading={this.state.searchSubID}
                    loadingValue="Searching..."
                  >
                    <ButtonIcon>arrow_forward</ButtonIcon>
                    Search By Subscription ID
                  </LoadingButton>
                )}
              </Card>
            </Elevation>
            {/* #END DASH MODULES*/}
          </LayoutGridCell>
          {/* #END GRID CELL */}
          {/* #START GRID CELL */}
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            {/* #START DASH MODULES */}
            {/* ANCHOR NAME */}
            <a
              name="CARD_ENTITY_SUBSCRIPTIONS"
              className={"visibility--hidden"}
            >
              .
            </a>
            {/* #END ANCHOR NAME */}
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--step2"
                }
              >
                <CardHeader>
                  {this.state.renderSubFor ? (
                    <CardTitle large>Subscriptions For:</CardTitle>
                  ) : (
                    <CardTitle large>Subscription Info For:</CardTitle>
                  )}
                  <div className="filter-searchbar-left">
                    <TypeaheadSearch
                      placeholder={"App name"}
                      search={search.input}
                      onUpdate={this.handleSearch}
                      searchOptions={this.state.options}
                    />
                  </div>
                  <CardSubtitle>
                    NAME: {this.state.legalEntityName}
                  </CardSubtitle>
                  <CardSubtitle>ID: {this.state.idFromSearch}</CardSubtitle>
                </CardHeader>
                <CardText />
                <CardText>
                  {/* #START CHART DATA */}

                  {/* #START CREATE SUBSCRIPTION LAYOUT */}
                  {this.state.renderSubFor ? (
                    <AutoExpansionPanelGroup>
                      <AutoExpansionPanel
                        className={"c-form-subscriptions__create-panel"}
                      >
                        <ExpansionPanelHeader>
                          <Icon className={"c-icon__subscription-listitem"}>
                            add_circle
                          </Icon>
                          <ExpansionPanelHeaderTitle>
                            Add a New Subscription:
                          </ExpansionPanelHeaderTitle>
                          <ExpansionPanelHeaderSubtitle>
                            Select an App and enter a valid Organization Id to
                            add new Subscription:
                          </ExpansionPanelHeaderSubtitle>
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent expandedHeight={300}>
                          <ExpansionPanelContentBody>
                            <div className="sol-search-container">
                              <div
                                className="sub-search-container"
                                width={800}
                                onBlur={
                                  this.state.runBlur
                                    ? () =>
                                        this.setState({
                                          searchName: "",
                                          options: [],
                                          apiHasDealerPlan: false,
                                          isAppCertified: false,
                                          loadingSolutionInfo: false,
                                        })
                                    : () =>
                                        this.setState({
                                          options: [],
                                          runBlur: true,
                                        })
                                }
                              >
                                <Select
                                  label="Select Environment"
                                  className="subscription-management-dropdown"
                                  value={this.state.environment}
                                  onChange={this.handleChange}
                                  allowEmpty="false"
                                  required
                                >
                                  <option value="prod">Production</option>
                                  <option value="test">Test</option>
                                </Select>
                                <AsyncTypeaheadFilter
                                  label="Search Solutions"
                                  style={{ width: 600 }}
                                  value={this.state.searchName}
                                  onChange={value => {
                                    this.setState({
                                      selectedSolutionName: "",
                                      searchName: value,
                                      resultIn: false
                                    });
                                    if (value === "") {
                                      this.setState({
                                        apiHasDealerPlan: false,
                                        isAppCertified: false,
                                        loadingSolutionInfo: false
                                      });
                                      this.onInputChange([], "options");
                                    }
                                  }}
                                  url={SOLUTION_SEARCH_URL}
                                  queryParam="q"
                                  requestOptions={{
                                    headers: {
                                      Authorization: `Bearer ${accessToken}`
                                    }
                                  }}
                                  onAsyncResponse={res => {
                                    this.onInputChange(
                                      res.data.solutions,
                                      "options"
                                    );
                                    this.onInputChange(true, "resultIn");
                                  }}
                                  onAsyncError={err => console.log(err)}
                                />

                                {this.state.options.length ? (
                                  this.state.options && (
                                    <div className="s-result" width={600}>
                                      {this.state.options.map(sol => (
                                        <div
                                          className="link-item"
                                          onMouseDown={() =>
                                            this.setState({
                                              selectedSolutionId: sol.id,
                                              selectedSolutionName:
                                                sol.displayName,
                                              searchName: sol.displayName,
                                              selectedSolutionStatus: sol.status,
                                              options: [],
                                              runBlur: false
                                            })
                                          }
                                        >
                                          <div className="link-description">
                                            {sol.mpAppName
                                              ? `${sol.displayName} (${sol.mpAppName}) : ${sol.id}`
                                              : `${sol.displayName} : ${sol.id}`}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                ) : this.state.searchName !== "" &&
                                  this.state.selectedSolutionName === "" &&
                                  this.state.resultIn ? (
                                  <div className="s-result" width={600}>
                                    <div
                                      className="link-item"
                                      onMouseDown={() =>
                                        this.setState({
                                          runBlur: false
                                        })
                                      }
                                    >
                                      <div className="link-description">
                                        No Results Found
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="input-helper-text">
                                {this.state.searchName ===
                                  this.state.selectedSolutionName &&
                                  (this.state.loadingSolutionInfo ? (
                                    <div className="small-loading-container">
                                      <CircularProgress />
                                      <div>{LOADING_TEXT}</div>
                                    </div>
                                  ) : (
                                    <>
                                      {this.state.apiHasDealerPlan &&
                                      <div style={{ display: "flex" }}>
                                        <Icon
                                          className={
                                            "c-icon__subscription-listitem"
                                          }
                                        >
                                          info
                                        </Icon>
                                        {DEALER_PRICING_TEXT}
                                      </div>}
                                      {this.state.selectedSolutionStatus === SOLUTION_STATUS.CANCELLED? <div style={{ display: "flex" , color: 'black', alignItems: 'center'}}>
                                        <Icon
                                          className={
                                            "c-icon__subscription-certification-info-icon"
                                          }
                                        >
                                          info
                                        </Icon>
                                        {APP_CANCELLED_TEXT}
                                      </div>:this.state.isAppCertified && 
                                      <div style={{ display: "flex" , color: 'black', alignItems: 'center'}}>
                                        <Icon
                                          className={
                                            "c-icon__subscription-certification-info-icon"
                                          }
                                        >
                                          info
                                        </Icon>
                                        {APP_CERTIFICATION_TEXT}
                                      </div>}
                                    </>
                                  ))}
                              </div>
                            </div>
                          </ExpansionPanelContentBody>
                          <ExpansionPanelContentActions>
                          <div className="mouse-hover">
                            <LoadingButton
                              raised
                              primary
                              dense
                              disabled={
                                !(
                                  this.state.idFromSearch &&
                                  this.state.selectedSolutionId &&
                                  this.state.searchName &&
                                  this.state.searchName ===
                                    this.state.selectedSolutionName &&
                                  !this.state.loadingSolutionInfo &&
                                  this.state.selectedSolutionStatus &&
                                  this.state.selectedSolutionStatus !== SOLUTION_STATUS.CANCELLED
                                ) 
                              }
                              onClick={this.createSubscription}
                              isLoading={this.state.createSubscriptionLoading}
                              loadingValue={
                                <FormattedMessage
                                  id="SubscriptionManagement.addSubscriptionLoading"
                                  defaultMessage="Adding subscription..."
                                />
                              }
                            >
                              <ButtonIcon>add_circle</ButtonIcon>
                              Add Subscription
                            </LoadingButton>
                          </div>
                          </ExpansionPanelContentActions>
                        </ExpansionPanelContent>
                      </AutoExpansionPanel>
                    </AutoExpansionPanelGroup>
                  ) : null}
                  {/* #END CREATE SUBSCRIPTION LAYOUT */}

                  {/* Subscription Results "Grid" or "Expansion Panel" HERE. */}
                  {/*http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react/#expansionpanel*/}
                  {/* #START SOLUTION LIST ITEM COMPONENT UI LAYOUT */}

                  {subscriptions
                    ? Object.keys(subscriptions).map(subId => {
                        const solutionInfo =
                          solutions[subscriptions[subId].solutionId];
                        const solutionName = subscriptions[subId].solutionName;
                        if (solutionInfo) {
                          if (
                            !solutionInfo.error &&
                            solutionName &&
                            solutionName
                              .toLowerCase()
                              .startsWith(search.input.toLowerCase())
                          ) {
                            return (
                              <Subscription
                                key={subId}
                                {...subscriptions[subId]}
                                onEvent={this.susbcriptionManagmentEventHandler}
                                displayName={solutionInfo.displayName}
                                solutionInfo={solutionInfo}
                                apiProducts={apiProducts}
                                providers={providers}
                                orgs={orgs}
                                asyncAccessGrants={asyncAccessGrants}
                                idFromSearch={this.state.idFromSearch}
                              />
                            );
                          } else {
                            return null;
                          }
                        } else {
                          return null;
                        }
                      })
                    : null}

                  {/* #END SOLUTION LIST ITEM COMPONENT UI LAYOUT */}

                  {/* #END CHART DATA */}
                </CardText>
                <CardActions>
                  {/* #TODO: function to anchor to #top of this card */}
                  <CardActionButton
                    href="#CARD_ENTITY_SUBSCRIPTIONS"
                    element={"a"}
                  >
                    Back to Top<Icon>arrow_upward</Icon>
                  </CardActionButton>
                </CardActions>
              </Card>
            </Elevation>
            {/* #END DASH MODULES*/}
          </LayoutGridCell>
          {/* #END GRID CELL */}
        </LayoutGrid>
        {/* #END GRID */}

        {/* #START ERRORMODAL */}
        {
          <Confirm
          // open={!!confirm}
          // onAccept={this.onAccept}
          // onCancel={this.onCancel}
          // message={confirm ? confirm.message() : false}
          // type={confirm.type}
          />
        }
        <Modal
          open={showAlertModal}
          onClose={this.onAlertModalClose}
          message={alertMessage}
        />
        <Modal
          open={showErrorModal}
          onClose={this.onErrorModalClose}
          message={errorMessage}
        />
        <APIActivationDeactivationErrorModal
          open={!!apiActivationDeactivation?.error}
          onClose={this.onErrorModalClose}
          error={apiActivationDeactivation?.error}
          inProgress={!!apiActivationDeactivation?.inProgress}
          operation={apiActivationDeactivation?.operation}
        />
        {/* #END ERRORMODAL */}
      </React.Fragment>
    );
  }
}

SubscriptionMangement = connect()(WithNavigationToHome(SubscriptionMangement));
class SubscriptionMangementContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SubscriptionMangement {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(SubscriptionMangementContainer);
