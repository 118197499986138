export default {
  REQUEST_PENDING_ENTITIES: "REQUEST_PENDING_ENTITIES",
  RECEIVE_PENDING_ENTITIES: "RECEIVE_PENDING_ENTITIES",
  ERROR_PENDING_ENTITIES: "ERROR_PENDING_ENTITIES",

  REQUEST_MORE_PENDING_ENTITIES: "REQUEST_MORE_PENDING_ENTITIES",
  RECEIVE_MORE_PENDING_ENTITIES: "RECEIVE_MORE_PENDING_ENTITIES",
  ERROR_MORE_PENDING_ENTITIES: "ERROR_MORE_PENDING_ENTITIES",

  ENTITY_VERIFY_PUT_REQUEST: "ENTITY_VERIFY_PUT_REQUEST",
  ENTITY_VERIFY_PUT_RECEIVE: "ENTITY_VERIFY_PUT_RECEIVE",
  ENTITY_VERIFY_PUT_ERROR: "ENTITY_VERIFY_PUT_ERROR",

  DISMISS_ERROR: "DISMISS_ERROR"
};
