import types from "./types";

const apiSubmissionsInitialState = {
  apis: [],
  fetchingApis: false,
  provider: "",
  fetchingProvider: false,
  ratePlans: null,
  fetchingRatePlans: false,
  errorMessage: "",
  response: "",
  reviewResponse: false,
  awaitingResponse: false,
  reviewError: false,
  approveError: false,
  rejectError: false,
  notesError: false,
  reviewInitiated: false,
  userDetails: false
};
const apiSubmissions = (state = apiSubmissionsInitialState, action) => {
  //fails w/ unexpected end of json input on 200, needs action.apierror.message
  // 422 requires: action.apierror.payload.error
  const getErrorMessage = apierror => {
    if (apierror.hasOwnProperty("payload")) {
      if (apierror.payload.error) {
        return apierror.payload.error;
      } else if (typeof apierror.payload === "string") {
        return apierror.payload;
      } else if (apierror.payload.message) {
        return apierror.payload.message;
      }
    } else if (apierror.hasOwnProperty("message")) {
      return apierror.message;
    } else {
      return "Error";
    }
  };

  let providerName;
  switch (action.type) {
    case types.API_GET_REQUEST:
      return {
        ...state,
        fetchingApis: true,
        errorMessage: ""
      };
    case types.API_GET_RECEIVE:
      return {
        ...state,
        apis: action.response,
        fetchingApis: false
      };
    case types.API_GET_ERROR:
      return {
        ...state,
        fetchingApis: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    //Provider request
    case types.PROVIDER_GET_REQUEST:
      return {
        ...state,
        fetchingProvider: true,
        errorMessage: ""
      };
    case types.PROVIDER_GET_RECEIVE:
      return {
        ...state,
        provider: action.response,
        fetchingProvider: false,
        providerReceived: true
      };
    case types.PROVIDER_GET_ERROR:
      return {
        ...state,
        fetchingProvider: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    //update notes
    case types.UPDATE_NOTES_PUT_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        notesError: false,
        errorMessage: ""
      };
    case types.UPDATE_NOTES_PUT_RECEIVE:
      return {
        ...state,
        response: { message: "notes updated" },
        awaitingResponse: false
      };
    case types.UPDATE_NOTES_PUT_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        notesError: true
      };
    // update pricing url
    case types.PRICING_URL_UPDATE_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        pricingUrlError: false,
        errorMessage: ""
      };
    case types.PRICING_URL_UPDATE_RECEIVE:
      providerName = action && action.response && action.response.productName;
      return {
        ...state,
        response: { message: `Pricing URL updated for: ${providerName}` },
        awaitingResponse: false,
        pricingUrlError: false
      };
    case types.PRICING_URL_UPDATE_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        pricingUrlError: true,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    // update catalog number
    case types.CATALOG_NUMBER_UPDATE_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        catalogNumberError: false,
        errorMessage: ""
      };
    case types.CATALOG_NUMBER_UPDATE_RECEIVE:
      providerName = action && action.response && action.response.productName;
      return {
        ...state,
        response: { message: `Catalog number updated for: ${providerName}` },
        awaitingResponse: false,
        catalogNumberError: false
      };
    case types.CATALOG_NUMBER_UPDATE_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        catalogNumberError: true,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    // pricing doc request
    case types.PROVIDER_FILE_GET_REQUEST:
      return {
        ...state,
        fetchingRatePlans: true,
        errorMessage: ""
      };
    case types.PROVIDER_FILE_GET_RECEIVE:
      return {
        ...state,
        ratePlans: action.response,
        fetchingRatePlans: false
      };
    case types.PROVIDER_FILE_GET_ERROR:
      return {
        ...state,
        fetchingRatePlans: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    //review provider
    case types.REVIEW_PROVIDER_PUT_REQUEST:
      return {
        ...state,
        reviewError: false,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.REVIEW_PROVIDER_PUT_RECEIVE:
      return {
        ...state,
        reviewResponse: action.response,
        awaitingResponse: false,
        reviewInitiated: true
      };
    case types.REVIEW_PROVIDER_PUT_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        reviewError: true
      };
    // Approve Provider
    case types.APPROVE_PROVIDER_PUT_REQUEST:
      return {
        ...state,
        approveError: false,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.APPROVE_PROVIDER_PUT_RECEIVE:
      return {
        ...state,
        response: { message: "Provider Api is Approved" },
        awaitingResponse: false
      };
    case types.APPROVE_PROVIDER_PUT_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        approveError: true
      };
    //reject provider
    case types.REJECT_PROVIDER_PUT_REQUEST:
      return {
        ...state,
        rejectError: false,
        awaitingResponse: true,
        errorMessage: ""
      };
    case types.REJECT_PROVIDER_PUT_RECEIVE:
      return {
        ...state,
        response: { message: "Provider Api is Rejected" },
        awaitingResponse: false
      };
    case types.REJECT_PROVIDER_PUT_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        },
        rejectError: true
      };
    case types.USER_DETAILS_GET_REQUEST:
      return {
        ...state,
        errorMessage: ""
      };
    case types.USER_DETAILS_GET_RECEIVE:
      return {
        ...state,
        userDetails: action.response
      };
    case types.USER_DETAILS_GET_ERROR:
      return {
        ...state,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    default:
      return state;
  }
};

export default apiSubmissions;
