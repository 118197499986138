import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";
import types from "./types";
import { stringify } from "query-string";

export const actions = {
  getCouponAssociations: ({ accessToken }) => ({
    [CALL_API]: {
      url: `${config.api.payment_service_url}/admin/coupons/associations`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.GET_COUPON_ASSOCIATIONS_REQUEST,
        types.GET_COUPON_ASSOCIATIONS_RECEIVE,
        types.GET_COUPON_ASSOCIATIONS_ERROR
      ]
    }
  }),
  associateCoupon: ({ accessToken, appId, couponId }) => ({
    [CALL_API]: {
      url: `${config.api.payment_service_url}/admin/coupon/association?${stringify(
        {appId, couponId}
      )}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ASSOCIATE_COUPON_REQUEST,
        types.ASSOCIATE_COUPON_RECEIVE,
        types.ASSOCIATE_COUPON_ERROR
      ]
    }
  }),
  dissociateCoupon: ({ accessToken, appId, couponId }) => ({
    [CALL_API]: {
      url: `${config.api.payment_service_url}/admin/coupon/dissociate?${stringify(
        {appId, couponId}
      )}`,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      query: {
        appId,
        couponId
      },
      types: [
        types.DISSOCIATE_COUPON_REQUEST,
        types.DISSOCIATE_COUPON_RECEIVE,
        types.DISSOCIATE_COUPON_ERROR
      ]
    }
  }),
  getCoupons: ({ accessToken }) => ({
    [CALL_API]: {
      url: `${config.api.payment_service_url}/admin/coupons`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.GET_COUPONS_REQUEST,
        types.GET_COUPONS_RECEIVE,
        types.GET_COUPONS_ERROR
      ]
    }
  }),
  resetAssociationState: () => ({ type: types.RESET_ASSOCIATION_STATE }),
}