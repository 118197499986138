import types from "./types";
import config from "../config/app.conf.json";
import axios from "axios";

export const actions = {
  getMonetizationReportsByDateRange: ({ accessToken, startDate, endDate }) => {
    return dispatch => {
      dispatch({
        type: types.GET_MONETIZATION_REPORT_REQUEST
      });
      axios
        .get(
          `${config.api.payment_service_url}/transcation/csv?startDate='${startDate}'&&endDate='${endDate}'`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer ".concat(accessToken)
            }
          }
        )
        .then(res => {
          dispatch(
            getResult(types.GET_MONETIZATION_REPORT_RECEIVE, { ...res.data })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(
              getResult(types.GET_MONETIZATION_REPORT_ERROR, error.response)
            );
          } else {
            dispatch(getResult(types.GET_MONETIZATION_REPORT_ERROR, {}));
          }
        });
    };
  },
  getMonetizationReportsByAppName: ({ accessToken, appName }) => {
    return dispatch => {
      dispatch({
        type: types.GET_MONETIZATION_REPORT_REQUEST
      });
      axios
        .get(
          `${config.api.payment_service_url}/transcation/csv?appName='${appName}'`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer ".concat(accessToken)
            }
          }
        )
        .then(res => {
          dispatch(
            getResult(types.GET_MONETIZATION_REPORT_RECEIVE, { ...res.data })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(
              getResult(types.GET_MONETIZATION_REPORT_ERROR, error.response)
            );
          } else {
            dispatch(getResult(types.GET_MONETIZATION_REPORT_ERROR, {}));
          }
        });
    };
  }
};
const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};
