import { CALL_API } from "@cdkglobal/react-core-reduxapi";

// Variables
import config from "../config/app.conf.json";

const API_USAGE_URL = `${config.api.billing_url}/report`;
export const INITIAL_STATE = {
  apiUsage: {
    loading: false,
    data: {},
    error: undefined
  }
};

export const TYPES = {
  // Billing types
  GET_API_USAGE_REQ: "GET_API_USAGE_REQ",
  GET_API_USAGE_RES: "GET_API_USAGE_RES",
  GET_API_USAGE_ERR: "GET_API_USAGE_ERR",
  GET_API_USAGE_BY_MONTH_REQ: "GET_API_USAGE_BY_MONTH_REQ",
  GET_API_USAGE_BY_MONTH_RES: "GET_API_USAGE_BY_MONTH_RES",
  GET_API_USAGE_BY_MONTH_ERR: "GET_API_USAGE_BY_MONTH_ERR"
};

export const ACTIONS = {
  getApiUsage: function({ accessToken }) {
    return {
      [CALL_API]: {
        method: "GET",
        url: API_USAGE_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          TYPES.GET_API_USAGE_REQ,
          TYPES.GET_API_USAGE_RES,
          TYPES.GET_API_USAGE_ERR
        ]
      }
    };
  },
  getApiUsageByMonth: function({ accessToken, date, updateMenu = false }) {
    return {
      [CALL_API]: {
        method: "GET",
        url: `${API_USAGE_URL}/${date}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          { type: TYPES.GET_API_USAGE_BY_MONTH_REQ, date, updateMenu },
          { type: TYPES.GET_API_USAGE_BY_MONTH_RES, date, updateMenu },
          { type: TYPES.GET_API_USAGE_BY_MONTH_ERR, date, updateMenu }
        ]
      }
    };
  }
};

function stateUpdater(state, obj, update) {
  return {
    ...state,
    [obj]: {
      ...state[obj],
      ...update
    }
  };
}

export function apiUsageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get api usage
    case TYPES.GET_API_USAGE_REQ:
      return stateUpdater(state, "apiUsage", { loading: true });
    case TYPES.GET_API_USAGE_RES:
      return stateUpdater(state, "apiUsage", {
        loading: true,
        data: { type: "create", value: action.response },
        error: undefined
      });
    case TYPES.GET_API_USAGE_ERR:
      return stateUpdater(state, "apiUsage", {
        loading: false,
        data: action.apierror.payload,
        error: action.apierror.message
      });
    // Get api usage by month
    case TYPES.GET_API_USAGE_BY_MONTH_REQ:
      return stateUpdater(state, "apiUsage", { loading: !action.updateMenu });
    case TYPES.GET_API_USAGE_BY_MONTH_RES:
      let type = action.updateMenu ? "updateMenu" : "getByMonth";
      return stateUpdater(state, "apiUsage", {
        loading: false,
        data: { type, date: action.date, value: action.response },
        error: undefined
      });
    case TYPES.GET_API_USAGE_BY_MONTH_ERR:
      return stateUpdater(state, "apiUsage", {
        loading: false,
        data: action.apierror.payload,
        error: action.apierror.message
      });
    default:
      return state;
  }
}
