export default {
  SEARCH_SOLUTIONS_REQUEST: 'GET_SOLUTIONS_REQUEST',
  SEARCH_SOLUTIONS_RECEIVE: 'GET_SOLUTIONS_RECEIVE',
  SEARCH_SOLUTIONS_ERROR: 'GET_SOLUTIONS_ERROR',
  GET_COUPON_ASSOCIATIONS_REQUEST: 'GET_COUPON_ASSOCIATIONS_REQUEST',
  GET_COUPON_ASSOCIATIONS_RECEIVE: 'GET_COUPON_ASSOCIATIONS_RECEIVE',
  GET_COUPON_ASSOCIATIONS_ERROR: 'GET_COUPON_ASSOCIATIONS_ERROR',
  ASSOCIATE_COUPON_REQUEST: 'ASSOCIATE_COUPON_REQUEST',
  ASSOCIATE_COUPON_RECEIVE: 'ASSOCIATE_COUPON_RECEIVE',
  ASSOCIATE_COUPON_ERROR: 'ASSOCIATE_COUPON_ERROR',
  RESET_ASSOCIATION_STATE: 'RESET_ASSOCIATION_STATE',
  DISSOCIATE_COUPON_REQUEST: 'DISSOCIATE_COUPON_REQUEST',
  DISSOCIATE_COUPON_RECEIVE: 'DISSOCIATE_COUPON_RECEIVE',
  DISSOCIATE_COUPON_ERROR: 'DISSOCIATE_COUPON_ERROR',
  GET_COUPONS_REQUEST: 'GET_COUPONS_REQUEST',
  GET_COUPONS_RECEIVE: 'GET_COUPONS_RECEIVE',
  GET_COUPONS_ERROR: 'GET_COUPONS_ERROR'
}