import types from "./types";

const INITIAL_STATE = {
  monetizationReport: "",
  reportLoading: false,
  error: "",
  showErrorModal: false
};
const monetizationReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MONETIZATION_REPORT_REQUEST:
      return {
        ...state,
        monetizationReport: "",
        reportLoading: true,
        error: ""
      };
    case types.GET_MONETIZATION_REPORT_RECEIVE:
      return {
        ...state,
        monetizationReport: action.response,
        reportLoading: false,
        error: ""
      };
    case types.GET_MONETIZATION_REPORT_ERROR:
      let errorMessage = "";
      if (action.response.status === 403) {
        errorMessage = action.response.data.message;
      } else if (action.apierror && action.apierror.payload) {
        errorMessage = action.apierror.payload.Message;
      }
      return {
        ...state,
        reportLoading: false,
        showErrorModal: true,
        error:
          errorMessage !== ""
            ? errorMessage
            : "There is a problem in fetching data."
      };
    case types.ERROR:
      return {
        ...state,
        error: action.errorMessage,
        showErrorModal: true
      };
    case types.ERROR_RESET:
      return {
        ...state,
        error: action.errorMessage,
        showErrorModal: false
      };

    default:
      return state;
  }
};

export default monetizationReducers;
