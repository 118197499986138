import { Component } from "react";
import { DataTableCell, DataTableRow } from "@cdk-uip/react-data-table";
import { Icon } from "@cdk-uip/react-icon";
import { Select } from "@cdk-uip/react-select";
import { FormattedMessage } from "react-intl";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { SUBSCRIPTION_EVENTS, ASYNC_ACCESS_GRANT_EVENTS } from "./states";
import { Confirm } from "../components/Confirm";
import { SOLUTION_STATUS } from "../Utils/constants";

const ACTIVATE = "Activate";
const DEACTIVATE = "Deactivate";

const ConfirmActivate = ({ selected: { name } }) => (
  <FormattedMessage
    id="accessgrant.activateConfirmation"
    defaultMessage="Activate access grant for {name}?"
    values={{ name: name }}
  />
);

const ConfirmDeactivate = ({ selected: { name } }) => (
  <FormattedMessage
    id="accessgrant.deactivateConfirmation"
    defaultMessage="Deactivate access grant for {name}?"
    values={{ name: name }}
  />
);

class AsyncApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      org: "",
      app: "",
      asyncApi: "",
      accessGrants: "",
      confirm: false
    };
  }
  static defaultProps = {
    apiProvisioningStatus: "inactive"
  };

  promptAccessGrantActivation = () => {
    this.setState({
      confirm: {
        message: () => (
          <ConfirmActivate selected={{ name: this.state.asyncApi.id }} />
        ),
        onAccept: () => this.activateAccessGrant(),
        type: ACTIVATE
      }
    });
  };

  promptAccessGrantDeactivation = () => {
    this.setState({
      confirm: {
        message: () => (
          <ConfirmDeactivate selected={{ name: this.state.asyncApi.id }} />
        ),
        onAccept: () => this.deactivateAccessGrant(),
        type: DEACTIVATE
      }
    });
  };

  activateAccessGrant = () => {
    this.props.onEvent({
      event: ASYNC_ACCESS_GRANT_EVENTS.ACTIVATE,
      dataOwnerOrgId: this.props.idFromSearch,
      integrationId: this.props.asyncApiIntegration.id,
      api: this.state.asyncApi.id,
      subscriptionId: this.props.subscriptionId
    });
    this.setState({ confirm: false });
  };

  deactivateAccessGrant = () => {
    this.props.onEvent({
      event: ASYNC_ACCESS_GRANT_EVENTS.DEACTIVATE,
      id: this.props.asyncApiIntegration.asyncApiAccessGrants.id,
      integrationId: this.props.asyncApiIntegration.id,
      api: this.state.asyncApi.id
    });
    this.setState({ confirm: false });
  };

  onAccept = () => {
    const {
      confirm: { onAccept }
    } = this.state;
    onAccept();
  };

  onCancel = () => {
    this.setState({ confirm: false });
  };

  componentDidUpdate(prevProps) {
    const { asyncApiIntegration, onEvent, orgs } = this.props;
    const { org, asyncApi, app, accessGrants } = this.state;
    if (asyncApiIntegration && !asyncApi) {
      onEvent({
        event: SUBSCRIPTION_EVENTS.GET_ORG,
        orgId: asyncApiIntegration.asyncApi.orgId
      });
      this.setState({ asyncApi: asyncApiIntegration.asyncApi });
    }
    if (
      asyncApiIntegration &&
      !org &&
      orgs[asyncApiIntegration.asyncApi.orgId]
    ) {
      this.setState({ org: orgs[asyncApiIntegration.asyncApi.orgId] });
    }
    if (asyncApiIntegration && !app) {
      this.setState({ app: asyncApiIntegration.app });
    }
    if (
      asyncApiIntegration &&
      asyncApiIntegration.asyncApiAccessGrants &&
      !accessGrants
    ) {
      this.setState({ accessGrants: asyncApiIntegration.asyncApiAccessGrants });
    }
  }

  render() {
    const { status, apiProvisioningStatus, solutionInfo } = this.props;
    const { org, asyncApi, confirm } = this.state;

    return (
      <>
        {asyncApi ? (
          <DataTableRow key={asyncApi.id}>
            <DataTableCell nonNumeric>
              <Icon className={"c-icon__connection-listitem"}>settings</Icon>
              {asyncApi.name}
              <p className={"sm-api-type-subtitle"}>
                API Type:
                <p
                  style={{
                    fontWeight: "bold",
                    display: "inline-block",
                    margin: "0px"
                  }}
                >
                  &nbsp;{"Async"}
                </p>
              </p>
            </DataTableCell>
            <DataTableCell nonNumeric>
              <Select
                className={"c-provider-select--disabled"}
                fullWidth
                allowEmpty={false}
                disabled={true}
                label="Current Provider"
                value="The provider name"
              >
                <option value={org ? org.name : "Loading"}>
                  {org ? org.name : "Loading"}
                </option>
              </Select>
            </DataTableCell>
            <DataTableCell nonNumeric>
              <p
                data-status={status}
                className={
                  `c-status-indicator c-status-indicator-double-stack c-status-indicator--${status}`
                }
              >
                <Icon>brightness_1</Icon>
                Dealer {status}
              </p>
              <p
                data-status={apiProvisioningStatus}
                className={
                  `c-status-indicator c-status-indicator-double-stack c-status-indicator--${apiProvisioningStatus}`
                }
              >
                <Icon>brightness_1</Icon>
                Prov. {apiProvisioningStatus}
              </p>
            </DataTableCell>
            <DataTableCell nonNumeric>
              {status === "inactive" ? (
                <Button
                  raised
                  dense
                  primary
                  disabled={solutionInfo?.status === SOLUTION_STATUS.CANCELLED}
                  onClick={this.promptAccessGrantActivation}
                >
                  <ButtonIcon>flash_on</ButtonIcon>
                  Activate
                </Button>
              ) : (
                <Button
                  raised
                  dense
                  disabled = {solutionInfo?.status === SOLUTION_STATUS.CANCELLED}
                  onClick={this.promptAccessGrantDeactivation}
                >
                  <ButtonIcon>flash_off</ButtonIcon>
                  Deactivate
                </Button>
              )}
              <Confirm
                open={!!confirm}
                onAccept={this.onAccept}
                onCancel={this.onCancel}
                message={confirm ? confirm.message() : false}
                type={confirm.type}
              />
            </DataTableCell>
          </DataTableRow>
        ) : (
          <DataTableRow>
            <DataTableCell nonNumeric>
              <CircularProgress />
            </DataTableCell>
          </DataTableRow>
        )}
      </>
    );
  }
}

export default AsyncApi;
