import React, { Fragment } from "react";
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";
import { FormattedMessage } from "react-intl";
import { TextArea } from "@cdk-uip/react-text-area";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Column, Grid, Row } from "cdk-radial";

function getParsedPayload(data) {
  if (typeof data === "string") {
    return data;
  } else if (typeof data === "object") {
    return JSON.stringify(data, null, 2);
  } else {
    return "Invalid content detected";
  }
}

function ActivationDeactivationError({ error = {}, operation = "" }) {
  return (
    <Fragment>
      <div className="subscription-mgmt-api-activation-error-modal-heading">
        <b>{`API ${operation || ""} failed`}</b>
      </div>
      <br />
      {!(error.errors && error.errors.length) && (
        <div className="subscription-mgmt-api-activation-error-modal-heading">
          Error:{" "}
          {error.message ||
            "Fortellis failed to complete requested operation, please retry and reach out to DNP team if issue persists."}
        </div>
      )}
      {error.errors && (
        <div className={"subscription-mgmt-api-activation-error-modal"}>
          <div
            div
            className="subscription-mgmt-api-activation-error-modal-heading"
          >
            <h4>{`API Publisher failed to successfully acknowledge API ${operation} Request`}</h4>
          </div>
          {(error.errors || []).map(
            ({ url, statusCode, payload, additionalInfo } = {}, index) => (
              <div>
                {error.errors.length > 1 && (
                  <h4>
                    <b>
                      Errors: {index + 1}/{error.errors.length}
                    </b>
                  </h4>
                )}
                <Grid className={{}}>
                  <Row>
                    <Column s={3}>
                      <b>Admin API URL used: </b>
                    </Column>
                    <Column s={6}>{url}</Column>
                  </Row>
                  <Row>
                    <Column s={3}>
                      <b>HTTP Status Code Received: </b>
                    </Column>
                    <Column s={6}>{statusCode || "Not Available"}</Column>
                  </Row>
                  {additionalInfo && (
                    <Row>
                      <Column s={3}>
                        <b>Additional Info: </b>
                      </Column>
                      <Column s={6}>{additionalInfo}</Column>
                    </Row>
                  )}
                  <Row>
                    <Column s={6}>
                      <b>API Publisher Admin API Response: </b>
                    </Column>
                  </Row>
                  <Row>
                    <Column s={12}>
                      <TextArea
                        fullWidth
                        rows={10}
                        name="activation-request-response"
                        value={getParsedPayload(payload)}
                        disabled={true}
                      ></TextArea>
                    </Column>
                  </Row>
                </Grid>
              </div>
            )
          )}
        </div>
      )}
    </Fragment>
  );
}

const APIActivationDeactivationErrorModal = ({
  open = false,
  onClose,
  error,
  inProgress = false,
  operation = ""
}) => (
  <Dialog open={open || inProgress} onCancel={onClose}>
    <DialogBody>
      {inProgress && (
        <div align="center">
          <h3>{`API ${operation} in Progress..`}</h3>
          <br /> <CircularProgress />
        </div>
      )}
      {!inProgress && error && (
        <ActivationDeactivationError error={error} operation={operation} />
      )}
    </DialogBody>
    <DialogFooter>
      {!inProgress && (
        <DialogFooterButton action onClick={onClose}>
          <FormattedMessage id="ModalDialog.dismiss" defaultMessage="Dismiss" />
        </DialogFooterButton>
      )}
    </DialogFooter>
  </Dialog>
);

export default APIActivationDeactivationErrorModal;
