const SORT_TYPES = {
  ALPHANUMERIC: "ALPHANUMERIC",
  DATE: "DATE",
  EMAIL: "EMAIL"
};
const ID_TYPES = {
  CONTACT: "contact",
  SUBMITTED: "submitted",
  CREATED: "created",
  LAST_UPDATED: "last_updated",
  LASTUPDATED: "lastUpdated",
  UPDATED: "updated",
  APPROVED_DATE: "approved_date",
  APPROVEDDATE: "approvedDate",
  ADMIN_API_URL: "adminAPIUrl",
  CREATED_BY: "createdBy"
};

function createComparator(field, ascending, sortType) {
  return function comparator(a, b) {
    const REVERSE = ascending ? -1 : 1;
    let aField =
      sortType === SORT_TYPES.DATE
        ? new Date(a[field])
        : sortType === SORT_TYPES.EMAIL
        ? a[field][SORT_TYPES.EMAIL.toLowerCase()]
        : a[field];
    let bField =
      sortType === SORT_TYPES.DATE
        ? new Date(b[field])
        : sortType === SORT_TYPES.EMAIL
        ? b[field][SORT_TYPES.EMAIL.toLowerCase()]
        : b[field];
    if (field === ID_TYPES.APPROVED_DATE) {
      aField = a[field]
        ? new Date(a[field])
        : new Date(a[ID_TYPES.LAST_UPDATED]);
      bField = b[field]
        ? new Date(b[field])
        : new Date(b[ID_TYPES.LAST_UPDATED]);
    }
    if (field === ID_TYPES.APPROVEDDATE) {
      aField = a[field]
        ? new Date(a[field])
        : new Date(a[ID_TYPES.LASTUPDATED]);
      bField = b[field]
        ? new Date(b[field])
        : new Date(b[ID_TYPES.LASTUPDATED]);
    }
    if (field === ID_TYPES.ADMIN_API_URL || field === ID_TYPES.CREATED_BY) {
      aField = (a[field] ? a[field] : "").toLowerCase();
      bField = (b[field] ? b[field] : "").toLowerCase();
    }
    if (field === ID_TYPES.CREATED || field === ID_TYPES.LASTUPDATED) {
      aField = a[field] ? new Date(Number(a[field])) : "";
      bField = b[field] ? new Date(Number(b[field])) : "";
    }

    if (aField < bField) return REVERSE * 1;
    if (aField > bField) return REVERSE * -1;
    return 0;
  };
}

module.exports = {
  createComparator,
  SORT_TYPES,
  ID_TYPES
};
