import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import {
  List,
  ListGroup,
  ListItem,
  ListItemGraphic,
  ListSubheader
} from "@cdk-uip/react-list";
import { FormattedMessage } from "react-intl";
import { Icon } from "@cdk-uip/react-icon";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { withAuth } from "@cdk-prod/fortellis-auth-context";

class Agreements extends React.Component {
  goToFortellisDeveloperTerms = () => {
    this.props.history.push("/agreements/fortellis-developer-terms");
  };

  render() {
    return (
      <Card>
        <CardHeader>
          <CardTitle large className={"fortellis-developer-terms"}>
            Agreements
          </CardTitle>
          <CardSubtitle className={"fortellis-developer-terms-subtitle"}>
            Here is a list of all the user agreements past, present and the
            ability to add new agreements for the future.
          </CardSubtitle>
        </CardHeader>
        <CardText>
          <LayoutGrid nested className={"c-layout-section__home-grid"}>
            <LayoutGridCell
              span={8}
              className={
                "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
              }
            >
              <ListGroup>
                <ListSubheader className={"current-terms-title"}>
                  Terms
                </ListSubheader>
                <List>
                  <ListItem
                    id={"goToFortellisDeveloperTerms"}
                    onClick={this.goToFortellisDeveloperTerms}
                  >
                    <ListItemGraphic>
                      <Icon>folder</Icon>
                    </ListItemGraphic>
                    <FormattedMessage
                      id="Home.action.fortellisDeveloperTerms"
                      defaultMessage="Fortellis Developer Terms"
                    />
                  </ListItem>
                </List>
              </ListGroup>
            </LayoutGridCell>
            <LayoutGridCell span={12} />
          </LayoutGrid>
        </CardText>
      </Card>
    );
  }
}

Agreements = WithNavigationToHome(Agreements);
class AgreementsContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <Agreements {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(AgreementsContainer);
