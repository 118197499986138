import React from "react";
import "./style.css";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Icon } from "@cdk-uip/react-icon";
import { Radio, RadioGroup } from "@cdk-uip/react-radio";
import { TextField } from "@cdk-uip/react-text-field";
import { DateRangePicker } from "@cdk-uip/react-date-picker";
import { Button } from "@cdk-uip/react-button";
import { connect } from "react-redux";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import helpers from "./helpers";
import types from "../MonetizationReports/types";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Headline } from "@cdk-uip/react-typography";
import { Modal } from "../components/Confirm";

import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";

const FILTER_CONST = {
  OPTIONS: {
    DATE_RANGE: "dateRange",
    APP_NAME: "appName"
  },
  LABELS: {
    DATE_RANGE: "Date Range",
    APP_NAME: "App Name"
  }
};
class MonetizationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOption: FILTER_CONST.OPTIONS.DATE_RANGE,
      appName: "",
      startDate: null,
      endDate: null,
      shouldDownload: true
    };
  }

  handleFilterRadio = e => {
    if (e.target.value === FILTER_CONST.OPTIONS.DATE_RANGE)
      this.setState({ filterOption: e.target.value, appName: "" });
    else if (e.target.value === FILTER_CONST.OPTIONS.APP_NAME)
      this.setState({
        filterOption: e.target.value,
        startDate: null,
        endDate: null
      });
  };

  getMonetizationHandler = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (this.state.startDate && this.state.endDate) {
      this.props.getMonetizationReportsByDateRange({
        accessToken,
        startDate: this.state.startDate._d,
        endDate: helpers.addOneDays(this.state.endDate)._d
      });
      this.setState({ shouldDownload: true });
    } else if (this.state.appName) {
      this.props.getMonetizationReportsByAppName({
        accessToken,
        appName: this.state.appName
      });
      this.setState({ shouldDownload: true });
    } else {
      this.props.dispatch({
        type: types.ERROR,
        errorMessage: "Please select the start date and end date!"
      });
    }
  };

  onErrorModalClose = () => {
    this.props.dispatch({
      type: types.ERROR_RESET
    });
  };

  render() {
    const {
      startDate,
      endDate,
      monetizationReport,
      reportLoading,
      error,
      showErrorModal
    } = this.props.monetizationReport;
    if (
      !reportLoading &&
      monetizationReport !== "" &&
      this.state.shouldDownload
    ) {
      let csvData = new Blob([monetizationReport && monetizationReport.csv], {
        type: "text/csv;charset=utf-8;"
      });
      helpers.downLoadCSV(csvData);
      this.setState({ shouldDownload: false });
    }

    return (
      <React.Fragment>
        {reportLoading && (
          <div className="c-circular-progress--overlay-wrapper">
            <Headline>
              Downloading csv file ...
              <br />
              Please wait...
            </Headline>
            <CircularProgress className="c-circular-progress--overlay" />
          </div>
        )}
        <LayoutGrid
          className={
            "s-content-route--subscription-management c-layout-section__dash-grid"
          }
        >
          <LayoutGridCell
            className={"c-layout-section__dash-grid-cell"}
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <CardHeader>
                  <CardTitle large>
                    <Icon>settings</Icon> Monetization Reports
                  </CardTitle>
                  <CardSubtitle>
                    You'll be able to download the csv of all transcations:
                  </CardSubtitle>
                </CardHeader>
                <CardText>
                  <div className="c-datatable-wrapper">
                    <div className="filter-title">Filter By:</div>
                    <RadioGroup
                      name="filter-monetization-report"
                      value={this.state.filterOption}
                      onChange={this.handleFilterRadio}
                    >
                      <Radio value={FILTER_CONST.OPTIONS.DATE_RANGE}>
                        {FILTER_CONST.LABELS.DATE_RANGE}
                      </Radio>
                      <Radio value={FILTER_CONST.OPTIONS.APP_NAME}>
                        {FILTER_CONST.LABELS.APP_NAME}
                      </Radio>
                    </RadioGroup>
                    <div className="filter-input-container">
                      {this.state.filterOption ===
                      FILTER_CONST.OPTIONS.DATE_RANGE ? (
                        <div className="date-bar">
                          <div className="filter-date-range">
                            <DateRangePicker
                              startDate={startDate}
                              endDate={endDate}
                              rangeEndDate={helpers.tomorrow}
                              onChange={(startDate, endDate) => {
                                this.setState({
                                  startDate: startDate,
                                  endDate: endDate
                                });
                              }}
                              format={"YYYY-MM-DD"}
                            />
                          </div>
                        </div>
                      ) : null}
                      {this.state.filterOption ===
                      FILTER_CONST.OPTIONS.APP_NAME ? (
                        <div className="date-bar">
                          <div className="filter-app-name">
                            <div>
                              <TextField
                                type="string"
                                inputMode="text"
                                label={FILTER_CONST.LABELS.APP_NAME}
                                value={this.state.appName}
                                onChange={e =>
                                  this.setState({ appName: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <Button
                        id="btnDownload"
                        raised
                        onClick={this.getMonetizationHandler}
                        disabled={
                          !(this.state.startDate && this.state.endDate) &&
                          !this.state.appName.trim()
                        }
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                </CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>
        <Modal
          open={showErrorModal}
          onClose={this.onErrorModalClose}
          message={error}
        />
        ;
      </React.Fragment>
    );
  }
}

MonetizationReport = connect()(WithNavigationToHome(MonetizationReport));
class MonetizationReportContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <MonetizationReport {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(MonetizationReportContainer);
