export default {
  APPROVED_USERS_GET_REQUEST: "APPROVED_USERS_GET_REQUEST",
  APPROVED_USERS_GET_RECEIVE: "APPROVED_USERS_GET_RECEIVE",
  APPROVED_USERS_GET_ERROR: "APPROVED_USERS_GET_ERROR",
  LINKED_ENTITIES_GET_REQUEST: "LINKED_ENTITIES_GET_REQUEST",
  LINKED_ENTITIES_GET_RECEIVE: "LINKED_ENTITIES_GET_RECEIVE",
  LINKED_ENTITIES_GET_ERROR: "LINKED_ENTITIES_GET_ERROR",
  REMOVE_USER_FROM_ENTITY_DELETE_REQUEST:
    "REMOVE_USER_FROM_ENTITY_DELETE_REQUEST",
  REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE:
    "REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE",
  REMOVE_USER_FROM_ENTITY_DELETE_ERROR: "REMOVE_USER_FROM_ENTITY_DELETE_ERROR",
  ADD_USER_TO_ENTITY_GET_REQUEST: "ADD_USER_TO_ENTITY_GET_REQUEST",
  ADD_USER_TO_ENTITY_GET_RECEIVE: "ADD_USER_TO_ENTITY_GET_RECEIVE",
  ADD_USER_TO_ENTITY_GET_ERROR: "ADD_USER_TO_ENTITY_GET_ERROR",
  APPROVED_USERS_GET_REQUEST_ON_SHOW_MORE:
    "APPROVED_USERS_GET_REQUEST_ON_SHOW_MORE",
  APPROVED_USERS_GET_RECEIVE_ON_SHOW_MORE:
    "APPROVED_USERS_GET_RECEIVE_ON_SHOW_MORE",
  APPROVED_USERS_GET_ERROR_ON_SHOW_MORE:
    "APPROVED_USERS_GET_ERROR_ON_SHOW_MORE",
  SUSPEND_USER_REQUEST: "SUSPEND_USER_REQUEST",
  SUSPEND_USER_RECEIVE: "SUSPEND_USER_RECEIVE",
  SUSPEND_USER_ERROR: "SUSPEND_USER_ERROR"
};
