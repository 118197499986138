import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from "@cdk-uip/react-dialog";
import { Fluid } from "@cdk-uip/react-fluid";
import { Button } from "@cdk-uip/react-button";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";

const APP = "APP";
const API = "API";

const list = [API, APP];

class AgreementsManagement extends React.Component {
  static defaultProps = {
    entity: { id: "", name: "", fileName: "" },
    open: false,
    shouldShow: false,
    snackbarMessage: ""
  };

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    if (this.props.closeAgreementDialog) {
      this.props.closeAgreementDialog();
    }
  }

  uploadAgreement = (orgId, type, e) => {
    this.props.uploadAgreement(orgId, `${type.toLowerCase()}Agreement`, e);
  };

  viewAgreement = (entity, type) => {
    this.props.viewAgreement(entity, `${type.toLowerCase()}Agreement`);
  };

  deleteAgreement = (entity, type) => {
    this.props.deleteAgreement(entity, `${type.toLowerCase()}Agreement`);
  };

  replaceAgreement = (entity, type, e) => {
    this.props.replaceAgreement(entity, `${type.toLowerCase()}Agreement`, e);
  };

  render() {
    const { open, entity, shouldShow, snackbarMessage } = this.props;
    return (
      <Dialog
        className="manage_agreements-dialog"
        open={open}
        onCancel={this.onClose}
      >
        <DialogHeader>
          <div className="dialog-header-container">
            Manage Agreements
            <i
              onClick={this.onClose}
              className="manage_agreements-dialog cdkicon-close close-icon"
            />
          </div>
        </DialogHeader>
        <DialogBody scrollable className={"manage_agreements-dialog"}>
          <div>
            <div className="manage_agreements-dialog-org-name">
              <h2>{entity.name}</h2>
            </div>
            <div className="manage_agreements-dialog org-id">{entity.id}</div>
            <ul className="c-entity-search--result-items">
              {list.map(item => (
                <li key={item}>
                  <AgreementPanel
                    type={item}
                    entity={entity}
                    viewAgreement={this.viewAgreement}
                    deleteAgreement={this.deleteAgreement}
                    uploadAgreement={this.uploadAgreement}
                    replaceAgreement={this.replaceAgreement}
                    keyId={item}
                  />
                </li>
              ))}
            </ul>
            <div>
              <TemporarySnackbar
                show={shouldShow}
                message={snackbarMessage}
                timeout={1000}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <DialogFooterButton onClick={this.onClose}>Close</DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

const isAgreementExists = (entity, type) => {
  if (type === API) {
    return entity.apiAgreement ? entity.apiAgreement.exist : false;
  } else {
    return entity.appAgreement ? entity.appAgreement.exist : false;
  }
};

const isAgreementFileName = (entity, type) => {
  if (type === API) {
    return entity.apiAgreement && entity.apiAgreement.fileName
      ? entity.apiAgreement.fileName
      : "";
  } else {
    return entity.appAgreement && entity.appAgreement.fileName
      ? entity.appAgreement.fileName
      : "";
  }
};

const UploadedFile = ({ fileName, viewAgreement, entity }) => {
  return (
    <Fluid className={"manage-agreement-uploadfile"} halign="left">
      <div className="manage-agreement-upload__title">{`Uploaded file `}</div>
      <div className="c-search-list-item__divider">:</div>
      <div className="c-search-list-item__text manage-agreement-truncate-filename">
        <a>{fileName ? fileName : "UNAVALIABLE"}</a>
      </div>
    </Fluid>
  );
};

const AgreementPanel = ({
  type,
  entity,
  viewAgreement,
  deleteAgreement,
  uploadAgreement,
  replaceAgreement,
  keyId
}) => {
  return (
    <div className="manage-agreement-list-item">
      <div className="c-entity-search--result">
        <div className="manage_agreements-dialog-type">
          <h3>{`${type} Agreement`}</h3>
        </div>
        {isAgreementExists(entity, type) ? (
          <UploadedFile
            fileName={isAgreementFileName(entity, type)}
            entity={entity}
            viewAgreement={viewAgreement}
          />
        ) : null}
        <LayoutGrid nested className={"c-layout-section__home-grid"}>
          <LayoutGridCell
            className={
              "c-layout-section__home-grid-cell c-layout-section__home-grid-cell--navigational"
            }
          >
            {isAgreementExists(entity, type) ? (
              <Button
                className={"manage_agreements-dialog-button"}
                outlined
                onClick={e => viewAgreement(entity, type)}
              >
                VIEW
              </Button>
            ) : (
              <InputButton
                text="ADD"
                entity={entity}
                type={type}
                agreementAction={uploadAgreement}
                id={keyId}
              />
            )}
          </LayoutGridCell>
          <LayoutGridCell>
            {isAgreementExists(entity, type) ? (
              <InputButton
                text="REPLACE"
                entity={entity}
                type={type}
                agreementAction={replaceAgreement}
                id={keyId}
              />
            ) : null}
          </LayoutGridCell>
          <LayoutGridCell>
            {isAgreementExists(entity, type) ? (
              <Button
                className={"manage_agreements-dialog-button"}
                outlined
                onClick={e => deleteAgreement(entity, type)}
              >
                DELETE
              </Button>
            ) : null}
          </LayoutGridCell>
        </LayoutGrid>
      </div>
    </div>
  );
};

const InputButton = ({ text, entity, type, agreementAction, id }) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="mdc-button mdc-button--outlined manage_agreements-dialog-button"
      >
        {text}
      </label>
      <input
        id={id}
        style={{ display: "none" }}
        type={"file"}
        accept="application/pdf"
        onChange={e => {
          agreementAction(entity, type, e);
        }}
      />
    </div>
  );
};

export { AgreementsManagement };
