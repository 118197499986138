import React from "react";

class NumberWithTitle extends React.Component {
  render() {
    return (
      <div className="c-dash-chart__inline-card-text-wrapper">
        <h1 className="c-dash-chart__number-displayed--medium">
          {this.props.number}
        </h1>
        <p className="c-dash-chart__subtitle-displayed--small">
          {this.props.title}
        </p>
      </div>
    );
  }
}

export default NumberWithTitle;
