import types from "./types";

const TnCUploaderReducer = (
  state = { isSubmitting: false, error: "", isSubmitted: false },
  action
) => {
  switch (action.type) {
    case types.POST_TNC_REQUEST:
      return {
        isSubmitting: true,
        error: "",
        isSubmitted: false
      };
    case types.POST_TNC_RESPONSE:
      return {
        isSubmitting: false,
        error: "",
        isSubmitted: true
      };
    case types.POST_TNC_ERROR:
      return {
        isSubmitting: false,
        error: "An error occurred",
        isSubmitted: false
      };
    default:
      return state;
  }
};

export default TnCUploaderReducer;
