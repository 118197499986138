import { connect } from "react-redux";
import { withRouter } from "react-router";

import BulkSubscriptions from "./BulkSubscriptions";
import { ACTIONS } from "./reduxManagement";

export default withRouter(
  connect(
    state => {
      return { store: state.bulkSubscriptionsReducer };
    },
    ACTIONS
  )(BulkSubscriptions)
);
