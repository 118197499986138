import EntityManagement from "./EntityManagement";
import { connect } from "react-redux";
import { actions } from "./entityActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      entityManagement: state.entityManagement
    }),
    // Inject all actions into the component
    actions
  )(EntityManagement)
);
