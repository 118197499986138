import React from "react";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { Card, CardHeader, CardText } from "@cdk-uip/react-card";
import { Icon } from "@cdk-uip/react-icon";
import Editor from "draft-js-plugins-editor";
import addLinkPlugin from "./AddLinkPlugin";

class TnCEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty()
    };
    this.plugins = [addLinkPlugin];

    this.focus = () => this.refs.editor.focus();

    this.onChange = async editorState => {
      await this.setState({ editorState });
      this.props.handleEditorChanges(this.state);
    };
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    this.onAddLink = this._onAddLink.bind(this);
  }
  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }
  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }
  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }
  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  _onAddLink = () => {
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    if (RichUtils.currentBlockContainsLink(editorState)) {
      this.onChange(RichUtils.toggleLink(editorState, selection, null));
      return true;
    }
    const link = window.prompt("Paste the link -");
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return true;
  };

  render() {
    const { editorState } = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "tncEditor-editor";
    let contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }
    return (
      <div>
        <LayoutGrid>
          <LayoutGridCell span={12} border={1}>
            <Card className={"minHeight-864"}>
              <CardHeader className={"update-fortellis-tnc_toolbar"}>
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                />
                <AddLinkControl
                  editorState={editorState}
                  onClick={this.onAddLink}
                />
              </CardHeader>
              <CardText>
                <div
                  scrollable="true"
                  className={className}
                  onClick={this.focus}
                >
                  <Editor
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={this.handleKeyCommand}
                    keyBindingFn={this.mapKeyToEditorCommand}
                    onChange={this.onChange}
                    ref="editor"
                    spellCheck={true}
                    placeholder="Start typing here..."
                    plugins={this.plugins}
                  />
                </div>
              </CardText>
            </Card>
          </LayoutGridCell>
        </LayoutGrid>
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "update-fortellis-tnc_style_button";
    if (this.props.active) {
      className += "_active";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.icon ? (
          <Icon className={"update-fortellis-tnc_style_icons"}>
            {this.props.icon}
          </Icon>
        ) : (
          this.props.label
        )}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "UL", style: "unordered-list-item", icon: "format_list_bulleted" },
  { label: "OL", style: "ordered-list-item", icon: "format_list_numbered" }
];
const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="tncEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};
const INLINE_STYLES = [
  { label: "Bold", style: "BOLD", icon: "format_bold" },
  { label: "Italic", style: "ITALIC", icon: "format_italic" },
  { label: "Underline", style: "UNDERLINE", icon: "format_underlined" }
];
const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="tncEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};

const AddLinkControl = props => {
  return (
    <div className="tncEditor-controls">
      <span
        onClick={props.onClick}
        className={"update-fortellis-tnc_style_button"}
      >
        <Icon className={"update-fortellis-tnc_style_icons"}>link</Icon>
      </span>
    </div>
  );
};

export default TnCEditor;
