import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import types from "./types";
import getSignedUpUsers from "../api/getSignedUpUsers";
import resendEmail from "../api/resendEmail";

export const actions = {
  fetchSignedUpUsers: accessToken => {
    return {
      [CALL_API]: {
        ...getSignedUpUsers(),
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          types.SIGNED_UP_USERS_GET_REQUEST,
          types.SIGNED_UP_USERS_GET_RECEIVE,
          types.SIGNED_UP_USERS_GET_ERROR
        ]
      }
    };
  },
  resendEmailToSignedUpUsers: ({ email, accessToken }) => {
    return {
      [CALL_API]: {
        ...resendEmail(email),
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        types: [
          { type: types.SEND_EMAIL_POST_REQUEST, email },
          { type: types.SEND_EMAIL_POST_RECEIVE, email },
          { type: types.SEND_EMAIL_POST_ERROR, email }
        ]
      }
    };
  }
};
