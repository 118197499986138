export default {
  USER_PROFILE_GET_REQUEST: "USER_PROFILE_GET_REQUEST",
  USER_PROFILE_GET_RECEIVE: "USER_PROFILE_GET_RECEIVE",
  USER_PROFILE_GET_ERROR: "USER_PROFILE_GET_ERROR",

  LINKED_ENTITIES_GET_REQUEST: "LINKED_ENTITIES_GET_REQUEST",
  LINKED_ENTITIES_GET_RECEIVE: "LINKED_ENTITIES_GET_RECEIVE",
  LINKED_ENTITIES_GET_ERROR: "LINKED_ENTITIES_GET_ERROR",
  REMOVE_USER_FROM_ENTITY_DELETE_REQUEST:
    "REMOVE_USER_FROM_ENTITY_DELETE_REQUEST",
  REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE:
    "REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE",
  REMOVE_USER_FROM_ENTITY_DELETE_ERROR: "REMOVE_USER_FROM_ENTITY_DELETE_ERROR",
  ADD_USER_TO_ENTITY_GET_REQUEST: "ADD_USER_TO_ENTITY_GET_REQUEST",
  ADD_USER_TO_ENTITY_GET_RECEIVE: "ADD_USER_TO_ENTITY_GET_RECEIVE",
  ADD_USER_TO_ENTITY_GET_ERROR: "ADD_USER_TO_ENTITY_GET_ERROR",
  APPROVED_USERS_GET_REQUEST_ON_SHOW_MORE:
    "APPROVED_USERS_GET_REQUEST_ON_SHOW_MORE",
  APPROVED_USERS_GET_RECEIVE_ON_SHOW_MORE:
    "APPROVED_USERS_GET_RECEIVE_ON_SHOW_MORE",
  APPROVED_USERS_GET_ERROR_ON_SHOW_MORE:
    "APPROVED_USERS_GET_ERROR_ON_SHOW_MORE",
  RESET_IS_ADDED: "RESET_IS_ADDED",
  RESEND_EMAIL_POST_REQUEST: "RESEND_EMAIL_POST_REQUEST",
  RESEND_EMAIL_POST_RECEIVE: "RESEND_EMAIL_POST_RECEIVE",
  RESEND_EMAIL_POST_ERROR: "RESEND_EMAIL_POST_ERROR",
  APPROVE_USER_POST_REQUEST: "APPROVE_USER_POST_REQUEST",
  APPROVE_USER_POST_RECEIVE: "APPROVE_USER_POST_RECEIVE",
  APPROVE_USER_POST_ERROR: "APPROVE_USER_POST_ERROR",
  ASSIGN_USER_ROLES_REQUEST: "ASSIGN_USER_ROLES_REQUEST",
  ASSIGN_USER_ROLES_RECEIVE: "ASSIGN_USER_ROLES_RECEIVE",
  ASSIGN_USER_ROLES_ERROR: "ASSIGN_USER_ROLES_ERROR",
  SUSPEND_USER_REQUEST: "SUSPEND_USER_REQUEST",
  SUSPEND_USER_RECEIVE: "SUSPEND_USER_RECEIVE",
  SUSPEND_USER_ERROR: "SUSPEND_USER_ERROR",
  UNSUSPEND_USER_REQUEST: "UNSUSPEND_USER_REQUEST",
  UNSUSPEND_USER_RECEIVE: "UNSUSPEND_USER_RECEIVE",
  UNSUSPEND_USER_ERROR: "UNSUSPEND_USER_ERROR"
};
