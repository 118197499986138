import React from "react";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import types from "./types";
import { connect } from "react-redux";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { ButtonIcon } from "@cdk-uip/react-button";
import {
  Card,
  CardActions,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Form } from "@cdk-uip/react-form";
import { Icon } from "@cdk-uip/react-icon";
import { Subtitle2 } from "@cdk-uip/react-typography";
import {
  TextField,
  TextFieldIcon,
  TextFieldHelperText
} from "@cdk-uip/react-text-field";
import { LoadingButton } from "../components/LoadingButton";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { Confirm, ErrorModal } from "../components/Confirm";
import Flex from "../components/Flex";

import EntitySearch from "./EntitySearch";
import { FormattedMessage } from "react-intl";
import { Select } from "@cdk-uip/react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField as TextInput } from "@arterial/textfield";

import {
  businessTypes,
  country,
  validatePhoneNumber,
  validateGuid,
  validateDomain
} from "../Utils/validation";

import { Loader } from "@googlemaps/js-api-loader";

import config from "../config/app.conf.json";

// COUNTRY CODES AS CONSTs
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// MIGHT BE A LIB/HELPER TO DO THIS BETTER ONCE APP EXPANDS TO OTHER COUNTRIES

const COMPONENT_FORM = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "short_name",
  postal_code: "short_name",
  administrative_area_level_2: "long_name"
};

const ADDRESS_DETAILS = {
  street_number: "streetNumber",
  route: "streetAddress",
  locality: "city",
  administrative_area_level_1: "state",
  country: "countryCode",
  postal_code: "zipCode",
  administrative_area_level_2: "county"
};
class EntityManagement extends React.Component {
  constructor(props) {
    super(props);
    const loader = new Loader({
      apiKey: config.googleApiKey,
      libraries: ["places"]
    });
    const mapOptions = {
      center: {
        lat: 50,
        lng: 50
      },
      zoom: 5
    };
    loader
      .load()
      .then((google) => {
        let mapDiv = document.createElement("div");
        mapDiv.setAttribute("type", "hidden");
        mapDiv.setAttribute("id", "map");
        document.getElementById("root").appendChild(mapDiv);
        const map = new google.maps.Map(document.getElementById("map"),mapOptions);
        this.placesService = new window.google.maps.places.PlacesService(map);
        this.autocompleteService = new google.maps.places.AutocompleteService();
      
      })
      .catch(error => {
        console.log(error);
      });
    this.state = {
      legalEntityName: "",
      primaryPhone: "",
      streetAddress: "",
      streetNumber: "",
      city: "",
      state: "",
      county: "",
      zipCode: "",
      legalEntityBelongsTo: "",
      legalEntityEmailDomains: "",
      legalEntityGeoLocationLatitude: "",
      legalEntityGeoLocationLongitude: "",
      legalEntityTaxId: "",
      legalEntityType: "",
      legalEntityWebsite: "",
      countryCode: "",
      selectCountry: "US",
      predicationList: [],
      selectedAddress: "",
      isOrgAddressValid: false,
      hasIssueWithSelectedAddress: false,
      isAddressSelected: false
    };
  }

  static defaultProps = {
    legalEntityStatus: "active"
  };

  registerEntity = () => {
    const {
      auth: { accessToken }
    } = this.props;
    if (
      this.state.legalEntityName &&
      this.state.streetAddress &&
      this.state.city &&
      this.state.state &&
      this.state.countryCode &&
      this.state.zipCode
    ) {
      if (this.validateForm()) {
        var dataobject = this.formDataObject();
        this.props.registerEntity({
          accessToken,
          dataobject
        });
      }
    } else {
      this.props.dispatch({
        type: types.ERROR,
        errorMessage:
          "Cannot register an Invalid Organization. Required: Name, Phone, Full Address and Business Type"
      });
    }
  };

  formDataObject = () => {
    var dataobject = {
      name:
        this.state.legalEntityName === "" ? null : this.state.legalEntityName,
      phone: this.state.primaryPhone === "" ? null : this.state.primaryPhone,
      address: {
        street: this.state.streetNumber + " " + this.state.streetAddress,
        city: this.state.city,
        region: this.state.state,
        subRegion: this.state.county === "" ? null : this.state.county,
        countryCode: this.state.countryCode,
        postalCode: this.state.zipCode
      },
      geoLocation:
        this.state.legalEntityGeoLocationLatitude === "" &&
        this.state.legalEntityGeoLocationLongitude === ""
          ? null
          : "(" +
            this.state.legalEntityGeoLocationLongitude +
            "),(" +
            this.state.legalEntityGeoLocationLatitude +
            ")",

      belongsTo:
        this.state.legalEntityBelongsTo === ""
          ? null
          : this.state.legalEntityBelongsTo,
      emailDomains:
        this.state.legalEntityEmailDomains === ""
          ? null
          : this.state.legalEntityEmailDomains
              .split(",")
              .map(domain => domain.trim()),
      taxId:
        this.state.legalEntityTaxId === "" ? null : this.state.legalEntityTaxId,
      type:
        this.state.legalEntityType === "" ? null : this.state.legalEntityType,
      website:
        this.state.legalEntityWebsite === ""
          ? null
          : this.state.legalEntityWebsite,
      status: this.props.legalEntityStatus
    };
    for (var propName in dataobject) {
      if (dataobject[propName] === null) {
        delete dataobject[propName];
      }
    }

    return dataobject;
  };

  onErrorModalClose = () => {
    this.props.dispatch({
      type: types.ERROR_RESET
    });
  };

  onSuccessModalClose = () => {
    this.props.dispatch({
      type: types.SUCCESS_RESET
    });
  };

  toSubscriptions = () => {
    this.props.history.push({
      pathname: "/subscriptions",
      entity: {
        id: this.props.entityManagement.legalEntityID
      }
    });
  };

  validateEmailDomains = (canBeEmpty = false) => {
    const { legalEntityEmailDomains } = this.state;
    if (!legalEntityEmailDomains.length) {
      return canBeEmpty;
    }
    // Clean input
    const domains = legalEntityEmailDomains
      .split(",")
      .map(domain => domain.trim());
    // Validate and check for duplicates. If every item passed validation it will return true
    return domains.every(
      (domain, index) =>
        validateDomain(domain) && index === domains.indexOf(domain)
    );
  };

  validateForm = () => {
    const { legalEntityBelongsTo, countryCode } = this.state;

    // validate email domains
    if (!this.validateEmailDomains(true)) {
      return false;
    }
    // validate belongsTo if there
    if (legalEntityBelongsTo.length) {
      if (!validateGuid(legalEntityBelongsTo, true)) {
        return false;
      }
    }

    if (!countryCode) {
      return false;
    }
    return true;
  };

  mapEntityTypesToOptions = entityTypes =>
    Object.keys(entityTypes).map(val => (
      <option value={val} key={entityTypes[val]}>
        {entityTypes[val]}
      </option>
    ));

  onCountryChange = value => {
    this.setState({
      selectCountry: value || "",
      selectedAddress: "",
      countryCode: ""
    });
    this.searchAddress("");
  };

  searchAddress = value => {
    if (value !== "") {
      const { selectCountry } = this.state;
      this.autocompleteService.getPlacePredictions(
        {
          input: value,
          componentRestrictions: {
            country: selectCountry
          }
        },
        this.displaySuggestions
      );
    } else {
      this.setState({
        predicationList: []
      });
    }
  };

  displaySuggestions = (predictions, status) => {
    let suggestionsList = [];
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      return;
    }
    if (predictions !== null && predictions !== undefined) {
      predictions.forEach(function(prediction) {
        const predicationObj = {
          placeId: prediction.place_id,
          description: prediction.description
        };
        suggestionsList.push(predicationObj);
      });
    }
    this.setState({ predicationList: suggestionsList });
  };

  updateEntity = (field, value) =>
    this.setState({
      [field]: value
    });

  getPlaceDetails = value => {
    if (value != null) {
      this.placesService.getDetails(
        { placeId: value.placeId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            for (var i = 0; i < place.address_components.length; i++) {
              const addressType = place.address_components[i].types[0];
              if (COMPONENT_FORM[addressType]) {
                this.updateEntity(
                  ADDRESS_DETAILS[addressType],
                  place.address_components[i][COMPONENT_FORM[addressType]]
                );
              }
            }
            this.doesAddressHasAllRequiredFields();
          }
        }
      );
    }
    this.setState({
      predicationList: []
    });
  };

  doesAddressHasAllRequiredFields = () => {
    if (
      this.state.streetNumber !== "" &&
      this.state.streetAddress !== "" &&
      this.state.city !== "" &&
      this.state.state !== "" &&
      this.state.countryCode !== "" &&
      this.state.zipCode !== "" &&
      this.state.county !== ""
    ) {
      this.setState({ hasIssueWithSelectedAddress: false });
    } else {
      this.setState({ hasIssueWithSelectedAddress: true });
    }
  };

  onAddressChange() {
    this.setState({ hasIssueWithSelectedAddress: false });
    this.setState({
      streetNumber: "",
      streetAddress: "",
      city: "",
      state: "",
      countryCode: "",
      zipCode: "",
      county: ""
    });
  }

  doesOrgAddressValid = value => {
    const { predicationList } = this.state;
    const valid = predicationList.filter(
      predication => predication.description === value
    );
    this.setState({
      isOrgAddressValid: valid.length !== 0 ? false : true
    });
  };

  render() {
    const {
      entityManagement: {
        showInProgress,
        showSuccessModal,
        successMessage,
        showErrorModal,
        errorMessage
      }
    } = this.props;
    const {
      selectCountry,
      predicationList,
      selectedAddress,
      isOrgAddressValid,
      isAddressSelected,
      hasIssueWithSelectedAddress
    } = this.state;
    return (
      <React.Fragment>
        <LayoutGrid className="s-content-route--entity-management c-layout-section__dash-grid entity-management">
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--start"
                }
              >
                <CardHeader>
                  <CardTitle className="org-management-title" large>
                    <Icon>settings</Icon> Organization Management
                  </CardTitle>
                  <CardSubtitle className="subscriptions-link-wrapper">
                    <a
                      className="subscriptions-link"
                      onClick={this.toSubscriptions}
                    >
                      Manage Subscriptions
                    </a>
                  </CardSubtitle>
                </CardHeader>
                <CardText>
                  {/* #START CHART DATA */}

                  {/* #END CHART DATA */}
                </CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
          {/* #START ENTITY SEARCH UI */}
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <EntitySearch />
          </LayoutGridCell>

          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Card
                className={
                  "c-dash-card c-form-subscriptions c-form-subscriptions--step1"
                }
              >
                <CardHeader>Last Organization Registered</CardHeader>
                <CardText>
                  {this.props.entityManagement.legalEntityID || "none"}
                </CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
          {/* #END ENTITY SEARCH UI */}
          <LayoutGridCell
            className="c-layout-section__dash-grid-cell"
            span={12}
            spanTablet={8}
            spanPhone={4}
          >
            <Elevation z={10} className={"c-dash-card__wrapper"}>
              <Form id={""} onSubmit={this.registerEntity} autocomplete="off">
                <Card
                  className={
                    "c-dash-card c-form-subscriptions c-form-subscriptions--step1"
                  }
                >
                  <CardHeader>
                    <CardTitle large>Register An Organization:</CardTitle>
                  </CardHeader>
                  <CardText>
                    <EntityRegistrationInput
                      autoFocus={true}
                      required
                      id="legalEntityName"
                      name="legalEntityName"
                      label="Organization Name"
                      value={this.state.legalEntityName}
                      onChange={e =>
                        this.setState({ legalEntityName: e.target.value })
                      }
                      onClear={() => this.setState({ legalEntityName: "" })}
                    />

                    <EntityRegistrationInput
                      id="legalEntityPhone"
                      name="legalEntityPhone"
                      label="Phone"
                      value={this.state.primaryPhone}
                      onChange={e =>
                        this.setState({ primaryPhone: e.target.value })
                      }
                      onClear={() => this.setState({ primaryPhone: "" })}
                      invalid={
                        !validatePhoneNumber(this.state.primaryPhone, true)
                      }
                    />
                    <Subtitle2 className={"c-form__section-header"}>
                      Physical Address:
                    </Subtitle2>
                    <section className={"c-form__section-fields"}>
                      <div>
                        <Select
                          label="Country"
                          value={selectCountry}
                          onChange={e => {
                            this.setState({ selectCountry: e.target.value });
                            this.onCountryChange(e.target.value);
                          }}
                          required
                        >
                          {this.mapEntityTypesToOptions(country)}
                        </Select>
                      </div>
                      <div>
                        <Autocomplete
                          id="address"
                          options={predicationList}
                          getOptionLabel={option => option.description}
                          onChange={(event, newValue) => {
                            this.onAddressChange();
                            this.getPlaceDetails(newValue);
                            this.setState({ isAddressSelected: true });
                          }}
                          onInputChange={(event, newInputValue) => {
                            this.searchAddress(newInputValue);
                            this.setState({
                              selectedAddress: newInputValue,
                              isAddressSelected: false
                            });
                            this.doesOrgAddressValid(newInputValue);
                          }}
                          inputValue={selectedAddress}
                          noOptionsText="Your search returned no result"
                          renderInput={params => (
                            <div ref={params.InputProps.ref}>
                              <TextInput
                                label="Address"
                                onClick={this.searchAddress}
                                helperText={
                                  <TextFieldHelperText
                                    className={"cdk-bulksubs__text-field-error"}
                                    persistent
                                  >
                                    {isAddressSelected &&
                                      hasIssueWithSelectedAddress &&
                                      "Select a valid street address"}
                                  </TextFieldHelperText>
                                }
                                invalid={
                                  isOrgAddressValid ||
                                  (isAddressSelected &&
                                    hasIssueWithSelectedAddress)
                                }
                                {...params.inputProps}
                                required
                              />
                            </div>
                          )}
                        />
                      </div>
                    </section>
                    <Subtitle2 className={"c-form__section-header"}>
                      Geographical Location:
                    </Subtitle2>
                    <section className={"c-form__section-fields"}>
                      <Flex justify="space-between">
                        <div className="c-geo-inline-item">
                          <EntityRegistrationInput
                            id="legalEntityGeoLocationLatitude"
                            name="legalEntityGeoLocationLatitude"
                            label="GeoLocation Latitude"
                            value={this.state.legalEntityGeoLocationLatitude}
                            onChange={e =>
                              this.setState({
                                legalEntityGeoLocationLatitude: e.target.value
                              })
                            }
                            onClear={() =>
                              this.setState({
                                legalEntityGeoLocationLatitude: ""
                              })
                            }
                            helperText="The ISO 6709 standard latitude of the location"
                            type="number"
                            inputMode="number"
                          />
                        </div>
                        <div className="c-geo-inline-item">
                          <EntityRegistrationInput
                            id="legalEntityGeoLocationLongitude"
                            name="legalEntityGeoLocationLongitude"
                            label="GeoLocation Longitude"
                            value={this.state.legalEntityGeoLocationLongitude}
                            onChange={e =>
                              this.setState({
                                legalEntityGeoLocationLongitude: e.target.value
                              })
                            }
                            onClear={() =>
                              this.setState({
                                legalEntityGeoLocationLongitude: ""
                              })
                            }
                            helperText="The ISO 6709 standard longitude of the location"
                            type="number"
                            inputMode="number"
                          />
                        </div>
                      </Flex>
                    </section>

                    <EntityRegistrationInput
                      id="legalEntityWebsite"
                      name="legalEntityWebsite"
                      label="Website"
                      value={this.state.legalEntityWebsite}
                      onChange={e =>
                        this.setState({ legalEntityWebsite: e.target.value })
                      }
                      onClear={() => this.setState({ legalEntityWebsite: "" })}
                      helperText="The website of an organization"
                    />

                    <EntityRegistrationInput
                      id="legalEntityEmailDomains"
                      name="legalEntityEmailDomains"
                      label="Email Domains"
                      value={this.state.legalEntityEmailDomains}
                      onChange={e =>
                        this.setState({
                          legalEntityEmailDomains: e.target.value
                        })
                      }
                      onClear={() =>
                        this.setState({ legalEntityEmailDomains: "" })
                      }
                      invalid={!this.validateEmailDomains(true)}
                      helperText="Comma separated list of email domains values. Example:
                      'fortellis.io, fortellis.com'"
                    />

                    <EntityRegistrationInput
                      id="legalEntityTaxId"
                      name="legalEntityTaxId"
                      label="Tax ID"
                      value={this.state.legalEntityTaxId}
                      onChange={e =>
                        this.setState({ legalEntityTaxId: e.target.value })
                      }
                      onClear={() => this.setState({ legalEntityTaxId: "" })}
                      helperText="The Tax ID of an organization"
                    />

                    <Select
                      id="legalEntityType"
                      name="legalEntityType"
                      className="c-entity-type__selection"
                      label="Business Type"
                      value={this.state.legalEntityType}
                      onChange={e =>
                        this.setState({ legalEntityType: e.target.value })
                      }
                    >
                      {this.mapEntityTypesToOptions(businessTypes)}
                    </Select>

                    <EntityRegistrationInput
                      id="legalEntityBelongsTo"
                      name="legalEntityBelongsTo"
                      label="Parent ID"
                      value={this.state.legalEntityBelongsTo}
                      invalid={
                        !validateGuid(this.state.legalEntityBelongsTo, true)
                      }
                      onChange={e =>
                        this.setState({ legalEntityBelongsTo: e.target.value })
                      }
                      onClear={() =>
                        this.setState({ legalEntityBelongsTo: "" })
                      }
                      helperText="The parent ID this organization belongs to (if applicable)."
                    />

                    <EntityRegistrationInput
                      disabled
                      id={"legalEntityStatus"}
                      name={"legalEntityStatus"}
                      label="Registration Status"
                      value={this.props.legalEntityStatus}
                      onChange={e =>
                        this.setState({ legalEntityStatus: e.target.value })
                      }
                      onClear={() => this.setState({ legalEntityStatus: "" })}
                      helperText='Default: "pending". One of the following: "pending", "approved", "rejected", "blocked".'
                    />
                  </CardText>
                  <CardActions fullBleed>
                    <LoadingButton
                      className="loadingButton-leftPadding"
                      raised
                      primary
                      dense
                      disabled={
                        !this.validateForm() ||
                        isOrgAddressValid ||
                        (isAddressSelected && hasIssueWithSelectedAddress)
                      }
                      isLoading={showInProgress}
                      type="submit"
                      loadingValue={
                        <FormattedMessage
                          id="EntityManagement.entityRegistering"
                          defaultMessage="Registering organization..."
                        />
                      }
                    >
                      <ButtonIcon>arrow_forward</ButtonIcon>
                      Register Organization
                    </LoadingButton>
                    {showInProgress !== true || showErrorModal === true ? (
                      <React.Fragment />
                    ) : (
                      <CircularProgress className="loadingButton-leftPadding" />
                    )}
                  </CardActions>
                </Card>
              </Form>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>

        <ErrorModal
          open={showErrorModal}
          onCancel={this.onErrorModalClose}
          message={errorMessage}
        />
        <Confirm
          open={showSuccessModal}
          onCancel={this.onSuccessModalClose}
          onAccept={this.toSubscriptions}
          message={successMessage}
        />
      </React.Fragment>
    );
  }
}

const EntityRegistrationInput = ({ helperText, onClear, ...rest }) => (
  <TextField
    autoComplete="off"
    autoCorrect="off"
    spellCheck={false}
    fullWidth
    type="string"
    inputMode="text"
    trailingIcon={
      <TextFieldIcon title="Clear Current Value" onClick={onClear}>
        clear
      </TextFieldIcon>
    }
    helperText={
      <TextFieldHelperText persistent>{helperText}</TextFieldHelperText>
    }
    {...rest}
  />
);

EntityManagement = connect()(WithNavigationToHome(EntityManagement));
class EntityManagementContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <EntityManagement {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(EntityManagementContainer);
