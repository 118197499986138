import PendingActivations from "../components/PendingActivations";
import { connect } from "react-redux";
import { actions } from "../reducers/pendingUsers";

export default connect(
  // mapStateToProps
  state => ({
    error: state.pendingUsers.error,
    adminUpdateStatus: state.pendingUsers.adminUpdateStatus,
    pendingUsers: state.pendingUsers.pendingUsers,
    entities: state.pendingUsers.organizations
  }),
  // Inject all actions into the component
  actions
)(PendingActivations);
