import React from "react";

import { Button } from "@cdk-uip/react-button";
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from "@cdk-uip/react-data-table";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Icon } from "@cdk-uip/react-icon";
import { TextField } from "@cdk-uip/react-text-field";

import PropTypes from "prop-types";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import NumberWithTitle from "../components/NumberWithTitle";
import { Select } from "@cdk-uip/react-select";
import registrationTypes from "./types";
import { connect } from "react-redux";

const STATE = {
  APPROVED: "ACTIVE",
  STAGED: "STAGED",
  SUSPENDED: "SUSPENDED",
  PROVISIONED: "PROVISIONED"
};

const ORGANIZATION = "Company/Organization";
const TEST_REGISTRATIONS_DOMAIN = "mailinator.com";
const INTERNAL_EMAILS = [
  "cdk.com",
  "fortellis.io",
  "apigee.com",
  "google.com",
  "example.com",
  "apigee.corp-partner.google.com",
  "achieveinternet.com"
];

const validUserAccount = email => {
  return !email.endsWith(TEST_REGISTRATIONS_DOMAIN);
};

class RegistrationMetrics extends React.Component {
  static propTypes = {
    fetchUserRegistrations: PropTypes.func.isRequired
  };

  static defaultProps = {};
  state = {
    registrationsData: {
      userlist: []
    },
    startDate: "",
    endDate: "",
    firstUserCountCall: false
  };

  componentDidMount() {
    const {
      auth: { accessToken }
    } = this.props;
    const { startDate, endDate } = this.state;
    if (!(startDate && endDate)) {
      let todaysDate = new Date();
      let dateInUTC = `${todaysDate.getFullYear()}-${todaysDate.getMonth() +
        1}-${todaysDate.getDate()}`;
      this.props.fetchUserRegistrations({
        accessToken,
        startDate: dateInUTC,
        endDate: dateInUTC
      });
      this.props.fetchAppRegistrations({
        accessToken,
        startDate: dateInUTC,
        endDate: dateInUTC
      });
      this.props.fetchAllTermsAndConditionsVersions({ accessToken });
      this.setState({
        startDate: dateInUTC,
        endDate: dateInUTC
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      list,
      selectedVersion
    } = this.props.allTermsAndConditionsVersionsData;
    if (!this.state.firstUserCountCall && list) {
      this.getUserCount(selectedVersion);
      this.setState({ firstUserCountCall: true });
      this.getTotalUserCount();
    }
  }

  fetchRegistrations = () => {
    const {
      auth: { accessToken }
    } = this.props;
    const { selectedVersion } = this.props.allTermsAndConditionsVersionsData;
    const { startDate, endDate } = this.state;
    this.props.fetchUserRegistrations({ accessToken, startDate, endDate });
    this.props.fetchAppRegistrations({ accessToken, startDate, endDate });
    this.props.fetchUserCount({
      accessToken,
      startDate,
      endDate,
      selectedVersion
    });
  };

  getAppRegistrations = () => {
    const { apps, isFetching, error } = this.props.appRegistrationsData;

    let display = "";
    if (!isFetching && error) {
      display = <h3>Unable to load app metrics.</h3>;
    } else if (!apps) {
      display = <h3>Loading App Metrics...</h3>;
    } else {
      display = (
        <React.Fragment>
          <NumberWithTitle
            number={apps.internalApps.length}
            title="Internal Apps"
          />
          <NumberWithTitle
            number={apps.externalApps.length}
            title="External Apps"
          />
        </React.Fragment>
      );
    }
    return (
      <div className="c-dash-chart__centered-card-text-wrapper">{display}</div>
    );
  };

  onVersionChange = e => {
    this.props.dispatch({
      type: registrationTypes.SET_SELECTED_VERSION,
      selectedVersion: e.target.value
    });
    this.getUserCount(e.target.value);
  };

  getAllTnCVersion = () => {
    const {
      list,
      selectedVersion
    } = this.props.allTermsAndConditionsVersionsData;

    return (
      <div>
        <Select
          label="Select A Version :"
          className="c-search-input--type"
          value={selectedVersion}
          onChange={this.onVersionChange.bind(this)}
          allowEmpty={false}
        >
          {list &&
            list.map((version, index) => (
              <option key={index} value={version}>
                Terms And Conditions {version}
              </option>
            ))}
        </Select>
      </div>
    );
  };

  getUserCount = selectedVersion => {
    const {
      auth: { accessToken }
    } = this.props;
    const { startDate, endDate } = this.state;

    this.props.fetchUserCount({
      accessToken,
      startDate,
      endDate,
      selectedVersion
    });
  };

  getTotalUserCount = () => {
    const {
      auth: { accessToken }
    } = this.props;

    this.props.fetchTotalUserCount({
      accessToken
    });
  };

  render() {
    const {
      list: registrations,
      isFetching,
      error = ""
    } = this.props.registrationsData;
    const { count } = this.props.userCountData;
    const { totalUserCount } = this.props.totalUserCountData;

    if (!isFetching && error) {
      return <div>Unable to load metrics.</div>;
    } else if (!registrations) {
      return <div>Loading Metrics...</div>;
    } else if (registrations) {
      let organizationNames = [];
      let registrationCount = 0,
        byCategory = {
          cdk: 0,
          nonCdk: 0
        },
        byType = {
          individual: 0,
          organization: 0
        },
        byRegistrationStatus = {
          approved: {
            count: 0,
            list: []
          },
          declined: {
            count: 0,
            list: []
          },
          pendingApproval: {
            count: 0,
            list: []
          },
          pendingEmailVerification: {
            count: 0,
            list: []
          },
          pendingUserAction: {
            count: 0,
            list: []
          }
        };

      registrations.forEach(eachItem => {
        const {
          status,
          profile: { isEmailValidated, login, legalEntityType, legalEntityName }
        } = eachItem;
        if (validUserAccount(login)) {
          registrationCount++;
          // CHECK REGISTRATION TYPE
          if (legalEntityType === ORGANIZATION) {
            byType.organization++;
            organizationNames.push(legalEntityName);
          } else {
            byType.individual++;
          }

          // CHECK CDK/NON-CDK
          let [, emailDomain = ""] = login.split("@");

          INTERNAL_EMAILS.includes(emailDomain)
            ? byCategory.cdk++
            : byCategory.nonCdk++;

          //CHECK REGISTRATION STATUS
          if (status === STATE.APPROVED) {
            byRegistrationStatus.approved.count++;
            byRegistrationStatus.approved.list.push(login);
          } else if (status === STATE.STAGED) {
            if (isEmailValidated) {
              byRegistrationStatus.pendingApproval.count++;
              byRegistrationStatus.pendingApproval.list.push(login);
            } else {
              byRegistrationStatus.pendingEmailVerification.count++;
              byRegistrationStatus.pendingEmailVerification.list.push(login);
            }
          } else if (status === STATE.SUSPENDED) {
            byRegistrationStatus.declined.count++;
            byRegistrationStatus.declined.list.push(login);
          } else if (status === STATE.PROVISIONED) {
            byRegistrationStatus.pendingUserAction.count++;
            byRegistrationStatus.pendingUserAction.list.push(login);
          } else {
            console.error({
              message: "UNKNOWN USER STATE IDENTIFIED",
              critical: true,
              context: "ACCOUNTS_ADMIN_UI"
            });
          }
        }
      });

      return (
        <React.Fragment>
          {/* #START DASH SIMPLE LAYOUT */}

          {/* #TEMPLATE GRID CELL (BLANK) */}
          {/* #START GRID CELL */}
          {/*<LayoutGridCell*/}
          {/*className="c-layout-section__dash-grid-cell"*/}
          {/*span={12}*/}
          {/*spanTablet={8}*/}
          {/*spanPhone={4}*/}
          {/*>*/}
          {/*</LayoutGridCell>*/}
          {/* #END GRID CELL */}
          {/* #END TEMPLATE GRID CELL (BLANK) */}

          {/* #TEMPLATE DASH MODULE (BLANK) */}
          {/*<Elevation z={10} className={"c-dash-chart__wrapper"}>*/}
          {/*<Card className={"c-dash-chart"}>*/}
          {/*<CardHeader>*/}
          {/*<CardTitle large>*/}
          {/*Title*/}
          {/*</CardTitle>*/}
          {/*<CardSubtitle>*/}
          {/*Subtitle*/}
          {/*</CardSubtitle>*/}
          {/*</CardHeader>*/}
          {/*<CardText>*/}
          {/*/!* #START CHART DATA *!/*/}

          {/* #UI-DESIGN: I recommend @cdk-uip/react <DataTable>s because it can have pagination, toolbars for sorting, date ranging, other selections, etc.
              styleguide:
              http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react/#datatable
              storybook:
              http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react-stories/?knob-z=2&knob-media%20content=true&knob-Show%20home=true&knob-Selectable%20rows=true&knob-Show%20toolbar=true&knob-Show%20pagination%20controls=true&knob-Hover%20state=true&knob-media%20scale=16-9&knob-On%20a%20card=true&knob-large%20title=true&selectedKind=data-table&selectedStory=props&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybooks%2Fstorybook-addon-knobs
          */}

          {/*/!* #END CHART DATA *!/*/}
          {/*</CardText>*/}
          {/*</Card>*/}
          {/*</Elevation>*/}
          {/* #END TEMPLATE DASH MODULE (BLANK) */}

          {/*// Grid of items with tweaks at different screen sizes*/}
          {/*// Grid with max width (1280px) and center alignment by default*/}
          {/* #START GRID */}
          <LayoutGrid className="s-content-route--registration-metrics c-layout-section__dash-grid">
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={12}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle>Report by Date Range</CardTitle>
                    {/*<CardSubtitle>*/}
                    {/*<h1 className={"c-dash-chart__number-displayed--large"}>{registrationCount}</h1>*/}
                    {/*</CardSubtitle>*/}
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}
                    {/*From: <input id="date" type="date" value="2017-06-01" />*/}
                    {/*<br />*/}
                    {/*To: <input id="date" type="date" value="2017-06-01" />*/}

                    <React.Fragment>
                      <DataTable>
                        <DataTableHeader>
                          <DataTableRow>
                            <DataTableHeaderCell nonNumeric>
                              Start Date: {this.state.startDate}
                            </DataTableHeaderCell>
                            <DataTableHeaderCell nonNumeric>
                              End Date: {this.state.endDate}
                            </DataTableHeaderCell>
                            <DataTableHeaderCell nonNumeric />
                          </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                          <DataTableRow>
                            <DataTableCell nonNumeric>
                              <TextField
                                dense
                                type="date"
                                label=""
                                leadingIcon={
                                  // #TODO: DEV COMMENT: WILL CONVERT TO 'TextFieldIcon' as-soon-as WE UPDATE THEMEING, SO THAT FONT OVERRIDES STOP BREAKING THE LIB.
                                  <Icon>today{/* calendar/day icon */}</Icon>
                                  // <TextFieldIcon>today{/* calendar/day icon */}</TextFieldIcon>
                                }
                                value={this.state.startDate}
                                onChange={e =>
                                  this.setState({ startDate: e.target.value })
                                }
                              />
                            </DataTableCell>
                            <DataTableCell nonNumeric>
                              <TextField
                                dense
                                type="date"
                                label=""
                                leadingIcon={
                                  // #TODO: DEV COMMENT: WILL CONVERT TO 'TextFieldIcon' as-soon-as WE UPDATE THEMEING, SO THAT FONT OVERRIDES STOP BREAKING THE LIB.
                                  <Icon>today{/* calendar/day icon */}</Icon>
                                  // <TextFieldIcon>today{/* calendar/day icon */}</TextFieldIcon>
                                }
                                value={this.state.endDate}
                                onChange={e =>
                                  this.setState({ endDate: e.target.value })
                                }
                              />
                            </DataTableCell>
                            <DataTableCell nonNumeric>
                              <Button
                                primary
                                raised
                                onClick={this.fetchRegistrations}
                              >
                                {/* #TODO: DEV COMMENT: WILL CONVERT TO 'ButtonIcon' as-soon-as WE UPDATE THEMEING, SO THAT FONT OVERRIDES STOP BREAKING THE LIB.*/}
                                <Icon
                                  className={
                                    "c-card-pending-users__button-icon"
                                  }
                                >
                                  insert_chart {/* chart icon */}
                                </Icon>
                                Update Dashboard
                              </Button>
                            </DataTableCell>
                          </DataTableRow>
                        </DataTableBody>
                      </DataTable>
                    </React.Fragment>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES*/}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle>Total User Registrations</CardTitle>
                    {/*<CardSubtitle>*/}
                    {/*<h1 className={"c-dash-chart__number-displayed--large"}>{registrationCount}</h1>*/}
                    {/*</CardSubtitle>*/}
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}
                    <h1 className={"c-dash-chart__number-displayed--large"}>
                      {registrationCount}
                    </h1>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES*/}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle>Total Apps Registered</CardTitle>
                    <CardSubtitle>
                      {/*<h1>{appRegistrationCount}</h1>*/}
                    </CardSubtitle>
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}
                    {this.getAppRegistrations()}
                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES*/}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Registration Type</CardTitle>
                    <CardSubtitle>Individual or Organization</CardSubtitle>
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}

                    <DataTable>
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell nonNumeric>
                            Type
                          </DataTableHeaderCell>
                          <DataTableHeaderCell>Quantity</DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                      <DataTableBody>
                        <DataTableRow>
                          <DataTableCell nonNumeric>Individual</DataTableCell>
                          <DataTableCell>{byType.individual}</DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableCell nonNumeric>Organization</DataTableCell>
                          <DataTableCell>{byType.organization}</DataTableCell>
                        </DataTableRow>
                      </DataTableBody>
                    </DataTable>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES*/}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Registration Category</CardTitle>
                    <CardSubtitle>Non-CDK or CDK</CardSubtitle>
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}

                    <DataTable>
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell nonNumeric>
                            Category
                          </DataTableHeaderCell>
                          <DataTableHeaderCell>Quantity</DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                      <DataTableBody>
                        <DataTableRow>
                          <DataTableCell nonNumeric>Non-CDK</DataTableCell>
                          <DataTableCell>{byCategory.nonCdk}</DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableCell nonNumeric>CDK</DataTableCell>
                          <DataTableCell>{byCategory.cdk}</DataTableCell>
                        </DataTableRow>
                      </DataTableBody>
                    </DataTable>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES */}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Approval Status</CardTitle>
                    <CardSubtitle>
                      Verified, Approved, Pending, Declined
                    </CardSubtitle>
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}

                    <DataTable>
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell nonNumeric>
                            Status
                          </DataTableHeaderCell>
                          <DataTableHeaderCell>Quantity</DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                      <DataTableBody>
                        <DataTableRow>
                          <DataTableCell nonNumeric>Approved</DataTableCell>
                          <DataTableCell>
                            {byRegistrationStatus.approved.count}
                          </DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableCell nonNumeric>
                            Pending Approval
                          </DataTableCell>
                          <DataTableCell>
                            {byRegistrationStatus.pendingApproval.count}
                          </DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableCell nonNumeric>
                            Pending Email Verification
                          </DataTableCell>
                          <DataTableCell>
                            {
                              byRegistrationStatus.pendingEmailVerification
                                .count
                            }
                          </DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableCell nonNumeric>
                            Pending User Action
                          </DataTableCell>
                          <DataTableCell>
                            {byRegistrationStatus.pendingUserAction.count}
                          </DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                          <DataTableCell nonNumeric>Declined</DataTableCell>
                          <DataTableCell>
                            {byRegistrationStatus.declined.count}
                          </DataTableCell>
                        </DataTableRow>
                      </DataTableBody>
                    </DataTable>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES */}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={6}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Terms & Conditions</CardTitle>
                    <CardSubtitle>
                      Latest document and previous versions
                    </CardSubtitle>
                  </CardHeader>
                  <CardText className={"minHeight-264"}>
                    {this.getAllTnCVersion()}
                    <h1 className={"c-dash-chart__centered-card-text-wrapper"}>
                      <React.Fragment>
                        <NumberWithTitle
                          number={count}
                          title="Users Accepted"
                        />
                        <NumberWithTitle
                          number={totalUserCount}
                          title="Total Users"
                        />
                      </React.Fragment>
                    </h1>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES */}
            </LayoutGridCell>
            {/* #END GRID CELL */}
            {/* #START GRID CELL */}
            <LayoutGridCell
              className="c-layout-section__dash-grid-cell"
              span={12}
              spanTablet={8}
              spanPhone={4}
            >
              {/* #START DASH MODULES */}

              <Elevation z={10} className={"c-dash-chart__wrapper"}>
                <Card className={"c-dash-chart"}>
                  <CardHeader>
                    <CardTitle large>Organizations Registered</CardTitle>
                    <CardSubtitle />
                  </CardHeader>
                  <CardText>
                    {/* #START CHART DATA */}

                    <DataTable>
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell nonNumeric>
                            Organization Name
                          </DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                      <DataTableBody>
                        {(organizationNames || []).map(name => (
                          <DataTableRow key={name}>
                            <DataTableCell nonNumeric>{name}</DataTableCell>
                          </DataTableRow>
                        ))}
                      </DataTableBody>
                    </DataTable>

                    {/* #END CHART DATA */}
                  </CardText>
                </Card>
              </Elevation>

              {/* #END DASH MODULES*/}
            </LayoutGridCell>
            {/* #END GRID CELL */}
          </LayoutGrid>
          {/* #END GRID */}
        </React.Fragment>
      );
    }
  }
}

RegistrationMetrics = connect()(WithNavigationToHome(RegistrationMetrics));
class RegistrationMetricsContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <RegistrationMetrics {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(RegistrationMetricsContainer);
