import React, { useState, useEffect, Fragment } from "react";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import BundleCard from "./BundleCard";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import axios from "axios";
import "./style.css";
import config from "../config/app.conf.json";

function BundleListV2({
  setBundleList,
  setUnAuthorizedUser,
  bundleList,
  entity = {},
  accessToken,
  searchQuery,
  setBundleShareName,
  setBundleShareId,
  setScreen,
  setSelectedBundle
}) {
  const [fetchingBundle, setFetchingBundle] = useState(false);
  const [filteredBundleList,setFilteredBundleList]=useState([])

  useEffect(()=>{
    setFilteredBundleList(bundleList)
  },[])

  useEffect(()=>{
    const filteredBundles=bundleList?.filter((bundle) =>
      bundle?.name?.toLowerCase().includes(searchQuery?.toLowerCase()));
      setFilteredBundleList(filteredBundles)
  },[bundleList,searchQuery])

  useEffect(() => {
    setFetchingBundle(true);
    if (accessToken !== null) {
      axios
        .get(`${config.api.api_gateway_url}/v2/admin/bundles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          setFetchingBundle(false); 
          setBundleList(
            response?.data?.items?.sort(
              (a, b) => new Date(b.updated) - new Date(a.updated)
            )
          );
          setUnAuthorizedUser(false);
        })
        .catch(error => {
          setUnAuthorizedUser(true);
          setFetchingBundle(false);
        });
    }
  }, [accessToken]);
  return (
    <Fragment>
      {fetchingBundle ? (
        <div className="loading-container">
          <h3>Loading Bundle Details...</h3>
          <CircularProgress />
        </div>
      ) : (
        <div className="bundle-list_container">
          <Grid>
            <Row></Row>
            <Row>
              {filteredBundleList.map((bundle, index) => (
                <Cell
                  desktopColumns={6}
                  phoneColumns={5}
                  tabletColumns={5}
                  key={index}
                >
                  <BundleCard
                    bundleList={bundleList}
                    apiBundle={bundle}
                    entity={entity}
                    setScreen = {setScreen}
                    setBundleShareId={setBundleShareId}
                    setBundleShareName={setBundleShareName}
                    isVersion2 = {true}
                    setSelectedBundle = {setSelectedBundle}
                  />
                </Cell>
              ))}
            </Row>
          </Grid>
        </div>
      )}
    </Fragment>
  );
}
export default BundleListV2;

