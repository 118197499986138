import React from "react";

import { Icon } from "@cdk-uip/react-icon";

const FieldWithIcon = ({ icon, value, type }) => {
  return (
    <div className="c-field-with-icon" title={type}>
      <Icon>{icon}</Icon>
      <div className="c-field-with-icon__value">{value}</div>
    </div>
  );
};

export default FieldWithIcon;
