import types from "./types";

const INITIAL_STATE = {
  auditEvent: [],
  nextPage: null,
  reportLoading: false,
  error: "",
  showErrorModal: false
};
const auditReportReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_AUDIT_REPORT_REQUEST:
      return {
        ...state,
        reportLoading: true,
        error: ""
      };

    case types.GET_AUDIT_REPORT_RECEIVE:
      return {
        ...state,
        ...action.response,
        reportLoading: false,
        error: ""
      };

    case types.GET_AUDIT_REPORT_ERROR:
      return {
        ...state,
        reportLoading: false,
        error: "Failed to load data"
      };

    default:
      return state;
  }
};

export default auditReportReducers;
