import SignedUpUsers from "../SignedUpUsers/SignedUpUsers";
import { connect } from "react-redux";
import { actions } from "../SignedUpUsers/SignedUpUsersAction";

export default connect(
  state => {
    return {
      signedUpUsers: state.signedUpUsersReducer.signedUpUsers || {},
      sendEmailToSignedUpUsers:
        state.signedUpUsersReducer.resendEmailToVerify || {}
    };
  },
  actions
)(SignedUpUsers);
