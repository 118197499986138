import React from "react";
const Flex = ({ direction, justify, align, children, gap, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction || "",
        justifyContent: justify || "",
        alignItems: align || "",
        gap: gap || ""
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
