import React, { Component } from "react";
import {
  DataTableHeader,
  DataTableRow,
  DataTableHeaderCell
} from "@cdk-uip/react-data-table";
import { SORT_TYPES, ID_TYPES } from "./comparator";

export default class Header extends Component {
  render() {
    return (
      <DataTableHeader>
        <DataTableRow>
          {this.props.categories
            .filter(category => {
              if (
                !this.props.isApprovedColumnVisible &&
                (category.id === ID_TYPES.APPROVED_DATE ||
                  category.id === ID_TYPES.APPROVEDDATE)
              ) {
                return false;
              }
              return true;
            })
            .map(category => (
              <HeaderCell
                sortTypes={SORT_TYPES}
                key={category.name}
                name={category.name}
                id={category.id}
                onClick={this.props.onClick}
                ascending={this.props.ascending}
                previousClicked={this.props.previousClicked}
                className={category.className}
                style={category.style}
              />
            ))}
        </DataTableRow>
      </DataTableHeader>
    );
  }
}

class HeaderCell extends Component {
  render() {
    const sortable =
      this.props.sortable !== undefined ? this.props.sortable : true;
    return (
      <DataTableHeaderCell
        sortable={sortable}
        nonNumeric
        className={this.props.className}
        style={this.props.style}
        sortDirection={
          this.props.ascending === true &&
          this.props.id === this.props.previousClicked
            ? "asc"
            : this.props.ascending === false &&
              this.props.id === this.props.previousClicked
            ? "desc"
            : "none"
        }
        onClick={() => {
          let type = SORT_TYPES.ALPHANUMERIC;
          switch (this.props.id) {
            case ID_TYPES.CONTACT:
              type = SORT_TYPES.EMAIL;
              break;
            case ID_TYPES.SUBMITTED:
            case ID_TYPES.CREATED:
            case ID_TYPES.LAST_UPDATED:
            case ID_TYPES.LASTUPDATED:
            case ID_TYPES.APPROVED_DATE:
            case ID_TYPES.APPROVEDDATE:
              type = SORT_TYPES.DATE;
              break;
            default:
              type = SORT_TYPES.ALPHANUMERIC;
              break;
          }
          this.props.onClick(this.props.id, !this.props.ascending, type);
        }}
      >
        {this.props.name}
      </DataTableHeaderCell>
    );
  }
}
