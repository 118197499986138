import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, IconFileDownload } from "cdk-radial";

const PanelBodyButton = ({ id, icon, children, ...rest }) => {
  return (
    <PrimaryButton
      id={id}
      className="fdn-content__action-button"
      icon={<IconFileDownload />}
      text={children}
      {...rest}
    />
  );
};

PanelBodyButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default PanelBodyButton;
