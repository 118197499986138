import types from "./types";
import { combineReducers } from "redux";

const approvedUsers = (
  state = {
    isFetching: false,
    error: false,
    list: [],
    isUserSuspended: false,
    userSuspendedMessage: ""
  },
  action
) => {
  switch (action.type) {
    case types.APPROVED_USERS_GET_REQUEST:
      return Object.assign({}, state, { isFetching: true, error: false });
    case types.APPROVED_USERS_GET_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.response.approvedUsers,
        error: false,
        nextUri: action.response.retrieveNextUri
      });
    case types.APPROVED_USERS_GET_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: "An error occured fetching approved users"
      });
    case types.APPROVED_USERS_GET_REQUEST_ON_SHOW_MORE:
      return Object.assign({}, state, {
        isFetchingOnShowMore: true,
        error: false
      });
    case types.APPROVED_USERS_GET_RECEIVE_ON_SHOW_MORE:
      let newState1 = Object.assign({}, state, {});
      let updatedlist = newState1.list.concat(action.response.approvedUsers);
      return Object.assign({}, state, {
        isFetchingOnShowMore: false,
        list: updatedlist,
        error: false,
        nextUri: action.response.retrieveNextUri
      });
    case types.APPROVED_USERS_GET_ERROR_ON_SHOW_MORE:
      return Object.assign({}, state, {
        isFetchingOnShowMore: false,
        error: "An error occured fetching more approved users"
      });
    case types.SUSPEND_USER_REQUEST:
      return Object.assign({}, state, {
        userSuspendedMessage: false,
        isUserSuspended: false
      });

    case types.SUSPEND_USER_RECEIVE:
      let suspendUserSuccessMessage = "User suspended successfully";
      return Object.assign({}, state, {
        userSuspendedMessage: suspendUserSuccessMessage,
        isUserSuspended: true
      });
    case types.SUSPEND_USER_ERROR:
      let suspendUserErrorMessage = "Failed to suspend user";
      if (action.response.status === 403) {
        suspendUserErrorMessage = action.response.data;
      }
      return Object.assign({}, state, {
        userSuspendedMessage: suspendUserErrorMessage,
        isUserSuspended: true
      });
    default:
      return state;
  }
};

const userEntities = (
  state = {
    isFetching: false,
    fetchingError: false,
    list: [],
    isRemoving: false,
    removingError: "",
    isAdding: false,
    addingError: false
  },
  action
) => {
  switch (action.type) {
    case types.LINKED_ENTITIES_GET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        fetchingError: false,
        message: false
      });
    case types.LINKED_ENTITIES_GET_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.response.organizations,
        fetchingError: false,
        message: false
      });
    case types.LINKED_ENTITIES_GET_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        fetchingError: "An error occured while fetching linked entities",
        message: false
      });
    case types.REMOVE_USER_FROM_ENTITY_DELETE_REQUEST:
      return Object.assign({}, state, {
        isRemoving: true,
        removingError: false,
        message: false
      });
    case types.REMOVE_USER_FROM_ENTITY_DELETE_RECEIVE:
      let newState = Object.assign({}, state, {});
      let list = newState.list.filter(entity => {
        return entity.id !== action.entityId;
      });
      return Object.assign({}, state, {
        isRemoving: false,
        list,
        response: action.response,
        removingError: false,
        message: "User removed from organisation successfully"
      });
    case types.REMOVE_USER_FROM_ENTITY_DELETE_ERROR:
      return Object.assign({}, state, {
        isRemoving: false,
        removingError: action.apierror.message,
        message: "User could not be removed from entity"
      });
    case types.ADD_USER_TO_ENTITY_GET_REQUEST:
      return Object.assign({}, state, {
        isAdding: true,
        entityId: action.entity.id,
        addingError: false,
        message: false
      });
    case types.ADD_USER_TO_ENTITY_GET_RECEIVE:
      let newState1 = Object.assign({}, state, {});
      let list1 = newState1.list.push(action.entity);
      return Object.assign({}, state, {
        isAdding: false,
        list1,
        entityId: action.entity.id,
        addingError: false,
        message: "User added to organisation successfully"
      });
    case types.ADD_USER_TO_ENTITY_GET_ERROR:
      return Object.assign({}, state, {
        isAdding: false,
        addingError: true,
        message: "User could not be added to organisation"
      });
    default:
      return state;
  }
};

export default combineReducers({
  approvedUsers,
  userEntities
});
