import types from "./types";
import { stateMapper } from "./stateMapper";

const INITIAL_STATE = {
  errorMessage: "",
  showErrorModal: false,
  getSubSolutionError: "",
  solutions: "",
  subscriptions: "",
  asyncAccessGrants: "",
  apiProducts: "",
  providers: "",
  orgs: "",
  alertMessage: "",
  showAlertModal: false,
  apiActivationDeactivation: {}
};

const SUB_RESET = {
  errorMessage: "",
  showErrorModal: false,
  subscriptions: ""
};

const ERROR_RESET = {
  errorMessage: "",
  showErrorModal: false,
  apiActivationDeactivation: { error: "" }
};

const ALERT_RESET = {
  alertMessage: "",
  showAlertModal: false
};

const returnStateWithUpdate = function(state, update) {
  return Object.assign({}, state, update);
};

export default function subscriptionManagement(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ERROR:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        showErrorModal: true
      });
    case types.SOLUTIONS_GET_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.SOLUTIONS_GET_RECEIVE:
      return action.response && action.response.payload
        ? stateMapper.mapSolutionsDataToState({
            state,
            data: action.response.payload
          })
        : state;
    case types.SOLUTIONS_GET_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to fetch solutions",
        showErrorModal: true
      });
    case types.GET_ENTITY_SUB_REQUEST:
      return Object.assign(
        {},
        stateMapper.resetEntityContext({ state, entityId: action.entityId }),
        ERROR_RESET
      );
    case types.GET_ENTITY_SUB_RECEIVE:
      return action.response && action.response.subscriptions
        ? stateMapper.mapSubscriptionsDataToState({
            state,
            loadedEntityId: action.entityId,
            data: action.response.subscriptions
          })
        : state;
    case types.GET_ENTITY_SUB_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch subscriptions for ${action.entityId}`,
        showErrorModal: true
      });
    case types.GET_ENTITY_ASYNC_ACCESS_GRANTS_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.GET_ENTITY_ASYNC_ACCESS_GRANTS_RECEIVE:
      return action.response
        ? stateMapper.mapEntityAsyncAccessGrantsToState({
            state,
            data: action.response
          })
        : state;
    case types.GET_ENTITY_ASYNC_ACCESS_GRANTS_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch Async Access Grants for ${action.dataOwnerOrgId}`,
        showErrorModal: true
      });
    case types.UPSERT_ASYNC_ACCESS_GRANTS_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.UPSERT_ASYNC_ACCESS_GRANTS_RECEIVE:
      return action.response
        ? stateMapper.mapUpsertAsyncAccessGrantsToState({
            state,
            data: action.response
          })
        : state;
    case types.UPSERT_ASYNC_ACCESS_GRANTS_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to upsert Async Access Grants for ${action.api}`,
        showErrorModal: true
      });
    case types.DISABLE_ASYNC_ACCESS_GRANTS_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.DISABLE_ASYNC_ACCESS_GRANTS_RECEIVE:
      return action.integrationId
        ? stateMapper.mapDisableAsyncAccessGrantsToState({
            state,
            data: action.integrationId
          })
        : state;
    case types.DISABLE_ASYNC_ACCESS_GRANTS_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch Async Access Grants for ${action.api}`,
        showErrorModal: true
      });
    case types.GET_SUB_SOLUTION_REQUEST:
      return returnStateWithUpdate(state, {
        getSubSolutionError: null
      });
    case types.GET_SUB_SOLUTION_RECEIVE:
      return action.response
        ? stateMapper.mapSubSolutionInfoToState({
            state,
            solutionId: action.solutionId,
            data: action.response
          })
        : state;
    case types.GET_SUB_SOLUTION_ERROR:
      return stateMapper.mapSubSolutionInfoToState({
        state,
        solutionId: action.solutionId,
        error: true
      });
    case types.GET_SUB_CONNECTIONS_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.GET_SUB_CONNECTIONS_RECEIVE:
      return action.response && action.response.connections
        ? stateMapper.mapConnectionDataToState({
            state,
            data: action.response.connections,
            subscriptionId: action.subscriptionId
          })
        : state;
    case types.GET_SUB_CONNECTIONS_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch connection information for ${action.subscriptionId}`,
        showErrorModal: true
      });
    case types.GET_ORG_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.GET_ORG_RECEIVE:
      return action.response
        ? stateMapper.mapOrgsToState({
            state,
            data: action.response,
            orgId: action.orgId
          })
        : state;
    case types.GET_ORG_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch async api information for ${action.apiId}`,
        showErrorModal: true
      });
    case types.GET_PROVIDERS_FOR_API_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.GET_PROVIDERS_FOR_API_RECEIVE:
      return action.response
        ? stateMapper.mapAvailbleProvidersForAPIToState({
            state,
            data: action.response,
            api: action.api
          })
        : state;
    case types.GET_PROVIDERS_FOR_API_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch providers for ${action.api}`,
        showErrorModal: true
      });
    case types.GET_PROVIDER_BY_ID_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.GET_PROVIDER_BY_ID_RECEIVE:
      return action.response
        ? stateMapper.mapProviderInfoToState({
            state,
            data: action.response,
            providerId: action.providerId
          })
        : state;
    case types.GET_PROVIDER_BY_ID_ERROR:
      return Object.assign({}, state, {
        errorMessage: "Unable to fetch providerInfo",
        showErrorModal: true
      });
    case types.ACTIVATE_CONNECTION_REQUEST:
      return returnStateWithUpdate(state, {
        apiActivationDeactivation: { operation: "Activation", inProgress: true }
      });
    case types.ACTIVATE_CONNECTION_RECEIVE:
      return action.response
        ? stateMapper.mapConnectionActivationResponseToState({
            state,
            data: action.response
          })
        : state;
    case types.ACTIVATE_CONNECTION_ERROR:
      return Object.assign({}, state, {
        apiActivationDeactivation: {
          operation: "Activation",
          error: (action.apierror && action.apierror.payload) || {},
          inProgress: false
        }
      });
    case types.DISABLE_CONNECTION_REQUEST:
      return returnStateWithUpdate(state, {
        apiActivationDeactivation: {
          operation: "Deactivation",
          inProgress: true
        }
      });
    case types.DISABLE_CONNECTION_RECEIVE:
      return action.response
        ? stateMapper.mapDisableConnectionResponseToState({
            state,
            data: action.response
          })
        : state;
    case types.DISABLE_CONNECTION_ERROR:
      return Object.assign({}, state, {
        apiActivationDeactivation: {
          operation: "Deactivation",
          error: (action.apierror && action.apierror.payload) || {},
          inProgress: false
        }
      });
    case types.CREATE_SUB_POST_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.CREATE_SUB_POST_RECEIVE:
      return action.response
        ? stateMapper.mapCreateSubscriptionResposeToState({
            state,
            data: action.response,
            displayName: action.displayName
          })
        : state;
    case types.CREATE_SUB_POST_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Subscription failed for ${action.displayName}`,
        showErrorModal: true
      });
    case types.POST_ENABLE_SUB_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.POST_ENABLE_SUB_RECEIVE:
      return action.response
        ? stateMapper.mapSubscriptionEnablementToState({
            state,
            data: action.response,
            displayName: action.displayName
          })
        : state;
    case types.POST_ENABLE_SUB_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Enabling ${action.displayName} subscription failed`,
        showErrorModal: true
      });
    case types.POST_DISABLE_SUB_REQUEST:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.POST_DISABLE_SUB_RECEIVE:
      return action.response
        ? stateMapper.mapSubscriptionDisablementToState({
            state,
            data: action.response,
            displayName: action.displayName
          })
        : state;
    case types.POST_DISABLE_SUB_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Disabling ${action.displayName} subscription failed`,
        showErrorModal: true
      });
    case types.ERROR_RESET:
      return returnStateWithUpdate(state, ERROR_RESET);
    case types.ALERT_RESET:
      return returnStateWithUpdate(state, ALERT_RESET);
    case types.GET_SUB_BY_ID_REQUEST:
      return returnStateWithUpdate(state, SUB_RESET);
    case types.GET_SUB_BY_ID_RECEIVE:
      return action.response && action.response.subscription
        ? stateMapper.mapSubDatatoState({
            state,
            data: action.response.subscription
          })
        : state;
    case types.GET_SUB_BY_ID_ERROR:
      return Object.assign({}, state, {
        errorMessage: `Unable to fetch subscription info for ${action.subscriptionId}`,
        showErrorModal: true
      });
    case types.RESET_SUBSCRIPTIONS:
      return returnStateWithUpdate(state, SUB_RESET);
    default:
      return returnStateWithUpdate(state, ERROR_RESET);
  }
}
