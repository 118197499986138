import types from "./types";

const allTermsAndConditions = (
  state = { isFetching: false, error: "", list: "" },
  action
) => {
  switch (action.type) {
    case types.ALL_TERMS_AND_CONDITIONS_GET_REQUEST:
      return { isFetching: true, error: "" };
    case types.ALL_TERMS_AND_CONDITIONS_GET_RECEIVE:
      return {
        isFetching: false,
        list: action.response.documents,
        error: ""
      };
    case types.ALL_TERMS_AND_CONDITIONS_GET_ERROR:
      return { isFetching: false, error: "An error occured" };
    default:
      return state;
  }
};

export default allTermsAndConditions;
