import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@cdk-uip/react-dialog";
import { TextEditor } from "@fortellis/text-editor";
import moment from "moment";

let isValidJsonString = tncText => {
  try {
    JSON.parse(tncText);
  } catch (e) {
    return false;
  }
  return true;
};

const TnCDetailsDialog = ({
  open,
  onCancel,
  tnc_version,
  tnc_text,
  updated_on
}) => {
  return (
    <Dialog
      open={open}
      onCancel={onCancel}
      className="terms_and_conditions-dialog"
    >
      <DialogHeader>
        <div className="dialog-header-container">
          Terms and Conditions Version{" "}
          {tnc_version ? tnc_version.toFixed(2) : tnc_version}
          <i
            onClick={onCancel}
            className="terms_and_conditions-dialog cdkicon-close close-icon"
          />
        </div>
      </DialogHeader>
      <DialogBody scrollable className={"terms_and_conditions-dialog"}>
        <div className="terms_and_conditions-dialog-updateOn">{`Updated: ${moment(
          updated_on
        ).format("MMMM DD, YYYY")}`}</div>
        {tnc_text && isValidJsonString(tnc_text) ? (
          <TextEditor
            readOnly={true}
            rawEditorContentState={JSON.parse(tnc_text)}
          />
        ) : (
          <div className={"terms_and_conditions-dialog-errorText"}>
            Unable to load invalid terms and conditions text
          </div>
        )}
      </DialogBody>
      <DialogFooter />
    </Dialog>
  );
};

export { TnCDetailsDialog };
