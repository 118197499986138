import moment from "moment";
const tomorrow = moment().add(1, "days");

const addOneDays = inputDate => {
  return moment(inputDate, "YYYY-MM-DD").add(1, "days");
};
const downLoadCSV = csvData => {
  const csvURL = window.URL.createObjectURL(csvData);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `Payment.csv`);
  tempLink.click();
};

export default { tomorrow, addOneDays, downLoadCSV };
