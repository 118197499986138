import SubscriptionReports from "./SubcriptionReports";
import { connect } from "react-redux";
import { actions } from "./SubscriptionReportsActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    state => ({
      subscriptionReports: state.subscriptionReports
    }),
    actions
  )(SubscriptionReports)
);
