import React, { useEffect, useState } from "react";
import "./style.css";
import {
  SimpleTable,
  SimpleTableBody,
  SimpleTableContainer,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  SimpleTableWrapper,
  SimpleTableTitle,
  SimpleTableToolbar,
  Heading,
  HEADING_TYPES,
  DatePicker,
  INPUT_SIZES,
  Button,
  BUTTON_VARIANTS,
  Dialog,
} from "cdk-radial";
import moment from 'moment';
import {
  DATE_FORMAT,
  API_TYPE,
  SAVE_DIALOG_MSG,
  SAVE_DIALOG_TITLE,
  DATE_PICKER_HELPER_TEXT,
  DATE_SAVE_PENDING_TEXT,
} from "./constants";

function ApiIntegrationTable({ heading, tableData, onSave, key }) {
  const [restApiData, setRestApiData] = useState({});
  const [asyncApiData, setAsyncApiData] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (
      (!Object.keys(restApiData).length && !Object.keys(asyncApiData).length) ||
      Object.values(restApiData).some((i) => i === "error") ||
      Object.values(asyncApiData).some((i) => i === "error")
    )
      setIsSaveDisabled(true);
    else setIsSaveDisabled(false);
  }, [asyncApiData, restApiData]);

  const handleSave = () => {
    setIsOpen(false);
    let restBody = [];
    let asyncBody = [];
    if (Object.keys(restApiData).length) {
      restBody = Object.keys(restApiData).map((apiIntegrationId) => ({
        apiIntegrationId,
        freeTrialEndDate:
          restApiData[apiIntegrationId]?.freeTrialEndDate || null,
        apiName: restApiData[apiIntegrationId]?.apiName || null,
      }));
    }
    if (Object.keys(asyncApiData).length) {
      asyncBody = Object.keys(asyncApiData).map((apiIntegrationId) => ({
        apiIntegrationId,
        freeTrialEndDate:
          asyncApiData[apiIntegrationId]?.freeTrialEndDate || null,
        apiName: asyncApiData[apiIntegrationId]?.apiName || null,
      }));
    }

    onSave(restBody, asyncBody);
  };

  const updateState = ({ apiType, id, data }) => {
    if (apiType === API_TYPE.REST) {
      if (
        data?.freeTrialEndDate !==
        (tableData[id].freeTrialOverrideEndDate &&
          tableData[id].freeTrialOverrideEndDate.format(DATE_FORMAT))
      ) {
        setRestApiData({ ...restApiData, [id]: data });
      } else if (restApiData[id] === null || restApiData[id]) {
        const restData = restApiData;
        delete restData[id];
        setRestApiData({ ...restData });
      }
    } else {
      if (
        data?.freeTrialEndDate !==
        (tableData[id].freeTrialOverrideEndDate &&
          tableData[id].freeTrialOverrideEndDate.format(DATE_FORMAT))
      ) {
        setAsyncApiData({ ...asyncApiData, [id]: data });
      } else if (asyncApiData[id] === null || asyncApiData[id]) {
        const asyncData = asyncApiData;
        delete asyncData[id];
        setAsyncApiData({ ...asyncData });
      }
    }
  };

  const onDialogCancel = () => setIsOpen(false);

  return (
    <React.Fragment>
      <SimpleTableWrapper
        data-testid="api-integration-table-wrapper"
        id="apiIntegrationTableWrapper"
        style={{ minHeight: "500px" }}
      >
        <SimpleTableToolbar>
          <SimpleTableTitle>
            <Heading headingType={HEADING_TYPES.SUBTITLE_1}>
              Active API Integrations :{" "}
              <span style={{ fontWeight: "500" }}>
                {heading.name}
                {` (${heading.id})`}
              </span>
            </Heading>
          </SimpleTableTitle>
        </SimpleTableToolbar>
        <SimpleTableContainer>
          <SimpleTable
            aria-label="Table"
            id="apiIntegrationTable"
            data-testid="api-integration-table"
          >
            <SimpleTableHead>
              <SimpleTableRow>
                <SimpleTableHeader
                  textAlign="left"
                  colSpan="1"
                  data-testid="apiintegraion-header-col-api-name"
                >
                  API Name
                </SimpleTableHeader>
                <SimpleTableHeader
                  colSpan="1"
                  data-testid="apiintegraion-header-col-api-id"
                >
                  API ID
                </SimpleTableHeader>
                <SimpleTableHeader
                  textAlign="left"
                  colSpan="1"
                  data-testid="apiintegraion-header-col-integration-id"
                >
                  Integration ID
                </SimpleTableHeader>
                <SimpleTableHeader
                  textAlign="left"
                  colSpan="1"
                  data-testid="apiintegraion-header-col-plan-type"
                >
                  Pricing Plan Type
                </SimpleTableHeader>
                <SimpleTableHeader
                  textAlign="left"
                  colSpan="1"
                  width="16%"
                  data-testid="apiintegraion-header-col-o-ed"
                >
                  Original Free Trial End Date
                </SimpleTableHeader>
                <SimpleTableHeader
                  textAlign="left"
                  colSpan="1"
                  width="22%"
                  data-testid="apiintegraion-header-col-override"
                >
                  Free Trial End Date Override
                </SimpleTableHeader>
              </SimpleTableRow>
            </SimpleTableHead>
            <SimpleTableBody>
              {Object.keys(tableData)
                .sort()
                .map((id, index) => (
                  <SimpleTableRow
                    key={index}
                    data-testid={`apiintegraion-table-data-row-${index}`}
                  >
                    <SimpleTableData textAlign="left">
                      {tableData[id].apiName}
                    </SimpleTableData>
                    <SimpleTableData textAlign="left">
                      {tableData[id].apiId}
                    </SimpleTableData>
                    <SimpleTableData textAlign="left">
                      {tableData[id].integrationId}
                    </SimpleTableData>
                    <SimpleTableData textAlign="left">
                      {tableData[id].pricingPlanDisplayName}
                    </SimpleTableData>
                    <SimpleTableData textAlign="left">
                      {tableData[id].originalFreeTrialEndDate}
                    </SimpleTableData>
                    <SimpleTableData textAlign="left">
                      <CustomDatePicker
                        row={tableData[id]}
                        updateState={updateState}
                        key={key}
                        hasChanged={
                          tableData[id].apiType === API_TYPE.REST
                            ? restApiData[id] === null ||
                              (restApiData[id] && restApiData[id] !== "error")
                            : asyncApiData[id] === null ||
                              (asyncApiData[id] && asyncApiData[id] !== "error")
                        }
                      />
                    </SimpleTableData>
                  </SimpleTableRow>
                ))}
            </SimpleTableBody>
          </SimpleTable>
        </SimpleTableContainer>
      </SimpleTableWrapper>
      <div>
        <div className="save-btn">
          <Button
            text={"Save"}
            variant={BUTTON_VARIANTS.PRIMARY}
            isDisabled={isSaveDisabled}
            onClick={() => setIsOpen(true)}
            style={{
              height: "2.5rem",
              fontSize: "16px",
              padding: "1rem 1rem",
              width: "12%",
            }}
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isOpen}
        onCancel={onDialogCancel}
        onAccept={handleSave}
        title={SAVE_DIALOG_TITLE}
        message={SAVE_DIALOG_MSG}
      />
    </React.Fragment>
  );
}

const CustomDatePicker = ({ row, key, updateState, hasChanged}) => {
  const [date, setDate] = useState(row.freeTrialOverrideEndDate);
  const [hasError, setHasError] = useState(false);
  const [dateKey, setDateKey] = useState(key);
  const disabled =
    row &&
    row.pricingPlanType &&
    row.pricingPlanType.toLowerCase() !== "advanced";
  const dateInputHelperText = disabled ? (
    ""
  ) : hasChanged ? (
    <div style={{ color: "#074FAF" }}>{DATE_SAVE_PENDING_TEXT}</div>
  ) : (
    DATE_PICKER_HELPER_TEXT
  );

  const handleError = (errors) => {
    if(errors && 
      (errors.includes('datePicker.invalidDateInPast') || 
      errors.includes('datePicker.dateBlocked'))) {
      setHasError(false);
    }
    if(row.freeTrialOverrideEndDate && !hasChanged){
      setHasError(false)
    } else {
      const newHasError = errors.length > 0;
      setHasError(newHasError);
      newHasError &&
        updateState({
          apiType: row.apiType,
          id: row.integrationId,
          data: "error",
        });
    }
  };
  const handleDateChange = (momentDateObject) => {
    setDate(momentDateObject);
    updateState({
      apiType: row.apiType,
      id: row.integrationId,
      data: {
        freeTrialEndDate:
          momentDateObject && momentDateObject.format(DATE_FORMAT),
        apiName: row.apiName,
      },
    });
  };

  const dateInputProps = {
    helperText: dateInputHelperText,
    isRequired: true,
    size: INPUT_SIZES.SMALL,
    isDisabled: disabled,
    hasError: hasError,
    onError: handleError,
    enableCustomValidation: true,
  };
  return (
    <div>
      <DatePicker
        date={date}
        dateInputProps={dateInputProps}
        highlightToday
         blockedDateRanges={[{
           startDate: moment().subtract(1, 'd'),
           endDate: moment().subtract(1, 'd')
       }]}
        id={`date-picker-customized-${row.id}`}
        label="Date"
        name="date-picker-customized"
        numberOfMonths={1}
        onDateChange={handleDateChange}
        key={dateKey}
      />
    </div>
  );
};

const ConfirmationDialog = ({ isOpen, title, message, onCancel, onAccept }) => {
  return (
    <Dialog
      buttonsProps={[
        {
          id: "dialog-action-1",
          onClick: onCancel,
          text: "Cancel",
          variant: BUTTON_VARIANTS.TEXT,
        },
        {
          id: "dialog-action-2",
          onClick: onAccept,
          text: "Save",
          variant: BUTTON_VARIANTS.PRIMARY,
        },
      ]}
      id="dialog-save"
      isOpen={isOpen}
      onClose={onCancel}
      title={title}
    >
      {message}
    </Dialog>
  );
};

export default ApiIntegrationTable;
