import BulkUserUploadManagement from "./BulkUserUploadManagement";
import { connect } from "react-redux";
import { actions } from "./userUploadActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      bulkUserUploadManagement: state.bulkUserUploadManagement
    }),
    actions
  )(BulkUserUploadManagement)
);
