import types from "./types";

const apiSubmissionsInitialState = {
  apis: [],
  fetchingApis: false,
  errorMessage: ""
};

const apiSubmissions = (state = apiSubmissionsInitialState, action) => {
  switch (action.type) {
    case types.API_GET_REQUEST:
      return {
        ...state,
        fetchingApis: true,
        errorMessage: ""
      };
    case types.API_GET_RECIEVE:
      return {
        ...state,
        apis: action.response,
        fetchingApis: false
      };
    case types.API_GET_ERROR:
      return {
        ...state,
        fetchingApis: false,
        errorMessage: action.apierror.message
      };
    default:
      return state;
  }
};

export default apiSubmissions;
