import React from "react";
import { convertToRaw } from "draft-js";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { Elevation } from "@cdk-uip/react-elevation";
import { Button } from "@cdk-uip/react-button";
import TnCEditor from "./TnCEditor";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from "@cdk-uip/react-card";
import { Checkbox } from "@cdk-uip/react-checkbox";
import WithNavigationToHome from "../../components/WithNavigationToHome";
import { FormattedMessage } from "react-intl";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import { Confirm, ErrorModal } from "../../components/Confirm";
const errorMessage = "Please enter Terms and Conditions";

class TnCUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tncText: {},
      isMajorUpdate: false,
      showConfirmModal: false,
      latestTnCVersion: null,
      lastTnCVersion: null,
      isEditorEmpty: true,
      showErrorModal: false,
      showMajorVersionUpdateNotification: false,
      showMajorVersionLabel: false,
      disableIsMajorUpdate: false
    };
  }

  gotoFortellisDeveloperTerms = () => {
    this.props.history.push("/agreements/fortellis-developer-terms");
  };

  onSubmit = async () => {
    if (!this.state.isEditorEmpty) {
      if (
        this.state.isMajorUpdate ||
        this.isNextVersionMajor(this.state.latestTnCVersion)
      ) {
        this.setState({ showConfirmModal: true });
      } else {
        this.uploadTnC();
      }
    } else {
      this.setState({ showErrorModal: true });
    }
  };

  onCancel = () => {
    this.setState({ showConfirmModal: false });
  };
  uploadTnC = () => {
    const {
      auth: {
        accessToken,
        userData: { name }
      }
    } = this.props;
    this.props.postTnC(accessToken, {
      content: this.state.tncText,
      version: this.state.latestTnCVersion,
      created_by: name
    });
  };

  handleEditorChanges = updateContent => {
    const {
      editorState: {
        _immutable: { currentContent }
      }
    } = updateContent;
    if (currentContent.hasText() && !!currentContent.getPlainText().trim()) {
      let rawContent = JSON.stringify(convertToRaw(currentContent));
      this.setState({
        tncText: rawContent,
        isEditorEmpty: false
      });
    } else {
      this.setState({
        tncText: {},
        isEditorEmpty: true
      });
    }
  };

  getNextMajorVersion() {
    const { version } = this.props.location.latestTnC;
    let versionArray = version.toString().split(".");
    return (++versionArray[0]).toString() + ".00";
  }

  getNextVersion() {
    const { version } = this.props.location.latestTnC;
    let versionArray = version.toString().split(".");
    const minorVersion = versionArray[1]
      ? Number(versionArray[0]) +
        (versionArray[1].length === 1
          ? versionArray[1] / 10
          : versionArray[1] / 100) +
        0.01
      : Number(versionArray[0]) + 0.01;
    return minorVersion.toFixed(2);
  }

  componentDidMount() {
    const { version } = this.props.location.latestTnC;
    if (version || version === 0) {
      let latestVersion = this.getNextVersion();
      if (this.isNextVersionMajor(latestVersion)) {
        this.setState({
          isMajorUpdate: true,
          disableIsMajorUpdate: true,
          showMajorVersionUpdateNotification: true
        });
      }
      this.setState({ latestTnCVersion: latestVersion });
      this.setState({ lastTnCVersion: version.toFixed(2) });
    } else {
      return <div>Unable to fetch last Terms and Conditions Version</div>;
    }
  }
  isNextVersionMajor = latestVersion => {
    const versionArray = latestVersion.split(".");
    return versionArray[1]
      ? Number(versionArray[1]) === 0
        ? true
        : false
      : true;
  };

  handleIsMajorUpdateCheckbox = e => {
    this.setState({ isMajorUpdate: e.target.checked });
    let latestVersion = this.state.latestTnCVersion;
    if (e.target.checked) {
      latestVersion = this.getNextMajorVersion();
      this.setState({ showMajorVersionUpdateNotification: true });
    } else {
      latestVersion = this.getNextVersion();
      this.setState({ showMajorVersionUpdateNotification: false });
    }
    this.setState({ latestTnCVersion: latestVersion });
  };

  render() {
    const { isSubmitting, error = "", isSubmitted } = this.props.postTncState;

    if (!isSubmitting && error) {
      return (
        <div>
          Unable to upload new Terms and Conditions. {JSON.stringify(error)}
        </div>
      );
    } else if (isSubmitting) {
      return (
        <div className="loading-container">
          <h3>Uploading new Terms and Conditions...</h3>
          <CircularProgress />
        </div>
      );
    } else if (isSubmitted) {
      this.gotoFortellisDeveloperTerms();
    }

    return (
      <React.Fragment>
        <LayoutGrid>
          <LayoutGridCell span={12}>
            <Elevation z={10}>
              <Card>
                <CardHeader className={"update-fortellis-tnc_card_header"}>
                  <CardTitle className={"update-fortellis-tnc_card_title"}>
                    <FormattedMessage
                      id="TncUpload.title"
                      defaultMessage="Update Fortellis Developer Terms"
                    />
                  </CardTitle>
                  <CardSubtitle
                    className={"update-fortellis-tnc_card_subTitle"}
                  >
                    This will update version {this.state.lastTnCVersion} to{" "}
                    {this.state.latestTnCVersion}
                  </CardSubtitle>
                  {this.state.showMajorVersionUpdateNotification ? (
                    <CardSubtitle
                      className={"update-fortellis-tnc_card_subTitle"}
                    >
                      <FormattedMessage
                        id="TncUpload.subTitleForMajorUpdate"
                        defaultMessage="This edit will result in major version update, requiring all existing users to accept new Terms and Conditions."
                      />
                    </CardSubtitle>
                  ) : (
                    false
                  )}
                  <CardSubtitle
                    className={"update-fortellis-tnc_card_subTitle"}
                  >
                    <FormattedMessage
                      id="TncUpload.subTitle2"
                      defaultMessage="Paste the updated Fortellis Terms and Conditions below."
                    />
                    <div className={"update-fortellis-tnc_checkbox_div"}>
                      <Checkbox
                        id="tncUpload.isMajorUpdate"
                        checked={this.state.isMajorUpdate}
                        onChange={this.handleIsMajorUpdateCheckbox}
                        disabled={this.state.disableIsMajorUpdate}
                      />
                      <FormattedMessage
                        id="TncUpload.isMajorUpdateCheckbox"
                        defaultMessage="User acceptance required"
                      />
                    </div>
                  </CardSubtitle>
                </CardHeader>
                <CardText className={"update-fortellis-tnc_card_text_section"}>
                  <TnCEditor handleEditorChanges={this.handleEditorChanges} />
                  <Button
                    id="tncUpload.submit"
                    raised
                    onClick={this.onSubmit}
                    className={"update-fortellis-tnc_button"}
                  >
                    <FormattedMessage
                      id="TncUpload.submit"
                      defaultMessage="Submit"
                    />
                  </Button>
                  <Button
                    id="tncUpload.cancel"
                    raised
                    onClick={this.gotoFortellisDeveloperTerms}
                    className={"update-fortellis-tnc_button"}
                  >
                    <FormattedMessage
                      id="TncUpload.cancel"
                      defaultMessage="Cancel"
                    />
                  </Button>
                </CardText>
              </Card>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>
        <ErrorModal
          open={this.state.showErrorModal}
          onCancel={() => {
            this.setState({ showErrorModal: false });
          }}
          message={errorMessage}
        />
        <Confirm
          open={this.state.showConfirmModal}
          onCancel={this.onCancel}
          onAccept={this.uploadTnC}
          message={
            "This will affect all Fortellis users. They will have to accept latest Terms and Conditions before they login next time. Are you sure you want to upload new Terms and Conditions?"
          }
        />
      </React.Fragment>
    );
  }
}

const TnCUploaderComp = WithNavigationToHome(TnCUploader);
class TnCUploaderContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <TnCUploaderComp {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(TnCUploaderContainer);
