import types from "./types";

const publishedAppsInitialState = {
  //Initial state
  publicFortellisApps: "",
  privateFortellisApps: "",
  publicListingApps: "",
  privateListingApps: "",
  fetchingAppCount: false,
  errorMessage: ""
};

const fetchAppCount = (state = publishedAppsInitialState, action) => {
  switch (action.type) {
    case types.APP_POST_REQUEST: //requesting details to api
      return Object.assign({
        fetchingAppCount: true,
        errorMessage: ""
      });
    case types.APP_POST_RECIEVE:
      return Object.assign({
        //returning recieved data from api
        publicFortellisApps: action.response.fortellisApps.public,
        privateFortellisApps: action.response.fortellisApps.private,
        publicListingApps: action.response.listingApps.public,
        privateListingApps: action.response.listingApps.private,
        fetchingAppCount: false,
        errorMessage: ""
      });
    case types.APP_POST_ERROR: //if error occurs while fetching app details
      return Object.assign({
        fetchingAppCount: false,
        errorMessage: "An error occured"
      });
    default:
      return state;
  }
};

export default fetchAppCount;
