import React, { useState } from "react";
import "./style.css";
import {
  IconButton,
  IconInfo,
  SimpleTable,
  SimpleTableBody,
  SimpleTableToolbar,
  SimpleTableContainer,
  SimpleTableTitle,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  SimpleTableToolbarButtons,
  SimpleTableHeaderButton,
  SimpleTableWrapper,
  IconArrowForward,
  IconArrowBack,
  Button,
  DropdownMenu
} from "cdk-radial";
import { Dialog, DialogHeader } from "@cdk-uip/react-dialog";
import ReactJson from "react-json-view";
import AuditFilters from "./AuditFilters";
import moment from "moment";

const AuditTable = ({ auditEvent = [], nextPage, getFilterAuditRecords }) => {
  const title = "Fortellis Audit";
  const [isModelOpen, setModelState] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const createData = (
    eventCreationTime,
    entityId,
    eventName,
    eventType,
    userId,
    userEmailId,
    userType,
    fields,
    event
  ) => {
    return {
      eventCreationTime,
      entityId,
      eventName,
      eventType,
      userId,
      userEmailId,
      userType,
      fields,
      event
    };
  };

  const data = auditEvent.map(event => {
    let {
      eventCreationTime,
      entityId,
      eventName,
      eventType,
      userId,
      userEmailId,
      userType,
      eventFields = []
    } = event;
    let fields = {};
    eventFields.map(({ fieldName, fieldValue }) => {
      fields[fieldName] = fieldValue;
    });
    return createData(
      eventCreationTime,
      entityId,
      eventName,
      eventType,
      userId,
      userEmailId,
      userType,
      <ReactJson
        src={fields}
        collapsed={true}
        displayDataTypes={false}
        sortKeys={false}
      />,
      <IconButton
        onClick={() => {
          setSelectedEvent(event);
          setModelState(true);
        }}
        icon={<IconInfo />}
        text={"Show more"}
      />
    );
  });

  const [maxResults, setMaxResults] = useState(50);
  const [filterState, setFilterState] = useState({ nextPage: 0 });
  const updateState = values => {
    setFilterState({ ...filterState, ...values });
  };
  const handleClear = () => {
    setFilterState({ maxResults, nextPage: 0 });
  };
  const applyFilters = () => {
    getFilterAuditRecords({ ...filterState, maxResults, nextPage: 0 });
  };

  const handleNext = () => {
    let nextState = { ...filterState, nextPage: filterState.nextPage + 1 };
    setFilterState(nextState);
    getFilterAuditRecords({ ...nextState, maxResults });
  };

  const handlePrev = () => {
    let nextState = { ...filterState, nextPage: filterState.nextPage - 1 };
    setFilterState(nextState);
    getFilterAuditRecords({ ...nextState, maxResults });
  };

  const handlePageSize = ({ value }) => {
    setMaxResults(value);
    let nextState = { ...filterState, nextPage: 0 };
    setFilterState(nextState);
    getFilterAuditRecords({ ...nextState, maxResults: value });
  };

  return (
    <SimpleTableWrapper data-testid="simple-table-wrapper">
      <SimpleTableToolbar>
        <SimpleTableTitle>{title}</SimpleTableTitle>
        <SimpleTableToolbarButtons>
          <SimpleTableHeaderButton>
            {
              <AuditFilters
                applyFilters={applyFilters}
                handleClear={handleClear}
                updateState={updateState}
                filterState={filterState}
              />
            }
          </SimpleTableHeaderButton>
        </SimpleTableToolbarButtons>
      </SimpleTableToolbar>
      <SimpleTableContainer>
        <SimpleTable aria-label="Table">
          <SimpleTableHead>
            <SimpleTableRow>
              <SimpleTableHeader className="col-span-1" colSpan="1">
                Timestamp
              </SimpleTableHeader>
              <SimpleTableHeader className="col-span-1" colSpan="1">
                Entity ID
              </SimpleTableHeader>
              <SimpleTableHeader
                className="col-span-1"
                textAlign="left"
                colSpan="1"
              >
                Event Group
              </SimpleTableHeader>
              <SimpleTableHeader
                className="col-span-1"
                textAlign="left"
                colSpan="1"
              >
                Event Type
              </SimpleTableHeader>
              {/* <SimpleTableHeader textAlign="left" colSpan="1">
                  User Id
                </SimpleTableHeader> */}
              <SimpleTableHeader
                className="col-span-1"
                textAlign="left"
                colSpan="1"
              >
                User Email
              </SimpleTableHeader>
              {/* <SimpleTableHeader textAlign="left" colSpan="1">
                  User Type
                </SimpleTableHeader> */}
              <SimpleTableHeader
                className="col-span-2"
                textAlign="left"
                colSpan="6"
              >
                Fields
              </SimpleTableHeader>
              <SimpleTableHeader
                className="col-span-1"
                textAlign="left"
                colSpan="1"
              >
                More
              </SimpleTableHeader>
            </SimpleTableRow>
          </SimpleTableHead>
          <SimpleTableBody>
            {data.map((row, index) => (
              <SimpleTableRow key={index}>
                <SimpleTableData textAlign="left">
                  {moment(row.eventCreationTime).format("DD MMM YYYY hh:mm:ss")}
                </SimpleTableData>
                <SimpleTableData textAlign="left">
                  {row.entityId}
                </SimpleTableData>
                <SimpleTableData textAlign="left">
                  {row.eventName}
                </SimpleTableData>
                <SimpleTableData textAlign="left">
                  {row.eventType}
                </SimpleTableData>
                {/* <SimpleTableData textAlign="left">
                    {row.userId}
                  </SimpleTableData> */}
                <SimpleTableData textAlign="left">
                  {row.userEmailId}
                </SimpleTableData>
                {/* <SimpleTableData textAlign="left">
                    {row.userType}
                  </SimpleTableData> */}
                <SimpleTableData colSpan="6" textAlign="left">
                  {row.fields}
                </SimpleTableData>
                <SimpleTableData textAlign="left">{row.event}</SimpleTableData>
              </SimpleTableRow>
            ))}
          </SimpleTableBody>
        </SimpleTable>
      </SimpleTableContainer>
      {data && data.length < 1 && (
        <div className="empty-data">
          No records found for the selected filters. Change your filters and
          search again.
        </div>
      )}
      <span className="pagination-footer">
        <DropdownMenu
          className="pagination-dropdown"
          label="Page Size"
          onChange={handlePageSize}
          options={[
            {
              label: "10",
              value: 10
            },
            {
              label: "20",
              value: 20
            },
            {
              label: "50",
              value: 50
            },
            {
              label: "100",
              value: 100
            },
            {
              label: "200",
              value: 200
            }
          ]}
          value={{
            label: "" + maxResults,
            value: maxResults
          }}
        />

        <Button
          className="pagination-prev"
          onClick={handlePrev}
          icon={<IconArrowBack />}
          text={"Previous"}
          variant="text"
          isDisabled={filterState.nextPage === 0}
        />

        <Button
          className="pagination-next"
          onClick={handleNext}
          icon={<IconArrowForward />}
          text={"Next"}
          variant="text"
          isDisabled={!nextPage}
        />
      </span>
      <EventModal
        open={isModelOpen}
        onClose={() => setModelState(false)}
        event={selectedEvent}
      ></EventModal>
    </SimpleTableWrapper>
  );
};

const EventModal = ({ open, onClose, event = {} }) => {
  return (
    <Dialog open={open} onCancel={onClose}>
      <DialogHeader>Audit Event</DialogHeader>
      <div className="modal-body">
        <ReactJson src={event} displayDataTypes={false} sortKeys={false} />
      </div>
    </Dialog>
  );
};

export default AuditTable;
