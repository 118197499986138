import React from "react";
import { connect } from "react-redux";
import UserUpload from "./UserUpload";
import { Modal } from "../components/Confirm";
import types from "./types";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { TemporarySnackbar } from "@cdk-uip/react-snackbar";

class BulkUserUploadManagement extends React.Component {
  constructor(props) {
    super(props);
    this.fileReader = new FileReader();
  }

  state = {
    shouldShow: false
  };

  static defaultProps = {
    legalEntityStatus: "active"
  };

  onModalClose = () => {
    this.props.dispatch({
      type: types.MODEL_RESET
    });
  };

  showSnackbar = message => {
    this.setState({
      messageSnackbar: message,
      shouldShow: true
    });
  };

  hideSnackbar = () => {
    this.setState({ shouldShow: false });
  };

  render() {
    const {
      bulkUserUploadManagement: { showModal, message }
    } = this.props;
    const { messageSnackbar, shouldShow } = this.state;

    return (
      <React.Fragment>
        <UserUpload showSnackbar={this.showSnackbar} />
        <Modal open={showModal} onClose={this.onModalClose} message={message} />
        <TemporarySnackbar
          show={shouldShow}
          message={messageSnackbar}
          onClose={this.hideSnackbar}
        />
      </React.Fragment>
    );
  }
}

BulkUserUploadManagement = connect()(
  WithNavigationToHome(BulkUserUploadManagement)
);
class BulkUserUploadManagementContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <BulkUserUploadManagement {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(BulkUserUploadManagementContainer);
