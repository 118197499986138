import types from "./types";

const apiSubmissionsInitialState = {
  apisPage: {},
  apis: [],
  fetchingApis: false,
  fetchingApisError: false,
  asyncApisPage: {},
  asyncApis: [],
  fetchingAsyncApis: false,
  fetchingAsyncApisError: false,
  spec: "",
  fetchingSpec: false,
  specFile: "",
  fetchingSpecFile: false,
  errorMessage: "",
  response: "",
  awaitingResponse: false,
  apiEditError: false
};

const apiSubmissions = (state = apiSubmissionsInitialState, action) => {
  //fails w/ unexpected end of json input on 200, needs action.apierror.message
  // 422 requires: action.apierror.payload.error
  const getErrorMessage = apierror => {
    if (apierror.hasOwnProperty("payload")) {
      return apierror.payload.error || apierror.payload.message;
    } else if (apierror.hasOwnProperty("message")) {
      return apierror.message;
    } else {
      return "Error";
    }
  };

  switch (action.type) {
    case types.ADMIN_API_GET_REQUEST:
      return {
        ...state,
        fetchingApis: true,
        fetchingApisError: false,
        errorMessage: ""
      };
    case types.ADMIN_API_GET_RECEIVE:
      return {
        ...state,
        apisPage: action.response,
        apis: action.response.items,
        fetchingApis: false
      };
    case types.ADMIN_API_GET_ERROR:
      return {
        ...state,
        fetchingApis: false,
        fetchingApisError: true,
        apis: [],
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };
    case types.ADMIN_ASYNC_API_GET_REQUEST:
      return {
        ...state,
        fetchingAsyncApis: true,
        fetchingAsyncApisError: false,
        errorMessage: ""
      };
    case types.ADMIN_ASYNC_API_GET_RECEIVE:
      let asyncApis = action.response ? action.response.items : [];
      if (asyncApis && Array.isArray(asyncApis)) {
        asyncApis = asyncApis.map(asyncApi => ({
          ...asyncApi,
          isAsyncApi: true
        }));
      }
      return {
        ...state,
        asyncApisPage: action.response,
        asyncApis,
        fetchingAsyncApis: false
      };
    case types.ADMIN_ASYNC_API_GET_ERROR:
      return {
        ...state,
        fetchingAsyncApis: false,
        fetchingAsyncApisError: true,
        asyncApis: [],
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };

    case types.ADMIN_API_INFO_GET_REQUEST:
      return {
        ...state,
        fetchingApi: true,
        fetchingApiError: false,
        errorMessage: ""
      };
    case types.ADMIN_API_INFO_GET_RECEIVE:
      return {
        ...state,
        api: action.response,
        fetchingApi: false
      };
    case types.ADMIN_API_INFO_GET_ERROR:
      return {
        ...state,
        fetchingApi: false,
        fetchingApiError: true,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };

    case types.UPDATE_API_PATCH_REQUEST:
      return {
        ...state,
        awaitingResponse: true,
        apiEditError: false,
        apiEditResponse: null,
        errorMessage: ""
      };
    case types.UPDATE_API_PATCH_RECEIVE:
      return {
        ...state,
        apiEditResponse: true,
        awaitingResponse: false
      };
    case types.UPDATE_API_PATCH_ERROR:
      return {
        ...state,
        awaitingResponse: false,
        apiEditError: true,
        apiEditResponse: null,
        errorMessage: {
          message: action.apierror,
          generateMessage: getErrorMessage(action.apierror)
        }
      };

    case types.ENTITY_SEARCH_GET_REQUEST:
      return {
        ...state,
        entitySearchRequest: true,
        entitySearchError: false
      };
    case types.ENTITY_SEARCH_GET_RECEIVE:
      return {
        ...state,
        entitySearchRequest: false,
        entitySearchResponse: action.response
      };
    case types.ENTITY_SEARCH_GET_ERROR:
      return {
        ...state,
        entitySearchRequest: false,
        entitySearchError: true
      };

    case types.CLEAR_ERRORS:
      return {
        ...state,
        fetchingApisError: false,
        apiEditError: false
      };
    case types.CLEAR_PATCH_RESP:
      return {
        ...state,
        apiEditResponse: false,
        errorMessage: "",
        response: "",
        awaitingResponse: false,
        apiEditError: false
      };
    default:
      return state;
  }
};

export default apiSubmissions;
