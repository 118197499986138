import React, { useEffect, useState } from "react";
import { Button } from "@cdk-uip/react-button";
import {
  BUTTON_VARIANTS, Dialog, Checkbox, TextArea, Input, PrimaryButton, Card, IconInfo,
  withTooltip, TOOLTIP_HORIZONTAL_ALIGNMENTS, TOOLTIP_VERTICAL_ALIGNMENTS, THEMES, Loader
} from "cdk-radial";

import "./style.css";
import axios from "axios";
import config from "../config/app.conf.json";
import ApisListTable from './ApisListTable'

function EditBundle({ auth, reservedBundles, setShowSuccessToast, setScreen, selectedBundle, setSelectedBundle }) {
  const [bundleName, setBundleName] = useState(selectedBundle?.name);
  const [bundleDescription, setBundleDescription] = useState(selectedBundle?.description);
  const [bundleNameErrorMessage, setBundleNameErrorMessage] = useState("");
  const [
    bundleDescriptionErrorMessage,
    setBundleDescriptionErrorMessage
  ] = useState("");

  const [selectedApis, setSelectedApis] = useState([]);

  const [hasNameError, setHasNameError] = useState(false);
  const [hasDescError, setHasDescError] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isVisibilityDisabled, setIsVisibilityDisabled] = useState(false);
  const [fetchingApis, setFetchingApis] = useState(false);
  const [errorFetchingApis, setErrorFetchingApis] = useState(false);
  const [apis, setApis] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { accessToken } = auth;
  const tooltipProps = {
    id: 'info-icon-tooltip',
    styles: { width: '350px', maxWidth: '350px' },
    text: 'Your bundle is automatically private if it has Private APIs in it',
    delay: 0,
    theme: THEMES.LIGHT
  };

  const ComponentToRender = ({
    ...props
  }) => {
    return <IconInfo {...props} />;
  };

  const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);

  useEffect(() => {
    setFetchingApis(true);
    fetchApis()
    if(selectedBundle?.visibility === 'private'){
        setIsPrivate(true)
    }
  }, [])

    const fetchApis = () => {
        axios.get(
            `${config.api.api_gateway_url}/v1/admin/bundle-apis`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )
            .then(response => {
                setFetchingApis(false);
                let selectedBundleApis = selectedBundle?.apis?.map(api => api.id)
                let apis = response?.data.map(api => ({
                    ...api,
                    isExistingApi: selectedBundleApis.includes(api?.api_id)
                }));
                setApis(apis)
                let selectedApis = apis.filter(api => !!api?.isExistingApi)
                setSelectedApis(selectedApis)
            })
            .catch((err) => {
                setFetchingApis(false);
                setErrorFetchingApis(true);
            });
    }

  const handleBundleNameChange = event => {
    setBundleName(event.target.value);
  };
  const handleBundleNameBlur = e => {
    if (Boolean(e?.target?.value)) {
      if (reservedBundles.find(b => (b === e?.target?.value) && (e?.target?.value !== selectedBundle?.name))) {
        setBundleNameErrorMessage("Bundle name already exist");
        setHasNameError(true);
      } else {
        setBundleNameErrorMessage("");
        setHasNameError(false);
      }
    } else {
      setBundleNameErrorMessage("please add Bundle name");
      setHasNameError(true);
    }
  };
  const onDescriptionChange = event => {
    setBundleDescription(event.target.value);
  };
  const onDescriptionBlur = e => {
    if (Boolean(e?.target?.value)) {
      setBundleDescriptionErrorMessage("");
      setHasDescError(false);
    } else {
      setBundleDescriptionErrorMessage("please add Bundle description");
      setHasDescError(true);
    }
  };

  const handleEditBundle = () => {
    setIsDialogOpen(true);
  }

  const handleEditBundleConfirm = () => {
    const apis = selectedApis.map(api => {
      return { id: api?.api_id, type: api?.api_type === 'Rest' ? 'api' : 'async-api' }
    })
    axios.put(
      `${config.api.api_gateway_url}/v2/admin/bundles`,
      {
        id: selectedBundle?.id,
        name: bundleName,
        description: bundleDescription,
        status: 'active',
        visibility: isPrivate ? 'private' : 'public',
        apis: apis
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    ).then((resp) => {
        setIsDialogOpen(false);
        setScreen("View Bundles")
        setSelectedBundle(resp?.data)
        setShowSuccessToast(true);
      }).catch(error => {
        setIsDialogOpen(false);
        setEditError(true);
        setEditErrorMessage(error?.response?.data?.message || 'Failed to edit the bundle');
      });
  }

  return (
    <>
      {errorFetchingApis ?
        <div className="error-div">
          Something went wrong, please try again.
        </div>
        :
        <Card>
          <div className="create-bundle-card">
            <div className="create-bundle-title">Bundle {selectedBundle?.name}</div>
            <Input
              dataTestId="input-api-bundle-name"
              enableCustomValidation={true}
              enableIsRequiredValidation
              errorMessage={bundleNameErrorMessage}
              id="api-name-input"
              hasError={hasNameError}
              label="Bundle Name"
              name={"api-name-input-panel"}
              isRequired
              value={bundleName}
              onBlur={handleBundleNameBlur}
              onChange={handleBundleNameChange}
            />
            <TextArea
              enableCustomValidation
              id="description-input"
              isRequired
              label="Bundle Description"
              maxLength="500"
              name="description"
              placeholder="Add Bundle Description"
              onBlur={onDescriptionBlur}
              onChange={onDescriptionChange}
              value={bundleDescription}
              hasError={hasDescError}
              errorMessage={bundleDescriptionErrorMessage}
              hasCharacterCount
            />
            <div className="bundle-visibility-div">
              <Checkbox
                dataTestId="bundle-visibility"
                id="bundle-visibility"
                label="Change bundle to Private"
                labelPosition="after"
                name="visibility"
                onChange={() => setIsPrivate(prev => !prev)}
                size="standard"
                checked={isPrivate}
                isDisabled={isVisibilityDisabled}
                className='visibility-checkbox'
              />
              <ComponentWithTooltip horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT} verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP} />
            </div>

            {!!fetchingApis ?
              <Loader
                dataTestId="fectching-apis-loader"
                size="small"
                label="Fetching Apis..."
              />
              : <>
                <div>
                  <ApisListTable apis={selectedApis} isViewOnly={true} />
                </div>

                <div>
                  <ApisListTable apis={apis} setSelectedApis={setSelectedApis} isViewOnly={false} setIsPrivate={setIsPrivate} setIsVisibilityDisabled={setIsVisibilityDisabled} />
                </div>
              </>
            }

            <div className="api-details-panel-save-button">
              <PrimaryButton
                dataTestId='save-bundle'
                text="Save Changes"
                onClick={handleEditBundle}
                isDisabled={
                  !(
                    !!bundleName &&
                    !hasNameError &&
                    !!bundleDescription &&
                    selectedApis?.length
                  )
                }
              />
            </div>
            {editError ? (
              <Dialog
                className="create-api-dialog"
                buttonsProps={[
                  {
                    id: "cancel-action",
                    onClick: () => setEditError(false),
                    text: "Cancel",
                    variant: BUTTON_VARIANTS.SECONDARY
                  }
                ]}
                id="share-api-dialog"
                isOpen={editError}
                onClose={() => setEditError(false)}
                title="Bundle Edit Failed"
              >
                {!! editErrorMessage && <div>{editErrorMessage}</div> }
              </Dialog>
            ) : null}
          </div>
        </Card>
      }
     { !!isDialogOpen && <Dialog
        buttonsProps={[
          {
            id: 'dialog-action-1',
            onClick: () => setIsDialogOpen(false),
            text: 'Cancel',
            variant: BUTTON_VARIANTS.SECONDARY
          },
          {
            id: 'dialog-action-2',
            onClick: handleEditBundleConfirm,
            text: 'Confirm',
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        dataTestId="bundle-create-dialog"
        id="bundle-create-dialog"
        onClose={() => setIsDialogOpen(false)}
        title="Confirmation"
        isOpen={isDialogOpen}
      >
        Are you sure you want to make these changes to the existing bundle? <br/>
        The confirmed changes will automatically be included in the bundle for all organizations where this bundle is already mapped.
      </Dialog>}
    </>
  );
}
class editBundleContainer extends React.Component {
  render() {
    return (
      <>
        <br />
        <Button
          raised
          onClick={() => {
            this.props.setScreen("View Bundles")
          }}
          className="cdk-navigation-button"
        >
          Back to Bundles
        </Button>

        <br />
        <br />
        <EditBundle {...this.props} />
      </>
    )
  }
}
export default editBundleContainer;
