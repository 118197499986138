import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { INPUT_SIZES, Input } from "cdk-radial";
import { TextArea } from "cdk-radial";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Button, BUTTON_VARIANTS, Dialog } from "cdk-radial";
import { Card } from "@cdk-uip/react-card";
import { Elevation } from "@cdk-uip/react-elevation";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import "./style.css";
import { PrimaryButton } from "cdk-radial";
import axios from "axios";
import config from "../config/app.conf.json";

function Create({ auth }) {
  const [existingBundles, setExistingBundles] = useState([]);
  const [bundleName, setBundleName] = useState("");
  const [bundleDescription, setBundleDescription] = useState("");
  const [bundleWeight, setBundleWeight] = useState("");
  const [apiList, setApiList] = useState([]);
  const [restList, setRestList] = useState([]);
  const [asyncList, setAsyncList] = useState([]);
  const [bundleNameErrorMessage, setBundleNameErrorMessage] = useState("");
  const [
    bundleDescriptionErrorMessage,
    setBundleDescriptionErrorMessage
  ] = useState("");
  const [apiListErrorMessage, setApiListErrorMessage] = useState("");
  const [restListErrorMessage, setRestListErrorMessage] = useState("");
  const [asyncListErrorMessage, setAsyncListErrorMessage] = useState("");
  const [bundleWeightsErrorMessage, setBundleWeightsErrorMessage] = useState(
    ""
  );
  const [hasNameError, setHasNameError] = useState(false);
  const [hasDescError, setHasDescError] = useState(false);
  const [hasWeightError, setHasWeightError] = useState(false);
  const [hasApiListError, setHasApiListError] = useState(false);
  const [hasrestListError, setHasrestListError] = useState(false);
  const [hasasyncListError, setHasasyncListError] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [createErrorMessage, setCreateErrorMessage] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { accessToken } = auth;

  const handleBundleNameChange = event => {
    setBundleName(event.target.value);
  };
  const handleBundleNameBlur = e => {
    if (Boolean(e?.target?.value)) {
      if (existingBundles.find(b => b === e?.target?.value)) {
        setBundleNameErrorMessage("Bundle name already exist");
        setHasNameError(true);
      } else {
        setBundleNameErrorMessage("");
        setHasNameError(false);
      }
    } else {
      setBundleNameErrorMessage("please add Bundle name");
      setHasNameError(true);
    }
  };
  const onDescriptionChange = event => {
    setBundleDescription(event.target.value);
  };
  const onWeightChange = event => {
    setBundleWeight(event.target.value);
  };
  const onRestApiListChange = event => {
    setRestList(event.target.value.split(/[ ,]+/));
  };
  const onAsyncApiListChange = event => {
    setAsyncList(event.target.value.split(/[ ,]+/));
  };
  const onDescriptionBlur = e => {
    if (Boolean(e?.target?.value)) {
      setBundleDescriptionErrorMessage("");
      setHasDescError(false);
    } else {
      setBundleDescriptionErrorMessage("please add Bundle description");
      setHasDescError(true);
    }
  };
  const onWeightBlur = e => {
    const nWeight = Number(e.target.value);
    if (!nWeight && nWeight !== 0) {
      setBundleWeightsErrorMessage("please add Bundle weight between 0 and 1");
      setHasWeightError(true);
    } else if (nWeight < 0 || nWeight > 1) {
      setBundleWeightsErrorMessage("please add Bundle weight between 0 and 1");
      setHasWeightError(true);
    } else {
      setBundleWeightsErrorMessage("");
      setHasWeightError(false);
    }
  };

  const onRestApiListBlur = e => {
    const list = e.target.value.split(/[ ,]+/);
    if (Array.isArray(list) && list[0].length > 0) {
      setRestListErrorMessage("");
      setHasrestListError(false);
    } else {
      setRestListErrorMessage(`please add API'IDs as comma seperated value`);
      setHasrestListError(true);
    }
  };
  const onAsyncApiListBlur = e => {
    const list = e.target.value.split(/[ ,]+/);
    if (Array.isArray(list) && list[0].length > 0) {
      setAsyncListErrorMessage("");
      setHasasyncListError(false);
    } else {
      setAsyncListErrorMessage(`please add API'IDs as comma seperated value`);
      setHasasyncListError(true);
    }
  };

  const handleBundleCreate = () => {
    let mappedRest = [];
    let mappedAsync = [];

    if (
      Array.isArray(restList) &&
      restList.length > 0 &&
      restList[0].length > 0
    ) {
      mappedRest = restList.map(value => {
        return {
          id: value,
          type: "api"
        };
      });
    }
    if (
      Array.isArray(asyncList) &&
      asyncList.length > 0 &&
      asyncList[0].length > 0
    ) {
      mappedAsync = asyncList.map(value => {
        return {
          id: value,
          type: "async-api"
        };
      });
    }
    let api = [...mappedRest, ...mappedAsync];

    axios
      .post(
        `${config.api.api_gateway_url}/v1/admin/bundles`,
        {
          name: bundleName,
          description: bundleDescription,
          weight: bundleWeight || 0,
          apis: api
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(response => {
        setCreateError(false);
      })
      .catch(function(error) {
        setCreateError(true);
        let errorArray = [];
        if (
          !!error?.response?.data?.errors &&
          error?.response?.data?.errors.length > 0
        ) {
          setCreateErrorMessage(error.response.data.errors);
        } else if (!!error?.response?.data?.message) {
          errorArray.push({ message: error?.response?.data?.message });
          setCreateErrorMessage(errorArray);
        } else if (error?.response?.data !== "") {
          errorArray.push({ message: error?.response?.data });
          setCreateErrorMessage(errorArray);
        }
      });
  };


  useEffect(() => {
    if (!!location?.state?.bundlesList) {
      setExistingBundles(
        location?.state?.bundlesList.map(e => {
          return e.name;
        })
      );
    }
  }, []);

  return (
    <Fragment>
      <LayoutGrid
        className={
          "s-content-route--subscription-management c-layout-section__dash-grid"
        }
      >
        <LayoutGridCell
          className={"c-layout-section__dash-grid-cell"}
          span={12}
          spanTablet={8}
          spanPhone={4}
        >
          <Elevation z={10} className={"c-dash-card__wrapper"}>
            <Card
              className={
                "c-dash-card c-form-subscriptions c-form-subscriptions--start"
              }
            >
              <div className="bundle-create-page">
                <div className="app-details-body">
                  <Input
                    dataTestId="input-api-bundle-name"
                    enableCustomValidation={true}
                    enableIsRequiredValidation
                    errorMessage={bundleNameErrorMessage}
                    id="api-name-input"
                    hasError={hasNameError}
                    label="Bundle Name"
                    name={"api-name-input-panel"}
                    isRequired
                    value={bundleName}
                    onBlur={handleBundleNameBlur}
                    onChange={handleBundleNameChange}
                  />
                  <Input
                    dataTestId="input-bundle-weight-name"
                    enableCustomValidation={true}
                    enableIsRequiredValidation
                    errorMessage={bundleWeightsErrorMessage}
                    id="api-name-input"
                    hasError={hasWeightError}
                    label="Bundle Weight"
                    placeholder="Add Bundle Weight between 0 and 1"
                    name={"api-name-input-panel"}
                    value={bundleWeight}
                    onBlur={onWeightBlur}
                    onChange={onWeightChange}
                  />
                  <TextArea
                    enableCustomValidation
                    id="description-input"
                    isRequired
                    label="Bundle Description"
                    maxLength="500"
                    name="description"
                    placeholder="Add Bundle Description"
                    onBlur={onDescriptionBlur}
                    onChange={onDescriptionChange}
                    value={bundleDescription}
                    hasError={hasDescError}
                    errorMessage={bundleDescriptionErrorMessage}
                    hasCharacterCount
                  />
                  <TextArea
                    enableCustomValidation
                    id="api-list-input"
                    isRequired
                    maxLength="5000"
                    label="List of REST API's ID"
                    placeholder="Add REST API Identifier as comma seperated values"
                    name="apilist"
                    onBlur={onRestApiListBlur}
                    onChange={onRestApiListChange}
                    value={restList}
                    hasError={hasrestListError}
                    errorMessage={restListErrorMessage}
                  />
                  <TextArea
                    enableCustomValidation
                    id="api-list-input"
                    isRequired
                    maxLength="5000"
                    label="List of Async API's ID"
                    name="apilist"
                    placeholder="Add Async API Identifier as comma seperated values"
                    onBlur={onAsyncApiListBlur}
                    onChange={onAsyncApiListChange}
                    value={asyncList}
                    hasError={hasasyncListError}
                    errorMessage={asyncListErrorMessage}
                  />

                  <div className="api-details-panel-save-button">
                    <PrimaryButton
                      text="Create Bundle"
                      onClick={handleBundleCreate}
                      isDisabled={
                        !(
                          !!bundleName &&
                          !hasNameError &&
                          !!bundleDescription &&
                          ((restList.length > 0 && restList[0].length > 0) ||
                            (asyncList.length > 0 && asyncList[0].length > 0))
                        )
                      }
                    />
                  </div>
                  {createError ? (
                    <Dialog
                      className="create-api-dialog"
                      buttonsProps={[
                        {
                          id: "cancel-action",
                          onClick: () => setCreateError(false),
                          text: "Cancel",
                          variant: BUTTON_VARIANTS.SECONDARY
                        }
                      ]}
                      id="share-api-dialog"
                      isOpen={createError}
                      onClose={() => setCreateError(false)}
                      title="Bundle Creation Failed"
                    >
                      {createErrorMessage.length > 0 &&
                        createErrorMessage.map(m => {
                          return <div>{m.message}</div>;
                        })}
                    </Dialog>
                  ) : null}
                </div>
              </div>
            </Card>
          </Elevation>
        </LayoutGridCell>
      </LayoutGrid>
    </Fragment>
  );
}

Create = WithNavigationToHome(Create);
class createContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <Create {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(createContainer);