import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import classNames from 'classnames';
import './certficationManagementList.css'
import { fortellisTheme, Button } from "cdk-radial";
import { ThemeProvider } from "styled-components";
import {
  TableHeaderCustomContent,
  TableContent,
  TablePagination,
  TableContainer,
  IconRenderer,
  TextRenderer,
  RatingRenderer,
  CELL_RENDERERS,
  usePreventTableScrollingRef,
  IconFileDownload,
  TextButton,
  SearchInput,
  INPUT_SIZES,
  preventDefaultBehavior,
  ButtonRenderer,
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  THEMES,
  Input
} from 'cdk-radial';

import { CERTIFICATION_MANAGEMENT_APP_CANCELLED_TEXT, CERTIFICATION_MANAGEMENT_APP_ERROR_TEXT, CERTIFICATION_STATUS_DISPLAY, SOLUTION_STATUS, CERTIFICATION_MANAGEMENT_APP_PROVISIONING_TEXT } from './certificationConstants';

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [
  {
    label: 50,
    value: 50
  },
  {
    label: 70,
    value: 70
  },
  {
    label: 150,
    value: 150
  },
  {
    label: 200,
    value: 200
  }
]


function CertificationTable({ solutionList, certificationStatus }) {
  const componentMainClass = `apiErrorTable`;
  const agGridThemeClass = 'ag-theme-balham';
  const tableClass = classNames(componentMainClass, agGridThemeClass);
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [allColumns, setAllColumns] = useState();
  const isAgGridReady = !!gridApi;
  const hasNoRows = totalRows === 0;
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [showPagination, setShowPagination] = useState(true);
  const defaultPaginationPageSize = DEFAULT_ROWS_PER_PAGE_OPTIONS[0];
  const rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS;

  const history = useHistory();

  useEffect(() => {
    setTableData(solutionList);
  }, [solutionList]);
  const buttonWithToolTip = (props) => {
    const tooltipProps = {
      id: "tooltip-id-2",
      text: props?.data?.status === SOLUTION_STATUS.CANCELLED ? CERTIFICATION_MANAGEMENT_APP_CANCELLED_TEXT :
       props?.data?.status === SOLUTION_STATUS.ERROR ? CERTIFICATION_MANAGEMENT_APP_ERROR_TEXT : CERTIFICATION_MANAGEMENT_APP_PROVISIONING_TEXT,
      theme: THEMES.LIGHT,
    };
    /* eslint-disable react/prop-types */
    const ComponentToRender = ({ setTooltipText, showTooltip, ...other }) => {
      if (props?.data?.status === SOLUTION_STATUS.CANCELLED || props?.data?.status === SOLUTION_STATUS.ERROR || props?.data?.status === SOLUTION_STATUS.PROVISIONING ) {
        showTooltip(true);
      } else {
        showTooltip(false);
      }
      const handleChange = () => {
        showTooltip(true);
      };
      return (
        <div className='certification_view_tooltip'>
        <Button
          onChange={handleChange}
          onClick={() => {
            history.push({
              pathname: `/certification-management/app/${props?.data?.id}`,
              state: {
                id: props?.data?.id,
                orgId: props?.data?.orgId,
              },
            });
          }}
          text="View"
          isDisabled={props?.data?.status === SOLUTION_STATUS.ERROR || props?.data?.status === SOLUTION_STATUS.CANCELLED || props?.data?.status === SOLUTION_STATUS.PROVISIONING}
          {...other}
        />
        </div>
      );
    };
    const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);
    return (
      <div css="display: flex;">
        <ComponentWithTooltip
          horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT}
          marginAroundElement={8}
          verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
        />
      </div>
    );
  };

  const customCertificationStatus = props => {
    // eslint-disable-next-line react/prop-types
    return CERTIFICATION_STATUS_DISPLAY[props?.value] || 'Unknown'
  };

  const columns = [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'displayName',
      headerName: 'APP Name',
      width: 390,
      sortable: true,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'id',
      headerName: 'APP ID',
      width: 290,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'orgId',
      headerName: 'Org ID',
      width: 290,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'lastUpdated',
      headerName: 'Last updated',
      width: 390,
      sort: 'desc',
      sortable: true,
      resizable: true
    },
    {
      cellRendererFramework: customCertificationStatus,
      field: 'certificationStatus',
      headerName: 'Certification Status',
      maxWidth: 215,
      hide: true,
      resizable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'status',
      headerName: 'APP Status',
      width: 175,
      resizable: true,
      resizable: true
    },
    {
      cellRendererFramework: buttonWithToolTip,
      field: 'id',
      headerName: '',
      minWidth: 100,
      width: 190
    }
  ];

  const tableContentRef = usePreventTableScrollingRef();
  const frameworkComponentsExtended = {
    textRenderer: TextRenderer,
    iconRenderer: IconRenderer,
    ratingRenderer: RatingRenderer,
    buttonRenderer: ButtonRenderer,
  };

  const onSearchInputChange = newValue => {
    setSearchInputValue(newValue);
  };

  const handleGridReady = gridParams => {
    setGridApi(gridParams.api);
    setTotalRows(gridParams.api.getDisplayedRowCount());
    gridParams.api.sizeColumnsToFit();
  };

  const handleDownloadClick = () => {
    const params = {
      fileName: `Apps-${CERTIFICATION_STATUS_DISPLAY[certificationStatus]}.csv`,
      skipHeader: false,
      columnKeys: ['displayName', 'id', 'orgId', 'lastUpdated', 'certificationStatus', 'status']
    };
    gridApi.exportDataAsCsv(params);
  };

  const handleSearchInputChange = searchInput => {
    onSearchInputChange(searchInput);

    if (gridApi) {
      gridApi.setQuickFilter(searchInput); // Refresh is needed for filtered cells highlighting to work properly

      gridApi.refreshCells({
        force: true
      });
      const displayedRowsCount = gridApi.getDisplayedRowCount();
      setTotalRows(displayedRowsCount);

      if (searchInput && displayedRowsCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  };

  const handlePaginationChanged = () => {
    if (!isAgGridReady) return;
    setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
    setTotalPages(gridApi.paginationGetTotalPages());
    setTotalRows(gridApi.getDisplayedRowCount());
  };
  const paginationPageSize = () => {
    if (isAgGridReady) {
      return gridApi.paginationGetPageSize();
    }
    if (
      defaultPaginationPageSize.value === DEFAULT_ROWS_PER_PAGE_OPTIONS[0].value
    ) {
      return rowsPerPageOptions[0].value;
    }
    if (
      rowsPerPageOptions.map(
        rowPerPageOption => rowPerPageOption === defaultPaginationPageSize.value
      )
    ) {
      return defaultPaginationPageSize.value;
    }
  };
  
  const getPageSummaryText = (currentPage, totalPages) => {
    return `Page ${currentPage} of ${totalPages}`;
  };
  const getRowsSummaryText = (currentPage, pageSize, totalRows) => {
    if (totalRows === 0) {
      return `${totalRows}-${totalRows} of ${totalRows}`;
    }
    const rowsFrom = (currentPage - 1) * pageSize + 1;
    const rowsTo = currentPage * pageSize;
    return `${rowsFrom}-${rowsTo > totalRows ? totalRows : rowsTo
      } of ${totalRows}`;
  };

  const paginationProps = {
    ariaLabel: 'Pagination',
    buttonLabels: {
      goToFirst: 'Go to first',
      goToLast: 'Go to last',
      goToNext: 'Go to next',
      goToPrevious: 'Go to previous'
    },
    className: '',
    dataTestId: `table-pagination`,
    defaultPaginationPageSize: DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
    gridApi,
    hideBorder: false,
    pageSizeDropdownId: `page-size-dropdown-table`,
    pageSummaryLabelId: `pagination-summary-label-table`,
    pageSummaryText: getPageSummaryText(
      totalPages === 0 ? 0 : currentPage,
      totalPages
    ),
    rowsPerPageLabel: 'Rows per page:',
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    rowsSummaryText: getRowsSummaryText(
      currentPage,
      paginationPageSize(),
      totalRows
    ),
    showGoToFirstLastPage: true
  };
  return (
    <TableContainer className={tableClass} tableLayout={'normal'}>
      <TableHeaderCustomContent
        className="custom-header"
        customHeaderContent={
          <div className="table-header-certification">
            <SearchInput
              className="search-box-table"
              clearButtonLabel="clearButtonLabel"
              id="search-input-expanded"
              isAlwaysExpanded
              name="search-input-expanded"
              onChange={handleSearchInputChange}
              placeholder="Filter Listed Results"
              size={INPUT_SIZES.STANDARD}
              value={searchInputValue}
            />
            <TextButton
              className="download-csv-button"
              icon={<IconFileDownload />}
              text="Download CSV"
              onClick={handleDownloadClick}
            />
          </div>
        }
      />

      <TableContent data-testid="table-content" ref={tableContentRef} >
        <div className="table-container-certfication">
          <AgGridReact
            defaultColDef={{
              sortable: true
            }}
            style={{
              height: '400px'
            }}
            frameworkComponents={frameworkComponentsExtended}
            columnDefs={allColumns || columns}
            onGridReady={handleGridReady}
            rowData={tableData || []}
            onPaginationChanged={handlePaginationChanged}
            pagination={true}
            paginationPageSize={paginationPageSize()}
            paginationAutoPageSize={false}
            suppressPaginationPanel
            rowHeight={50}
            resizable={true}
          />
        </div>
      </TableContent>
      <div className="table-pagination-bar">
        {isAgGridReady && showPagination && (
          <TablePagination
            defaultPaginationPageSize={defaultPaginationPageSize}
            {...paginationProps}
          />
        )}
      </div>
    </TableContainer>
  );
}

export default CertificationTable;
