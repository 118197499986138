import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";
import { stringify } from "query-string";

export const actions = {
  clearErrors: () => ({ type: types.CLEAR_ERRORS }),
  clearModal: () => ({ type: types.CLEAR_MODAL }),
  clearAfterApiUpdate: () => ({ type: types.CLEAR_PATCH_RESP }),

  addApiForInfoPage: api => ({
    type: types.ADMIN_API_INFO_GET_RECEIVE,
    response: api
  }),

  fetchApis: ({ accessToken, params }) => ({
    [CALL_API]: {
      url: `${config.api.api_gateway_url}/v2/admin/apis?${stringify(params)}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ADMIN_API_GET_REQUEST,
        types.ADMIN_API_GET_RECEIVE,
        types.ADMIN_API_GET_ERROR
      ]
    }
  }),

  fetchApiPage: ({ accessToken, path }) => ({
    [CALL_API]: {
      url: `${config.api.api_gateway_url}${path}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ADMIN_API_GET_REQUEST,
        types.ADMIN_API_GET_RECEIVE,
        types.ADMIN_API_GET_ERROR
      ]
    }
  }),

  fetchAsyncApis: ({ accessToken, params }) => ({
    [CALL_API]: {
      url: `${config.api.async_api_service_url}/v1/admin/async-apis?${stringify(
        params
      )}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ADMIN_ASYNC_API_GET_REQUEST,
        types.ADMIN_ASYNC_API_GET_RECEIVE,
        types.ADMIN_ASYNC_API_GET_ERROR
      ]
    }
  }),

  fetchAsyncApiPage: ({ accessToken, path }) => ({
    [CALL_API]: {
      url: `${config.api.async_api_service_url}${path}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ADMIN_ASYNC_API_GET_REQUEST,
        types.ADMIN_ASYNC_API_GET_RECEIVE,
        types.ADMIN_ASYNC_API_GET_ERROR
      ]
    }
  }),

  fetchApi: ({ accessToken, id, expand }) => ({
    [CALL_API]: {
      url: `${config.api.api_gateway_url}/v2/admin/apis/${id}${
        expand ? "?expand=true" : ""
      }`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.ADMIN_API_INFO_GET_REQUEST,
        types.ADMIN_API_INFO_GET_RECEIVE,
        types.ADMIN_API_INFO_GET_ERROR
      ]
    }
  }),

  updateApi: ({
    accessToken,
    apiId,
    featured,
    weight,
    notes,
    catalogs,
    systemSettings,
    isAsyncApi,
    isPublicListingApproved,
  }) => ({
    [CALL_API]: {
      url: isAsyncApi
        ? `${config.api.async_api_service_url}/v1/admin/async-apis/${apiId}`
        : `${config.api.api_gateway_url}/v2/admin/apis/${apiId}`,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        search: { featured, weight },
        catalogs,
        systemSettings,
        isPublicListingApproved,
        notes,
      },
      types: [
        types.UPDATE_API_PATCH_REQUEST,
        types.UPDATE_API_PATCH_RECEIVE,
        types.UPDATE_API_PATCH_ERROR
      ]
    }
  }),

  searchEntity: ({ accessToken, name }) => ({
    [CALL_API]: {
      url: `${config.api.organizations_uri}?name=${encodeURIComponent(name)}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      },
      types: [
        types.ENTITY_SEARCH_GET_REQUEST,
        types.ENTITY_SEARCH_GET_RECEIVE,
        types.ENTITY_SEARCH_GET_ERROR
      ]
    }
  })
};
