import React from "react";
import { withRouter } from "react-router";
import withNavigation from "../components/withNavigation";

const withNavigationToApiAdmin = Component => withRouter(
  withNavigation([
    {
      text: "Back To APIs",
      goTo: "/api-admin"
    }
  ])(Component)
);

export default withNavigationToApiAdmin;
