import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { Input, Button, Dialog, BUTTON_VARIANTS, Loader } from "cdk-radial";
import { Card } from "@cdk-uip/react-card";
import "./style.css";
import SharedOrgsTable from "./SharedOrgsTable";
import axios from "axios";
import config from "../config/app.conf.json";


function Share({ auth, bundleName, bundleId, setScreen }) {
  const [orgIdInput, setOrgIdInput] = useState("");
  const [isOrgIdValid, setIsOrgIdValid] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharingBundle, setSharingBundle] = useState(false);
  const [errorSharingBundle, setErrorSharingBundle] = useState(null)
  const [bundleShared,setBundleShared] = useState(false)
  const [orgs, setOrgs]=useState([])
  const [orgInptError, setOrgInptError] = useState("")
  const history = useHistory();
  const [orgDetails, setOrgDetails] = useState({});
  const [errorGettingOrgDetails , setErrorGettingOrgDetails] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
 
  const { accessToken } = auth;

  useEffect(() => {
    if (accessToken !== null) {
      axios
        .get(`${config.api.api_gateway_url}/v2/admin/bundle/${bundleId}/shared-orgs`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
         setOrgs(response?.data?.items)        
        })
        .catch(error => {
          console.error('Error fetching shared organizations:', error); 
        });
  }
  if(orgIdInput.length > 0 && isOrgIdValid) {
    setIsLoading(true);
    axios
    .get(`${config.api.organizations_uri}/${orgIdInput}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
    setOrgDetails(response?.data);
    setErrorGettingOrgDetails(false);
    setIsLoading(false);
    })
    .catch(error => {
      setOrgInptError("Please enter a valid Organization ID");
      setErrorGettingOrgDetails(true);
      setIsLoading(false);
      console.error('Error fetching organization details:', error); 
    }
  );
}
  }, [accessToken,bundleShared, orgIdInput]);

  const handleOrgIdChange = (id) => {
    setOrgIdInput(id);
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/iu;
    let isValidInput = true;
    if(!uuidRegex.test(id)){
      isValidInput = false;
      setOrgInptError("Please enter a valid Organization ID")
    }
    else if(orgs.map(org => org?.organizationId).some(orgId => orgId === id)){
      isValidInput = false;
      setOrgInptError("Bundle already shared with the given org")
    }
    else {
      setOrgInptError("");
    }
    setIsOrgIdValid(isValidInput);
  };

  const handleShareBundle = () => {
    setShareDialogOpen(false);
    setSharingBundle(true);
    let sharePayload = {
      id : bundleId,
      orgId: orgIdInput,
      type : "bundle",
      name: bundleName
    }
    axios
        .post(`${config.api.api_gateway_url}/v2/admin/bundles/share`, 
         [sharePayload]
        , {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          setSharingBundle(false);
          setBundleShared(prev => !prev)
          setOrgIdInput("")
        })
        .catch(error => {
          setSharingBundle(false);
          setErrorSharingBundle(error?.response?.data?.message || 'Error sharing bundle with the org')
          console.error('Error sharing bundle with the org:', error); 
        });
  }

  const dialogTitle = `Share Bundle - ${bundleName}`;

  return (
    <Fragment>
      <Button
        dataTestId="button"
        iconColor="primary"
        iconSize={24}
        onClick={() => setScreen('View Bundles')}
        size="standard"
        text="Back to Bundles"
        variant="primary"
        className="back_to_bundles_button"
      />
      <Card className="bundle_share_card">
        <div className="bundle_share_card_container">
          <div className="bundle_share_card_title">
            Share Bundle - {bundleName}
          </div>
          <div className="bundle_share_card_description">
            Organizations invited to can be able to view and use the bundle even
            if it is private
          </div>
          <div className="org_id_input_container">
            <Input
              dataTestId="org-id-input"
              errorMessage={
                orgInptError
              }
              hasError={((!isOrgIdValid  || errorGettingOrgDetails) && orgIdInput.length > 0) }
              enableCustomValidation
              hasClearButton
              id="input-custom-error"
              isRequired
              label="Org ID"
              name="org-input"
              onChange={(event) => handleOrgIdChange(event.target.value)}
              onInputClear={() => {
                setOrgIdInput("");
              }}
              placeholder="Enter Organization ID here"
              value={orgIdInput}
            />
            <div className="bundle_share_button">
              <Button
                dataTestId="share-button"
                iconColor="primary"
                iconSize={24}
                onClick={() => {
                  setShareDialogOpen(true);
                }}
                size="standard"
                text="Share"
                variant="primary"
                isLoading= {isLoading}
                isDisabled={!isOrgIdValid || orgIdInput.length === 0 || errorGettingOrgDetails || isLoading}
              />
            </div>
            {
              <Dialog
                buttonsProps={[
                  {
                    id: "dialog-action-1",
                    onClick: function noRefCheck() {
                      setShareDialogOpen(false);
                    },
                    text: "Cancel",
                    variant: BUTTON_VARIANTS.TEXT,
                  },
                  {
                    id: "dialog-action-2",
                    dataTestId: 'shareConfirm',
                    onClick: handleShareBundle,
                    text: "Share",
                    variant: BUTTON_VARIANTS.PRIMARY,
                  },
                ]}
                dataTestId="sbdialog"
                id="sbdialog"
                onClose={() => setShareDialogOpen(false)}
                title={dialogTitle}
                isOpen={shareDialogOpen}
                className="bundle_share_dialog_box"
              >
                You are sharing{" "}
                <b>Bundle {bundleName}</b> with <b>{orgDetails.name}.</b> <br />
                <br />
                Please note that if your API has a public plan it will
                automatically be shared. In case you need to share a private
                plan, you can use the existing functionality of "inviting an org
                to use a pricing plan" from Fortellis. Would you like to
                continue sharing?
              </Dialog>
            }
          </div>
          <SharedOrgsTable orgs={orgs}/>
          {errorSharingBundle ? (
              <Dialog
                className="create-api-dialog"
                buttonsProps={[
                  {
                    id: "cancel-action",
                    onClick: () => setErrorSharingBundle(false),
                    text: "Cancel",
                    variant: BUTTON_VARIANTS.SECONDARY
                  }
                ]}
                id="share-api-dialog"
                isOpen
                onClose={() => setErrorSharingBundle(false)}
                title="Bundle Share Failed"
              >
                <div>{errorSharingBundle}</div> 
              </Dialog>
            ) : null}
            
        </div>
      </Card>
      {!!sharingBundle?
      <Dialog
      isOpen
      hideHeader>
        <div style={{display:'flex'}}>
         <Loader
                dataTestId="fectching-apis-loader"
                size="small"
                label="Sharing Bundle..."
              />
              </div>

      </Dialog>
              : null }
    </Fragment>
  );
}

class shareContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <Share {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(shareContainer);
