function findMax(arr) {
  let m = 0;
  for (let item of arr) {
    if (item.value > m) {
      m = item.value;
    }
  }
  return m;
}

function findRoundingDigit(val) {
  if (val > 100) return 2;
  if (val > 10) return 1;
  if (val > 1) return 0;
}

function round(val) {
  const rounder = Math.pow(10, findRoundingDigit(val));
  let temp = Math.floor(val);
  let power = 0;

  while (temp > rounder) {
    temp = Math.floor(temp / 10);
    power++;
  }

  return (temp + 1) * Math.pow(10, power);
}

export { findMax, round };
