import types from "./types";

const INITIAL_STATE = {
  apps: [],
  fetchingApps: false,
  subscriptionReport: "",
  reportLoading: false,
  error: "",
  appName: "",
  showErrorModal: false
};
const subscriptionReports = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_APP_REQUEST:
      return {
        ...state,
        fetchingApps: true,
        error: ""
      };
    case types.GET_APP_RECIEVE:
      return {
        ...state,
        apps: [action.response],
        fetchingApps: false
      };
    case types.GET_APP_ERROR:
      return {
        ...state,
        apps: [],
        fetchingApps: false,
        showErrorModal: true,
        error: action.apierror.message
      };
    case types.GET_ORG_APPS_REQUEST:
      return {
        ...state,
        fetchingApps: true,
        error: ""
      };
    case types.GET_ORG_APPS_RECEIVE:
      return {
        ...state,
        apps: action.response,
        fetchingApps: false
      };
    case types.GET_ORG_APP_ERROR:
      return {
        ...state,
        apps: [],
        fetchingApps: false,
        showErrorModal: true,
        error: action.apierror.message
      };
    case types.GET_APP_SUBSCRIPTION_REPORT_REQUEST:
      return {
        ...state,
        subscriptionReport: "",
        appName: action.appName,
        reportLoading: true,
        error: ""
      };
    case types.GET_APP_SUBSCRIPTION_REPORT_RECEIVE:
      return {
        ...state,
        subscriptionReport: action.response,
        reportLoading: false
      };
    case types.GET_APP_SUBSCRIPTION_REPORT_ERROR:
      return {
        ...state,
        reportLoading: false,
        showErrorModal: true,
        error: action.apierror.message
      };
    case types.ERROR:
      return {
        ...state,
        error: action.errorMessage,
        showErrorModal: true
      };
    case types.ERROR_RESET:
      return {
        ...state,
        error: action.errorMessage,
        showErrorModal: false
      };
    default:
      return state;
  }
};

export default subscriptionReports;
