import React, { useEffect, useState } from "react";
import { fortellisTheme, Dialog, BUTTON_VARIANTS, CheckboxGroup } from "cdk-radial";
import { ThemeProvider } from "styled-components";

const EnhancedFlagsDialog = ({open, entity, saveChanges, onClose}) => {

    const [checkedOptions, setCheckedOptions] = useState({});

    const onOptionSelect = event => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        setCheckedOptions({
        ...checkedOptions,
        [item]: isChecked
        });
    };

    useEffect(() => {
        const flags = entity?.enhancedFlags && JSON.parse(entity?.enhancedFlags);
        if(flags) {
            setCheckedOptions(flags);
        }
    }, [entity]);

    const options = [{
        label: 'Skip App Certification Fees',
        name: 'skipCertificationFee'
    }, {
        label: 'Skip App Listing Fees',
        name: 'skipListingFee'
    }, {
        label: 'Skip App Publisher Terms',
        name: 'skipAppPublisherTerms'
    }, {
        label: 'Skip API Terms',
        name: 'skipAPITerms'
    }, {
        label: 'Skip API Pricing',
        name: 'skipAPIPricing'
    }]

    return (
        <div >
            <ThemeProvider theme={fortellisTheme}>
                <Dialog 
                    buttonsProps={[
                        {
                            id: "dialog-action-1",
                            onClick: () => onClose(),
                            text: "Cancel",
                            variant: BUTTON_VARIANTS.TEXT,
                            dataTestId: 'cancel-btn'
                        },
                        {
                            id: "dialog-action-2",
                            onClick: () => saveChanges(entity, checkedOptions),
                            text: "Save",
                            variant: BUTTON_VARIANTS.PRIMARY,
                            dataTestId: 'continue-btn',
                        }
                    ]}
                    id={'enhanced-flags-dialog'}
                    dataTestId={'enhanced-flags-dialog'}
                    isOpen={open}
                    onClose={() => onClose()}
                    title={'Enhanced Flags'}
                >
                    <CheckboxGroup 
                        style={{width: '350px', padding: 'inherit'}}
                        checkedOptions={checkedOptions} 
                        hideGroupLabel 
                        onChange={onOptionSelect} 
                        options={options} />
                </Dialog>
            </ThemeProvider>
        </div>
    )
}

export default EnhancedFlagsDialog;