import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import config from "../config/app.conf.json";
import axios from 'axios';
import WithNavigationToHome from "../components/WithNavigationToHome";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import {
    CardWrapper,
    CardBody,
    Chip,
    fortellisTheme,
    Loader
} from 'cdk-radial';
import { ThemeProvider } from "styled-components";
import CertificationTable from './CertificationTable';
import './certficationManagementList.css'
import { CERTIFICATION_STATUS_CHIPS, CERTIFICATION_STATUS_DISPLAY, CERTIFICATION_STATUS } from './certificationConstants';

function CertificationManagementList({ auth }) {
    const [solutionList, setSolutionList] = useState([]);
    const [selectedChip, setSelectedChip] = useState("");
    const [fetchingData, setfetchingData] = useState(false);
    const [tabIndexValue, setTabIndexValue] = useState(1);
    const location = useLocation();
    const history = useHistory();

    const getSolutionList = async (status) => {
        try {
            setfetchingData(true);
            const response = await axios({
                url: `${config.api.solutions_url}?certificationStatus=${status}&&all=true`,
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${auth?.accessToken}`,
                }
            });
            const data = response && response.data
            setSolutionList(data);
            setfetchingData(false);
        } catch (error) {
            setfetchingData(false);
            console.error(error);
        }
    }

    useEffect(() => {
        if (location?.state?.goTo) {
            setSelectedChip(location.state.goTo)
            setTabIndexValue(CERTIFICATION_STATUS_CHIPS.indexOf(location.state.goTo)+1);
        } else {
            setSelectedChip(CERTIFICATION_STATUS.READY_TO_CERTIFY)
            setTabIndexValue(CERTIFICATION_STATUS_CHIPS.indexOf(CERTIFICATION_STATUS.READY_TO_CERTIFY)+1);
        }
    }, [])

    useEffect( () => {
        if (auth.accessToken && selectedChip) {
            getSolutionList(selectedChip);
        }
    }, [selectedChip, auth])

    const onClickChipHandler = (event, certificationStatus, tabIndexValue) => {
        setSelectedChip(certificationStatus);
        setTabIndexValue(tabIndexValue);
    };

    return (
        <ThemeProvider theme={fortellisTheme}>
            <CardWrapper>
                <CardBody>
                    <div className="status-card-chips-div">
                        {fetchingData ? (
                            <div className="body-loader"><Loader label={<span style={{ fontSize: '18px', fontWeight: '600' }}>Loading...</span>} /></div>
                        ) : (
                            <>
                                {
                                    CERTIFICATION_STATUS_CHIPS.map((chip, i) => {
                                        return (
                                            <Chip
                                                id={`${chip}-chip`}
                                                isSelected={tabIndexValue === i + 1}
                                                className="certification-status-chip"
                                                onClick={event => {
                                                    onClickChipHandler(
                                                        event,
                                                        chip,
                                                        i + 1
                                                    );
                                                }}
                                                label={CERTIFICATION_STATUS_DISPLAY[chip]}
                                            >
                                            </Chip>
                                        )
                                    })}
                                <CertificationTable solutionList={solutionList} certificationStatus={selectedChip} />
                            </>

                        )}
                    </div>
                </CardBody>
            </CardWrapper>
        </ThemeProvider>
    )
}

CertificationManagementList = WithNavigationToHome(CertificationManagementList);
class CertificationManagementListContainer extends React.Component {
    render() {
        return this.props.auth.isAuthenticated ? (
            <CertificationManagementList {...this.props} />
        ) : (
            <div> No Auth</div>
        );
    }
}
export default withAuth(CertificationManagementListContainer);