// keeping this around for now

import React from "react";
import { array } from "prop-types";

function SpecFileView({ lines }) {
  return (
    <div className="spec-preview-lines">
      {lines.map((line, idx) => {
        return (
          <div className="spec-preview-line" key={idx}>
            <div className="spec-preview-gutter">
              <div className="spec-preview-line-number">{idx + 1}</div>
            </div>
            <div>
              <pre className="spec-preview-line-content">{line || "\xA0"}</pre>
            </div>
          </div>
        );
      })}
    </div>
  );
}

SpecFileView.propTypes = {
  lines: array
};

export default SpecFileView;
