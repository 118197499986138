import types from "./types";

const INITIAL_STATE = {
  organizations: [],
  users: {},
  loadingMoreEntities: false,
  loadingEntities: false,
  loadingUsers: false,
  loadingApproval: false,
  loadingMessage: "",
  showingError: false,
  errorMessage: ""
};

const entityApprovalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISMISS_ERROR:
      return {
        ...state,
        showingError: false,
        errorMessage: ""
      };
    case types.REQUEST_PENDING_ENTITIES:
      return {
        ...state,
        loadingEntities: true,
        loadingMessage: "Loading unverified organizations..."
      };
    case types.RECEIVE_PENDING_ENTITIES:
      return {
        ...state,
        loadingEntities: false,
        organizations: {
          [action.response.page]: action.response.organizations
        },
        totalOrgCount: action.response.total,
        loadingMessage: ""
      };
    case types.ERROR_PENDING_ENTITIES:
      return {
        ...state,
        loadingEntities: false,
        loadingMessage: "",
        showingError: true,
        errorMessage: "Failed to get organizations"
      };
    case types.REQUEST_MORE_PENDING_ENTITIES:
      return {
        ...state,
        loadingMoreEntities: true
      };
    case types.RECEIVE_MORE_PENDING_ENTITIES:
      return {
        ...state,
        loadingMoreEntities: false,
        organizations: {
          ...state.organizations,
          [action.response.page]: action.response.organizations
        },
        totalOrgCount: action.response.total
      };
    case types.ERROR_MORE_PENDING_ENTITIES:
      return {
        ...state,
        loadingMoreEntities: false,
        showingError: true,
        errorMessage: "Failed to get organizations"
      };
    case types.ENTITY_VERIFY_PUT_REQUEST:
      return {
        ...state,
        loadingApproval: true,
        loadingMessage: "Waiting on organization update..."
      };
    case types.ENTITY_VERIFY_PUT_RECEIVE:
      return {
        ...state,
        loadingApproval: false,
        loadingMessage: ""
      };
    case types.ENTITY_VERIFY_PUT_ERROR:
      let apiErrorMsg = action.apierror.message;
      return {
        ...state,
        loadingApproval: false,
        loadingMessage: "",
        showingError: true,
        errorMessage:
          !!apiErrorMsg &&
          !(apiErrorMsg.indexOf("Internal Server Error") !== -1)
            ? apiErrorMsg
            : "Failed to update organization"
      };
    default:
      return state;
  }
};

export default entityApprovalReducer;
