import types from "./types";

const INITIAL_STATE = {};

const IN_PROGRESS = {
  showInProgress: true
};

const ERROR_RESET = {
  errorMessage: "",
  showErrorModal: false,
  showInProgress: false,
  searchError: false,
  message: "",
  showModal: false
};

const CLOSE_MODAL = {
  showModal: false
};

const closeModal = state => {
  Object.assign({}, state, CLOSE_MODAL);
};

const returnStateWithErrorReset = function(state) {
  return Object.assign({}, state, ERROR_RESET);
};

export default function bulkUserUploadManagement(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case types.ERROR:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        showErrorModal: true
      });
    case types.ENTITY_POST_REQUEST:
      return Object.assign({}, state, IN_PROGRESS);
    case types.ENTITY_POST_RECEIVE:
      return Object.assign({}, state, {
        message: "File uploaded successfully.",
        showModal: true
      });
    case types.ENTITY_POST_ERROR:
      return Object.assign({}, state, {
        message: "Failed to process event.",
        showModal: true,
        closeModal: closeModal
      });
    default:
      return returnStateWithErrorReset(state);
  }
}
