import MonetizationReport from "./MonetizationReport";
import { connect } from "react-redux";
import { actions } from "./MonetizationReportAction";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    state => ({
      monetizationReport: state.monetizationReducers
    }),
    actions
  )(MonetizationReport)
);
