import SubscriptonManagement from "./SubscriptionManagement";
import { connect } from "react-redux";
import { actions } from "./subscriptionActions";
import { withRouter } from "react-router";

export default withRouter(
  connect(
    // mapStateToProps
    state => ({
      subscriptionManagement: state.subscriptionManagement,
      legalEntityID: state.legalEntityID
    }),
    // Inject all actions into the component
    actions
  )(SubscriptonManagement)
);

//export default RegistrationMetrics;
