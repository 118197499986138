export default {
  API_GET_REQUEST: "API_GET_REQUEST",
  API_GET_RECEIVE: "API_GET_RECEIVE",
  API_GET_ERROR: "API_GET_ERROR",

  PROVIDER_GET_REQUEST: "PROVIDER_GET_REQUEST",
  PROVIDER_GET_RECEIVE: "PROVIDER_GET_RECEIVE",
  PROVIDER_GET_ERROR: "PROVIDER_GET_ERROR",

  UPDATE_NOTES_PUT_REQUEST: "UPDATE_NOTES_PUT_REQUEST",
  UPDATE_NOTES_PUT_RECEIVE: "UPDATE_NOTES_PUT_RECEIVE",
  UPDATE_NOTES_PUT_ERROR: "UPDATE_NOTES_PUT_ERROR",

  UPDATE_NOTES_POST_REQUEST: "UPDATE_NOTES_POST_REQUEST",
  UPDATE_NOTES_POST_RECEIVE: "UPDATE_NOTES_POST_RECEIVE",
  UPDATE_NOTES_POST_ERROR: "UPDATE_NOTES_POST_ERROR",

  PRICING_URL_UPDATE_REQUEST: "PRICING_URL_UPDATE_REQUEST",
  PRICING_URL_UPDATE_RECEIVE: "PRICING_URL_UPDATE_RECEIVE",
  PRICING_URL_UPDATE_ERROR: "PRICING_URL_UPDATE_ERROR",

  CATALOG_NUMBER_UPDATE_REQUEST: "CATALOG_NUMBER_UPDATE_REQUEST",
  CATALOG_NUMBER_UPDATE_RECEIVE: "CATALOG_NUMBER_UPDATE_RECEIVE",
  CATALOG_NUMBER_UPDATE_ERROR: "CATALOG_NUMBER_UPDATE_ERROR",

  PROVIDER_FILE_GET_REQUEST: "PROVIDER_FILE_GET_REQUEST",
  PROVIDER_FILE_GET_RECEIVE: "PROVIDER_FILE_GET_RECEIVE",
  PROVIDER_FILE_GET_ERROR: "PROVIDER_FILE_GET_ERROR",

  REVIEW_PROVIDER_PUT_REQUEST: "REVIEW_PROVIDER_PUT_REQUEST",
  REVIEW_PROVIDER_PUT_RECEIVE: "REVIEW_PROVIDER_PUT_RECEIVE",
  REVIEW_PROVIDER_PUT_ERROR: "REVIEW_PROVIDER_PUT_ERROR",

  APPROVE_PROVIDER_PUT_REQUEST: "APPROVE_PROVIDER_PUT_REQUEST",
  APPROVE_PROVIDER_PUT_RECEIVE: "APPROVE_PROVIDER_PUT_RECEIVE",
  APPROVE_PROVIDER_PUT_ERROR: "APPROVE_PROVIDER_PUT_ERROR",

  REJECT_PROVIDER_PUT_REQUEST: "REJECT_PROVIDER_PUT_REQUEST",
  REJECT_PROVIDER_PUT_RECEIVE: "REJECT_PROVIDER_PUT_RECEIVE",
  REJECT_PROVIDER_PUT_ERROR: "REJECT_PROVIDER_PUT_ERROR",

  USER_DETAILS_GET_REQUEST: "USER_DETAILS_GET_REQUEST",
  USER_DETAILS_GET_RECEIVE: "USER_DETAILS_GET_RECEIVE",
  USER_DETAILS_GET_ERROR: "USER_DETAILS_GET_ERROR",

  REVERT_REVIEW_PUT_REQUEST: "REVERT_REVIEW_PUT_REQUEST",
  REVERT_REVIEW_PUT_RECEIVE: "REVERT_REVIEW_PUT_RECEIVE",
  REVERT_REVIEW_PUT_ERROR: "REVERT_REVIEW_PUT_ERROR"
};
