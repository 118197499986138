import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { useHistory } from "react-router-dom";
import { TextButton, IconChevronLeft } from "cdk-radial";
import UsageReportContainer from "./UsageReportContainer";
import {
  Input,
  INPUT_SIZES,
  Button,
  BUTTON_VARIANTS,
  IconSearch,
  INPUT_ICON_POSITIONS,
  ListItem,
  ListItemPrimaryText,
  ListItemText,
  ListWrapper,
  Popup
} from "cdk-radial";

import "./usage-report.scss";
import config from "../config/app.conf.json";

const ORG_SERVICE_URL = config.api.organizations_uri;

const uuidEx = /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i;

const SearchComponent = ({ auth }) => {
  const [searchInput, setSearchInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");

  const history = useHistory();

  const searchInputIsId = uuidEx.test(searchInput);
  const authToken = auth.accessToken;
  let searchButtonText = searchInputIsId ? "Find By ID" : "Search";

  const handleInputChange = event => {
    setSearchInput(event.target.value);
  };
  const changeSelectedOrg = id => {
    setSelectedOrg(id);
  };
  const handleSelectionChange = async (id, name) => {
    setSearchInput(name);
    changeSelectedOrg(id);
    setIsOpen(false);
  };

  useEffect(() => {}, [selectedOrg]);

  const onSearchButton = async () => {
    setIsLoading(true);
    await axios({
      method: "GET",
      url: `${ORG_SERVICE_URL}?searchBy=${encodeURIComponent(searchInput)}`,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        let data = response.data.organizations;
        setSearchResults(data);
        setIsOpen(true);
        setIsLoading(false);
        setNoResults(data.length ? false : true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="usage-report-page-content">
      <div className="usage-report-back-button">
        <TextButton
          onClick={() => {
            history.goBack();
          }}
          icon={<IconChevronLeft />}
          text="Back"
        />
      </div>
      <form
        className="org-search-bar"
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onSearchButton();
        }}
      >
        <Input
          className="org-input-search-bar"
          name="orgSearch"
          id="Org-search"
          label="Search Organization"
          onChange={handleInputChange}
          placeholder="Search for an org by name, or enter an org ID to get usage report of particular org"
          value={searchInput}
          size={INPUT_SIZES.SMALL}
          icon={<IconSearch />}
          iconPosition={INPUT_ICON_POSITIONS.LEADING}
          hasClearButton={true}
          onInputClear={() => setSearchInput("")}
          dataTestId="search-input"
        />
        {noResults ? (
          <div className="search-results-container">
            <ListWrapper
              style={{ width: "100%" }}
              className={isOpen ? "search-results-popup" : null}
            >
              <ListItem>
                <ListItemText>
                  <ListItemPrimaryText>
                    No Results Found
                  </ListItemPrimaryText>
                </ListItemText>
              </ListItem>
            </ListWrapper>
          </div>
        ) : null}
        {searchResults.length ? (
          <div className="search-results-container">
            <Popup
              style={{ background: "#fff", zIndex: "5" }}
              dataTestId="popup"
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onOpen={() => {}}
              wrappedComponentContent={<div />}
            >
              <ListWrapper
                style={{ width: "100%" }}
                className={isOpen ? "search-results-popup" : null}
              >
                {searchResults.map(item => (
                  <ListItem
                    key={item.id}
                    onClick={() => {
                      handleSelectionChange(item.id, item.name);
                    }}
                  >
                    <ListItemText>
                      <ListItemPrimaryText>{item.name}</ListItemPrimaryText>
                    </ListItemText>
                  </ListItem>
                ))}
              </ListWrapper>
            </Popup>
          </div>
        ) : null}
        <Button
          label={searchButtonText}
          name={searchButtonText}
          text={searchButtonText}
          variant={BUTTON_VARIANTS.PRIMARY}
          isLoading={isLoading}
          type="submit"
        />
      </form>
      {selectedOrg && !isOpen ? (
        <UsageReportContainer selectedOrg={selectedOrg} />
      ) : null}
    </div>
  );
};

export default withAuth(SearchComponent);
