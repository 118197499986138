import React, { useMemo } from "react";
import {
  DataTable,
  DataTableBody,
  DataTablePagination
} from "@cdk-uip/react-data-table";
import { Button, ButtonIcon } from "@cdk-uip/react-button";
import moment from "moment";

export const DATA_FORMATS = {
  DATE: "Date",
  PLAIN_TEXT: "Plain Text"
};

const NO_DATA = "N/A";

// Reduced formatting for shortening year and seconds
export const formatDate = rawDate => {
  return rawDate
    ? moment(rawDate).format("M/DD/YY") + ", " + moment(rawDate).format("LT")
    : "";
};

export const TableWithPagination = ({
  pageSize,
  pageNumber,
  items,
  RowComponent,
  header,
  onPageUpdate
}) => {
  const currentPagedItems = useMemo(
    () => items.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize),
    [items, pageNumber]
  );

  const pageListForward = () => {
    if (!items.length || items.length < (pageNumber + 1) * pageSize + 1) return;
    onPageUpdate(true);
  };

  const pageListBackward = () => {
    if (!items.length || pageNumber - 1 < 0) return;
    onPageUpdate(false);
  };

  const formatData = (data, id, format) => {
    let result = "";
    if (typeof format === "function") return format(data, id);

    switch (format) {
      case DATA_FORMATS.DATE:
        result = formatDate(data[id]);
        break;
      case DATA_FORMATS.PLAIN_TEXT:
        result = data[id];
        break;
      default:
        break;
    }
    return result || NO_DATA;
  };

  return (
    <React.Fragment>
      <DataTable className="c-api-provider-table" sortable>
        {header()}
        <DataTableBody>
          {currentPagedItems.map(item => RowComponent(item, formatData))}
        </DataTableBody>
      </DataTable>
      <DataTablePagination>
        <div className="c-pagination-actions">
          <Button onClick={pageListBackward} disabled={pageNumber - 1 < 0}>
            <ButtonIcon>chevron_left</ButtonIcon>
            Previous
          </Button>
          <div className="page-count">
            {pageNumber + Math.ceil(currentPagedItems.length / pageSize)}/
            {Math.ceil(items.length / pageSize)}
          </div>
          <Button
            onClick={pageListForward}
            disabled={items.length < (pageNumber + 1) * pageSize + 1}
          >
            Next
            <ButtonIcon>chevron_right</ButtonIcon>
          </Button>
        </div>
      </DataTablePagination>
    </React.Fragment>
  );
};

export default {
  TableWithPagination,
  DATA_FORMATS,
  formatDate
};
