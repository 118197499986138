function getErrorMessage(err = "") {
  err = err.toLowerCase();
  if (err.includes("Fetching User Profile failed.".toLowerCase()))
    return "Unable to find user profile.";
  if (err.includes("Bad Input".toLowerCase()))
    return "Server unable to process this request.";
  if (err.includes("Does not have enough permissions".toLowerCase()))
    return "Not authorized to perform this operation, please contact CDK.ExchangeLeadership@cdk.com";
  if (err.includes("Account already active".toLowerCase()))
    return "Account approved by another admin.";
  return "Please try again.";
}

module.exports = {
  getErrorMessage
};
