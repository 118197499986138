export default {
  ERROR: "ERROR",
  ERROR_RESET: "ERROR_RESET",
  GET_APP_REQUEST: "GET_APP_REQUEST",
  GET_APP_RECIEVE: "GET_APP_RECIEVE",
  GET_APP_ERROR: "GET_APP_ERROR",
  GET_ORG_APPS_REQUEST: "GET_ORG_APPS_REQUEST",
  GET_ORG_APPS_RECEIVE: "GET_ORG_APPS_RECEIVE",
  GET_ORG_APP_ERROR: "GET_ORG_APP_ERROR",
  GET_APP_SUBSCRIPTION_REPORT_REQUEST: "GET_APP_SUBSCRIPTION_REPORT_REQUEST",
  GET_APP_SUBSCRIPTION_REPORT_RECEIVE: "GET_APP_SUBSCRIPTION_REPORT_RECEIVE",
  GET_APP_SUBSCRIPTION_REPORT_ERROR: "GET_APP_SUBSCRIPTION_REPORT_ERROR"
};
