export default {
  ADMIN_API_GET_REQUEST: "ADMIN_API_GET_REQUEST",
  ADMIN_API_GET_RECEIVE: "ADMIN_API_GET_RECEIVE",
  ADMIN_API_GET_ERROR: "ADMIN_API_GET_ERROR",

  ADMIN_ASYNC_API_GET_REQUEST: "ADMIN_ASYNC_API_GET_REQUEST",
  ADMIN_ASYNC_API_GET_RECEIVE: "ADMIN_ASYNC_API_GET_RECEIVE",
  ADMIN_ASYNC_API_GET_ERROR: "ADMIN_ASYNC_API_GET_ERROR",

  ADMIN_API_INFO_GET_REQUEST: "ADMIN_API_INFO_GET_REQUEST",
  ADMIN_API_INFO_GET_RECEIVE: "ADMIN_API_INFO_GET_RECEIVE",
  ADMIN_API_INFO_GET_ERROR: "ADMIN_API_INFO_GET_ERROR",

  UPDATE_API_PATCH_REQUEST: "UPDATE_API_PATCH_REQUEST",
  UPDATE_API_PATCH_RECEIVE: "UPDATE_API_PATCH_RECEIVE",
  UPDATE_API_PATCH_ERROR: "UPDATE_API_PATCH_ERROR",

  UPDATE_ASYNC_API_PATCH_REQUEST: "UPDATE_ASYNC_API_PATCH_REQUEST",
  UPDATE_ASYNC_API_PATCH_RECEIVE: "UPDATE_ASYNC_API_PATCH_RECEIVE",
  UPDATE_ASYNC_API_PATCH_ERROR: "UPDATE_ASYNC_API_PATCH_ERROR",

  ENTITY_SEARCH_GET_REQUEST: "ENTITY_SEARCH_GET_REQUEST",
  ENTITY_SEARCH_GET_RECEIVE: "ENTITY_SEARCH_GET_RECEIVE",
  ENTITY_SEARCH_GET_ERROR: "ENTITY_SEARCH_GET_ERROR",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_MODAL: "CLEAR_MODAL",
  CLEAR_PATCH_RESP: "CLEAR_PATCH_RESP"
};
