import React, { Component, Fragment } from "react";
import { parse } from "json2csv";
// Components
import { Card } from "@cdk-uip/react-card";
import { CircularProgress } from "@cdk-uip/react-circular-progress";
import {
  DataTable,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
  DataTableBody,
  DataTableCell,
  DataTableToolbar,
  DataTableToolbarTitle,
  DataTableToolbarTitlePrimary,
  DataTableToolbarAction,
  DataTableToolbarActions
} from "@cdk-uip/react-data-table";
import { Select } from "@cdk-uip/react-select";
import { Icon } from "@cdk-uip/react-icon";
import { Elevation } from "@cdk-uip/react-elevation";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
// Helpers
import WithNavigationToHome from "../components/WithNavigationToHome";
import csv from "csvtojson";
import moment from "moment";

const DATE_FORMAT = "MM-DD-YYYY";
const DATE_FORMAT_FILE = "YYYY-MM-DD";
const MONTH_FORMAT = "MMMM";
const MONTH = "month";
class ApiUsage extends Component {
  state = {
    authorized: true,
    currentData: "",
    dates: [],
    datesLoaded: false,
    open: false,
    selected: ""
  };

  componentDidMount() {
    const {
      auth: { accessToken },
      getApiUsageByMonth
    } = this.props;
    const lastMonth = moment().month() - 1;
    const date = this.getDate(lastMonth);
    this.setState({ dates: [date] });
    getApiUsageByMonth({ accessToken, date });
  }

  componentDidUpdate(prevProps) {
    const {
      auth: { accessToken },
      store: {
        apiUsage: { data, error }
      },
      getApiUsage,
      getApiUsageByMonth
    } = this.props;
    if (error !== prevProps.store.apiUsage.error) {
      if (error === "Report already exists.") {
        const date = this.getDateFromLocation(data.value.location);
        getApiUsageByMonth({ accessToken, date });
        return;
      } else if (error === "Unauthorized") {
        this.setState(() => ({ authorized: false }));
        return;
      }
    }
    if (
      data &&
      data.type &&
      data.value &&
      data.value !== prevProps.store.apiUsage.data.value
    ) {
      if (data.type === "create") {
        if (data.value.location) {
          const date = this.getDateFromLocation(data.value.location);
          getApiUsageByMonth({ accessToken, date });
        }
      } else if (data.type === "getByMonth") {
        if (data.value.status === "pending") {
          getApiUsageByMonth({ accessToken, date: data.date });
        } else if (
          data.value.status === "not found" &&
          !this.state.datesLoaded
        ) {
          getApiUsage({ accessToken });
        } else {
          csv()
            .fromString(data.value)
            .then(items => {
              this.setState(state => ({
                selected: data.date
              }));
              this.setState(() => ({
                currentData: this.getCurrentData(items)
              }));
              if (!this.state.datesLoaded) {
                const m = moment(data.date, DATE_FORMAT).month() - 1;
                getApiUsageByMonth({
                  accessToken,
                  date: this.getDate(m),
                  updateMenu: true
                });
              }
            });
        }
      } else if (data.type === "updateMenu") {
        const stopDate = moment()
          .month(0)
          .endOf(MONTH)
          .format(DATE_FORMAT);
        if (!data.value.status) {
          this.setState(state => {
            const dateSet = new Set([...state.dates, data.date]);
            return { dates: Array.from(dateSet) };
          });
        }
        if (data.date !== stopDate) {
          const m = moment(data.date, DATE_FORMAT).month() - 1;
          getApiUsageByMonth({
            accessToken,
            date: this.getDate(m),
            updateMenu: true
          });
        } else {
          this.setState(() => ({ datesLoaded: true }));
        }
      }
    }
  }

  getCurrentData = items => {
    const data = [];
    for (let item of items) {
      const row = [];
      for (let [key, value] of Object.entries(item)) {
        row.push({ key, value });
      }
      data.push(row);
    }
    return data;
  };

  getDate = month => {
    return moment()
      .month(month)
      .endOf(MONTH)
      .format(DATE_FORMAT);
  };

  getDateFromLocation = location =>
    location.substr(location.lastIndexOf("/") + 1);

  getMonthAndYear = date => {
    const m = moment(date, DATE_FORMAT);
    return `${m.format(MONTH_FORMAT)} ${m.year()}`;
  };

  getReportName = () => {
    const {
      store: {
        apiUsage: { loading }
      }
    } = this.props;
    if (loading) {
      return "";
    }
    return this.getMonthAndYear(this.state.selected);
  };

  handleDownload = () => {
    const data = [];
    for (const row of this.state.currentData) {
      let item = {};
      for (const col of row) {
        item = { ...item, [col.key]: col.value };
      }
      data.push(item);
    }
    const fields = [];
    for (const field of this.state.currentData[0]) {
      fields.push(field.key);
    }
    const csv = parse(data, { fields });
    const blob = new Blob([csv]);
    const filename = moment(this.state.selected, DATE_FORMAT).format(
      DATE_FORMAT_FILE
    );
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blob);
  };

  handleSelected = e => {
    const {
      auth: { accessToken },
      getApiUsageByMonth
    } = this.props;
    const selected = e.target.value;
    this.setState(() => ({ selected, open: false }));
    getApiUsageByMonth({ accessToken, date: selected });
  };

  render() {
    const {
      store: {
        apiUsage: { loading }
      }
    } = this.props;
    return (
      <Fragment>
        <LayoutGrid>
          <LayoutGridCell span={12} spanTablet={12} spanPhone={12}>
            <Elevation z={10}>
              <Card className="cdk-api-usage__card">
                <DataTableToolbar>
                  <DataTableToolbarTitle>
                    <DataTableToolbarTitlePrimary className="cdk-api-usage__title-primary">
                      API Usage Report
                      {((loading && this.state.datesLoaded) || !loading) &&
                        this.state.authorized &&
                        this.state.dates.length > 0 && (
                          <Select
                            className="cdk-api-usage__select"
                            value={this.state.selected}
                            onChange={this.handleSelected}
                          >
                            {this.state.dates.map(item => (
                              <option
                                value={item}
                                key={`select-option-${item}`}
                              >
                                {this.getMonthAndYear(item)}
                              </option>
                            ))}
                          </Select>
                        )}
                    </DataTableToolbarTitlePrimary>
                  </DataTableToolbarTitle>
                  <DataTableToolbarActions>
                    {!loading && this.state.authorized && (
                      <DataTableToolbarAction>
                        <Icon
                          className="cdk-api-usage__action-icon"
                          onClick={this.handleDownload}
                        >
                          cloud_download
                        </Icon>
                      </DataTableToolbarAction>
                    )}
                  </DataTableToolbarActions>
                </DataTableToolbar>
                {loading && (
                  <div className="cdk-api-usage__spinner-wrapper">
                    <div className="cdk-api-usage__spinner">
                      <div className="cdk-api-usage__spinner-text">
                        Loading API Usage Report...
                      </div>
                      <CircularProgress />
                    </div>
                  </div>
                )}
                {!loading && !this.state.authorized && (
                  <div className="cdk-api-usage__spinner-wrapper">
                    <div className="cdk-api-usage__spinner-text cdk-api-usage__spinner-text--error">
                      <Icon>error</Icon>
                    </div>
                    &nbsp;
                    <div className="cdk-api-usage__spinner-text cdk-api-usage__spinner-text--error">
                      Not Authorized
                    </div>
                  </div>
                )}
                {!loading &&
                  this.state.authorized &&
                  this.state.currentData &&
                  this.state.currentData[0] && (
                    <DataTable className="cdk-api-usage__data-table">
                      <DataTableHeader>
                        <DataTableRow className="cdk-api-usage__header-row">
                          {this.state.currentData[0].map((item, i) => (
                            <DataTableHeaderCell
                              nonNumeric={isNaN(item.value)}
                              key={`header-${item.key}-${item.value}-${i}`}
                            >
                              {item.key}
                            </DataTableHeaderCell>
                          ))}
                        </DataTableRow>
                      </DataTableHeader>
                      {this.state.currentData && (
                        <DataTableBody>
                          {this.state.currentData.map((row, i) => (
                            <DataTableRow
                              key={`row-${row.key}-${row.value}-${i}`}
                            >
                              {row.map((item, i) => (
                                <DataTableCell
                                  nonNumeric={isNaN(item.value)}
                                  key={`col-${item.key}-${item.value}-${i}`}
                                >
                                  {item.value}
                                </DataTableCell>
                              ))}
                            </DataTableRow>
                          ))}
                        </DataTableBody>
                      )}
                    </DataTable>
                  )}
              </Card>
            </Elevation>
          </LayoutGridCell>
        </LayoutGrid>
      </Fragment>
    );
  }
}

ApiUsage = WithNavigationToHome(ApiUsage);
class ApiUsageContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <ApiUsage {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(ApiUsageContainer);
