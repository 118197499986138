import types from "./types";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import config from "../config/app.conf.json";
import moment from "moment";
const dateFormat = "YYYY-MM-DD";
const DAY = "day";

const buildRequestObj = ({ startDate, endDate }) => ({
  url: `${
    config.api.registrations_users
  }?startDate=${startDate}&endDate=${endDate}`,
  method: "GET"
});

const appRegistrationObj = ({ startDate, endDate }) => ({
  url: `${
    config.api.registrations_apps
  }?startDate=${startDate}&endDate=${endDate}`,
  method: "GET"
});

const allTnCsVersionObj = () => ({
  url: `${config.api.documents_service_url}/fortellis/tnc`,
  method: "GET"
});

const userCountOfAcceptedTncVersionObj = ({
  startDate,
  endDate,
  selectedVersion
}) => ({
  url: `${config.api.user_count}?startDate=${moment(startDate, dateFormat)
    .startOf(DAY)
    .toISOString()}&endDate=${moment(endDate, dateFormat)
    .endOf(DAY)
    .toISOString()}&accepted_tnc_version=${selectedVersion}`,
  method: "GET"
});

const totalUserCountObj = () => ({
  url: `${config.api.total_user_count}`,
  method: "GET"
});

export const actions = {
  fetchUserRegistrations: ({ accessToken, startDate, endDate }) => ({
    [CALL_API]: {
      ...buildRequestObj({ startDate, endDate }),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.REGISTRATIONS_GET_REQUEST,
        types.REGISTRATIONS_GET_RECEIVE,
        types.REGISTRATIONS_GET_ERROR
      ]
    }
  }),
  fetchAppRegistrations: ({ accessToken, startDate, endDate }) => ({
    [CALL_API]: {
      ...appRegistrationObj({ startDate, endDate }),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.APP_REGISTRATIONS_GET_REQUEST,
        types.APP_REGISTRATIONS_GET_RECEIVE,
        types.APP_REGISTRATIONS_GET_ERROR
      ]
    }
  }),
  fetchAllTermsAndConditionsVersions: ({ accessToken }) => ({
    [CALL_API]: {
      ...allTnCsVersionObj(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.TERMS_AND_CONDITIONS_VERSIONS_GET_REQUEST,
        types.TERMS_AND_CONDITIONS_VERSIONS_GET_RECEIVE,
        types.TERMS_AND_CONDITIONS_VERSIONS_GET_ERROR
      ]
    }
  }),
  fetchUserCount: ({ accessToken, startDate, endDate, selectedVersion }) => ({
    [CALL_API]: {
      ...userCountOfAcceptedTncVersionObj({
        startDate,
        endDate,
        selectedVersion
      }),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.USERS_COUNT_GET_REQUEST,
        types.USERS_COUNT_GET_RECEIVE,
        types.USERS_COUNT_GET_ERROR
      ]
    }
  }),

  fetchTotalUserCount: ({ accessToken }) => ({
    [CALL_API]: {
      ...totalUserCountObj(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      types: [
        types.TOTAL_USERS_COUNT_GET_REQUEST,
        types.TOTAL_USERS_COUNT_GET_RECEIVE,
        types.TOTAL_USERS_COUNT_GET_ERROR
      ]
    }
  })
};
